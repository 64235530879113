import {Component, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DomSanitizer, SafeStyle} from "@angular/platform-browser";

@Component({
    selector: 'cs-dropped-file-image',
    standalone: false,
    template: '<ng-content></ng-content>',
    styleUrls: ['./dropped-file-image.component.scss']
})
export class DroppedFileImageComponent implements OnChanges {
    private url: string = '';

    @Input()
    public file: File | undefined;

    constructor(
        private sanitizer: DomSanitizer
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.file) {
            this.url = URL.createObjectURL(this.file);
        }
    }

    @HostBinding(`style.background-image`)
    private get backgroundImage(): SafeStyle {
        if (this.file) {
            if (this.url) {
                return this.sanitizer.bypassSecurityTrustStyle(`url(${this.url})`);
            } else {
                return this.sanitizer.bypassSecurityTrustStyle(`url(/assets/image/default/image-not-found.png)`);
            }
        } else {
            return this.sanitizer.bypassSecurityTrustStyle(`url(/assets/image/default/image-not-found.png)`);
        }
    }

}
