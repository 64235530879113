@if (book) {
    <div class="w-full px-2">
        <div #trigger class="w-full h-12 sticky top-0">
            <mat-card class="w-full h-full" appearance="outlined">
                <div class="w-full flex-grow h-0 flex flex-row justify-between items-center">
                    <div class="w-full flex flex-row justify-between items-center">
                        <div class="w-24 h-full px-2 flex flex-row justify-start items-center">
                            <button class="flex-grow-0" mat-icon-button (click)="onOpenMenu()"><mat-icon fontIcon="menu_book"></mat-icon></button>
                        </div>
                        <div class="flex-grow flex flex-row justify-center items-center">
                            <span *ngIf="selectedArticle"><strong>{{ selectedArticle.name }}</strong></span>
                        </div>
                        <div class="w-24 h-full px-2 flex flex-row justify-end items-center">
                            <button class="flex-grow-0 me-1" mat-icon-button [disabled]="!hasPreviousArticle()" (click)="onPreviousArticle()"><mat-icon fontIcon="keyboard_arrow_left"></mat-icon></button>
                            <button class="flex-grow-0 ms-1" mat-icon-button [disabled]="!hasNextArticle()" (click)="onNextArticle()"><mat-icon fontIcon="keyboard_arrow_right"></mat-icon></button>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
        <div class="w-full p-2">
            <cs-markdown-viewer class="w-full" [value]="selectedArticle.content" *ngIf="selectedArticle"></cs-markdown-viewer>
        </div>
        @if (menuOpened) {
            <ng-template cdkConnectedOverlay cdkConnectedOverlayWidth="100%" cdkConnectedOverlayHeight="100%" [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayPositions]="[{
            offsetX: 0,
            offsetY: -trigger.getBoundingClientRect().top,
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'top'
        }]" [cdkConnectedOverlayOpen]="menuOpened">
                <div [@fadeInExpandOnEnter] [@fadeOutCollapseOnLeave] class="w-full h-full flex flex-row justify-start items-stretch">
                    <div class="w-10/12 sm:w-10/12 md:w-96 lg:w-96 xl:w-96 2xl:w-96 h-full flex flex-col justify-start items-stretch">
                        <div class="w-full flex-grow-0" [style.height.px]="trigger.getBoundingClientRect().bottom" (click)="onCloseMenu()"></div>
                        <div class="w-full flex-grow h-0">
                            <mat-card class="w-full h-full" appearance="outlined">
                                <mat-card-content class="w-full flex-grow flex flex-col justify-start items-stretch">
                                    @for(bookIndexes of bookIndexesLayers; track $index) {
                                        @if ($index > 0 && bookIndexes.length) {
                                            <mat-form-field appearance="outline">
                                                <mat-select [(ngModel)]="selectedBookIndexes[$index]">
                                                    @for(bookIndex of bookIndexes; track bookIndex.id) {
                                                        <mat-option class="w-full" [value]="bookIndex" (click)="listIndex(bookIndex)">
                                                            @if (book.name === BookName.DEFAULT_BOOK) {
                                                                @switch (bookIndex.layer) {
                                                                    @case (1) { {{ bookIndex.createTime | date: 'yyyy' }} }
                                                                    @case (2) { {{ bookIndex.createTime | date: 'MM' }} }
                                                                    @case (3) { {{ bookIndex.createTime | date: 'dd' }} }
                                                                }
                                                            } @else {
                                                                {{ bookIndex.name }}
                                                            }
                                                        </mat-option>
                                                    }
                                                </mat-select>
                                            </mat-form-field>
                                        }
                                    }
                                    @if (selectedBookIndexes[selectedBookIndexes.length - 1] && selectedBookIndexes[selectedBookIndexes.length - 1].leaf) {
                                        <mat-form-field appearance="outline">
                                            <mat-select [(ngModel)]="selectedArticle" (selectionChange)="onCloseMenu()">
                                                @for (article of articles; track article.id) {
                                                    <mat-option class="w-full" [value]="article">{{ article.name }}</mat-option>
                                                }
                                            </mat-select>
                                        </mat-form-field>
                                    }
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <div class="flex-grow h-full w-0" (click)="onCloseMenu()"></div>
                </div>
            </ng-template>
        }
    </div>
}
