import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AudioPlayerDefaultVisualizer} from "./visualizer/audio-player-default-visualizer";
import {AudioPlayerService} from "../audio-player/audio-player.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {AudioPlayerVisualizer} from "./visualizer/audio-player-visualizer";
import {EnhancementAudio} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-audio";

@Component({
    selector: 'cs-audio-player-visualization',
    standalone: false,
    templateUrl: './audio-player-visualization.component.html',
    styleUrls: ['./audio-player-visualization.component.scss']
})
export class AudioPlayerVisualizationComponent implements OnInit, OnDestroy {
    private readonly resizeObserver: ResizeObserver = new ResizeObserver(() => {
        this.onResize();
    });

    @ViewChild('target', {static: true})
    private target: ElementRef<HTMLDivElement> | undefined;

    private audioPlayerVisualizers: AudioPlayerVisualizer[] = [];

    constructor(
        private audioPlayerService: AudioPlayerService
    ) {
        this.audioPlayerService.onPlayingAudioChanged().pipe(takeUntilDestroyed()).subscribe((audio: EnhancementAudio | undefined) => {
            if (audio) {
                this.audioPlayerVisualizers.forEach((audioPlayerVisualizer: AudioPlayerVisualizer) => {
                    audioPlayerVisualizer.updateAudio(audio);
                });
            }
        });
        this.audioPlayerService.onFrequencyBinChanged().pipe(takeUntilDestroyed()).subscribe((bin: Uint8Array) => {
            this.audioPlayerVisualizers.forEach((audioPlayerVisualizer: AudioPlayerVisualizer) => {
                audioPlayerVisualizer.updateData(bin);
            });
        });
        this.audioPlayerService.onPlaybackStarted().pipe(takeUntilDestroyed()).subscribe((audio: EnhancementAudio) => {
            this.audioPlayerVisualizers.forEach((audioPlayerVisualizer: AudioPlayerVisualizer) => {
                audioPlayerVisualizer.start(audio);
            });
        });
        this.audioPlayerService.onPlaybackPaused().pipe(takeUntilDestroyed()).subscribe((audio: EnhancementAudio) => {
            this.audioPlayerVisualizers.forEach((audioPlayerVisualizer: AudioPlayerVisualizer) => {
                audioPlayerVisualizer.pause(audio);
            });
        });
        this.audioPlayerService.onPlaybackResumed().pipe(takeUntilDestroyed()).subscribe((audio: EnhancementAudio) => {
            this.audioPlayerVisualizers.forEach((audioPlayerVisualizer: AudioPlayerVisualizer) => {
                audioPlayerVisualizer.resume(audio);
            });
        });
        this.audioPlayerService.onPlaybackEnded().pipe(takeUntilDestroyed()).subscribe((audio: EnhancementAudio) => {
            this.audioPlayerVisualizers.forEach((audioPlayerVisualizer: AudioPlayerVisualizer) => {
                audioPlayerVisualizer.end(audio);
            });
        });
    }

    ngOnInit(): void {
        if (this.target) {
            this.resizeObserver.observe(this.target.nativeElement);
            this.audioPlayerVisualizers = [new AudioPlayerDefaultVisualizer(this.target)];
            this.audioPlayerVisualizers.forEach((audioPlayerVisualizer: AudioPlayerVisualizer) => {
                audioPlayerVisualizer.onCreate();
            });
            this.audioPlayerVisualizers.forEach((audioPlayerVisualizer: AudioPlayerVisualizer) => {
                const audio: EnhancementAudio | undefined = this.audioPlayerService.audio();
                if (audio) {
                    audioPlayerVisualizer.updateAudio(audio);
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.audioPlayerVisualizers.forEach((audioPlayerVisualizer: AudioPlayerVisualizer) => {
            audioPlayerVisualizer.onDestroy();
        });
        this.resizeObserver.disconnect();
    }

    private onResize(): void {
        this.audioPlayerVisualizers.forEach((audioPlayerVisualizer: AudioPlayerVisualizer) => {
            audioPlayerVisualizer.resize();
        });
    }

}
