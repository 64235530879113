import {Component} from '@angular/core';
// import {AudioPlayerService} from "../../core/component/subject/audio/audio-player/audio-player.component.service";
import {AuthPageComponentService} from "./auth-page.component.service";
import {CsCommonsModule} from "../../core/module/commons/cs.commons.module";

@Component({
    selector: 'cs-auth-page',
    standalone: true,
    templateUrl: './auth-page.component.html',
    imports: [
        CsCommonsModule
    ],
    styleUrls: ['./auth-page.component.scss']
})
export class AuthPageComponent {

    constructor(
        private authPageComponentService: AuthPageComponentService
        // private audioPlayerService: AudioPlayerService,
    ) {
        // this.audioPlayerService.pause();
    }

}
