<div class="w-full">
    <mat-form-field class="w-full" appearance="outline">
        <input matInput type="text" [formControl]="keyword" placeholder="{{ 'Page.MePage.MeImageCollectionPage.MeImageCollectionListPage.TITLE' | translate }}">
        <button matSuffix mat-icon-button (click)="loadNext(true)">
            <mat-icon fontIcon="search"></mat-icon>
        </button>
    </mat-form-field>
</div>
<cs-media-collection-grid-viewer class="w-full" [mediaCollections]="pageContent.values()" (mediaCollectionClick)="onMediaCollectionClick($event)"></cs-media-collection-grid-viewer>

<button class="fixed" [style.bottom.px]="16" [style.right.px]="16" mat-fab (click)="onCreate()">
    <mat-icon>add</mat-icon>
</button>
