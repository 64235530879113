import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SortablePagination} from "../../../model/commons/sortablePagination";
import {Page} from "../../../model/commons/page";
import {VideoUpdateRequest} from "../../../model/request/video-update-request";
import {Service} from '../service';
import {EnhancementVideo} from "../../../model/data/persist/jpa/entity/enhancement/enhancement-video";

@Injectable({
    providedIn: 'root'
})
export class MeVideoService {

    constructor(
        private http: HttpClient
    ) {
    }

    upload(file: File): Observable<HttpEvent<EnhancementVideo>> {
        const formData: FormData = new FormData();
        formData.append("file", file);
        return this.http.post<EnhancementVideo>(`${Service.Media.prefixUrl}/me/media/video/upload`, formData, {
            reportProgress: true,
            observe: "events"
        });
    }

    page(pagination: SortablePagination): Observable<Page<EnhancementVideo>> {
        return this.http.get<Page<EnhancementVideo>>(`${Service.Media.prefixUrl}/me/media/video${pagination.toRequestParameter()}`);
    }

    pageByMediaCollection(mediaCollectionId: number, pagination: SortablePagination): Observable<Page<EnhancementVideo>> {
        return this.http.get<Page<EnhancementVideo>>(`${Service.Media.prefixUrl}/me/media/video/collection/${mediaCollectionId}${pagination.toRequestParameter()}`);
    }

    load(id: number): Observable<EnhancementVideo> {
        return this.http.get<EnhancementVideo>(`${Service.Media.prefixUrl}/me/media/video/${id}`);
    }

    update(id: number, request: VideoUpdateRequest): Observable<EnhancementVideo> {
        return this.http.put<EnhancementVideo>(`${Service.Media.prefixUrl}/me/media/video/${id}`, request);
    }

    updateCoverImage(id: number, coverImageId: number): Observable<EnhancementVideo> {
        return this.http.put<EnhancementVideo>(`${Service.Media.prefixUrl}/me/media/video/${id}/cover/${coverImageId}`, '');
    }

    delete(id: number): Observable<EnhancementVideo> {
        return this.http.delete<EnhancementVideo>(`${Service.Media.prefixUrl}/me/media/video/${id}`);
    }

    search(keyword: string, pagination: SortablePagination): Observable<Page<EnhancementVideo>> {
        if (keyword) {
            return this.http.get<Page<EnhancementVideo>>(`${Service.Search.prefixUrl}/me/media/video${pagination.toRequestParameter()}&keyword=${keyword}`);
        } else {
            return this.http.get<Page<EnhancementVideo>>(`${Service.Search.prefixUrl}/me/media/video${pagination.toRequestParameter()}`);
        }
    }

}
