import {AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
    selector: '[csScrollTracker]'
})
export class ScrollTrackerDirective implements AfterViewInit {
    private startPosition: number = 0;

    @Output()
    public scrollToTop: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    public scrollToBottom: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public scrolling: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    public scrollUp: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    public scrollDown: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private ref: ElementRef
    ) {
    }

    ngAfterViewInit(): void {
        this.scrollToTop.emit();
    }

    @HostListener('scroll', ['$event'])
    protected onScroll(event: any): void {
        if (this.ref.nativeElement.scrollTop + this.ref.nativeElement.offsetHeight >= this.ref.nativeElement.scrollHeight) {
            this.scrollToBottom.emit();
        }

        if (this.ref.nativeElement.scrollTop === 0) {
            this.scrollToTop.emit();
        }

        this.scrolling.emit();
        if (this.startPosition <= event.target.offsetHeight + event.target.scrollTop) {
            this.scrollDown.emit();
        } else {
            this.scrollUp.emit();
        }
        this.startPosition = event.target.offsetHeight + event.target.scrollTop;
    }

}
