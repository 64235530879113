import {Inject, Injectable} from '@angular/core';
import {Theme} from "../../../model/theme";
import {DOCUMENT} from "@angular/common";

@Injectable()
export class ThemeService {

    constructor(
        @Inject(DOCUMENT) private document: Document
    ) {
    }

    changeTheme(theme: Theme): void {
        if (theme === Theme.auto) {
            this.document.documentElement.removeAttribute('theme');
        } else {
            this.document.documentElement.setAttribute('theme', theme);
        }
    }

}
