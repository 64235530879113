<div class="w-full h-full relative">
    <div class="w-full h-full absolute">
        @if (blur) {
            @if (size === ImageBackgroundSize.Cover) {
                <cs-image class="w-full h-full bg-center bg-cover bg-no-repeat blur shadow-inner" [image]="image" [resolution]="resolution"></cs-image>
            } @else if (size === ImageBackgroundSize.Contain) {
                <cs-image class="w-full h-full bg-center bg-contain bg-no-repeat blur shadow-inner" [image]="image" [resolution]="resolution"></cs-image>
            }
        } @else {
            @if (size === ImageBackgroundSize.Cover) {
                <cs-image class="w-full h-full bg-center bg-cover" [image]="image" [resolution]="resolution"></cs-image>
            } @else if (size === ImageBackgroundSize.Contain) {
                <cs-image class="w-full h-full bg-center bg-contain bg-no-repeat" [image]="image" [resolution]="resolution"></cs-image>
            }
        }
    </div>
    @if (dark) {
        <div class="w-full h-full absolute bg-black opacity-50"></div>
    }
    <div class="w-full h-full absolute">
        <ng-content></ng-content>
    </div>
</div>
