export interface Page<T> {
    content: T[];
    empty: boolean;
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    size: number;
    totalElements: number;
    totalPages: number;
}

export class PageContent<T> {
    private items: T[] = [];
    private index: { [key: string | number]: T } = {};

    private readonly toIndex: (item: T) => string | number;
    private readonly sort: (left: T, right: T) => number;

    constructor(toIndex: (item: T) => (string | number), sort: (left: T, right: T) => number = (left: T, right: T) => {
        return 1;
    }) {
        this.toIndex = toIndex;
        this.sort = sort;
    }

    reset(): void {
        this.items = [];
        this.index = {};
    }

    add(item: T): void {
        this.addAll([item]);
    }

    remove(item: T): void {
        if (this.index[this.toIndex(item)]) {
            this.items = this.items.filter((existitem: T) => {
                return this.toIndex(item) !== this.toIndex(existitem);
            });
            delete this.index[this.toIndex(item)];
        }
    }

    update(item: T): boolean {
        if (this.index[this.toIndex(item)]) {
            // Object.assign(this.items[this.items.indexOf(this.index[this.toIndex(item)])] as any, item);
            this.items[this.items.indexOf(this.index[this.toIndex(item)])] = item;
            this.index[this.toIndex(item)] = item;
            return true;
        } else {
            return false;
        }
    }

    addAll(items: T[]): void {
        items.forEach((item: T) => {
            if (this.index[this.toIndex(item)]) {
                this.items[this.items.indexOf(this.index[this.toIndex(item)])] = item;
            } else {
                this.items.push(item);
            }
            this.index[this.toIndex(item)] = item;
        });
        this.items = this.items.sort(this.sort);
    }

    contains(item: T): boolean {
        return this.items.includes(item);
    }

    values(): T[] {
        return this.items;
    }

    get(i: number): T {
        return this.items[i];
    }

    first(): T | undefined {
        if (this.items.length) {
            return this.items[0];
        } else {
            return undefined;
        }
    }

    last(): T | undefined {
        if (this.items.length) {
            return this.items[this.items.length - 1];
        } else {
            return undefined;
        }
    }

    indexOf(t: T): number {
        return this.items.map(this.toIndex).indexOf(this.toIndex(t));
    }

    size(): number {
        return this.items.length;
    }

    isEmpty(): boolean {
        return this.items.length === 0;
    }

}
