import {Component} from '@angular/core';

@Component({
    selector: 'cs-copyright',
    standalone: false,
    templateUrl: './copyright.component.html',
    styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent {
    protected now: Date = new Date();
}
