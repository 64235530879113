import {ModuleWithProviders, NgModule} from '@angular/core';
import {ThemeService} from "./theme.service";

@NgModule()
export class CsThemeModule {

    static forRoot(): ModuleWithProviders<CsThemeModule> {
        return {
            ngModule: CsThemeModule,
            providers: [
                {
                    provide: ThemeService
                }
            ]
        };
    }

}
