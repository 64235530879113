import { Registry } from '../../registry/common/platform.js';
//#region Editor / Resources DND
export const CodeDataTransfers = {
  EDITORS: 'CodeEditors',
  FILES: 'CodeFiles'
};
class DragAndDropContributionRegistry {}
export const Extensions = {
  DragAndDropContribution: 'workbench.contributions.dragAndDrop'
};
Registry.add(Extensions.DragAndDropContribution, new DragAndDropContributionRegistry());
//#endregion
//#region DND Utilities
/**
 * A singleton to store transfer data during drag & drop operations that are only valid within the application.
 */
export class LocalSelectionTransfer {
  static {
    this.INSTANCE = new LocalSelectionTransfer();
  }
  constructor() {
    // protect against external instantiation
  }
  static getInstance() {
    return LocalSelectionTransfer.INSTANCE;
  }
  hasData(proto) {
    return proto && proto === this.proto;
  }
  getData(proto) {
    if (this.hasData(proto)) {
      return this.data;
    }
    return undefined;
  }
}
//#endregion