import {ActivatedRoute, RouterOutlet} from "@angular/router";
import {Component, ComponentRef, Directive, HostListener} from "@angular/core";
import {timer} from "rxjs";

export interface OnAttach {
    ngOnAttach(): void;
}

export interface OnDetach {
    ngOnDetach(): void;
}

@Component({
    template: ''
})
export abstract class ScrollRestorableComponent implements OnAttach, OnDetach {
    private selectedElement: HTMLElement | undefined;

    @HostListener('click', ['$event'])
    protected onClick(event: MouseEvent): void {
        this.selectedElement = event.target as HTMLElement;
    }

    ngOnAttach(): void {
        if (this.selectedElement) {
            this.selectedElement.scrollIntoView({
                behavior: 'instant',
                block: 'center',
                inline: 'nearest'
            });
        }
    }

    ngOnDetach(): void {
    }
}

@Directive({
    selector: 'enhanced-router-outlet',
})
export class EnhancedRouterOutlet extends RouterOutlet {

    public override attach(ref: ComponentRef<any>, activatedRoute: ActivatedRoute): void {
        super.attach(ref, activatedRoute);
        timer(32).subscribe(() => {
            if (ref.instance && typeof ref.instance['ngOnAttach'] === 'function') {
                ref.instance['ngOnAttach'](ref, activatedRoute);
            }
        });
    }

    public override detach(): ComponentRef<any> {
        const instance: any = this.component;
        if (instance && typeof instance['ngOnDetach'] === 'function') {
            instance['ngOnDetach']();
        }
        return super.detach();
    }

}
