import {Component} from '@angular/core';
import {EnhancementAudio} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-audio";
import {MediaGridViewerComponent} from "../../media/media-grid-viewer/media-grid-viewer.component";

@Component({
    selector: 'cs-audio-grid-viewer',
    standalone: false,
    templateUrl: '../../media/media-grid-viewer/media-grid-viewer.component.html',
    styleUrls: ['../../media/media-grid-viewer/media-grid-viewer.component.scss']
})
export class AudioGridViewerComponent extends MediaGridViewerComponent<EnhancementAudio> {

}
