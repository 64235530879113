<mat-card class="w-full h-full">
    <mat-card-header class="w-full flex-grow-0">
        <mat-icon fontIcon="reset_image"></mat-icon>{{ 'Core.Component.Media.Image.Dialog.MeImageEditDialog.TITLE' | translate }}
    </mat-card-header>
    <mat-card-content class="w-full flex-grow h-0 grid grid-rows-12 grid-cols-12 gap-1">
        <div class="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-8 xl:col-span-8 2xl:col-span-9 row-span-6 sm:row-span-6 md:row-span-12 lg:row-span-12 xl:row-span-12 2xl:row-span-12">
            <mat-card class="w-full h-full" appearance="outlined">
                <mat-card-content class="w-full flex-grow h-0 flex flex-row justify-center items-center">
                    <image-cropper class="w-full h-full"
                                   format="png"
                                   output="blob"
                                   [imageQuality]="100"
                                   [imageFile]="image"
                                   [maintainAspectRatio]="!!ImageTypeAspectTable[type]"
                                   [resizeToHeight]="0"
                                   [aspectRatio]="ImageTypeAspectTable[type]"
                                   (imageCropped)="imageCropped($event)"></image-cropper>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-4 2xl:col-span-3 row-span-6 sm:row-span-6 md:row-span-12 lg:row-span-12 xl:row-span-12 2xl:row-span-12">
            <mat-card class="w-full h-full" appearance="outlined">
                <div  class="w-full h-full bg-no-repeat bg-center bg-contain" [style.background-image]="'url(' + this.croppedImageUrl + ')'" *ngIf="croppedImageUrl"></div>
            </mat-card>
        </div>
    </mat-card-content>
    <mat-card-actions class="w-full flex flex-row justify-end items-center">
        <button class="me-1" mat-flat-button (click)="onSubmit()"><mat-icon fontIcon="check"></mat-icon>{{ 'COMMONS.CONFIRM' | translate }}</button>
        <button class="ms-1" mat-flat-button (click)="onClose()"><mat-icon fontIcon="close"></mat-icon>{{ 'COMMONS.CANCEL' | translate }}</button>
    </mat-card-actions>
</mat-card>
