import {Component} from '@angular/core';
import {EnhancementVideo} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-video";
import {MediaCardComponent} from "../../media/media-card/media-card.component";

@Component({
    selector: 'cs-video-card',
    standalone: false,
    templateUrl: '../../media/media-card/media-card.component.html',
    styleUrls: ['../../media/media-card/media-card.component.scss']
})
export class VideoCardComponent  extends MediaCardComponent<EnhancementVideo> {

}
