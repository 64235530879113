import {SimpleUser} from "./user";
import {VisibleScope} from "./visible-scope";
import {JpaAuditIdentifiable4Long} from "../jpa-audit-identifiable-4-long";

export enum SubjectType {
    Audio = 'Audio',
    Video = 'Video',
    Image = 'Image',
    Book = 'Book',
    BookArticle = 'BookArticle',
    TopicBook = 'TopicBook',
    TopicBookArticle = 'TopicBookArticle'
}

export enum MediaCategory {
    Image = 'Image',
    Audio = 'Audio',
    Video = 'Video',
    Book = 'Book',
    Article = 'Article'
}

export interface Media extends JpaAuditIdentifiable4Long {
    simpleUser: SimpleUser;

    category: MediaCategory;

    name: string;
    description: string;

    visibleScope: VisibleScope;

    deletable: boolean;
    updatable: boolean;
}
