import {Component, OnDestroy, OnInit} from '@angular/core';
import {SortablePagination} from "../../../../core/model/commons/sortablePagination";
import {Page, PageContent} from "../../../../core/model/commons/page";
import {PermissionMode} from "../../../../core/service/data/permission-mode";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxPermissionsService} from "ngx-permissions";
import {HomePageComponentService} from "../../home-page.component.service";
import {PublicBookService} from "../../../../core/service/data/public/public-book.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {CommonModule} from "@angular/common";
import {CsCommonsModule} from "../../../../core/module/commons/cs.commons.module";
import {CsMediaModule} from "../../../../core/module/component/media/cs.media.module";
import {EnhancementBook} from "../../../../core/model/data/persist/jpa/entity/enhancement/enhancement-book";
import {OnAttach, OnDetach} from "../../../../core/module/commons/route/enhanced-router-outlet";
import {ImageType, ImageTypeAspectRatioTable} from "../../../../core/model/data/persist/jpa/entity/image";
import {EnhancementMediaCollection} from "../../../../core/model/data/persist/jpa/entity/media-collection";
import {MeService} from "../../../../core/service/data/me/me.service";
import {PublicMediaCollectionService} from "../../../../core/service/data/public/public-media-collection.service";
import {SimpleMe} from "../../../../core/model/data/persist/jpa/entity/user";
import {Duration} from "../../../../core/model/data/utils/time-range";
import {MediaCategory} from "../../../../core/model/data/persist/jpa/entity/media";
import {MatIconModule} from "@angular/material/icon";
import {MatToolbarModule} from "@angular/material/toolbar";

@Component({
    selector: 'cs-home-book-discovery-page',
    standalone: true,
    templateUrl: './home-book-discovery-page.component.html',
    styleUrls: ['./home-book-discovery-page.component.scss'],
    imports: [
        CommonModule,
        MatIconModule,
        MatToolbarModule,
        CsCommonsModule,
        CsMediaModule
    ]
})
export class HomeBookDiscoveryPageComponent implements OnInit, OnDestroy, OnAttach, OnDetach {
    protected readonly PermissionMode = PermissionMode;
    protected readonly ImageTypeAspectTable = ImageTypeAspectRatioTable;
    protected readonly ImageType = ImageType;

    protected hottestBookInDayPagination: SortablePagination = new SortablePagination(0, 12);
    protected hottestBookInDayPage: Page<EnhancementBook> | undefined;
    protected hottestBookInDayPageContent: PageContent<EnhancementBook> = new PageContent<EnhancementBook>((media: EnhancementBook) => {
        return media.id;
    });

    protected hottestBookInWeekPagination: SortablePagination = new SortablePagination(0, 6);
    protected hottestBookInWeekPage: Page<EnhancementBook> | undefined;
    protected hottestBookInWeekPageContent: PageContent<EnhancementBook> = new PageContent<EnhancementBook>((media: EnhancementBook) => {
        return media.id;
    });

    protected hottestMediaCollectionInWeekPagination: SortablePagination = new SortablePagination(0, 6);
    protected hottestMediaCollectionInWeekPage: Page<EnhancementMediaCollection> | undefined;
    protected hottestMediaCollectionInWeekPageContent: PageContent<EnhancementMediaCollection> = new PageContent<EnhancementMediaCollection>((media: EnhancementMediaCollection) => {
        return media.id;
    });

    protected recommendBookPagination: SortablePagination = new SortablePagination(0, 6);
    protected recommendBookPage: Page<EnhancementBook> | undefined;
    protected recommendBookPageContent: PageContent<EnhancementBook> = new PageContent<EnhancementBook>((media: EnhancementBook) => {
        return media.id;
    });

    private isLogin: boolean = false;

    constructor(
        private homeComponentService: HomePageComponentService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private permissionsService: NgxPermissionsService,
        private translateService: TranslateService,
        private meService: MeService,
        private publicBookService: PublicBookService,
        private publicMediaCollectionService: PublicMediaCollectionService
    ) {
        this.homeComponentService.onScrollToBottom().pipe(takeUntilDestroyed()).subscribe(() => {
            this.loadNextRecommendationBook();
        });

        this.meService.onMeChanged().pipe(takeUntilDestroyed()).subscribe((me: SimpleMe | undefined) => {
            const isLogin: boolean = !!me;
            if (this.isLogin !== isLogin) {
                this.isLogin = isLogin;
                // this.loadNextRecommendation(true);
            }
        });
    }

    ngOnInit(): void {
        this.loadNextHottestBookInDay();
        this.loadNextHottestBookInWeek()
        this.loadNextHottestMediaCollectionInWeek();
        // this.loadNextRecommendation();
    }

    ngOnAttach(): void {
    }

    ngOnDetach(): void {
    }

    ngOnDestroy(): void {
    }

    private loadNextHottestBookInDay(reset: boolean = false): void {
        if (reset) {
            this.hottestBookInDayPagination.reset();
            this.hottestBookInDayPage = undefined;
            this.hottestBookInDayPageContent.reset();
        }

        if (!this.hottestBookInDayPage || this.hottestBookInDayPage && !this.hottestBookInDayPage.last) {
            this.publicBookService.pageHottest(this.hottestBookInDayPagination, Duration.OneDay).subscribe((page: Page<EnhancementBook>) => {
                this.hottestBookInDayPagination.page++;

                this.hottestBookInDayPage = page;
                this.hottestBookInDayPageContent.addAll(this.hottestBookInDayPage.content);
            });
        }
    }

    private loadNextHottestBookInWeek(reset: boolean = false): void {
        if (reset) {
            this.hottestBookInWeekPagination.reset();
            this.hottestBookInWeekPage = undefined;
            this.hottestBookInWeekPageContent.reset();
        }

        if (!this.hottestBookInWeekPage || this.hottestBookInWeekPage && !this.hottestBookInWeekPage.last) {
            this.publicBookService.pageHottest(this.hottestBookInWeekPagination, Duration.OneWeek).subscribe((page: Page<EnhancementBook>) => {
                this.hottestBookInWeekPagination.page++;

                this.hottestBookInWeekPage = page;
                this.hottestBookInWeekPageContent.addAll(this.hottestBookInWeekPage.content);
            });
        }
    }

    private loadNextHottestMediaCollectionInWeek(reset: boolean = false): void {
        if (reset) {
            this.hottestMediaCollectionInWeekPagination.reset();
            this.hottestMediaCollectionInWeekPage = undefined;
            this.hottestMediaCollectionInWeekPageContent.reset();
        }

        if (!this.hottestMediaCollectionInWeekPage || this.hottestMediaCollectionInWeekPage && !this.hottestMediaCollectionInWeekPage.last) {
            this.publicMediaCollectionService.pageHottestByCategory(MediaCategory.Book, this.hottestMediaCollectionInWeekPagination, Duration.OneWeek).subscribe((page: Page<EnhancementMediaCollection>) => {
                this.hottestMediaCollectionInWeekPagination.page++;

                this.hottestMediaCollectionInWeekPage = page;
                this.hottestMediaCollectionInWeekPageContent.addAll(this.hottestMediaCollectionInWeekPage.content);
            });
        }
    }

    private loadNextRecommendationBook(reset: boolean = false): void {
        if (reset) {
            this.recommendBookPagination.reset();
            this.recommendBookPage = undefined;
            this.recommendBookPageContent.reset();
        }

        if (!this.recommendBookPage || this.recommendBookPage && !this.recommendBookPage.last) {
            this.publicBookService.pageRecommendation(this.recommendBookPagination).subscribe((page: Page<EnhancementBook>) => {
                this.recommendBookPagination.page++;

                this.recommendBookPage = page;
                this.recommendBookPageContent.addAll(this.recommendBookPage.content);
            });
        }
    }

    protected onBookClick(book: EnhancementBook): void {
        this.router.navigate([`/book/${book.id}`]).then();
    }

    protected onMediaCollectionClick(mediaCollection: EnhancementMediaCollection): void {
        this.router.navigate([`/media-collection/${mediaCollection.id}`]).then();
    }

}

