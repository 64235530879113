import Konva from "konva";
import {AudioPlayerVisualizer} from "./audio-player-visualizer";
import {IFrame} from "konva/lib/types";
import {ImageResolution} from "../../../../../../../model/data/persist/jpa/entity/image-file";
import {EnhancementAudio} from "../../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-audio";

export class AudioPlayerDefaultVisualizer extends AudioPlayerVisualizer {
    private readonly layer: Konva.Layer = new Konva.Layer();

    private coverImageSize: number = 180;
    private coverImage: Konva.Image | undefined;
    private coverImageAnimation: Konva.Animation = new Konva.Animation((frame: IFrame | undefined) => {
        if (frame && this.coverImage) {
            const angleDiff: number = (frame.timeDiff * 30) / 1000;
            this.coverImage.rotate(angleDiff);
        }
    }, this.layer)

    private waveLines: Konva.Line[] = [];
    private waveTails: Konva.Rect[] = [];

    override onCreate(): void {
        super.onCreate();
        this.coverImageAnimation.start();
    }

    override onDestroy(): void {
        if (this.coverImageAnimation) {
            this.coverImageAnimation.stop();
        }

        super.onDestroy();
    }

    protected override onInflate(stage: Konva.Stage): void {
        stage.add(this.layer);
    }

    protected override onUpdateAudio(audio: EnhancementAudio): void {
        Konva.Image.fromURL(audio.coverImage ? `/service-media/stream/image/${audio.coverImage.id}/resolution/${ImageResolution.Small}` : '/assets/image/default/image-not-found.png', (coverImage: Konva.Image) => {
            if (this.coverImage) {
                this.coverImage.destroy();
                this.layer.removeName(this.coverImage.name())
            }

            this.coverImage = coverImage;
            this.coverImage.setAttrs({
                x: this.obtainCenterX(),
                y: this.obtainCenterY(),
                width: this.coverImageSize,
                height: this.coverImageSize,
                cropX: this.coverImage.width() > this.coverImage.height() ? (this.coverImage.width() - this.coverImage.height()) / 2 : 0,
                cropY: this.coverImage.width() > this.coverImage.height() ? 0 : (this.coverImage.height() - this.coverImage.width()) / 2,
                cropWidth: this.coverImage.width() > this.coverImage.height() ? this.coverImage.height() : this.coverImage.height(),
                cropHeight: this.coverImage.width() > this.coverImage.height() ? this.coverImage.height() : this.coverImage.height(),
                offsetX: this.coverImageSize / 2,
                offsetY: this.coverImageSize / 2,
                cornerRadius: this.coverImageSize / 2,
                opacity: 1
            });

            this.layer.add(this.coverImage);
        });
    }

    protected override onUpdateData(bin: Uint8Array): void {
        if (bin.length) {
            const radius: number = this.coverImageSize / 2;

            const angleSegment: number = Math.PI * 2 / bin.length;
            for (let i: number = 0; i < bin.length; i++) {
                const angle: number = angleSegment * i;
                const height: number = bin[i] / 255 * radius;

                const points: number[] = [
                    this.obtainCenterX() + Math.cos(angle) * radius,
                    this.obtainCenterY() + Math.sin(angle) * radius,
                    this.obtainCenterX() + Math.cos(angle) * (radius + height),
                    this.obtainCenterY() + Math.sin(angle) * (radius + height)
                ];

                if (this.waveLines[i]) {
                    this.waveLines[i].setAttrs({
                        points: points
                    });
                } else {
                    const line: Konva.Line = new Konva.Line({
                        points: points,
                        file: 'white',
                        stroke: 'white',
                        strokeWidth: Math.PI * 2 * radius / bin.length,
                        lineCap: 'butt',
                        lineJoin: 'round',
                        dash: [2],
                        opacity: 1,
                        shadowColor: 'red',
                        shadowBlur: Math.PI * 2 * radius / bin.length,
                        shadowOpacity: 0.25,
                    });
                    this.waveLines.push(line);

                    this.layer.add(line);
                }

                if (this.waveTails[i]) {
                    const oldRadius: number = Math.sqrt(Math.pow(this.waveTails[i].x() - this.obtainCenterX(), 2) + Math.pow(this.waveTails[i].y() - this.obtainCenterY(), 2));
                    const newRadius: number = radius + height;
                    if (oldRadius < newRadius) {
                        const tailHighestPointX: number = this.obtainCenterX() + Math.cos(angle) * (radius + height + (newRadius - oldRadius) * 2);
                        const tailHighestPointY: number = this.obtainCenterY() + Math.sin(angle) * (radius + height + (newRadius - oldRadius) * 2);
                        this.waveTails[i].setAttrs({
                            x: tailHighestPointX,
                            y: tailHighestPointY,
                        });
                    } else {
                        const updatedPointX: number = this.obtainCenterX() + Math.cos(angle) * Math.max(newRadius, oldRadius - Math.max((radius * 2 - oldRadius) / 16, 8));
                        const updatedPointY: number = this.obtainCenterY() + Math.sin(angle) * Math.max(newRadius, oldRadius - Math.max((radius * 2 - oldRadius) / 16, 8))
                        this.waveTails[i].setAttrs({
                            x: updatedPointX,
                            y: updatedPointY,
                        });
                    }
                } else {
                    const tailHighestPointX: number = this.obtainCenterX() + Math.cos(angle) * (radius + height * 2);
                    const tailHighestPointY: number = this.obtainCenterY() + Math.sin(angle) * (radius + height * 2);
                    const rect: Konva.Rect = new Konva.Rect({
                        x: tailHighestPointX,
                        y: tailHighestPointY,
                        offsetX: 2,
                        offsetY: Math.PI * 2 * radius / bin.length / 2,
                        rotation: angle * (180 / Math.PI),
                        width: 3,
                        height: Math.PI * 2 * radius / bin.length,
                        strokeWidth: 0,
                        cornerRadius: 2,
                        fill: 'white',
                        shadowColor: 'red',
                        shadowBlur: 6,
                        shadowOffsetX: Math.cos(angle) * 3,
                        shadowOffsetY: Math.sin(angle) * 3,
                        shadowOpacity: 0.75
                    });
                    this.waveTails.push(rect);

                    this.layer.add(rect);
                }
            }
        }
    }

    protected override onStarted(audio: EnhancementAudio): void {
        if (this.coverImageAnimation) {
            this.coverImageAnimation.start();
        }
    }

    protected override onPaused(audio: EnhancementAudio): void {
        if (this.coverImageAnimation) {
            this.coverImageAnimation.stop();
        }
    }

    protected override onResumed(audio: EnhancementAudio): void {
        if (this.coverImageAnimation) {
            this.coverImageAnimation.start();
        }
    }

    protected override onEnded(audio: EnhancementAudio): void {
        if (this.coverImageAnimation) {
            this.coverImageAnimation.stop();
        }
    }

    protected override onResize(): void {
        super.onResize();
        this.updatePhotoImage();
        this.updateWaveTails();
    }

    private updatePhotoImage(): void {
        if (this.coverImage) {
            this.coverImage.setAttrs({
                x: this.obtainCenterX(),
                y: this.obtainCenterY()
            });
        }
    }

    private updateWaveTails(): void {
        this.waveTails.forEach((waveTail: Konva.Rect) => {
            waveTail.destroy();
        });
        this.waveTails = [];
    }
}
