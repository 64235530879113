import {Pipe, PipeTransform} from '@angular/core';
import {EnhancementImage} from "../../../../model/data/persist/jpa/entity/enhancement/enhancement-image";
import {EnhancementMedia} from "../../../../model/data/persist/jpa/entity/enhancement/enhancement-media";
import {MediaCategory} from "../../../../model/data/persist/jpa/entity/media";
import {EnhancementAudio} from "../../../../model/data/persist/jpa/entity/enhancement/enhancement-audio";
import {EnhancementVideo} from "../../../../model/data/persist/jpa/entity/enhancement/enhancement-video";
import {EnhancementBook} from "../../../../model/data/persist/jpa/entity/enhancement/enhancement-book";
import {Image} from "../../../../model/data/persist/jpa/entity/image";

@Pipe({
    name: 'mediaToCoverImage'
})
export class MediaToCoverImagePipe implements PipeTransform {

    transform(media: EnhancementMedia): Image | EnhancementImage | undefined {
        switch (media.category) {
            case MediaCategory.Image:
                return media as EnhancementImage;
            case MediaCategory.Audio:
                return (media as EnhancementAudio).coverImage;
            case MediaCategory.Video:
                return (media as EnhancementVideo).coverImage;
            case MediaCategory.Book:
                return (media as EnhancementBook).coverImage;
            case MediaCategory.Article:
                return undefined;
        }
    }

}
