import {ApplicationConfig, importProvidersFrom, provideZoneChangeDetection} from '@angular/core';
import {provideRouter, withInMemoryScrolling, withRouterConfig} from '@angular/router';
import {routes} from './app.routes';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {HttpClient, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {NgxPermissionsModule} from "ngx-permissions";
import {APP_HTTP_INTERCEPTOR} from "./app.http.interceptor";
import {CsToastModule} from "./core/module/component/toast/cs.toast.module";
import {CsThemeModule} from "./core/module/component/theme/cs.theme.module";
import {CsDialogModule} from "./core/module/component/dialog/cs.dialog.module";
import {LoadingBarHttpClientModule} from "@ngx-loading-bar/http-client";
import {LoadingBarModule} from "@ngx-loading-bar/core";
import {LoadingBarRouterModule} from "@ngx-loading-bar/router";
import {CsFileUploaderModule} from "./core/module/component/file-uploader/cs.file-uploader.module";
// import {MonacoEditorModule} from "ngx-monaco-editor-v2";
import {provideAnimations} from "@angular/platform-browser/animations";
import {MarkdownModule, MARKED_OPTIONS, MarkedRenderer} from "ngx-markdown";
import {APP_ROUTES_REUSE_STRATEGY} from "./app.routes.reuse-strategy";
import {provideNativeDateAdapter} from "@angular/material/core";
import {CsMediaModule} from "./core/module/component/media/cs.media.module";

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({
            eventCoalescing: true
        }),

        provideRouter(routes, withInMemoryScrolling({
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled',
        })),

        provideAnimations(),
        provideAnimationsAsync(),

        provideNativeDateAdapter(),

        provideHttpClient(withInterceptorsFromDi()),

        importProvidersFrom(LoadingBarHttpClientModule),
        importProvidersFrom(LoadingBarRouterModule),
        importProvidersFrom(LoadingBarModule),

        importProvidersFrom(TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => {
                    return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
                },
                deps: [HttpClient]
            }
        })),

        importProvidersFrom(NgxPermissionsModule.forRoot()),

        // importProvidersFrom(MonacoEditorModule.forRoot()),
        importProvidersFrom(MarkdownModule.forRoot({
            markedOptions: {
                provide: MARKED_OPTIONS,
                useFactory: () => {
                    const renderer: MarkedRenderer = new MarkedRenderer();
                    // renderer.heading  = (text: string, level: number) => {
                    //     return `<h${level} class="cursor-pointer">${text}</h${level}>`;
                    // };
                    return {
                        renderer: renderer,
                        gfm: true,
                        breaks: false,
                        pedantic: false,
                    };
                }
            },
        })),

        importProvidersFrom(CsThemeModule.forRoot()),
        importProvidersFrom(CsDialogModule.forRoot()),
        importProvidersFrom(CsToastModule.forRoot()),
        importProvidersFrom(CsFileUploaderModule.forRoot()),
        importProvidersFrom(CsMediaModule.forRoot()),

        APP_HTTP_INTERCEPTOR,
        APP_ROUTES_REUSE_STRATEGY
    ]
};
