<mat-card class="w-full h-full">
    <mat-card-header class="w-full flex-grow-0">
        <mat-icon fontIcon="video_settings"></mat-icon>{{ 'Core.Component.Media.Video.Dialog.MeVideoEditDialog.TITLE' | translate }}
    </mat-card-header>
    <mat-card-content class="w-full flex-grow h-0 grid grid-rows-12 grid-cols-12 gap-1" *ngIf="video">
        <div class="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-8 xl:col-span-8 2xl:col-span-9 row-span-6 sm:row-span-6 md:row-span-12 lg:row-span-12 xl:row-span-12 2xl:row-span-12">
            <mat-card class="w-full h-full" appearance="outlined">
                <cs-video-player class="w-full h-full" [video]="video" [autoPlay]="true"></cs-video-player>
            </mat-card>
        </div>
        <div class="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-4 2xl:col-span-3 row-span-6 sm:row-span-6 md:row-span-12 lg:row-span-12 xl:row-span-12 2xl:row-span-12">
            <cs-media-update-card class="w-full h-full" [media]="video" (submit)="onSubmit($event)" (close)="onClose($event)">
                <mat-card class="w-full aspect-16/10" appearance="outlined">
                    <cs-image-background class="w-full h-full" [image]="video.coverImage" [resolution]="ImageResolution.Large" [dark]="true">
                        <div class="w-full h-full flex flex-row justify-center items-center">
                            <button mat-flat-button (click)="onChangeCoverImage()">
                                <mat-icon fontIcon="replace_image"></mat-icon>{{ 'COMMONS.CHANGE' | translate }}
                            </button>
                        </div>
                    </cs-image-background>
                </mat-card>
                <div class="w-full mt-1 flex flex-row justify-start items-start flex-wrap">
                    @for (resolution of video.videoFile.resolutions; track resolution) {
                        <span class="px-1 py-0.5 ms-1 mt-1 text-white rounded bg-blue-400">{{ resolution }}</span>
                    }
                </div>
                <div class="w-full mt-1 flex flex-row justify-start items-start flex-wrap">
                    @for (tag of video.videoFile.tags; track tag) {
                        <span class="px-1 py-0.5 ms-1 mt-1 text-white rounded bg-gray-500">{{ tag }}</span>
                    }
                </div>
            </cs-media-update-card>
        </div>
    </mat-card-content>
</mat-card>
