<mat-card class="w-full h-full">
    <mat-card-header class="w-full flex-grow-0">
        <mat-icon fontIcon="image"></mat-icon><span class="ms-2 inline-flex flex-row justify-start items-center">{{ 'Page.MePage.MeImagePage.Dialog.MeImageEditDialog.TITLE' | translate }}</span>
    </mat-card-header>
    <mat-card-content class="w-full flex-grow h-0 grid grid-rows-12 grid-cols-12 gap-1" *ngIf="image">
        <div class="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-8 xl:col-span-8 2xl:col-span-9 row-span-6 sm:row-span-6 md:row-span-12 lg:row-span-12 xl:row-span-12 2xl:row-span-12">
            <mat-card class="w-full h-full" appearance="outlined">
                <cs-image-background class="w-full h-full" [image]="image" [resolution]="ImageResolution.Origin" [size]="ImageBackgroundSize.Contain"></cs-image-background>
            </mat-card>
        </div>
        <div class="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-4 2xl:col-span-3 row-span-6 sm:row-span-6 md:row-span-12 lg:row-span-12 xl:row-span-12 2xl:row-span-12">
            <cs-media-update-card class="w-full h-full" [media]="image" (submit)="onSubmit($event)" (close)="onClose($event)">
                <div class="w-full mt-1 flex flex-row justify-start items-start flex-wrap">
                    @for (resolution of image.imageFile.resolutions; track resolution) {
                        <span class="px-1 py-0.5 ms-1 mt-1 text-white rounded bg-blue-400">{{ resolution | imageResolutionTranslate }}</span>
                    }
                </div>
                <div class="w-full mt-1 flex flex-row justify-start items-start flex-wrap">
                    @for (tag of image.imageFile.tags; track tag) {
                        <span class="px-1 py-0.5 ms-1 mt-1 text-white rounded bg-gray-500">{{ tag }}</span>
                    }
                </div>
            </cs-media-update-card>
        </div>
    </mat-card-content>
</mat-card>
