import {Component, Input} from '@angular/core';
import {ImageResolution} from '../../../../model/data/persist/jpa/entity/image-file';
import {EnhancementUser, SimpleMe} from "../../../../model/data/persist/jpa/entity/user";
import {MeService} from "../../../../service/data/me/me.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
    selector: 'cs-enhancement-avatar',
    standalone: false,
    templateUrl: './enhancement-avatar.component.html',
    styleUrls: ['./enhancement-avatar.component.scss']
})
export class EnhancementAvatarComponent {
    protected readonly ImageResolution = ImageResolution;

    protected me: SimpleMe | undefined;

    @Input()
    public user: EnhancementUser | undefined;

    constructor(
        private meService: MeService
    ) {
        this.meService.onMeChanged().pipe(takeUntilDestroyed()).subscribe((me: SimpleMe | undefined) => {
            this.me = me;
        });
    }

}
