import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'lyricsTime'
})
export class LyricsTimeFormatterPipe implements PipeTransform {
    transform(value: number, accuracy: 2 | 3 = 2, unit: 'millisecond' | 'second' = 'millisecond'): string {
        const normalizedValue: number = unit === 'millisecond' ? value : unit === 'second' ? value * 1000 : value;
        if (accuracy === 2) {
            return `${Math.trunc(normalizedValue / 1000 / 60).toFixed(0).padStart(2, '0')}:${Math.trunc(normalizedValue / 1000 % 60).toFixed(0).padStart(2, '0')}`;
        } else if (accuracy === 3) {
            return `${Math.trunc(normalizedValue / 1000 / 60).toFixed(0).padStart(2, '0')}:${Math.trunc(normalizedValue / 1000 % 60).toFixed(0).padStart(2, '0')}:${Math.trunc(normalizedValue % 1000).toFixed(0).padStart(3, '0')}`;
        } else {
            return '';
        }
    }
}
