import {Component, OnInit} from '@angular/core';
import {Router, RouterModule} from "@angular/router";
import {CommonModule} from "@angular/common";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatDrawerMode, MatSidenavModule} from "@angular/material/sidenav";
import {MatListModule} from "@angular/material/list";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatMenuModule} from "@angular/material/menu";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatCardActions} from "@angular/material/card";
import {CsCommonsModule} from "../../core/module/commons/cs.commons.module";
import {CsAvatarModule} from "../../core/module/component/avatar/cs.avatar.module";
import {MenuItem} from "../../core/module/component/nav/menu-item";
import {SimpleMe} from "../../core/model/data/persist/jpa/entity/user";
import {HomePageComponentService} from "./home-page.component.service";
import {BreakpointObserver, Breakpoints, BreakpointState} from "@angular/cdk/layout";
import {MeService} from "../../core/service/data/me/me.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {ScrollRestorableComponent} from "../../core/module/commons/route/enhanced-router-outlet";
import {UserProfile} from "../../core/model/data/persist/jpa/entity/user-profile";

@Component({
    selector: 'cs-discovery-page',
    standalone: true,
    templateUrl: './home-page.component.html',
    styleUrl: './home-page.component.scss',
    imports: [
        RouterModule,
        CommonModule,
        TranslateModule,
        MatIconModule,
        MatButtonModule,
        MatSidenavModule,
        MatListModule,
        MatToolbarModule,
        MatMenuModule,
        MatInputModule,
        ReactiveFormsModule,
        MatCardActions,
        CsCommonsModule,
        CsAvatarModule,
    ]
})
export class HomePageComponent extends ScrollRestorableComponent implements OnInit {
    protected sidenavOpen: boolean = true;
    protected sidenavMode: MatDrawerMode = 'side';

    protected menuItems: MenuItem[] = [];
    protected userMenuItems: MenuItem[] = [];

    protected me: SimpleMe | undefined;

    constructor(
        protected homeComponentService: HomePageComponentService,
        private breakpointObserver: BreakpointObserver,
        private router: Router,
        // private toastService: NbToastrService,
        private translateService: TranslateService,
        // private dialogService: NbDialogService,
        // private audioPlayerService: AudioPlayerService,
        private meService: MeService,
    ) {
        super();
        // this.audioPlayerService.pause();

        this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe((breakpointState: BreakpointState) => {
            if ((breakpointState.breakpoints[Breakpoints.XSmall] || breakpointState.breakpoints[Breakpoints.Small])) {
                this.sidenavOpen = false;
                this.sidenavMode = 'over';
            } else {
                this.sidenavOpen = true;
                this.sidenavMode = 'side';
            }
        });

        this.meService.onMeChanged().pipe(takeUntilDestroyed()).subscribe((me: SimpleMe | undefined) => {
            this.me = me;
        });
        this.meService.onMeProfileChanged().pipe(takeUntilDestroyed()).subscribe((meProfile: UserProfile | undefined) => {
            if (meProfile) {
                this.updateMenu();
            }
        });
    }

    ngOnInit(): void {
       this.updateMenu()
    }

    private updateMenu(): void {
        this.menuItems = [
            {
                title: this.translateService.instant('Page.HomePage.MENU.HOME.TITLE'),
                icon: 'home',
                link: '/discovery',
                hidden: false
            },
            {
                title: this.translateService.instant('Page.HomePage.MENU.IMAGE.TITLE'),
                icon: 'photo_library',
                link: '/image',
                hidden: false
            },
            {
                title: this.translateService.instant('Page.HomePage.MENU.AUDIO.TITLE'),
                icon: 'library_music',
                link: '/audio',
                hidden: false
            },
            {
                title: this.translateService.instant('Page.HomePage.MENU.VIDEO.TITLE'),
                icon: 'video_library',
                link: '/video',
                hidden: false
            },
            {
                title: this.translateService.instant('Page.HomePage.MENU.BOOK.TITLE'),
                icon: 'library_books',
                link: '/book',
                hidden: false
            }
        ];

        this.userMenuItems = [
            {
                title: this.translateService.instant('Page.HomePage.USER_MENU.PERSONAL_CENTER'),
                icon: 'person',
                link: '/me',
                hidden: false
            },
            {
                title: this.translateService.instant('COMMONS.LOGOUT'),
                icon: 'logout',
                link: '/auth/logout',
                hidden: false
            }
        ];
    }

}
