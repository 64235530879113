import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {DialogService} from "../../dialog/dialog.service";
import * as monaco from 'monaco-editor';
import {editor} from "monaco-editor";
import IModelChangedEvent = editor.IModelChangedEvent;
import IModelContentChangedEvent = editor.IModelContentChangedEvent;

@Component({
    selector: 'cs-markdown-editor',
    standalone: false,
    templateUrl: './markdown-editor.component.html',
    styleUrls: ['./markdown-editor.component.scss']
})
export class MarkdownEditorComponent implements AfterViewInit {
    @ViewChild('editor', {static: true})
    private editorElement: ElementRef<HTMLDivElement> | undefined;

    private editor: monaco.editor.IStandaloneCodeEditor | undefined;
    private option: monaco.editor.IStandaloneEditorConstructionOptions = {
        automaticLayout: true,
        fontSize: 14,
        minimap: {
            enabled: false
        },
        folding: false,
        theme: 'vs-dark',
        language: 'markdown',
        readOnly: false
    };

    @Input()
    public value: string = '';
    @Output()
    public valueChange: EventEmitter<string> = new EventEmitter<string>();

    @Input()
    public enableHeading: boolean = true;

    constructor(
        private dialogService: DialogService
    ) {
    }

    ngAfterViewInit(): void {
        if (this.editorElement) {
            this.editor = monaco.editor.create(this.editorElement.nativeElement, this.option);
            this.editor.setValue(this.value);
            this.editor.onDidChangeModelContent((event: IModelContentChangedEvent) => {
                if (this.editor) {
                    this.value = this.editor.getValue();
                    this.valueChange.emit(this.value);
                }
            });
        }
    }

    protected onHeadingClick(): void {
        if (this.editor) {
            const model: monaco.editor.ITextModel | null = this.editor.getModel();
            const selection: monaco.Selection | null = this.editor.getSelection();
            if (model && selection) {
                const value: string = model.getLineContent(selection.startLineNumber);
                this.editor.setSelection({
                    startLineNumber: selection.startLineNumber,
                    startColumn: 1,
                    endLineNumber: selection.startLineNumber,
                    endColumn: value.length + 1
                });

                const wholeLineSelection: monaco.Selection | null = this.editor.getSelection();
                if (wholeLineSelection) {
                    let headingPrefixLength: number = 0;
                    for (let i: number = 0; i < value.length; i++) {
                        if (value.charAt(i) === '#') {
                            headingPrefixLength++;
                        }
                    }
                    headingPrefixLength = Math.min(headingPrefixLength, 6);

                    const headingPrefix: string = Array.from({length: (headingPrefixLength + 1) % 7}, (x, i) => i).map(i => '#').join('');
                    const valueSuffix: string = value.replaceAll(/^#+?\s+/g, '');
                    this.editor.executeEdits('', [{
                        range: {
                            startLineNumber: wholeLineSelection.selectionStartLineNumber,
                            startColumn: wholeLineSelection.selectionStartColumn,
                            endLineNumber: wholeLineSelection.endLineNumber,
                            endColumn: wholeLineSelection.endColumn
                        },
                        text: headingPrefix ? `${headingPrefix} ${valueSuffix}` : valueSuffix,
                        forceMoveMarkers: true
                    }]);
                }
            }
        }
    }

    protected onBoldClick(): void {
        if (this.editor) {
            const model: monaco.editor.ITextModel | null = this.editor.getModel();
            const selection: monaco.Selection | null = this.editor.getSelection();
            if (model && selection) {
                const value: string = model.getValueInRange(selection);
                if (value) {
                    this.editor.executeEdits('', [{
                        range: {
                            startLineNumber: selection.selectionStartLineNumber,
                            startColumn: selection.selectionStartColumn,
                            endLineNumber: selection.endLineNumber,
                            endColumn: selection.endColumn
                        },
                        text: `**${value}**`,
                        forceMoveMarkers: true
                    }]);
                }
            }
        }
    }

    protected onItalicClick(): void {
        if (this.editor) {
            const model: monaco.editor.ITextModel | null = this.editor.getModel();
            const selection: monaco.Selection | null = this.editor.getSelection();
            if (model && selection) {
                const value: string = model.getValueInRange(selection);
                if (value) {
                    this.editor.executeEdits('', [{
                        range: {
                            startLineNumber: selection.selectionStartLineNumber,
                            startColumn: selection.selectionStartColumn,
                            endLineNumber: selection.endLineNumber,
                            endColumn: selection.endColumn
                        },
                        text: `*${value}*`,
                        forceMoveMarkers: true
                    }]);
                }
            }
        }
    }

    protected onSlashClick(): void {
        if (this.editor) {
            const model: monaco.editor.ITextModel | null = this.editor.getModel();
            const selection: monaco.Selection | null = this.editor.getSelection();
            if (model && selection) {
                const value: string = model.getValueInRange(selection);
                if (value) {
                    this.editor.executeEdits('', [{
                        range: {
                            startLineNumber: selection.selectionStartLineNumber,
                            startColumn: selection.selectionStartColumn,
                            endLineNumber: selection.endLineNumber,
                            endColumn: selection.endColumn
                        },
                        text: `~~${value}~~`,
                        forceMoveMarkers: true
                    }]);
                }
            }
        }
    }

    protected onHighLightClick(): void {
        if (this.editor) {
            const model: monaco.editor.ITextModel | null = this.editor.getModel();
            const selection: monaco.Selection | null = this.editor.getSelection();
            if (model && selection) {
                const value: string = model.getValueInRange(selection);
                if (value) {
                    this.editor.executeEdits('', [{
                        range: {
                            startLineNumber: selection.selectionStartLineNumber,
                            startColumn: selection.selectionStartColumn,
                            endLineNumber: selection.endLineNumber,
                            endColumn: selection.endColumn
                        },
                        text: `\`${value}\``,
                        forceMoveMarkers: true
                    }]);
                }
            }
        }
    }

    protected onUnorderedListClick(): void {
        if (this.editor) {
            const model: monaco.editor.ITextModel | null = this.editor.getModel();
            const selection: monaco.Selection | null = this.editor.getSelection();
            if (model && selection) {
                const lines: string[] = Array.from({length: selection.endLineNumber - selection.startLineNumber + 1}, (x, i) => selection.startLineNumber + i).map(lineNumber => model.getLineContent(lineNumber));
                this.editor.setSelection({
                    startLineNumber: selection.startLineNumber,
                    startColumn: 1,
                    endLineNumber: selection.endLineNumber,
                    endColumn: ([...lines].sort((left, right) => {
                        if (left.length === right.length) {
                            return 0;
                        } else {
                            return left.length < right.length ? -1 : 1;
                        }
                    }).pop() || '').length + 1
                });

                const wholeLineSelection: monaco.Selection | null = this.editor.getSelection();
                if (wholeLineSelection) {
                    this.editor.executeEdits('', [{
                        range: {
                            startLineNumber: wholeLineSelection.startLineNumber,
                            startColumn: wholeLineSelection.startColumn,
                            endLineNumber: wholeLineSelection.endLineNumber,
                            endColumn: wholeLineSelection.endColumn
                        },
                        text: lines.filter(line => line.startsWith('*')).length ? lines.map(line => line.replaceAll(/^\*+?\s+/g, '')).join('\n') : lines.map(line => `* ${line}`).join('\n'),
                        forceMoveMarkers: true
                    }]);
                }
            }
        }
    }

    protected onOrderedListClick(): void {
        if (this.editor) {
            const model: monaco.editor.ITextModel | null = this.editor.getModel();
            const selection: monaco.Selection | null = this.editor.getSelection();
            if (model && selection) {
                const lines: string[] = Array.from({length: selection.endLineNumber - selection.startLineNumber + 1}, (x, i) => selection.startLineNumber + i).map(lineNumber => model.getLineContent(lineNumber));
                this.editor.setSelection({
                    startLineNumber: selection.startLineNumber,
                    startColumn: 1,
                    endLineNumber: selection.endLineNumber,
                    endColumn: ([...lines].sort((left, right) => {
                        if (left.length === right.length) {
                            return 0;
                        } else {
                            return left.length < right.length ? -1 : 1;
                        }
                    }).pop() || '').length + 1
                });

                const wholeLineSelection: monaco.Selection | null = this.editor.getSelection();
                if (wholeLineSelection) {
                    this.editor.executeEdits('', [{
                        range: {
                            startLineNumber: wholeLineSelection.startLineNumber,
                            startColumn: wholeLineSelection.startColumn,
                            endLineNumber: wholeLineSelection.endLineNumber,
                            endColumn: wholeLineSelection.endColumn
                        },
                        text: lines.filter(line => line.match(/^\d+?\.\s+?.*/g)).length ? lines.map(line => line.replaceAll(/^\d+?\.\s+/g, '')).join('\n') : lines.map((line, i) => `${i + 1}. ${line}`).join('\n'),
                        forceMoveMarkers: true
                    }]);
                }
            }
        }
    }

    protected onQuoteClick(): void {
        if (this.editor) {
            const model: monaco.editor.ITextModel | null = this.editor.getModel();
            const selection: monaco.Selection | null = this.editor.getSelection();
            if (model && selection) {
                const lines: string[] = Array.from({length: selection.endLineNumber - selection.startLineNumber + 1}, (x, i) => selection.startLineNumber + i).map(lineNumber => model.getLineContent(lineNumber));
                this.editor.setSelection({
                    startLineNumber: selection.startLineNumber,
                    startColumn: 1,
                    endLineNumber: selection.endLineNumber,
                    endColumn: ([...lines].sort((left, right) => {
                        if (left.length === right.length) {
                            return 0;
                        } else {
                            return left.length < right.length ? -1 : 1;
                        }
                    }).pop() || '').length + 1
                });

                const wholeLineSelection: monaco.Selection | null = this.editor.getSelection();
                if (wholeLineSelection) {
                    this.editor.executeEdits('', [{
                        range: {
                            startLineNumber: wholeLineSelection.startLineNumber,
                            startColumn: wholeLineSelection.startColumn,
                            endLineNumber: wholeLineSelection.endLineNumber,
                            endColumn: wholeLineSelection.endColumn
                        },
                        text: lines.filter(line => line.startsWith('>')).length ? lines.map(line => line.replaceAll(/^>\s+/g, '')).join('\n') : lines.map(line => `> ${line}`).join('\n'),
                        forceMoveMarkers: true
                    }]);
                }
            }
        }
    }

    protected onCodeClick(): void {
        if (this.editor) {
            const model: monaco.editor.ITextModel | null = this.editor.getModel();
            const selection: monaco.Selection | null = this.editor.getSelection();
            if (model && selection) {
                const lines: string[] = Array.from({length: selection.endLineNumber - selection.startLineNumber + 1}, (x, i) => selection.startLineNumber + i).map(lineNumber => model.getLineContent(lineNumber));
                this.editor.setSelection({
                    startLineNumber: selection.startLineNumber,
                    startColumn: 1,
                    endLineNumber: selection.endLineNumber,
                    endColumn: ([...lines].sort((left, right) => {
                        if (left.length === right.length) {
                            return 0;
                        } else {
                            return left.length < right.length ? -1 : 1;
                        }
                    }).pop() || '').length + 1
                });

                const wholeLineSelection: monaco.Selection | null = this.editor.getSelection();
                if (wholeLineSelection) {
                    this.editor.executeEdits('', [{
                        range: {
                            startLineNumber: wholeLineSelection.startLineNumber,
                            startColumn: wholeLineSelection.startColumn,
                            endLineNumber: wholeLineSelection.endLineNumber,
                            endColumn: wholeLineSelection.endColumn
                        },
                        text: lines.filter(line => line.startsWith('```')).length ? lines.filter(line => !line.match(/^```.*/g)).join('\n') : ['```', ...lines, '```'].join('\n'),
                        forceMoveMarkers: true
                    }]);
                }
            }
        }
    }

    protected onImageClick(): void {
        // this.dialogService.open(ImageSelectDialogComponent, {
        //     hasBackdrop: true,
        //     closeOnBackdropClick: false,
        //     closeOnEsc: true,
        //     hasScroll: false,
        //     autoFocus: true
        // }).onClose.subscribe((images: EnhancementImage[]) => {
        //     if (images.length) {
        //         if (this.editor) {
        //             const model: monaco.editor.ITextModel | null = this.editor.getModel();
        //             const selection: monaco.Selection | null = this.editor.getSelection();
        //             if (model && selection) {
        //                 this.editor.executeEdits('', [{
        //                     range: {
        //                         startLineNumber: selection.selectionStartLineNumber,
        //                         startColumn: selection.selectionStartColumn,
        //                         endLineNumber: selection.endLineNumber,
        //                         endColumn: selection.endColumn
        //                     },
        //                     text: images.map((image: EnhancementImage) => {
        //                         return `![Image](/service-media/stream/image/${image.id}/resolution/Large "${image.imageFile.tags.join(', ')}")`;
        //                     }).join('\n\n'),
        //                     forceMoveMarkers: true
        //                 }]);
        //             }
        //         }
        //     }
        // });
    }

}
