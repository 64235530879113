import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {PermissionMode} from "../../../core/service/data/permission-mode";
import {ToastService} from "../../../core/module/component/toast/toast.service";
import {DialogService} from "../../../core/module/component/dialog/dialog.service";
import {EnhancementMediaCollection} from "../../../core/model/data/persist/jpa/entity/media-collection";
import {CommonModule} from "@angular/common";
import {CsCommonsModule} from "../../../core/module/commons/cs.commons.module";
import {ImageResolution} from '../../../core/model/data/persist/jpa/entity/image-file';
import {
    ImageBackgroundSize
} from '../../../core/module/commons/component/image/image-background/image-background.component';
import {PublicMediaCollectionService} from "../../../core/service/data/public/public-media-collection.service";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {PermissionService} from "../../../core/service/data/god/permission.service";
import {HomePageComponentService} from "../../home-page/home-page.component.service";
import {CsMediaModule} from "../../../core/module/component/media/cs.media.module";

@Component({
    selector: 'cs-media-collection-detail-page',
    standalone: true,
    templateUrl: './media-collection-detail-page.component.html',
    styleUrls: ['./media-collection-detail-page.component.scss'],
    imports: [
        CommonModule,
        TranslateModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        CsCommonsModule,
        CsMediaModule
    ]
})
export class MediaCollectionDetailPageComponent implements OnInit, OnDestroy {
    protected readonly PermissionMode = PermissionMode;
    protected readonly ImageBackgroundSize = ImageBackgroundSize;
    protected readonly ImageResolution = ImageResolution;

    protected mediaCollection: EnhancementMediaCollection | undefined;

    constructor(
        protected homePageComponentService: HomePageComponentService,
        private router: Router,/**/
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private permissionService: PermissionService,
        private dialogService: DialogService,
        private toastService: ToastService,
        private publicMediaCollectionService: PublicMediaCollectionService
    ) {
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe((params: Params) => {
            const id: number = params['id'] as number;
            if (id) {
                this.load(id);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.mediaCollection) {
            this.load(this.mediaCollection.id);
        }
    }

    private load(id: number): void {
        this.publicMediaCollectionService.load(id).subscribe((mediaCollection: EnhancementMediaCollection) => {
            this.onMediaCollectionChange(mediaCollection);
        });
    }

    protected onMediaCollectionChange(mediaCollection: EnhancementMediaCollection): void {
        this.mediaCollection = mediaCollection;
        this.homePageComponentService.notifyMediaCollectionChanged(this.mediaCollection);
    }

    protected onMediaCollectionClick(mediaCollection: EnhancementMediaCollection): void {
        this.router.navigate([`/media-collection/${mediaCollection.id}`]).then();
    }

}
