<cs-avatar class="w-auto h-auto" [user]="user"></cs-avatar>

<ng-template #templateRef>
    <mat-card class="mb-0 border-0" [style.width.px]="320" *ngIf="user">
        <mat-card-content class="w-full px-3 pt-3 pb-1 flex flex-col justify-start items-stretch">
            <div class="w-full flex-grow-0 flex flex-row justify-between items-stretch">
                <div class="flex-grow-0 flex flex-col justify-start items-start">
                    <cs-avatar class="w-auto h-auto" [user]="user"></cs-avatar>
                </div>
                <div class="flex-grow flex flex-row justify-end items-start" *ngIf="me">
<!--                    <button class="flex-grow-0" nbButton size="small" shape="round" [status]="user.likedByCurrentUser ? 'basic' : 'primary'" *ngIf="me && user.id !== me.id">-->
<!--                        {{ (user.likedByCurrentUser ? 'COMMONS.UNSUBSCRIBE' : 'COMMONS.SUBSCRIBE') | translate}}-->
<!--                    </button>-->
                </div>
            </div>
            <div class="w-full flex-grow-0 flex flex-row justify-start items-center" [style.height.px]="46">
                <cs-username class="w-auto h-auto" [user]="user"></cs-username>
            </div>
            <div class="w-full flex-grow-0 py-1 flex flex-row justify-start items-center">
                <span class="inline-flex flex-row justify-start items-center" [style.font-size.rem]="0.825">TODO</span>
            </div>
            <div class="w-full flex-grow-0 flex flex-row justify-start items-stretch" [style.height.px]="40">
                <div class="flex-grow h-full w-0 flex flex-row justify-start items-center">
                    <span class="inline-flex flex-row justify-start items-center" [style.font-size.rem]="0.825">{{ user.likingCount }}{{ 'COMMONS.FOLLOWING' | translate }}</span>
                </div>
                <div class="flex-grow h-full w-0 flex flex-row justify-start items-center">
                    <span class="inline-flex flex-row justify-start items-center" [style.font-size.rem]="0.825">{{ user.likedCount }}{{ 'COMMONS.FOLLOWED' | translate }}</span>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>
