<mat-card class="w-full h-full" appearance="outlined">
    <div class="w-full h-full flex flex-col justify-start items-stretch">
        <div class="w-full flex-grow h-0 keen-slider" #sliderRef>
            @for (media of medias; track media.id) {
                <div class="w-full h-full flex flex-col justify-start items-stretch keen-slider__slide">
                    @switch (media.category){
                        @case (MediaCategory.Image) {
                            <cs-image-background class="w-full flex-grow h-0 cursor-pointer" [size]="ImageBackgroundSize.Cover" [image]="media | mediaToImage" [resolution]="ImageResolution.Medium" [blur]="false" (click)="onMediaClick(media)"></cs-image-background>
                        }
                        @case (MediaCategory.Audio) {
                            <cs-image-background class="w-full flex-grow h-0 cursor-pointer" [size]="ImageBackgroundSize.Cover" [image]="(media | mediaToAudio).coverImage" [resolution]="ImageResolution.Medium" [blur]="false" (click)="onMediaClick(media)"></cs-image-background>
                        }
                        @case (MediaCategory.Video) {
                            <cs-image-background class="w-full flex-grow h-0 cursor-pointer" [size]="ImageBackgroundSize.Cover" [image]="(media | mediaToVideo).coverImage" [resolution]="ImageResolution.Medium" [blur]="false" (click)="onMediaClick(media)">
                                @if (media | mediaToCoverImage) {
                                    <div #thumbnailContainer class="w-full h-full bg-cover"
                                         (mouseenter)="thumbnailContainer.style.backgroundImage = 'url(/service-media/stream/video/' + media.id + '/animated-thumbnail)'"
                                         (mouseleave)="thumbnailContainer.style.backgroundImage = ''">
                                    </div>
                                } @else {
                                    <div #thumbnailContainer class="w-full h-full bg-cover"
                                         [style.background-image]="'url(/service-media/stream/video/' + media.id + '/thumbnail)'"
                                         (mouseenter)="thumbnailContainer.style.backgroundImage = 'url(/service-media/stream/video/' + media.id + '/animated-thumbnail)'"
                                         (mouseleave)="thumbnailContainer.style.backgroundImage = 'url(/service-media/stream/video/' + media.id + '/thumbnail)'">
                                    </div>
                                }
                            </cs-image-background>
                        }
                        @case (MediaCategory.Book) {
                            <cs-image-background class="w-full flex-grow h-0 cursor-pointer" [size]="ImageBackgroundSize.Cover" [image]="(media | mediaToBook).coverImage" [resolution]="ImageResolution.Medium" [blur]="false" (click)="onMediaClick(media)"></cs-image-background>
                        }
                        @case (MediaCategory.Article) {
                        }
                    }
                </div>
            }
        </div>
        <div class="w-full flex-grow-0 relative" [style.height.px]="extraHeight">
            @if (currentMedia) {
                <div class="w-full h-full absolute flex flex-row justify-start items-stretch" [style.background-color]="currentMedia | mediaColor">
                    <div class="flex-grow h-full w-0 flex flex-col justify-start items-stretch">
                        <div class="w-full flex-grow-0 p-2 flex flex-row justify-start items-center" [style.height.px]="64">
                            @if (currentMedia.category === MediaCategory.Book) {
                                <span class="text-xl text-nowrap truncate" [style.color]="currentMedia | mediaInvertColor"><strong>{{ currentMedia | mediaToBook | bookNameTranslate }}</strong></span>
                            } @else {
                                <span class="text-xl text-nowrap truncate" [style.color]="currentMedia | mediaInvertColor"><strong>{{ currentMedia.name }}</strong></span>
                            }
                        </div>
                        <div class="w-full flex-grow h-0 px-2" [style.height.px]="0">
                            <span class="text-base text-wrap" [style.color]="currentMedia | mediaToImage | mediaInvertColor"><strong>{{ currentMedia.description }}</strong></span>
                        </div>
                    </div>
                    <div class="flex-grow-0 h-full flex flex-col justify-start items-stretch" [style.width.px]="96">
                        <div class="w-full p-2 flex flex-row justify-around items-center" [style.height.px]="64">
                            <button class="me-1" [style.color]="currentMedia | mediaToImage | mediaInvertColor" mat-icon-button (mouseover)="onPauseScroll()" (mouseout)="onResumeScroll()" (click)="previous()"><mat-icon fontIcon="arrow_back_ios"></mat-icon></button>
                            <button class="ms-1" [style.color]="currentMedia | mediaToImage | mediaInvertColor" mat-icon-button (mouseover)="onPauseScroll()" (mouseout)="onResumeScroll()" (click)="next()"><mat-icon fontIcon="arrow_forward_ios"></mat-icon></button>
                        </div>
                    </div>
                </div>
                <div class="w-full h-4 bottom-0 absolute flex flex-row justify-around items-center" (mouseover)="onPauseScroll()" (mouseout)="onResumeScroll()">
                    @for (media of medias; track media.id) {
                        <button mat-flat-button class="flex-grow w-0 min-w-0 h-2 p-0 mx-1 shadow" [ngClass]="media.id === currentMedia.id ? 'bg-white' : 'bg-gray-300'" (click)="changeMedia(media)"></button>
                    }
                </div>
            }
        </div>
    </div>
</mat-card>
