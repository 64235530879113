import {Injectable} from '@angular/core';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {ImageType} from "../../../model/data/persist/jpa/entity/image";
import {MeImageService} from "../../../service/data/me/me-image.service";
import {MeAudioService} from "../../../service/data/me/me-audio.service";
import {MeVideoService} from "../../../service/data/me/me-video.service";
import {EnhancementImage} from "../../../model/data/persist/jpa/entity/enhancement/enhancement-image";
import {EnhancementAudio} from "../../../model/data/persist/jpa/entity/enhancement/enhancement-audio";
import {EnhancementVideo} from "../../../model/data/persist/jpa/entity/enhancement/enhancement-video";

export interface FileUploaderUploadProgressEvent {
    file: File;
    progress: number;
}

export interface FileUploaderImageUploadFinishEvent {
    file: File;
    image: EnhancementImage;
}

export interface FileUploaderAudioUploadFinishEvent {
    file: File;
    audio: EnhancementAudio;
}

export interface FileUploaderVideoUploadFinishEvent {
    file: File;
    video: EnhancementVideo;
}

@Injectable()
export class FileUploaderService {
    private imageFiles: File[] = [];
    private imageFilesSubject: BehaviorSubject<File[]> = new BehaviorSubject<File[]>(this.imageFiles);
    private imageProgressEventSubject: Subject<FileUploaderUploadProgressEvent> = new Subject<FileUploaderUploadProgressEvent>();
    private imageUploadFinishEventSubject: Subject<FileUploaderImageUploadFinishEvent> = new Subject<FileUploaderImageUploadFinishEvent>();

    private audioFiles: File[] = [];
    private audioFilesSubject: BehaviorSubject<File[]> = new BehaviorSubject<File[]>(this.audioFiles);
    private audioProgressEventSubject: Subject<FileUploaderUploadProgressEvent> = new Subject<FileUploaderUploadProgressEvent>();
    private audioUploadFinishEventSubject: Subject<FileUploaderAudioUploadFinishEvent> = new Subject<FileUploaderAudioUploadFinishEvent>();

    private videoFiles: File[] = [];
    private videoFilesSubject: BehaviorSubject<File[]> = new BehaviorSubject<File[]>(this.videoFiles);
    private videoProgressEventSubject: Subject<FileUploaderUploadProgressEvent> = new Subject<FileUploaderUploadProgressEvent>();
    private videoUploadFinishEventSubject: Subject<FileUploaderVideoUploadFinishEvent> = new Subject<FileUploaderVideoUploadFinishEvent>();

    private filesSubject: BehaviorSubject<File[]> = new BehaviorSubject<File[]>([...this.imageFiles, ...this.audioFiles, ...this.videoFiles]);
    private uploadProgressEventSubject: Subject<FileUploaderUploadProgressEvent> = new Subject<FileUploaderUploadProgressEvent>();

    constructor(
        private meImageService: MeImageService,
        private meAudioService: MeAudioService,
        private meVideoService: MeVideoService
    ) {
    }

    onImageFilesChanged(): Observable<File[]> {
        return this.imageFilesSubject.asObservable();
    }

    onImageFileUploadProgressChanged(): Observable<FileUploaderUploadProgressEvent> {
        return this.imageProgressEventSubject.asObservable();
    }

    onImageUploadFinished(): Observable<FileUploaderImageUploadFinishEvent> {
        return this.imageUploadFinishEventSubject.asObservable();
    }

    onAudioFilesChanged(): Observable<File[]> {
        return this.audioFilesSubject.asObservable();
    }

    onAudioFileUploadProgressChanged(): Observable<FileUploaderUploadProgressEvent> {
        return this.audioProgressEventSubject.asObservable();
    }

    onAudioUploadFinished(): Observable<FileUploaderAudioUploadFinishEvent> {
        return this.audioUploadFinishEventSubject.asObservable();
    }

    onVideoFilesChanged(): Observable<File[]> {
        return this.videoFilesSubject.asObservable();
    }

    onVideoFileUploadProgressChanged(): Observable<FileUploaderUploadProgressEvent> {
        return this.videoProgressEventSubject.asObservable();
    }

    onVideoUploadFinished(): Observable<FileUploaderVideoUploadFinishEvent> {
        return this.videoUploadFinishEventSubject.asObservable();
    }

    onFilesChanged(): Observable<File[]> {
        return this.filesSubject.asObservable();
    }

    onFileUploadProgressChanged(): Observable<FileUploaderUploadProgressEvent> {
        return this.uploadProgressEventSubject.asObservable();
    }

    addImageFile(file: File, type: ImageType): void {
        this.imageFiles.push(file);
        this.imageFilesSubject.next([...this.imageFiles]);
        this.filesSubject.next([...this.imageFiles, ...this.audioFiles, ...this.videoFiles]);

        this.meImageService.upload(file, type).subscribe((event: HttpEvent<EnhancementImage>) => {
            switch (event.type) {
                case HttpEventType.UploadProgress:
                    if (event.total) {
                        const fileUploaderUploadProgressEvent: FileUploaderUploadProgressEvent = {
                            file: file,
                            progress: event.loaded / event.total
                        };
                        this.imageProgressEventSubject.next(fileUploaderUploadProgressEvent);
                        this.uploadProgressEventSubject.next(fileUploaderUploadProgressEvent);
                    }
                    break;
                case HttpEventType.Response:
                    if (event.body) {
                        this.imageUploadFinishEventSubject.next({
                            file: file,
                            image: event.body
                        });
                    }
                    this.imageFiles = this.imageFiles.filter((existFile: File) => existFile !== file);
                    this.imageFilesSubject.next([...this.imageFiles]);
                    this.filesSubject.next([...this.imageFiles, ...this.audioFiles, ...this.videoFiles]);
                    break;
            }
        });
    }

    addAudioFile(file: File): void {
        this.audioFiles.push(file);
        this.audioFilesSubject.next([...this.audioFiles]);
        this.filesSubject.next([...this.imageFiles, ...this.audioFiles]);

        this.meAudioService.upload(file).subscribe((event: HttpEvent<EnhancementAudio>) => {
            switch (event.type) {
                case HttpEventType.UploadProgress:
                    if (event.total) {
                        const fileUploaderUploadProgressEvent: FileUploaderUploadProgressEvent = {
                            file: file,
                            progress: event.loaded / event.total
                        };
                        this.audioProgressEventSubject.next(fileUploaderUploadProgressEvent);
                        this.uploadProgressEventSubject.next(fileUploaderUploadProgressEvent);
                    }
                    break;
                case HttpEventType.Response:
                    if (event.body) {
                        this.audioUploadFinishEventSubject.next({
                            file: file,
                            audio: event.body
                        });
                    }
                    this.audioFiles = this.audioFiles.filter((existFile: File) => existFile !== file);
                    this.audioFilesSubject.next([...this.audioFiles]);
                    this.filesSubject.next([...this.imageFiles, ...this.audioFiles]);
                    break;
            }
        });
    }

    addVideoFile(file: File): void {
        this.videoFiles.push(file);
        this.videoFilesSubject.next([...this.videoFiles]);
        this.filesSubject.next([...this.imageFiles, ...this.audioFiles, ...this.videoFiles]);

        this.meVideoService.upload(file).subscribe((event: HttpEvent<EnhancementVideo>) => {
            switch (event.type) {
                case HttpEventType.UploadProgress:
                    if (event.total) {
                        const fileUploaderUploadProgressEvent: FileUploaderUploadProgressEvent = {
                            file: file,
                            progress: event.loaded / event.total
                        };
                        this.videoProgressEventSubject.next(fileUploaderUploadProgressEvent);
                        this.uploadProgressEventSubject.next(fileUploaderUploadProgressEvent);
                    }
                    break;
                case HttpEventType.Response:
                    if (event.body) {
                        this.videoUploadFinishEventSubject.next({
                            file: file,
                            video: event.body
                        });
                    }
                    this.videoFiles = this.videoFiles.filter((existFile: File) => existFile !== file);
                    this.videoFilesSubject.next([...this.videoFiles]);
                    this.filesSubject.next([...this.imageFiles, ...this.audioFiles, ...this.videoFiles]);
                    break;
            }
        });
    }
}
