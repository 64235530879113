import {Component} from '@angular/core';
import {MediaGalleryComponent} from "../../media/media-gallery/media-gallery.component";
import {EnhancementImage} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-image";

@Component({
    selector: 'cs-image-gallery',
    standalone: false,
    templateUrl: '../../media/media-gallery/media-gallery.component.html',
    styleUrls: ['../../media/media-gallery/media-gallery.component.scss']
})
export class ImageGalleryComponent extends MediaGalleryComponent<EnhancementImage>  {

}
