import {Injectable} from '@angular/core';
import DashJs, {MediaPlayer, MediaPlayerClass, PlaybackTimeUpdatedEvent} from "dashjs";
import {BehaviorSubject, interval, Observable, Subject, Subscription} from "rxjs";
import {PermissionMode} from "../../../../../../service/data/permission-mode";
import {SortablePagination} from "../../../../../../model/commons/sortablePagination";
import {EnhancementMediaCollection} from "../../../../../../model/data/persist/jpa/entity/media-collection";
import {EnhancementAudio} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-audio";

export interface PlaybackTime {
    duration: number;
    time: number
}

export enum PlayMode {
    SingleCycle = 'SingleCycle',
    ListLoop = 'ListLoop',
    Random = 'Random'
}

@Injectable()
export class AudioPlayerService {
    private audioElement: HTMLAudioElement = new Audio();

    private player: MediaPlayerClass = DashJs.MediaPlayer().create();

    private audioContext: AudioContext | undefined;
    private analyserNode: AnalyserNode | undefined;
    private mediaElementAudioSourceNode: MediaElementAudioSourceNode | undefined;

    private streamInitializedSubject: Subject<EnhancementAudio> = new Subject<EnhancementAudio>();
    private playbackStartedSubject: Subject<EnhancementAudio> = new Subject<EnhancementAudio>();
    private playbackPausedSubject: Subject<EnhancementAudio> = new Subject<EnhancementAudio>();
    private playbackResumedSubject: Subject<EnhancementAudio> = new Subject<EnhancementAudio>();
    private playbackEndedSubject: Subject<EnhancementAudio> = new Subject<EnhancementAudio>();
    private playbackTimeSubject: Subject<PlaybackTime> = new Subject<PlaybackTime>();
    private frequencyBinSubject: Subject<Uint8Array> = new Subject<Uint8Array>();

    private frequencyBinSubscription: Subscription | undefined;

    private audioCollection: EnhancementMediaCollection | undefined;
    private permissionMode: PermissionMode = PermissionMode.Public;
    private audios: EnhancementAudio[] = [];
    private audioCollectionSubject: BehaviorSubject<EnhancementMediaCollection | undefined> = new BehaviorSubject<EnhancementMediaCollection | undefined>(undefined);
    private audiosSubject: BehaviorSubject<EnhancementAudio[]> = new BehaviorSubject<EnhancementAudio[]>([]);

    private currentPlayMode: PlayMode = PlayMode.SingleCycle;
    private playModeSubject: BehaviorSubject<PlayMode> = new BehaviorSubject<PlayMode>(this.currentPlayMode);

    private playingAudio: EnhancementAudio | undefined;
    private playingAudioSubject: BehaviorSubject<EnhancementAudio | undefined> = new BehaviorSubject<EnhancementAudio | undefined>(undefined);

    private lyricsVisibility: boolean = false;

    constructor(
        // private meAudioCollectionService: MeAudioCollectionService,
        // private publicAudioCollectionService: PublicAudioCollectionService
    ) {
        this.player.on(MediaPlayer.events.STREAM_INITIALIZED, () => {
            if (this.playingAudio) {
                this.streamInitializedSubject.next(this.playingAudio);
            }

            if (!(this.audioContext && this.analyserNode && this.mediaElementAudioSourceNode)) {
                this.audioContext = new AudioContext();
                this.analyserNode = this.audioContext.createAnalyser();
                this.mediaElementAudioSourceNode = this.audioContext.createMediaElementSource(this.audioElement);

                this.analyserNode.fftSize = 256;

                this.mediaElementAudioSourceNode.connect(this.analyserNode);
                this.analyserNode.connect(this.audioContext.destination);
            }
        });

        this.player.on(MediaPlayer.events.PLAYBACK_STARTED, () => {
            if (this.playingAudio) {
                this.playbackStartedSubject.next(this.playingAudio);
            }

            this.frequencyBinSubscription = interval(1000 / 30).subscribe(() => {
                if (this.analyserNode) {
                    const data: Uint8Array = new Uint8Array(this.analyserNode.frequencyBinCount);
                    this.analyserNode.getByteFrequencyData(data);
                    this.frequencyBinSubject.next(data);
                }
            });
        });

        this.player.on(MediaPlayer.events.PLAYBACK_PAUSED, () => {
            if (this.playingAudio) {
                this.playbackPausedSubject.next(this.playingAudio);
            }
        });

        this.player.on(MediaPlayer.events.PLAYBACK_PLAYING, () => {
            if (this.playingAudio) {
                this.playbackResumedSubject.next(this.playingAudio);
            }
        });

        this.player.on(MediaPlayer.events.PLAYBACK_ENDED, () => {
            if (this.playingAudio) {
                this.playbackEndedSubject.next(this.playingAudio);
            }

            if (this.currentPlayMode === PlayMode.SingleCycle) {
                this.player.play();
            } else if (this.currentPlayMode === PlayMode.ListLoop) {
                if (this.hasNext()) {
                    this.playNext();
                } else {
                    if (this.audios.length) {
                        this.play(this.audios[0]);
                    }
                }
            } else if (this.currentPlayMode === PlayMode.Random) {
                this.playRandom();
            }

            if (this.frequencyBinSubscription) {
                this.frequencyBinSubscription.unsubscribe();
                this.frequencyBinSubscription = undefined;
            }
        });

        this.player.on(MediaPlayer.events.PLAYBACK_TIME_UPDATED, (event: PlaybackTimeUpdatedEvent) => {
            this.playbackTimeSubject.next({
                duration: event.duration,
                time: event.time || 0
            });
        });
    }

    setPlayList(audioCollection: EnhancementMediaCollection, permissionMode: PermissionMode): void {
        this.audioCollection = audioCollection;
        this.permissionMode = permissionMode;
        this.audioCollectionSubject.next(this.audioCollection);

        const pagination: SortablePagination = new SortablePagination(0, 128, [
            {
                name: 'audio.name',
                direction: 'asc'
            },
            {
                name: 'createTime',
                direction: 'asc'
            }
        ]);

        // if (this.permissionMode === PermissionMode.God) {
        //     //TODO
        // } else if (this.permissionMode === PermissionMode.Me) {
        //     this.meAudioCollectionService.pageBinding(audioCollection.id, pagination).pipe(expand((page: Page<EnhancementAudio>) => {
        //         if (page.last) {
        //             return of();
        //         } else {
        //             pagination.page++;
        //             return this.meAudioCollectionService.pageBinding(audioCollection.id, pagination);
        //         }
        //     }), reduce((left, right) => {
        //         return left.concat(right.content);
        //     }, [] as EnhancementAudio[])).subscribe((audios: EnhancementAudio[]) => {
        //         this.audios = audios;
        //         this.audiosSubject.next(this.audios);
        //     });
        // } else if (this.permissionMode === PermissionMode.Public) {
        //     this.publicAudioCollectionService.pageBinding(audioCollection.id, pagination).pipe(expand((page: Page<EnhancementAudio>) => {
        //         if (page.last) {
        //             return of();
        //         } else {
        //             pagination.page++;
        //             return this.publicAudioCollectionService.pageBinding(audioCollection.id, pagination);
        //         }
        //     }), reduce((left: EnhancementAudio[], right: Page<EnhancementAudio>) => {
        //         return left.concat(right.content);
        //     }, [] as EnhancementAudio[])).subscribe((audios: EnhancementAudio[]) => {
        //         this.audios = audios;
        //         this.audiosSubject.next(this.audios);
        //     });
        // }
    }

    onAudioCollectionChanged(): Observable<EnhancementMediaCollection | undefined> {
        return this.audioCollectionSubject.asObservable();
    }

    onAudiosChanged(): Observable<EnhancementAudio[]> {
        return this.audiosSubject.asObservable();
    }

    onPlayingAudioChanged(): Observable<EnhancementAudio | undefined> {
        return this.playingAudioSubject.asObservable();
    }

    onStreamInitialized(): Observable<EnhancementAudio> {
        return this.streamInitializedSubject.asObservable();
    }

    onPlaybackStarted(): Observable<EnhancementAudio> {
        return this.playbackStartedSubject.asObservable();
    }

    onPlaybackPaused(): Observable<EnhancementAudio> {
        return this.playbackPausedSubject.asObservable();
    }

    onPlaybackResumed(): Observable<EnhancementAudio> {
        return this.playbackResumedSubject.asObservable();
    }

    onPlaybackEnded(): Observable<EnhancementAudio> {
        return this.playbackEndedSubject.asObservable();
    }

    onPlaybackTimeChanged(): Observable<PlaybackTime> {
        return this.playbackTimeSubject.asObservable();
    }

    onFrequencyBinChanged(): Observable<Uint8Array> {
        return this.frequencyBinSubject.asObservable();
    }

    play(audio: EnhancementAudio, autoPlay: boolean = true): void {
        if (this.playingAudio && this.playingAudio.id === audio.id) {
            this.playingAudio = audio;
            this.playingAudioSubject.next(this.playingAudio);

            if (autoPlay && this.isPaused()) {
                this.toggle();
            }
        } else {
            this.playingAudio = audio;
            this.playingAudioSubject.next(this.playingAudio);

            this.player.setAutoPlay(autoPlay);
            this.player.initialize(this.audioElement, `/service-media/stream/audio/${audio.id}/manifest`, autoPlay);

            if (this.lyricsVisibility) {
                this.lyricsVisibility = this.playingAudio.lyrics.lines.length > 0;
            }
        }
    }

    reset(): void {
        if (this.player.isReady()) {
            this.player.reset();
        }

        this.playingAudio = undefined;
        this.playingAudioSubject.next(this.playingAudio);
    }

    playRandom(): void {
        const currentIndex: number = this.obtainPlayingAudioIndex();
        const leftIndex: number = Math.floor(currentIndex * Math.random());
        const rightIndex: number = currentIndex + Math.floor((this.audios.length - currentIndex - 1) * Math.random()) + 1;
        if (currentIndex === 0) {
            this.play(this.audios[rightIndex]);
        } else if (currentIndex === this.audios.length - 1) {
            this.play(this.audios[leftIndex]);
        } else {
            if (Math.random() < 0.5) {
                this.play(this.audios[leftIndex]);
            } else {
                this.play(this.audios[rightIndex]);
            }
        }
    }

    playNext(): void {
        if (this.currentPlayMode === PlayMode.Random) {
            this.playRandom();
        } else {
            if (this.hasNext()) {
                const playingAudioIndex: number = this.obtainPlayingAudioIndex();
                if (playingAudioIndex >= 0) {
                    this.play(this.audios[playingAudioIndex + 1]);
                } else {
                    this.play(this.audios[0]);
                }
            }
        }
    }

    playPrevious(): void {
        if (this.currentPlayMode === PlayMode.Random) {
            this.playRandom();
        } else {
            if (this.hasPrevious()) {
                const playingAudioIndex: number = this.obtainPlayingAudioIndex();
                if (playingAudioIndex >= 0) {
                    this.play(this.audios[playingAudioIndex - 1]);
                } else {
                    this.play(this.audios[this.audios.length - 1]);
                }
            }
        }
    }

    hasNext(): boolean {
        if (this.audios.length) {
            if (this.currentPlayMode === PlayMode.Random) {
                return true;
            } else {
                const playingAudioIndex: number = this.obtainPlayingAudioIndex();
                if (playingAudioIndex >= 0) {
                    if (playingAudioIndex < this.audios.length - 1) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            }
        } else {
            return false;
        }
    }

    hasPrevious(): boolean {
        if (this.audios.length) {
            if (this.currentPlayMode === PlayMode.Random) {
                return true;
            } else {
                const playingAudioIndex: number = this.obtainPlayingAudioIndex();
                if (playingAudioIndex >= 0) {
                    if (playingAudioIndex > 0) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            }
        } else {
            return false;
        }
    }

    private obtainPlayingAudioIndex(): number {
        if (this.playingAudio) {
            return this.audios.map((audio: EnhancementAudio) => {
                return audio.id;
            }).indexOf(this.playingAudio.id);
        } else {
            return -1;
        }
    }

    audio(): EnhancementAudio | undefined {
        return this.playingAudio;
    }

    isReady(): boolean {
        return this.player.isReady();
    }

    toggle(): void {
        if (this.player.isPaused()) {
            this.resume();
        } else {
            this.pause();
        }
    }

    isPaused(): boolean {
        return this.player.isPaused();
    }

    pause(): void {
        if (this.player.isReady()) {
            this.player.pause();
        }
    }

    resume(): void {
        if (this.player.isReady()) {
            this.player.play();
        }
    }

    isSeeking(): boolean {
        return this.player.isSeeking();
    }

    seek(time: number): void {
        if (this.player.isReady()) {
            this.player.seek(time);
        }
    }

    setPlaybackRate(playbackRate: number): void {
        if (this.player.isReady()) {
            this.player.setPlaybackRate(playbackRate);
        }
    }

    resumePlaybackRate(): void {
        if (this.player.isReady()) {
            this.setPlaybackRate(1);
        }
    }

    isMuted(): boolean {
        return this.player.isMuted();
    }

    mute(): void {
        if (this.player.isReady()) {
            this.player.setMute(true);
        }
    }

    unmute(): void {
        if (this.player.isReady()) {
            this.player.setMute(false);
        }
    }

    getVolume(): number {
        return this.player.getVolume();
    }

    setVolume(value: number): void {
        if (this.player.isReady()) {
            this.player.setVolume(value);
        }
    }

    time(): number {
        return this.player.time();
    }

    duration(): number {
        return this.player.duration();
    }

    isShowLyrics(): boolean {
        return this.lyricsVisibility;
    }

    canToggleLyricsVisibility(): boolean {
        if (this.playingAudio) {
            return this.playingAudio.lyrics.lines.length > 0;
        } else {
            return false;
        }
    }

    toggleLyricsVisibility(): void {
        this.lyricsVisibility = !this.lyricsVisibility;
    }

    playMode(): PlayMode {
        return this.currentPlayMode;
    }

    setPlayMode(playMode: PlayMode): void {
        this.currentPlayMode = playMode;
        this.playModeSubject.next(this.currentPlayMode);
    }

    togglePlayMode(): void {
        this.setPlayMode(Object.keys(PlayMode)[(Object.keys(PlayMode).indexOf(this.currentPlayMode) + 1) % Object.keys(PlayMode).length] as PlayMode);
    }

}
