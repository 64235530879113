import {Component, Inject, OnInit} from '@angular/core';
import {SimpleMe} from "../../../../../../../model/data/persist/jpa/entity/user";
import {SortablePagination} from "../../../../../../../model/commons/sortablePagination";
import {CommentReply} from "../../../../../../../model/data/persist/jpa/entity/comment-reply";
import {Page, PageContent} from "../../../../../../../model/commons/page";
import {EnhancementComment} from "../../../../../../../model/data/persist/jpa/entity/comment";
import {ToastService} from "../../../../../toast/toast.service";
import {NgxPermissionsService} from "ngx-permissions";
import {TranslateService} from "@ngx-translate/core";
import {MeService} from "../../../../../../../service/data/me/me.service";
import {MeCommentService} from "../../../../../../../service/data/me/me-comment.service";
import {PublicCommentService} from "../../../../../../../service/data/public/public-comment.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'cs-comment-card-reply-dialog',
    standalone: false,
    templateUrl: './comment-reply-dialog.component.html',
    styleUrls: ['./comment-reply-dialog.component.scss']
})
export class CommentReplyDialogComponent implements OnInit {

    protected me: SimpleMe | undefined;

    protected publicCommentReplyPagination: SortablePagination = new SortablePagination(0, 24, [{
        name: 'createTime',
        direction: 'desc'
    }]);
    protected publicCommentReplyPage: Page<CommentReply> | undefined;
    protected publicCommentReplyPageContent: PageContent<CommentReply> = new PageContent<CommentReply>((commentReply: CommentReply) => {
        return commentReply.id;
    }, (left: CommentReply, right: CommentReply) => {
        return new Date(right.createTime).getTime() - new Date(left.createTime).getTime();
    });

    protected myCommentReplyPageContent: PageContent<CommentReply> = new PageContent<CommentReply>((commentReply: CommentReply) => {
        return commentReply.id;
    });

    protected commentReplyForm: {
        value: string;
    } = {
        value: ''
    };

    protected comment: EnhancementComment | undefined;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any = {},
        private dialogRef: MatDialogRef<CommentReplyDialogComponent>,
        private toastService: ToastService,
        private permissionsService: NgxPermissionsService,
        private translateService: TranslateService,
        private meService: MeService,
        private meCommentService: MeCommentService,
        private publicCommentService: PublicCommentService
    ) {
        this.comment = this.data.comment;

        this.meService.onMeChanged().pipe(takeUntilDestroyed()).subscribe((me: SimpleMe | undefined) => {
            this.me = me;

            if (this.me) {
                this.listMyCommentReply();
            }
        });
    }

    ngOnInit(): void {
        this.loadNext();
    }

    private listMyCommentReply(): void {
        if (this.comment) {
            this.meCommentService.listCommentReplay(this.comment.id).subscribe((comments: CommentReply[]) => {
                this.myCommentReplyPageContent.addAll(comments);
                this.publicCommentReplyPageContent.addAll(comments);
            });
        }
    }

    protected loadNext(): void {
        if (this.comment) {
            if (!this.publicCommentReplyPage || this.publicCommentReplyPage && !this.publicCommentReplyPage.last) {
                this.publicCommentService.pageCommentReply(this.comment.id, this.publicCommentReplyPagination).subscribe((page: Page<CommentReply>) => {
                    this.publicCommentReplyPagination.page++;

                    this.publicCommentReplyPage = page;
                    this.publicCommentReplyPageContent.addAll(this.publicCommentReplyPage.content);
                });
            }
        }
    }

    protected onReply(): void {
        if (this.comment) {
            this.meCommentService.createCommentReplay(this.comment.id, {
                value: this.commentReplyForm.value
            }).subscribe((commentReply: CommentReply) => {
                this.commentReplyForm = {
                    value: ''
                };
                this.myCommentReplyPageContent.add(commentReply);
                this.publicCommentReplyPageContent.add(commentReply);

                if (this.comment) {
                    this.meCommentService.load(this.comment.id).subscribe((comment: EnhancementComment) => {
                        this.comment = comment;
                    });
                }
            });
        }
    }

    protected onClose(): void {
        this.dialogRef.close(this.comment);
    }
}
