import {Component, inject, OnInit} from '@angular/core';
import {SortablePagination} from "../../../../core/model/commons/sortablePagination";
import {Page, PageContent} from "../../../../core/model/commons/page";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {MeBookService} from "../../../../core/service/data/me/me-book.service";
import {ActivatedRoute, Router, RouterModule} from "@angular/router";
import {NgxPermissionsService} from "ngx-permissions";
import {PermissionName} from '../../../../core/model/data/persist/jpa/entity/permission';
import {PermissionMode} from "../../../../core/service/data/permission-mode";
import {CommonModule} from "@angular/common";
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {CsCommonsModule} from "../../../../core/module/commons/cs.commons.module";
import {MePageComponentService} from "../../me-page.component.service";
import {DialogService} from "../../../../core/module/component/dialog/dialog.service";
import {
    MeBookCreateDialogComponent
} from "../../../../core/module/component/media/media/media-book/dialog/me-book-create-dialog/me-book-create-dialog.component";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {ToastService} from "../../../../core/module/component/toast/toast.service";
import {
    MeBookEditDialogComponent
} from "../../../../core/module/component/media/media/media-book/dialog/me-book-edit-dialog/me-book-edit-dialog.component";
import {CsMediaModule} from "../../../../core/module/component/media/cs.media.module";
import {EnhancementBook} from "../../../../core/model/data/persist/jpa/entity/enhancement/enhancement-book";

@Component({
    selector: 'cs-me-book-list-page',
    standalone: true,
    templateUrl: './me-book-list-page.component.html',
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatGridListModule,
        CsCommonsModule,
        CsMediaModule
    ],
    styleUrls: ['./me-book-list-page.component.scss']
})
export class MeBookListPageComponent implements OnInit {
    protected readonly PermissionName = PermissionName;
    protected readonly PermissionMode = PermissionMode;

    private pagination: SortablePagination = new SortablePagination(0, 25, [
        {
            name: 'createTime',
            direction: 'desc'
        }
    ]);
    private page: Page<EnhancementBook> | undefined;

    protected keyword: FormControl = inject(FormBuilder).control('', []);
    protected pageContent: PageContent<EnhancementBook> = new PageContent<EnhancementBook>((book: EnhancementBook) => {
        return book.id;
    }, (left: EnhancementBook, right: EnhancementBook) => {
        return new Date(right.createTime).getTime() - new Date(left.createTime).getTime();
    });

    constructor(
        protected mePageComponentService: MePageComponentService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private permissionsService: NgxPermissionsService,
        private toastService: ToastService,
        private dialogService: DialogService,
        private meBookService: MeBookService
    ) {
        this.mePageComponentService.onScrollToBottom().pipe(takeUntilDestroyed()).subscribe(() => {
            this.loadNext();
        });
    }

    ngOnInit(): void {
        this.loadNext(true);
    }

    protected loadNext(reset: boolean = false): void {
        if (reset) {
            this.pagination.reset();
            this.page = undefined;
            this.pageContent.reset();
        }

        if (!this.page || this.page && !this.page.last) {
            if (this.keyword.value) {
                this.meBookService.search(this.keyword.value, this.pagination).subscribe((page: Page<EnhancementBook>) => {
                    this.pagination.page++;

                    this.page = page;
                    this.pageContent.addAll(this.page.content);
                });
            } else {
                this.meBookService.page(this.pagination).subscribe((page: Page<EnhancementBook>) => {
                    this.pagination.page++;

                    this.page = page;
                    this.pageContent.addAll(this.page.content);
                });
            }
        }
    }

    protected onCreate(): void {
        this.dialogService.open<MeBookCreateDialogComponent, EnhancementBook>(MeBookCreateDialogComponent).afterClosed().subscribe((book: EnhancementBook | undefined) => {
            if (book) {
                this.pageContent.add(book);
            }
        });
    }

    protected onBookClick(book: EnhancementBook): void {
        this.dialogService.open<MeBookEditDialogComponent, EnhancementBook>(MeBookEditDialogComponent, {
            book: book
        }).afterClosed().subscribe((book: EnhancementBook | undefined) => {
            if (book) {
                this.pageContent.update(book);
            }
        });
    }

}
