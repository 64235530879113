import {Component, OnDestroy, OnInit} from '@angular/core';
import {SortablePagination} from "../../../../core/model/commons/sortablePagination";
import {Page, PageContent} from "../../../../core/model/commons/page";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxPermissionsService} from "ngx-permissions";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {PublicBookService} from "../../../../core/service/data/public/public-book.service";
import {HomePageComponentService} from "../../home-page.component.service";
import {PermissionMode} from "../../../../core/service/data/permission-mode";
import {SortMethod} from "../../../../core/model/sort-method";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {CommonModule} from "@angular/common";
import {CsCommonsModule} from "../../../../core/module/commons/cs.commons.module";
import {ToastService} from "../../../../core/module/component/toast/toast.service";
import {DialogService} from "../../../../core/module/component/dialog/dialog.service";
import {CsMediaModule} from "../../../../core/module/component/media/cs.media.module";
import {EnhancementBook} from "../../../../core/model/data/persist/jpa/entity/enhancement/enhancement-book";

@Component({
    selector: 'cs-home-book-list-page',
    standalone: true,
    templateUrl: './home-book-list-page.component.html',
    styleUrls: ['./home-book-list-page.component.scss'],
    imports: [
        CommonModule,
        TranslateModule,
        CsCommonsModule,
        CsMediaModule,
    ]
})
export class HomeBookListPageComponent implements OnInit, OnDestroy {
    protected readonly PermissionMode = PermissionMode;

    protected isLoading: boolean = true;

    protected sortMethod: SortMethod = SortMethod.Default;
    protected pagination: SortablePagination = new SortablePagination(0, 24);
    protected page: Page<EnhancementBook> | undefined;
    protected pageContent: PageContent<EnhancementBook> = new PageContent<EnhancementBook>((book: EnhancementBook) => {
        return book.id;
    });

    constructor(
        protected homeDiscoveryPageComponentService: HomePageComponentService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private permissionsService: NgxPermissionsService,
        private toastService: ToastService,
        private dialogService: DialogService,
        private publicBookService: PublicBookService
    ) {
        this.homeDiscoveryPageComponentService.onScrollToBottom().pipe(takeUntilDestroyed()).subscribe(() => {
            this.loadNext();
        });

        this.homeDiscoveryPageComponentService.onBookChanged().pipe(takeUntilDestroyed()).subscribe((book: EnhancementBook) => {
            this.pageContent.update(book);
        });
    }

    ngOnInit(): void {
        this.loadNext(true);
    }

    ngOnDestroy(): void {
    }

    private loadNext(reset: boolean = false): void {
        if (reset) {
            this.pagination.reset();
            this.page = undefined;
            this.pageContent.reset();
        }

        if (!this.page || this.page && !this.page.last) {
            this.isLoading = true;
            this.publicBookService.page(this.sortMethod, this.pagination).subscribe((page: Page<EnhancementBook>) => {
                this.pagination.page++;

                this.page = page;
                this.pageContent.addAll(this.page.content);

                this.isLoading = false;
            });
        }
    }

    protected onBookClick(book: EnhancementBook): void {
        this.router.navigate([`/book/${book.id}`]).then();
    }

}

