import {Component, inject} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {EnhancementMediaCollection} from "../../../../../../model/data/persist/jpa/entity/media-collection";
import {VisibleScope} from "../../../../../../model/data/persist/jpa/entity/visible-scope";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToastService} from "../../../../toast/toast.service";
import {MatDialogRef} from "@angular/material/dialog";
import {MeMediaCollectionService} from "../../../../../../service/data/me/me-media-collection.service";
import {PermissionService} from "../../../../../../service/data/god/permission.service";
import {DialogService} from "../../../../dialog/dialog.service";
import {MediaCategory} from "../../../../../../model/data/persist/jpa/entity/media";

@Component({
    selector: 'cs-me-media-collection-create-page',
    standalone: false,
    templateUrl: './me-media-collection-create-dialog.component.html',
    styleUrls: ['./me-media-collection-create-dialog.component.scss']
})
export class MeMediaCollectionCreateDialogComponent {
    protected readonly VisibleScope = VisibleScope;
    protected readonly MediaCategory = MediaCategory;

    protected mediaCollectionForm: FormGroup = inject(FormBuilder).group({
        name: ['', Validators.required],
        description: ['', Validators.required],
        visibleScope: [VisibleScope.Private, Validators.required],
        category: [MediaCategory.Image, Validators.required]
    });

    constructor(
        private dialogRef: MatDialogRef<MeMediaCollectionCreateDialogComponent>,
        private translateService: TranslateService,
        private permissionService: PermissionService,
        private dialogService: DialogService,
        private toastService: ToastService,
        private meMediaCollectionService: MeMediaCollectionService,
    ) {
    }

    submit(): void {
        this.meMediaCollectionService.create(this.mediaCollectionForm.value).subscribe((mediaCollection: EnhancementMediaCollection) => {
            this.toastService.show('COMMONS.SUCCESS');
            this.dialogRef.close(true);
        });
    }

    protected onClose(): void {
        this.dialogRef.close();
    }

}
