<mat-card class="w-full h-full">
    <mat-card-header class="w-full flex-grow-0">
        <mat-icon fontIcon="kid_star"></mat-icon><span class="ms-2 inline-flex flex-row justify-start items-center">{{ 'Page.MePage.MeMediaCollectionPage.Dialog.MeMediaCollectionEditDialog.TITLE' | translate }}</span>
    </mat-card-header>
    @if (mediaCollection) {
        <mat-card-content class="w-full flex-grow h-0 grid grid-rows-12 grid-cols-12 gap-1">
            <div class="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-8 xl:col-span-8 2xl:col-span-9 row-span-6 sm:row-span-6 md:row-span-12 lg:row-span-12 xl:row-span-12 2xl:row-span-12">
                <mat-card class="w-full h-full" appearance="outlined">
                    <div class="w-full flex-grow-0 ps-4 pe-2 py-2 flex flex-row justify-between items-center">
                        <div class="flex-grow h-full flex flex-row justify-start items-center">
                        </div>
                        <div class="flex-grow-0 h-full flex flex-row justify-start items-center">
                            @if (selectBinding) {
                                <button class="me-1" mat-icon-button (click)="onEditBinding(selectBinding)"><mat-icon fontIcon="preview"></mat-icon></button>
                            }
                            <button class="mx-1" mat-icon-button [disabled]="mediaCollection.systemReserved" (click)="onCreateBinding()"><mat-icon fontIcon="add"></mat-icon></button>
                            <button class="ms-1" mat-icon-button [disabled]="mediaCollection.systemReserved" (click)="onDeleteBinding()"><mat-icon fontIcon="remove"></mat-icon></button>
                        </div>
                    </div>
                    <mat-selection-list class="w-full flex-grow h-0 p-0 overflow-y-auto" [multiple]="false" csScrollTracker (scrollToBottom)="loadNextBindings()">
                        @for(media of pageContent.values(); track media.id) {
                            <mat-list-option class="w-full" [value]="media.id" (click)="onSelectBinding(media)" (dblclick)="onEditBinding(media)">
                                @if (media.category === MediaCategory.Book) {
                                    {{ media | bookNameTranslate }}
                                } @else {
                                    {{ media.name }}
                                }
                            </mat-list-option>
                        }
                    </mat-selection-list>
                </mat-card>
            </div>
            <div class="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-4 2xl:col-span-3 row-span-6 sm:row-span-6 md:row-span-12 lg:row-span-12 xl:row-span-12 2xl:row-span-12">
                <mat-card class="w-full h-full" appearance="outlined">
                    <mat-card-content class="w-full flex-grow h-0">
                        <form class="w-full" [formGroup]="mediaCollectionForm">
                            <mat-form-field class="w-full" appearance="outline">
                                <mat-label>{{ 'COMMONS.NAME' | translate }}</mat-label>
                                <input matInput type="text" placeholder="{{ 'COMMONS.NAME_HINT' | translate }}" formControlName="name">
                            </mat-form-field>
                            <mat-form-field class="w-full" appearance="outline">
                                <mat-label>{{ 'COMMONS.DESCRIPTION' | translate }}</mat-label>
                                <textarea matInput [rows]="3" type="text" placeholder="{{ 'COMMONS.DESCRIPTION_HINT' | translate }}" formControlName="description"></textarea>
                            </mat-form-field>
                            <mat-form-field class="w-full" appearance="outline">
                                <mat-label>{{ 'COMMONS.SCOPE' | translate }}</mat-label>
                                <mat-select formControlName="visibleScope">
                                    <mat-option [value]="VisibleScope.Private">{{ 'ENUMERATION.VisibleScope.Private' | translate }}</mat-option>
                                    <mat-option [value]="VisibleScope.Public">{{ 'ENUMERATION.VisibleScope.Public' | translate }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </form>
                        <mat-card class="w-full aspect-16/10" appearance="outlined">
                            <cs-image-background class="w-full h-full" [image]="mediaCollection.coverImage" [resolution]="ImageResolution.Large" [dark]="true">
                                <div class="w-full h-full flex flex-row justify-center items-center">
                                    <button mat-flat-button (click)="onChangeCoverImage()">
                                        <mat-icon fontIcon="replace_image"></mat-icon>{{ 'COMMONS.CHANGE' | translate }}
                                    </button>
                                </div>
                            </cs-image-background>
                        </mat-card>
                        <!--        <mat-form-field class="w-full" appearance="outline">-->
                        <!--            <mat-label>{{ 'COMMONS.ARTIST' | translate }}</mat-label>-->
                        <!--            <mat-chip-grid #artistChipGrid>-->
                        <!--                @for (tag of tags; track tag) {-->
                        <!--                    <mat-chip-row (removed)="removeTag($event)">{{ tag }}<button matChipRemove><mat-icon fontIcon="close"></mat-icon></button></mat-chip-row>-->
                        <!--                }-->
                        <!--            </mat-chip-grid>-->
                        <!--            <input [matChipInputFor]="artistChipGrid" (matChipInputTokenEnd)="addTag($event)">-->
                        <!--        </mat-form-field>-->
                    </mat-card-content>
                    <mat-card-actions class="w-full flex flex-row justify-end items-center">
                        <button class="me-1" mat-flat-button [disabled]="!mediaCollectionForm.valid" (click)="onSubmit()"><mat-icon fontIcon="check"></mat-icon>{{ 'COMMONS.CONFIRM' | translate }}</button>
                        <button class="ms-1" mat-flat-button (click)="onClose()"><mat-icon fontIcon="close"></mat-icon>{{ 'COMMONS.CANCEL' | translate }}</button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </mat-card-content>
    }
</mat-card>
