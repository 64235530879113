<mat-card class="w-full h-full" appearance="outlined">
    <mat-card-header class="w-full flex-grow-0">
        <mat-icon fontIcon="person"></mat-icon><span class="ms-2 inline-flex flex-row justify-start items-center">{{ (user ? 'Page.MePage.UserPage.Dialog.UserEditDialog.TITLE_EDIT' : 'Page.MePage.UserPage.Dialog.UserEditDialog.TITLE_CREATE') | translate }}</span>
    </mat-card-header>
    <mat-card-content class="w-full flex-grow h-0">
        <form class="w-full" [formGroup]="userForm">
            <mat-form-field class="w-full flex-grow-0" appearance="outline">
                <mat-label>{{ 'COMMONS.NAME' | translate }}</mat-label>
                <input matInput type="text" placeholder="{{ 'COMMONS.NAME_HINT' | translate }}" formControlName="name">
            </mat-form-field>
            <mat-form-field class="w-full flex-grow-0" appearance="outline">
                <mat-label>{{ 'COMMONS.PASSWORD' | translate }}</mat-label>
                <input matInput type="password" placeholder="{{ 'COMMONS.PASSWORD_HINT' | translate }}" formControlName="password">
            </mat-form-field>
            <div class="w-full flex-grow-0 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4">
                @for(role of roles; track role.id) {
                    <mat-checkbox class="w-full h-auto" [disabled]="!userForm.valid" [checked]="isSelectedRole(role)" (change)="toggleRole(role)">
                        {{ role.name }}
                    </mat-checkbox>
                }
            </div>
        </form>
    </mat-card-content>
    <mat-card-actions class="w-full flex-grow-0 flex flex-row justify-end items-center">
        <button class="me-1" mat-flat-button [disabled]="!userForm.valid" (click)="submit()"><mat-icon fontIcon="check"></mat-icon>{{ 'COMMONS.CONFIRM' | translate }}</button>
        <button class="ms-1" mat-flat-button (click)="close()"><mat-icon fontIcon="close"></mat-icon>{{ 'COMMONS.CANCEL' | translate }}</button>
    </mat-card-actions>
</mat-card>
