import {Component} from '@angular/core';
import {CsCommonsModule} from "../../../core/module/commons/cs.commons.module";

@Component({
    selector: 'cs-me-image-page',
    standalone: true,
    templateUrl: './me-image-page.component.html',
    styleUrls: ['./me-image-page.component.scss'],
    imports: [
        CsCommonsModule
    ]
})
export class MeImagePageComponent {

}
