import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PermissionMode} from '../../../../../service/data/permission-mode';
import {MediaFileStatus} from '../../../../../model/data/persist/jpa/entity/abstract-media-file';
import {VisibleScope} from '../../../../../model/data/persist/jpa/entity/visible-scope';
import {ImageResolution} from '../../../../../model/data/persist/jpa/entity/image-file';
import {EnhancementMediaCollection} from "../../../../../model/data/persist/jpa/entity/media-collection";

@Component({
    selector: 'cs-media-collection-card',
    standalone: false,
    templateUrl: './media-collection-card.component.html',
    styleUrls: ['./media-collection-card.component.scss']
})
export class MediaCollectionCardComponent {
    protected readonly PermissionMode = PermissionMode;
    protected readonly ImageResolution = ImageResolution;
    protected readonly MediaFileStatus = MediaFileStatus;
    protected readonly VisibleScope = VisibleScope;

    @Input()
    public permissionMode: PermissionMode = PermissionMode.God;
    @Input()
    public mediaCollection: EnhancementMediaCollection | undefined;
    @Output()
    public mediaCollectionClick: EventEmitter<EnhancementMediaCollection> = new EventEmitter<EnhancementMediaCollection>();

    protected onMediaCollectionClick(): void {
        if (this.mediaCollection) {
            this.mediaCollectionClick.next(this.mediaCollection);
        }
    }

}
