import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SortablePagination} from "../../../model/commons/sortablePagination";
import {Page} from "../../../model/commons/page";
import {MediaCollectionCreateRequest} from "../../../model/request/media-collection-create-request";
import {MediaCollectionUpdateRequest} from "../../../model/request/media-collection-update-request";
import {MediaCategory} from "../../../model/data/persist/jpa/entity/media";
import {Service} from '../service';
import {EnhancementMediaCollection} from "../../../model/data/persist/jpa/entity/media-collection";
import {EnhancementMedia} from "../../../model/data/persist/jpa/entity/enhancement/enhancement-media";

@Injectable({
    providedIn: 'root'
})
export class MeMediaCollectionService {

    constructor(
        private http: HttpClient
    ) {
    }

    listByCategory(category: MediaCategory): Observable<EnhancementMediaCollection[]> {
        return this.http.get<EnhancementMediaCollection[]>(`${Service.Media.prefixUrl}/me/media-collection/category/${category}/all`);
    }

    list(): Observable<EnhancementMediaCollection[]> {
        return this.http.get<EnhancementMediaCollection[]>(`${Service.Media.prefixUrl}/me/media-collection/all`);
    }

    page(pagination: SortablePagination): Observable<Page<EnhancementMediaCollection>> {
        return this.http.get<Page<EnhancementMediaCollection>>(`${Service.Media.prefixUrl}/me/media-collection${pagination.toRequestParameter()}`);
    }

    load(id: number): Observable<EnhancementMediaCollection> {
        return this.http.get<EnhancementMediaCollection>(`${Service.Media.prefixUrl}/me/media-collection/${id}`);
    }

    create(request: MediaCollectionCreateRequest): Observable<EnhancementMediaCollection> {
        return this.http.post<EnhancementMediaCollection>(`${Service.Media.prefixUrl}/me/media-collection`, request);
    }

    update(id: number, request: MediaCollectionUpdateRequest): Observable<EnhancementMediaCollection> {
        return this.http.put<EnhancementMediaCollection>(`${Service.Media.prefixUrl}/me/media-collection/${id}`, request);
    }

    updateCoverImage(id: number, mediaId: number): Observable<EnhancementMediaCollection> {
        return this.http.put<EnhancementMediaCollection>(`${Service.Media.prefixUrl}/me/media-collection/${id}/cover/${mediaId}`, '');
    }

    delete(id: number): Observable<EnhancementMediaCollection> {
        return this.http.delete<EnhancementMediaCollection>(`${Service.Media.prefixUrl}/me/media-collection/${id}`);
    }

    pageBinding(id: number, pagination: SortablePagination): Observable<Page<EnhancementMedia>> {
        return this.http.get<Page<EnhancementMedia>>(`${Service.Media.prefixUrl}/me/media-collection/${id}/binding${pagination.toRequestParameter()}`);
    }

    createBindings(id: number, mediaIds: number[]): Observable<EnhancementMediaCollection> {
        return this.http.post<EnhancementMediaCollection>(`${Service.Media.prefixUrl}/me/media-collection/${id}/binding`, mediaIds);
    }

    deleteBindings(id: number, mediaIds: number[]): Observable<EnhancementMediaCollection> {
        return this.http.delete<EnhancementMediaCollection>(`${Service.Media.prefixUrl}/me/media-collection/${id}/binding`, {
            body: mediaIds
        });
    }

}
