import {Component, EventEmitter, Input, Output} from '@angular/core';
import {KatexOptions, MermaidAPI} from "ngx-markdown";

@Component({
    selector: 'cs-markdown-viewer',
    standalone: false,
    templateUrl: './markdown-viewer.component.html',
    styleUrls: ['./markdown-viewer.component.scss']
})
export class MarkdownViewerComponent {

    protected katexOptions: KatexOptions = {
        displayMode: true,
        throwOnError: false,
        errorColor: '#cc0000'
    };

    protected mermaidOptions: MermaidAPI.MermaidConfig = {
        logLevel: 'info',
        theme: 'forest'
    };

    @Input()
    public value: string | undefined;
    @Output()
    public ready: EventEmitter<never> = new EventEmitter<never>();

    protected onReady(): void {
        this.ready.emit();
    }
}
