import {Component, OnInit} from '@angular/core';
import {Router, RouterModule} from "@angular/router";
import {MatDrawerMode, MatSidenavModule} from "@angular/material/sidenav";
import {MatListModule} from "@angular/material/list";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {CommonModule} from "@angular/common";
import {MatToolbarModule} from "@angular/material/toolbar";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {MenuItem} from '../../core/module/component/nav/menu-item';
import {MePageComponentService} from "./me-page.component.service";
import {MeService} from "../../core/service/data/me/me.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {SimpleMe} from "../../core/model/data/persist/jpa/entity/user";
import {PermissionName} from "../../core/model/data/persist/jpa/entity/permission";
import {BreakpointObserver, Breakpoints, BreakpointState} from "@angular/cdk/layout";
import {MatMenuModule} from "@angular/material/menu";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatCardActions} from "@angular/material/card";
import {CsCommonsModule} from "../../core/module/commons/cs.commons.module";
import {CsAvatarModule} from "../../core/module/component/avatar/cs.avatar.module";
import {UserProfile} from "../../core/model/data/persist/jpa/entity/user-profile";

@Component({
    selector: 'cs-me-page',
    standalone: true,
    templateUrl: './me-page.component.html',
    styleUrls: ['./me-page.component.scss'],
    imports: [
        RouterModule,
        CommonModule,
        TranslateModule,
        MatIconModule,
        MatButtonModule,
        MatSidenavModule,
        MatListModule,
        MatToolbarModule,
        MatMenuModule,
        MatInputModule,
        ReactiveFormsModule,
        MatCardActions,
        CsCommonsModule,
        CsAvatarModule,
    ]
})
export class MePageComponent implements OnInit {

    protected sidenavOpen: boolean = true;
    protected sidenavMode: MatDrawerMode = 'side';

    protected menuItems: MenuItem[] = [];
    protected userMenuItems: MenuItem[] = [];

    protected me: SimpleMe | undefined;

    constructor(
        protected systemPageComponentService: MePageComponentService,
        private breakpointObserver: BreakpointObserver,
        private router: Router,
        // private toastService: NbToastrService,
        private translateService: TranslateService,
        // private dialogService: NbDialogService,
        // private audioPlayerService: AudioPlayerService,
        private meService: MeService,
    ) {
        // this.audioPlayerService.pause();

        this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe((breakpointState: BreakpointState) => {
            if ((breakpointState.breakpoints[Breakpoints.XSmall] || breakpointState.breakpoints[Breakpoints.Small])) {
                this.sidenavOpen = false;
                this.sidenavMode = 'over';
            } else {
                this.sidenavOpen = true;
                this.sidenavMode = 'side';
            }
        });

        this.meService.onMeChanged().pipe(takeUntilDestroyed()).subscribe((me: SimpleMe | undefined) => {
            this.me = me;
        });
        this.meService.onMeProfileChanged().pipe(takeUntilDestroyed()).subscribe((meProfile: UserProfile | undefined) => {
            if (meProfile) {
                this.updateMenu();
            }
        });
    }

    ngOnInit(): void {
        this.updateMenu();
    }

    private updateMenu(): void {
        this.menuItems = [
            {
                title: this.translateService.instant('Page.MePage.MENU.HOME'),
                icon: 'home',
                link: '/discovery',
                hidden: false
            },
            {
                title: this.translateService.instant('Page.MePage.MENU.IMAGE'),
                icon: 'photo_library',
                link: '/me/image/list',
                hidden: !this.meService.hasAnyPermissions([PermissionName.IMAGE_FILE_UPLOAD_SELF, PermissionName.IMAGE_EDIT_SELF])
            },
            {
                title: this.translateService.instant('Page.MePage.MENU.AUDIO'),
                icon: 'library_music',
                link: '/me/audio/list',
                hidden: !this.meService.hasAnyPermissions([PermissionName.AUDIO_FILE_UPLOAD_SELF, PermissionName.AUDIO_EDIT_SELF])
            },
            {
                title: this.translateService.instant('Page.MePage.MENU.VIDEO'),
                icon: 'video_library',
                link: '/me/video/list',
                hidden: !this.meService.hasAnyPermissions([PermissionName.VIDEO_FILE_UPLOAD_SELF, PermissionName.VIDEO_EDIT_SELF])
            },
            {
                title: this.translateService.instant('Page.MePage.MENU.BOOK'),
                icon: 'library_books',
                link: '/me/book/list',
                hidden: !this.meService.hasAnyPermissions([PermissionName.BOOK_EDIT_SELF])
            },
            {
                title: this.translateService.instant('Page.MePage.MENU.MEDIA_COLLECTION'),
                icon: 'kid_star',
                link: '/me/media-collection/list',
                hidden: !this.meService.hasAnyPermissions([PermissionName.BOOK_EDIT_SELF])
            },
            {
                title: this.translateService.instant('Page.MePage.MENU.ROLE'),
                icon: 'shield_person',
                link: '/me/role/list',
                hidden: !this.meService.hasAnyPermissions([PermissionName.ROLE_VIEW, PermissionName.ROLE_EDIT])
            },
            {
                title: this.translateService.instant('Page.MePage.MENU.USER'),
                icon: 'person',
                link: '/me/user/list',
                hidden: !this.meService.hasAnyPermissions([PermissionName.USER_VIEW, PermissionName.USER_EDIT])
            },
            {
                title: this.translateService.instant('Page.MePage.MENU.Artist'),
                icon: 'brush',
                link: '/me/artist/list',
                hidden: !this.meService.hasAnyPermissions([PermissionName.USER_VIEW, PermissionName.USER_EDIT])
            },
            {
                title: this.translateService.instant('Page.MePage.MENU.ADVERTISEMENT'),
                icon: 'cast',
                link: '/me/advertisement/list',
                hidden: !this.meService.hasPermissions([PermissionName.ADVERTISEMENT_VIEW])
            },
            {
                title: this.translateService.instant('Page.MePage.MENU.LOGS'),
                icon: 'event_note',
                link: '/me/logs',
                hidden: !this.meService.hasPermissions([PermissionName.LOG_VIEW])
            },
            {
                title: this.translateService.instant('Page.MePage.MENU.CONFIGURATION'),
                icon: 'settings',
                link: '/me/configuration',
                hidden: !this.meService.hasAnyPermissions([PermissionName.SYSTEM_VALUE_VIEW, PermissionName.SYSTEM_VALUE_EDIT])
            }
        ];

        this.userMenuItems = [
            {
                title: this.translateService.instant('Page.MePage.USER_MENU.PROFILE'),
                icon: 'badge',
                link: '/me/profile',
                hidden: false
            },
            {
                title: this.translateService.instant('Page.MePage.USER_MENU.PASSWORD'),
                icon: 'password',
                link: '/me/password',
                hidden: false
            },
            {
                title: this.translateService.instant('COMMONS.LOGOUT'),
                icon: 'logout',
                link: '/auth/logout',
                hidden: false
            }
        ];
    }

}
