import {Directive, HostListener, Input} from '@angular/core';
import {Router} from "@angular/router";

@Directive({
    selector: '[csBack]'
})
export class BackDirective {

    @Input()
    public defaultBack: string | undefined;

    constructor(
        private router: Router,
    ) {
    }

    @HostListener('click')
    protected onClick(): void {
        try {
            const lastURL: URL = new URL(window.document.referrer);
            if (lastURL.hostname === window.location.hostname) {
                if (window.history.length <= 2) {
                    this.backDefault();
                } else {
                    window.history.back();
                }
            } else {
                this.backDefault();
            }
        } catch (e) {
            if (window.history.length <= 2) {
                this.backDefault();
            } else {
                window.history.back();
            }
        }
    }

    private backDefault(): void {
        if (this.defaultBack) {
            this.router.navigate([this.defaultBack]).then();
        } else {
            this.router.navigate(['/']).then();
        }
    }

}
