import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ComponentType} from "@angular/cdk/overlay";
import {ConfirmDialogComponent} from "./confirm-dialog/confirm-dialog.component";

@Injectable()
export class DialogService {

    constructor(
        private dialog: MatDialog
    ) {
    }

    open<T, R = void>(component: ComponentType<T>, data?: any): MatDialogRef<T, R> {
        return this.dialog.open<T, any, R>(component, {
            width: '96dvw',
            height: '96dvh',
            maxWidth: '96dvw',
            maxHeight: '96dvh',
            data: data
        });
    }

    confirm(): MatDialogRef<ConfirmDialogComponent, boolean> {
        return this.dialog.open<ConfirmDialogComponent, void, boolean>(ConfirmDialogComponent, {
            width: 'auto',
            height: 'auto',
            maxWidth: '96dvw',
            maxHeight: '96dvh'
        });
    }

    closeAll(): void {
        this.dialog.closeAll();
    }
}
