import {Route} from "@angular/router";
import {CustomizationResolver} from "../../core/resolver/customization.resolver";
import {MeAuthResolver} from "../../core/resolver/me.auth.resolver";
import {MePageComponent} from "./me-page.component";
import {MeImagePageComponent} from "./me-image-page/me-image-page.component";
import {MeImageListPageComponent} from "./me-image-page/me-image-list-page/me-image-list-page.component";
import {MeAudioPageComponent} from "./me-audio-page/me-audio-page.component";
import {MeAudioListPageComponent} from "./me-audio-page/me-audio-list-page/me-audio-list-page.component";
import {MeVideoPageComponent} from "./me-video-page/me-video-page.component";
import {MeVideoListPageComponent} from "./me-video-page/me-video-list-page/me-video-list-page.component";
import {MeBookPageComponent} from "./me-book-page/me-book-page.component";
import {MeBookListPageComponent} from "./me-book-page/me-book-list-page/me-book-list-page.component";
import {MeMediaCollectionPageComponent} from "./me-media-collection-page/me-media-collection-page.component";
import {
    MeMediaCollectionListPageComponent
} from "./me-media-collection-page/me-media-collection-list-page/me-media-collection-list-page.component";
import {MeRolePageComponent} from "./me-role-page/me-role-page.component";
import {MeRoleListPageComponent} from "./me-role-page/me-role-list-page/me-role-list-page.component";
import {MeUserPageComponent} from "./me-user-page/me-user-page.component";
import {MeUserListPageComponent} from "./me-user-page/me-user-list-page/me-user-list-page.component";
import {MeConfigurationPageComponent} from "./me-configuration-page/me-configuration-page.component";
import {MeLogPageComponent} from "./me-log-page/me-log-page.component";
import {MeProfilePageComponent} from "./me-profile-page/me-profile-page.component";

export const mePageRoute: Route = {
    path: 'me',
    component: MePageComponent,
    resolve: [
        MeAuthResolver
    ],
    children: [
        {
            path: 'profile',
            component: MeProfilePageComponent
        },
        {
            path: 'image',
            component: MeImagePageComponent,
            children: [
                {
                    path: 'list',
                    component: MeImageListPageComponent
                }
            ]
        },
        {
            path: 'audio',
            component: MeAudioPageComponent,
            children: [
                {
                    path: 'list',
                    component: MeAudioListPageComponent
                }
            ]
        },
        {
            path: 'video',
            component: MeVideoPageComponent,
            children: [
                {
                    path: 'list',
                    component: MeVideoListPageComponent
                }
            ]
        },
        {
            path: 'book',
            component: MeBookPageComponent,
            children: [
                {
                    path: 'list',
                    component: MeBookListPageComponent
                }
            ]
        },
        {
            path: 'media-collection',
            component: MeMediaCollectionPageComponent,
            children: [
                {
                    path: 'list',
                    component: MeMediaCollectionListPageComponent
                }
            ]
        },
        {
            path: 'role',
            component: MeRolePageComponent,
            children: [
                {
                    path: 'list',
                    component: MeRoleListPageComponent
                }
            ]
        },
        {
            path: 'user',
            component: MeUserPageComponent,
            children: [
                {
                    path: 'list',
                    component: MeUserListPageComponent
                }
            ]
        },
        {
            path: 'logs',
            component: MeLogPageComponent
        },
        {
            path: 'configuration',
            component: MeConfigurationPageComponent
        },
    ]
};
