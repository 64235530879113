import {Component, Inject, inject, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {PermissionService} from "../../../../../../service/data/god/permission.service";
import {DialogService} from "../../../../dialog/dialog.service";
import {ToastService} from "../../../../toast/toast.service";
import {VisibleScope} from '../../../../../../model/data/persist/jpa/entity/visible-scope';
import {PermissionMode} from '../../../../../../service/data/permission-mode';
import {
    EnhancementMediaCollection,
    MediaCollectionName
} from "../../../../../../model/data/persist/jpa/entity/media-collection";
import {MeMediaCollectionService} from "../../../../../../service/data/me/me-media-collection.service";
import {ImageResolution} from "../../../../../../model/data/persist/jpa/entity/image-file";
import {ImageBackgroundSize} from "../../../../../commons/component/image/image-background/image-background.component";
import {MediaCollectionNameTranslatePipe} from "../../../../../commons/pipe/media-collection-name-translate.pipe";
import {
    MeImageSelectDialogComponent
} from "../../../media/media-image/dialog/me-image-select-dialog/me-image-select-dialog.component";
import {Page, PageContent} from "../../../../../../model/commons/page";
import {SortablePagination} from "../../../../../../model/commons/sortablePagination";
import {
    MeImageEditDialogComponent
} from "../../../media/media-image/dialog/me-image-edit-dialog/me-image-edit-dialog.component";
import {
    MeAudioEditDialogComponent
} from "../../../media/media-audio/dialog/me-audio-edit-dialog/me-audio-edit-dialog.component";
import {
    MeVideoEditDialogComponent
} from "../../../media/media-video/dialog/me-video-edit-dialog/me-video-edit-dialog.component";
import {
    MeBookEditDialogComponent
} from "../../../media/media-book/dialog/me-book-edit-dialog/me-book-edit-dialog.component";
import {
    MeArticleEditDialogComponent
} from "../../../media/media-book/dialog/me-article-edit-dialog/me-article-edit-dialog.component";
import {
    MeAudioSelectDialogComponent
} from "../../../media/media-audio/dialog/me-audio-select-dialog/me-audio-select-dialog.component";
import {
    MeVideoSelectDialogComponent
} from "../../../media/media-video/dialog/me-video-select-dialog/me-video-select-dialog.component";
import {
    MeBookSelectDialogComponent
} from "../../../media/media-book/dialog/me-book-select-dialog/me-book-select-dialog.component";
import {EnhancementImage} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-image";
import {EnhancementAudio} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-audio";
import {EnhancementVideo} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-video";
import {EnhancementBook} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-book";
import {EnhancementArticle} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-article";
import {EnhancementMedia} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-media";
import {MediaCategory} from '../../../../../../model/data/persist/jpa/entity/media';

@Component({
    selector: 'cs-me-media-collection-edit-dialog',
    standalone: false,
    templateUrl: './me-media-collection-edit-dialog.component.html',
    styleUrls: ['./me-media-collection-edit-dialog.component.scss']
})
export class MeMediaCollectionEditDialogComponent implements OnInit {
    protected readonly ImageResolution = ImageResolution;
    protected readonly ImageBackgroundSize = ImageBackgroundSize;
    protected readonly VisibleScope = VisibleScope;
    protected readonly PermissionMode = PermissionMode;
    protected readonly MediaCategory = MediaCategory;

    protected selectBinding: EnhancementMedia | undefined;

    protected pagination: SortablePagination = new SortablePagination(0, 24);
    protected page: Page<EnhancementMedia> | undefined;
    protected pageContent: PageContent<EnhancementMedia> = new PageContent<EnhancementMedia>((media: EnhancementMedia) => {
        return media.id;
    }, (left: EnhancementMedia, right: EnhancementMedia) => {
        return new Date(right.createTime).getTime() - new Date(left.createTime).getTime();
    });

    protected mediaCollectionForm: FormGroup = inject(FormBuilder).group({
        name: ['', Validators.required],
        description: ['', Validators.required],
        visibleScope: [VisibleScope.Private, Validators.required]
    });

    protected mediaCollection: EnhancementMediaCollection | undefined;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any = {},
        private dialogRef: MatDialogRef<MeMediaCollectionEditDialogComponent>,
        private mediaCollectionNameTranslatePipe: MediaCollectionNameTranslatePipe,
        private translateService: TranslateService,
        private permissionService: PermissionService,
        private dialogService: DialogService,
        private toastService: ToastService,
        private meMediaCollectionService: MeMediaCollectionService) {
        this.mediaCollection = this.data.mediaCollection;
        // this.menuService.onItemClick().pipe(takeUntilDestroyed(), filter((bag: NbMenuBag) => {
        //     return bag.tag === this.MORE_MENU && bag.item.data['type'] === this.MORE_MENU_STATISTICS;
        // })).subscribe(() => {
        //     this.dialogService.open(MeMediaCollectionStatisticsViewDialogComponent, {
        //         hasBackdrop: true,
        //         closeOnBackdropClick: false,
        //         closeOnEsc: true,
        //         hasScroll: false,
        //         autoFocus: true,
        //         data: {
        //             media-mediaCollection: this.media-mediaCollection
        //         }
        //     });
        // });
        // this.menuService.onItemClick().pipe(takeUntilDestroyed(), filter((bag: NbMenuBag) => {
        //     return bag.tag === this.MORE_MENU && bag.item.data['type'] === this.MORE_MENU_DELETE;
        // })).subscribe(() => {
        //     this.dialogService.open(ConfirmDialogComponent, {
        //         hasBackdrop: true,
        //         closeOnBackdropClick: false,
        //         closeOnEsc: true,
        //         hasScroll: false,
        //         autoFocus: true
        //     }).onClose.subscribe((result: boolean) => {
        //         if (result) {
        //             if (this.media-mediaCollection) {
        //                 this.meMediaCollectionService.delete(this.media-mediaCollection.id).subscribe(() => {
        //                     this.close();
        //                     this.toastService.show(this.translateService.instant('COMMONS.SUCCESS_CONTENT'), this.translateService.instant('COMMONS.SUCCESS'), {
        //                         status: 'success',
        //                         duration: 2500
        //                     });
        //                 });
        //             }
        //         }
        //     });
        // });
    }

    ngOnInit(): void {
        if (this.mediaCollection) {
            const nameFiled: AbstractControl<string, string> | null = this.mediaCollectionForm.get('name');
            if (nameFiled) {
                nameFiled.patchValue(this.mediaCollectionNameTranslatePipe.transform(this.mediaCollection));
            }

            const descriptionFiled: AbstractControl<string, string> | null = this.mediaCollectionForm.get('description');
            if (descriptionFiled) {
                descriptionFiled.patchValue(this.mediaCollection.description);
            }

            const visibleScopeFiled: AbstractControl<VisibleScope, VisibleScope> | null = this.mediaCollectionForm.get('visibleScope');
            if (visibleScopeFiled) {
                visibleScopeFiled.patchValue(this.mediaCollection.visibleScope);
            }

            if (!this.mediaCollection.updatable) {
                this.mediaCollectionForm.disable();
            }
        }

        this.loadNextBindings(true);
    }

    protected loadNextBindings(reset: boolean = false): void {
        if (this.mediaCollection) {
            if (reset) {
                this.pagination.reset();
                this.page = undefined;
                this.pageContent.reset();
            }

            if (!this.page || this.page && !this.page.last) {
                this.meMediaCollectionService.pageBinding(this.mediaCollection.id, this.pagination).subscribe((page: Page<EnhancementMedia>) => {
                    this.pagination.page++;

                    this.page = page;
                    this.pageContent.addAll(this.page.content);
                });
            }
        }
    }

    protected onSelectBinding(media: EnhancementMedia): void {
        this.selectBinding = media;
    }

    protected onEditBinding(media: EnhancementMedia): void {
        switch (media.category) {
            case MediaCategory.Image:
                this.dialogService.open<MeImageEditDialogComponent, EnhancementImage>(MeImageEditDialogComponent, {
                    image: media as EnhancementImage
                }).afterClosed().subscribe((image: EnhancementImage | undefined) => {
                    if (image) {
                        this.pageContent.update(image);
                    }
                });
                break;
            case MediaCategory.Audio:
                this.dialogService.open<MeAudioEditDialogComponent, EnhancementAudio>(MeAudioEditDialogComponent, {
                    audio: media as EnhancementAudio
                }).afterClosed().subscribe((audio: EnhancementAudio | undefined) => {
                    if (audio) {
                        this.pageContent.update(audio);
                    }
                });
                break;
            case MediaCategory.Video:
                this.dialogService.open<MeVideoEditDialogComponent, EnhancementVideo>(MeVideoEditDialogComponent, {
                    video: media as EnhancementVideo
                }).afterClosed().subscribe((video: EnhancementVideo | undefined) => {
                    if (video) {
                        this.pageContent.update(video);
                    }
                });
                break;
            case MediaCategory.Book:
                this.dialogService.open<MeBookEditDialogComponent, EnhancementBook>(MeBookEditDialogComponent, {
                    book: media as EnhancementBook
                }).afterClosed().subscribe((book: EnhancementBook | undefined) => {
                    if (book) {
                        this.pageContent.update(book);
                    }
                });
                break;
            case MediaCategory.Article:
                this.dialogService.open<MeArticleEditDialogComponent, EnhancementArticle>(MeArticleEditDialogComponent, {
                    article: media as EnhancementArticle
                }).afterClosed().subscribe((article: EnhancementArticle | undefined) => {
                    if (article) {
                        this.pageContent.update(article);
                    }
                });
                break;
        }
    }

    protected onCreateBinding(): void {
        if (this.mediaCollection) {
            switch (this.mediaCollection.category) {
                case MediaCategory.Image:
                    this.dialogService.open<MeImageSelectDialogComponent, EnhancementImage[]>(MeImageSelectDialogComponent, {
                        single: false,
                        systemReserved: true,
                        defaultCollection: true
                    }).afterClosed().subscribe((images: EnhancementImage[] | undefined) => {
                        if (this.mediaCollection && images) {
                            this.meMediaCollectionService.createBindings(this.mediaCollection.id, images.map((image: EnhancementImage) => {
                                return image.id;
                            })).subscribe((mediaCollection: EnhancementMediaCollection) => {
                                this.pageContent.addAll(images);
                            });
                        }
                    });
                    break;
                case MediaCategory.Audio:
                    this.dialogService.open<MeAudioSelectDialogComponent, EnhancementAudio[]>(MeAudioSelectDialogComponent, {
                        single: false,
                        systemReserved: true,
                        defaultCollection: true
                    }).afterClosed().subscribe((audios: EnhancementAudio[] | undefined) => {
                        if (this.mediaCollection && audios) {
                            this.meMediaCollectionService.createBindings(this.mediaCollection.id, audios.map((audio: EnhancementAudio) => {
                                return audio.id;
                            })).subscribe((mediaCollection: EnhancementMediaCollection) => {
                                this.pageContent.addAll(audios);
                            });
                        }
                    });
                    break;
                case MediaCategory.Video:
                    this.dialogService.open<MeVideoSelectDialogComponent, EnhancementVideo[]>(MeVideoSelectDialogComponent, {
                        single: false,
                        systemReserved: true,
                        defaultCollection: true
                    }).afterClosed().subscribe((videos: EnhancementVideo[] | undefined) => {
                        if (this.mediaCollection && videos) {
                            this.meMediaCollectionService.createBindings(this.mediaCollection.id, videos.map((video: EnhancementVideo) => {
                                return video.id;
                            })).subscribe((mediaCollection: EnhancementMediaCollection) => {
                                this.pageContent.addAll(videos);
                            });
                        }
                    });
                    break;
                case MediaCategory.Book:
                    this.dialogService.open<MeBookSelectDialogComponent, EnhancementBook[]>(MeBookSelectDialogComponent, {
                        single: false,
                        systemReserved: true,
                        defaultCollection: true
                    }).afterClosed().subscribe((books: EnhancementBook[] | undefined) => {
                        if (this.mediaCollection && books) {
                            this.meMediaCollectionService.createBindings(this.mediaCollection.id, books.map((book: EnhancementBook) => {
                                return book.id;
                            })).subscribe((mediaCollection: EnhancementMediaCollection) => {
                                this.pageContent.addAll(books);
                            });
                        }
                    });
                    break;
                // case MediaCategory.Article:
                //     this.dialogService.open<MeArticleEditDialogComponent, EnhancementArticle>(MeArticleEditDialogComponent, {
                //         article: media as EnhancementArticle
                //     }).afterClosed().subscribe((article: EnhancementArticle | undefined) => {
                //         if (article) {
                //             this.pageContent.update(article);
                //         }
                //     });
                //     break;
            }
        }
    }

    protected onDeleteBinding(): void {
        if (this.mediaCollection && this.selectBinding) {
            this.meMediaCollectionService.deleteBindings(this.mediaCollection.id, [this.selectBinding.id]).subscribe((mediaCollection: EnhancementMediaCollection) => {
                if (this.selectBinding) {
                    this.pageContent.remove(this.selectBinding);
                    this.selectBinding = undefined;
                }
            });
        }
    }

    protected onChangeCoverImage(): void {
        this.dialogService.open<MeImageSelectDialogComponent, EnhancementImage[]>(MeImageSelectDialogComponent, {
            single: true,
            scopes: [
                MediaCollectionName.DEFAULT_MEDIA_COLLECTION_COVER_IMAGE_COLLECTION
            ]
        }).afterClosed().subscribe((images: EnhancementImage[] | undefined) => {
            if (images && images.length) {
                if (this.mediaCollection) {
                    this.meMediaCollectionService.updateCoverImage(this.mediaCollection.id, images[0].id).subscribe((mediaCollection: EnhancementMediaCollection) => {
                        this.mediaCollection = mediaCollection;
                    });
                }
            }
        });
    }

    protected onSubmit(): void {
        if (this.mediaCollection) {
            this.meMediaCollectionService.update(this.mediaCollection.id, this.mediaCollectionForm.value).subscribe((mediaCollection: EnhancementMediaCollection) => {
                this.mediaCollection = mediaCollection;

                this.dialogRef.close(this.mediaCollection);
            });
        }
    }

    protected onClose(): void {
        this.dialogRef.close(this.mediaCollection);
    }

}
