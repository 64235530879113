import {Component, inject, OnInit} from '@angular/core';
import {CsCommonsModule} from "../../../core/module/commons/cs.commons.module";
import {CommonModule} from "@angular/common";
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {SortablePagination} from "../../../core/model/commons/sortablePagination";
import {Page} from "../../../core/model/commons/page";
import {MePageComponentService} from "../me-page.component.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxPermissionsService} from "ngx-permissions";
import {ToastService} from "../../../core/module/component/toast/toast.service";
import {DialogService} from "../../../core/module/component/dialog/dialog.service";
import {LogService} from "../../../core/service/data/god/log.service";
import {Log} from "../../../core/model/data/search/es/entity/log";
import {Enumeration} from '../../../core/model/enumeration';
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSelectModule} from "@angular/material/select";
import {MatDatepickerModule} from "@angular/material/datepicker";

@Component({
    selector: 'cs-me-log-page',
    standalone: true,
    templateUrl: './me-log-page.component.html',
    styleUrls: ['./me-log-page.component.scss'],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatCardModule,
        MatTableModule,
        MatPaginatorModule,
        CsCommonsModule,
    ],
})
export class MeLogPageComponent implements OnInit {
    protected readonly Enumeration = Enumeration;

    protected pagination: SortablePagination = new SortablePagination(0, 25, [
        {
            name: 'createTime',
            direction: 'desc'
        }
    ]);
    protected page: Page<Log> | undefined;

    protected searchFormControl: FormControl = inject(FormBuilder).control('', []);

    constructor(
        protected mePageComponentService: MePageComponentService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private permissionsService: NgxPermissionsService,
        private toastService: ToastService,
        private dialogService: DialogService,
        private logService: LogService,
    ) {
    }

    ngOnInit(): void {
        this.paging(0);
    }

    protected paging(page: number): void {
        this.pagination.page = page;
        this.logService.search(this.searchFormControl.value, this.pagination).subscribe(data => {
            this.page = data;
        });
    }

}
