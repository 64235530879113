import {Component, Inject} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {VisibleScope} from "../../../../../../../model/data/persist/jpa/entity/visible-scope";
import {MeVideoService} from "../../../../../../../service/data/me/me-video.service";
import {PermissionMode} from "../../../../../../../service/data/permission-mode";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PermissionService} from "../../../../../../../service/data/god/permission.service";
import {ToastService} from "../../../../../toast/toast.service";
import {DialogService} from "../../../../../dialog/dialog.service";
import {
    ImageBackgroundSize
} from "../../../../../../commons/component/image/image-background/image-background.component";
import {ImageResolution} from "../../../../../../../model/data/persist/jpa/entity/image-file";
import {
    MeImageSelectDialogComponent
} from "../../../media-image/dialog/me-image-select-dialog/me-image-select-dialog.component";
import {MediaCollectionName} from "../../../../../../../model/data/persist/jpa/entity/media-collection";
import {EnhancementVideo} from "../../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-video";
import {EnhancementImage} from "../../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-image";
import {EnhancementMedia} from "../../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-media";

@Component({
    selector: 'cs-me-video-edit-dialog',
    standalone: false,
    templateUrl: './me-video-edit-dialog.component.html',
    styleUrls: ['./me-video-edit-dialog.component.scss']
})
export class MeVideoEditDialogComponent {
    protected readonly ImageBackgroundSize = ImageBackgroundSize;
    protected readonly ImageResolution = ImageResolution;
    protected readonly VisibleScope = VisibleScope;
    protected readonly PermissionMode = PermissionMode;

    protected video: EnhancementVideo | undefined;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any = {},
        private dialogRef: MatDialogRef<MeVideoEditDialogComponent>,
        private translateService: TranslateService,
        private permissionService: PermissionService,
        private dialogService: DialogService,
        private toastService: ToastService,
        private meVideoService: MeVideoService) {
        this.video = this.data.video;
        // this.menuService.onItemClick().pipe(takeUntilDestroyed(), filter((bag: NbMenuBag) => {
        //     return bag.tag === this.MORE_MENU && bag.item.data['type'] === this.MORE_MENU_STATISTICS;
        // })).subscribe(() => {
        //     this.dialogService.open(VideoStatisticsViewDialogComponent, {
        //         hasBackdrop: true,
        //         closeOnBackdropClick: false,
        //         closeOnEsc: true,
        //         hasScroll: false,
        //         autoFocus: true,
        //         data: {
        //             media-video: this.media-video
        //         }
        //     });
        // });
        // this.menuService.onItemClick().pipe(takeUntilDestroyed(), filter((bag: NbMenuBag) => {
        //     return bag.tag === this.MORE_MENU && bag.item.data['type'] === this.MORE_MENU_DELETE;
        // })).subscribe(() => {
        //     this.dialogService.open(ConfirmDialogComponent, {
        //         hasBackdrop: true,
        //         closeOnBackdropClick: false,
        //         closeOnEsc: true,
        //         hasScroll: false,
        //         autoFocus: true
        //     }).onClose.subscribe((result: boolean) => {
        //         if (result) {
        //             if (this.media-video) {
        //                 this.meVideoService.delete(this.media-video.id).subscribe(() => {
        //                     this.close();
        //                     this.toastService.show(this.translateService.instant('COMMONS.SUCCESS_CONTENT'), this.translateService.instant('COMMONS.SUCCESS'), {
        //                         status: 'success',
        //                         duration: 2500
        //                     });
        //                 });
        //             }
        //         }
        //     });
        // });
    }

    protected onChangeCoverImage(): void {
        this.dialogService.open<MeImageSelectDialogComponent, EnhancementImage[]>(MeImageSelectDialogComponent, {
            single: true,
            scopes: [
                MediaCollectionName.DEFAULT_MEDIA_COVER_IMAGE_COLLECTION
            ]
        }).afterClosed().subscribe((images: EnhancementImage[] | undefined) => {
            if (images && images.length) {
                if (this.video) {
                    this.meVideoService.updateCoverImage(this.video.id, images[0].id).subscribe((video: EnhancementVideo) => {
                        this.video = video;
                    });
                }
            }
        });
    }

    protected onSubmit(media: EnhancementMedia) {
        this.meVideoService.load(media.id).subscribe((video: EnhancementVideo) => {
            this.video = video;

            this.dialogRef.close(video);
        });
    }

    protected onClose(media: EnhancementMedia | undefined): void {
        if (media) {
            this.meVideoService.load(media.id).subscribe((video: EnhancementVideo) => {
                this.video = video;

                this.dialogRef.close(video);
            });
        } else {
            this.dialogRef.close(this.video);
        }
    }

}
