@if(me) {
    <mat-card class="w-full mb-5" appearance="outlined">
        <mat-card-header class="w-full flex-grow-0">
            <mat-icon fontIcon="person"></mat-icon>{{ 'Page.MePage.MeProfilePage.TITLE' | translate }}
        </mat-card-header>
        <mat-card-content class="w-full flex-grow-0">
            <div class="w-full mb-3 px-2 py-3 flex flex-col justify-start items-stretch border border-solid rounded">
                <div class="w-full flex-grow-0">
                    <span><strong>{{ 'COMMONS.USERNAME' | translate }}</strong></span>
                </div>
                <div class="w-full flex-grow-0">
                    <span>{{me.name}}</span>
                </div>
            </div>
            @for (role of me.roles; track role.id) {
                <div class="w-full mb-3 px-2 py-3 flex flex-col justify-start items-stretch border border-solid rounded">
                    <div class="w-full flex-grow-0">
                        <span>{{ 'COMMONS.ROLE' | translate }}: {{role.name}}</span>
                    </div>
                    <div class="w-full flex-grow-0">
                        @for (permission of role.permissions; track permission.id) {
                            <span class="me-1 px-1 text-xs rounded border border-solid"> {{ ('PERMISSION.' + permission.name) | translate }}</span>
                        }
                    </div>
                </div>
            }
        </mat-card-content>
    </mat-card>

    <mat-card class="w-full h-96 mb-5" appearance="outlined">
        <mat-card-header class="w-full flex-grow-0">
            <mat-icon fontIcon="account_circle"></mat-icon>{{ 'COMMONS.AVATAR' | translate }}
        </mat-card-header>
        <mat-card-content class="w-full flex-grow h-0 flex flex-row justify-start items-center">
            <div class="aspect-square h-full rounded shadow cursor-pointer overflow-hidden" (click)="onChangeAvatar()">
                <cs-image class="w-full h-full bg-image-cover" [image]="me.avatarId" [resolution]="ImageResolution.Large"></cs-image>
            </div>
        </mat-card-content>
        <mat-card-actions class="w-full flex flex-row justify-end items-center">
            <button class="flex-grow-0" mat-flat-button (click)="onChangeAvatar()"><mat-icon fontIcon="check"></mat-icon>{{ 'COMMONS.CHANGE' | translate }}</button>
        </mat-card-actions>
    </mat-card>
}

@if (userProfileUpdateRequest) {
    <mat-card class="w-full mb-5" appearance="outlined">
        <mat-card-header class="w-full flex-grow-0">
            <mat-icon fontIcon="for_you"></mat-icon>{{ 'COMMONS.OTHER_INFORMATION' | translate }}
        </mat-card-header>
        <mat-card-content class="w-full flex-grow-0">
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>{{ 'Page.MePage.MeProfilePage.LANGUAGE' | translate }}</mat-label>
                <mat-select class="w-full" [(ngModel)]="userProfileUpdateRequest.language" (selectionChange)="changeLanguage()">
                    <mat-option [value]="Language.zhCN">{{ 'ENUMERATION.LANGUAGE.zh_CN' | translate }}</mat-option>
                    <mat-option [value]="Language.enUS">{{ 'ENUMERATION.LANGUAGE.en_US' | translate }}</mat-option>
                    <mat-option [value]="Language.jaJP">{{ 'ENUMERATION.LANGUAGE.ja_JP' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>{{ 'Page.MePage.MeProfilePage.THEME' | translate }}</mat-label>
                <mat-select class="w-full" [(ngModel)]="userProfileUpdateRequest.theme" (selectionChange)="changeTheme()">
                    <mat-option [value]="Theme.auto">{{ 'ENUMERATION.THEME.auto' | translate }}</mat-option>
                    <mat-option [value]="Theme.light">{{ 'ENUMERATION.THEME.light' | translate }}</mat-option>
                    <mat-option [value]="Theme.dark">{{ 'ENUMERATION.THEME.dark' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>{{ 'COMMONS.MAIL' | translate }}</mat-label>
                <input matInput [(ngModel)]="userProfileUpdateRequest.mail">
            </mat-form-field>
        </mat-card-content>
        <mat-card-actions class="w-full flex flex-row justify-end items-center">
            <button class="flex-grow-0" mat-flat-button (click)="onSubmit()"><mat-icon fontIcon="check"></mat-icon>{{ 'COMMONS.CONFIRM' | translate }}</button>
        </mat-card-actions>
    </mat-card>
}
