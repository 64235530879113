import {Component, OnDestroy, OnInit} from '@angular/core';
import {SortablePagination} from "../../../../core/model/commons/sortablePagination";
import {Page, PageContent} from "../../../../core/model/commons/page";
import {PermissionMode} from "../../../../core/service/data/permission-mode";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxPermissionsService} from "ngx-permissions";
import {HomePageComponentService} from "../../home-page.component.service";
import {PublicVideoService} from "../../../../core/service/data/public/public-video.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {CommonModule} from "@angular/common";
import {CsCommonsModule} from "../../../../core/module/commons/cs.commons.module";
import {CsMediaModule} from "../../../../core/module/component/media/cs.media.module";
import {EnhancementVideo} from "../../../../core/model/data/persist/jpa/entity/enhancement/enhancement-video";
import {OnAttach, OnDetach} from "../../../../core/module/commons/route/enhanced-router-outlet";
import {ImageType, ImageTypeAspectRatioTable} from "../../../../core/model/data/persist/jpa/entity/image";
import {EnhancementMediaCollection} from "../../../../core/model/data/persist/jpa/entity/media-collection";
import {MeService} from "../../../../core/service/data/me/me.service";
import {PublicMediaCollectionService} from "../../../../core/service/data/public/public-media-collection.service";
import {SimpleMe} from "../../../../core/model/data/persist/jpa/entity/user";
import {Duration} from "../../../../core/model/data/utils/time-range";
import {MediaCategory} from "../../../../core/model/data/persist/jpa/entity/media";
import {MatIconModule} from "@angular/material/icon";
import {MatToolbarModule} from "@angular/material/toolbar";

@Component({
    selector: 'cs-home-video-discovery-page',
    standalone: true,
    templateUrl: './home-video-discovery-page.component.html',
    styleUrls: ['./home-video-discovery-page.component.scss'],
    imports: [
        CommonModule,
        MatIconModule,
        MatToolbarModule,
        CsCommonsModule,
        CsMediaModule
    ]
})
export class HomeVideoDiscoveryPageComponent implements OnInit, OnDestroy, OnAttach, OnDetach {
    protected readonly PermissionMode = PermissionMode;
    protected readonly ImageTypeAspectTable = ImageTypeAspectRatioTable;
    protected readonly ImageType = ImageType;

    protected hottestVideoInDayPagination: SortablePagination = new SortablePagination(0, 12);
    protected hottestVideoInDayPage: Page<EnhancementVideo> | undefined;
    protected hottestVideoInDayPageContent: PageContent<EnhancementVideo> = new PageContent<EnhancementVideo>((media: EnhancementVideo) => {
        return media.id;
    });

    protected hottestVideoInWeekPagination: SortablePagination = new SortablePagination(0, 6);
    protected hottestVideoInWeekPage: Page<EnhancementVideo> | undefined;
    protected hottestVideoInWeekPageContent: PageContent<EnhancementVideo> = new PageContent<EnhancementVideo>((media: EnhancementVideo) => {
        return media.id;
    });

    protected hottestMediaCollectionInWeekPagination: SortablePagination = new SortablePagination(0, 6);
    protected hottestMediaCollectionInWeekPage: Page<EnhancementMediaCollection> | undefined;
    protected hottestMediaCollectionInWeekPageContent: PageContent<EnhancementMediaCollection> = new PageContent<EnhancementMediaCollection>((media: EnhancementMediaCollection) => {
        return media.id;
    });

    protected recommendVideoPagination: SortablePagination = new SortablePagination(0, 6);
    protected recommendVideoPage: Page<EnhancementVideo> | undefined;
    protected recommendVideoPageContent: PageContent<EnhancementVideo> = new PageContent<EnhancementVideo>((media: EnhancementVideo) => {
        return media.id;
    });

    private isLogin: boolean = false;

    constructor(
        private homeComponentService: HomePageComponentService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private permissionsService: NgxPermissionsService,
        private translateService: TranslateService,
        private meService: MeService,
        private publicVideoService: PublicVideoService,
        private publicMediaCollectionService: PublicMediaCollectionService
    ) {
        this.homeComponentService.onScrollToBottom().pipe(takeUntilDestroyed()).subscribe(() => {
            this.loadNextRecommendationVideo();
        });

        this.meService.onMeChanged().pipe(takeUntilDestroyed()).subscribe((me: SimpleMe | undefined) => {
            const isLogin: boolean = !!me;
            if (this.isLogin !== isLogin) {
                this.isLogin = isLogin;
                // this.loadNextRecommendation(true);
            }
        });
    }

    ngOnInit(): void {
        this.loadNextHottestVideoInDay();
        this.loadNextHottestVideoInWeek()
        this.loadNextHottestMediaCollectionInWeek();
        // this.loadNextRecommendation();
    }

    ngOnAttach(): void {
    }

    ngOnDetach(): void {
    }

    ngOnDestroy(): void {
    }

    private loadNextHottestVideoInDay(reset: boolean = false): void {
        if (reset) {
            this.hottestVideoInDayPagination.reset();
            this.hottestVideoInDayPage = undefined;
            this.hottestVideoInDayPageContent.reset();
        }

        if (!this.hottestVideoInDayPage || this.hottestVideoInDayPage && !this.hottestVideoInDayPage.last) {
            this.publicVideoService.pageHottest(this.hottestVideoInDayPagination, Duration.OneDay).subscribe((page: Page<EnhancementVideo>) => {
                this.hottestVideoInDayPagination.page++;

                this.hottestVideoInDayPage = page;
                this.hottestVideoInDayPageContent.addAll(this.hottestVideoInDayPage.content);
            });
        }
    }

    private loadNextHottestVideoInWeek(reset: boolean = false): void {
        if (reset) {
            this.hottestVideoInWeekPagination.reset();
            this.hottestVideoInWeekPage = undefined;
            this.hottestVideoInWeekPageContent.reset();
        }

        if (!this.hottestVideoInWeekPage || this.hottestVideoInWeekPage && !this.hottestVideoInWeekPage.last) {
            this.publicVideoService.pageHottest(this.hottestVideoInWeekPagination, Duration.OneWeek).subscribe((page: Page<EnhancementVideo>) => {
                this.hottestVideoInWeekPagination.page++;

                this.hottestVideoInWeekPage = page;
                this.hottestVideoInWeekPageContent.addAll(this.hottestVideoInWeekPage.content);
            });
        }
    }

    private loadNextHottestMediaCollectionInWeek(reset: boolean = false): void {
        if (reset) {
            this.hottestMediaCollectionInWeekPagination.reset();
            this.hottestMediaCollectionInWeekPage = undefined;
            this.hottestMediaCollectionInWeekPageContent.reset();
        }

        if (!this.hottestMediaCollectionInWeekPage || this.hottestMediaCollectionInWeekPage && !this.hottestMediaCollectionInWeekPage.last) {
            this.publicMediaCollectionService.pageHottestByCategory(MediaCategory.Video, this.hottestMediaCollectionInWeekPagination, Duration.OneWeek).subscribe((page: Page<EnhancementMediaCollection>) => {
                this.hottestMediaCollectionInWeekPagination.page++;

                this.hottestMediaCollectionInWeekPage = page;
                this.hottestMediaCollectionInWeekPageContent.addAll(this.hottestMediaCollectionInWeekPage.content);
            });
        }
    }

    private loadNextRecommendationVideo(reset: boolean = false): void {
        if (reset) {
            this.recommendVideoPagination.reset();
            this.recommendVideoPage = undefined;
            this.recommendVideoPageContent.reset();
        }

        if (!this.recommendVideoPage || this.recommendVideoPage && !this.recommendVideoPage.last) {
            this.publicVideoService.pageRecommendation(this.recommendVideoPagination).subscribe((page: Page<EnhancementVideo>) => {
                this.recommendVideoPagination.page++;

                this.recommendVideoPage = page;
                this.recommendVideoPageContent.addAll(this.recommendVideoPage.content);
            });
        }
    }

    protected onVideoClick(video: EnhancementVideo): void {
        this.router.navigate([`/video/${video.id}`]).then();
    }

    protected onMediaCollectionClick(mediaCollection: EnhancementMediaCollection): void {
        this.router.navigate([`/media-collection/${mediaCollection.id}`]).then();
    }

}

