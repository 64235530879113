import {Pipe, PipeTransform} from '@angular/core';
import {MediaColorPipe} from "./media-color.pipe";
import {EnhancementMedia} from "../../../model/data/persist/jpa/entity/enhancement/enhancement-media";

@Pipe({
    name: 'mediaInvertColor'
})
export class MediaInvertColorPipe implements PipeTransform {

    constructor(
        private mediaColorPipe: MediaColorPipe
    ) {
    }

    transform(media: EnhancementMedia): string {
        return this.invertColor(this.mediaColorPipe.transform(media));
    }

    private invertColor(hex: string): string {
        const { r, g, b } = this.hexToRgb(hex);
        const brightness = 0.2126 * r + 0.7152 * g + 0.0722 * b;
        return brightness > 128 ? '#000000' : '#FFFFFF';
    }

    private hexToRgb(hex: string): { r: number, g: number, b: number } {
        let r: number = 0, g: number = 0, b: number = 0;
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        } else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }
        return { r, g, b };
    }

}
