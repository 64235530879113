import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Permission} from '../../../model/data/persist/jpa/entity/permission';
import {Service} from "../service";

@Injectable({
    providedIn: 'root'
})
export class PermissionService {

    constructor(private http: HttpClient) {
    }

    list(): Observable<Permission[]> {
        return this.http.get<Permission[]>(`${Service.Core.prefixUrl}/permission/all`);
    }

}
