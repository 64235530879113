import {Media} from "./media";
import {ImageFile} from "./image-file";

export enum ImageType {
    Image = 'Image',
    AvatarImage = 'AvatarImage',
    MediaCoverImage = 'MediaCoverImage',
    MediaCollectionCoverImage = 'MediaCollectionCoverImage'
}

export const ImageTypeAspectRatioTable: Record<ImageType, number> = {
    [ImageType.Image]: 16 / 10,
    [ImageType.AvatarImage]: 1,
    [ImageType.MediaCoverImage]: 16 / 10,
    [ImageType.MediaCollectionCoverImage]: 16 / 10
};

export interface Image extends Media {
    imageFile: ImageFile;
    type: ImageType;
}
