import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {SortablePagination} from '../../../model/commons/sortablePagination';
import {Observable} from 'rxjs';
import {User} from '../../../model/data/persist/jpa/entity/user';
import {Page} from '../../../model/commons/page';
import {UserUpdateRequest} from '../../../model/request/user-update-request';
import {UserCreateRequest} from '../../../model/request/user-create-request';
import {Service} from '../service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private http: HttpClient) {
    }

    login(username: string, password: string): Observable<any> {
        return this.http.post(`${Service.Core.prefixUrl}/user/login`, new HttpParams().set('username', username).set('password', password), {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        });
    }

    logout(): Observable<any> {
        return this.http.get(`${Service.Core.prefixUrl}/user/logout`);
    }

    list(): Observable<User[]> {
        return this.http.get<User[]>(`${Service.Core.prefixUrl}/user/all`);
    }

    page(pagination: SortablePagination): Observable<Page<User>> {
        return this.http.get<Page<User>>(`${Service.Core.prefixUrl}/user${pagination.toRequestParameter()}`);
    }

    search(keyword: string, pagination: SortablePagination): Observable<Page<User>> {
        return this.http.get<Page<User>>(`${Service.Search.prefixUrl}/user${pagination.toRequestParameter()}&keyword=${keyword}`);
    }

    load(id: number): Observable<User> {
        return this.http.get<User>(`${Service.Core.prefixUrl}/user/${id}`);
    }

    create(request: UserCreateRequest): Observable<User> {
        return this.http.post<User>(`${Service.Core.prefixUrl}/user`, request);
    }

    update(id: number, request: UserUpdateRequest): Observable<User> {
        return this.http.put<User>(`${Service.Core.prefixUrl}/user/${id}`, request);
    }

    delete(id: number): Observable<User> {
        return this.http.delete<User>(`${Service.Core.prefixUrl}/user/${id}`);
    }

}
