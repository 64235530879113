import {Routes} from "@angular/router";
import {DefaultPageComponent} from "./page/default-page/default-page.component";
import {mePageRoute} from "./page/me-page/me-page.routes";
import {authPageRoute} from "./page/auth-page/auth-page.routes";
import {homePageRoute} from "./page/home-page/home-page.routes";
import {detailPageRoutes} from "./page/detail-page/detail-page.routes";

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'discovery',
        pathMatch: 'full'
    },
    authPageRoute,
    homePageRoute,
    detailPageRoutes,
    mePageRoute,
    {
        path: '**',
        component: DefaultPageComponent
    }
];
