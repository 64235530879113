import {Component} from '@angular/core';
import {MediaGalleryComponent} from "../../media/media-gallery/media-gallery.component";
import {EnhancementVideo} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-video";

@Component({
    selector: 'cs-video-gallery',
    standalone: false,
    templateUrl: '../../media/media-gallery/media-gallery.component.html',
    styleUrls: ['../../media/media-gallery/media-gallery.component.scss']
})
export class VideoGalleryComponent extends MediaGalleryComponent<EnhancementVideo>  {

}
