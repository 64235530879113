<div class="w-full h-full flex flex-row justify-center justify-sm-center justify-md-start justify-lg-start justify-xl-start justify-xxl-start items-stretch bg-red-500">
    <div class="flex-grow h-full w-0 hidden sm:hidden md:hidden lg:flex xl:flex 2xl:flex">
    </div>
    <div class="flex-grow-0 h-full p-1 flex flex-col justify-center items-center" [style.width.px]="420">
        <mat-card class="w-full h-full" appearance="outlined">
            <mat-card-header class="w-full flex-grow-0 flex flex-row justify-center items-center">
                <mat-card-title class="w-full">{{ 'STATION.TITLE' | translate }}</mat-card-title>
            </mat-card-header>
            <mat-card-content class="w-full flex-grow h-0 flex flex-col justify-start items-stretch">
                <h1 class="text-3xl font-medium text-center">{{ 'Page.AuthPage.LoginPage.WELCOME' | translate }}</h1>
                <h6 class="text-base text-center">{{ 'Page.AuthPage.LoginPage.WELCOME_HINT' | translate }}</h6>
                <form class="w-full" [style.padding.px]="20" [formGroup]="authForm">
                    <mat-form-field class="w-full" [hideRequiredMarker]="true" appearance="outline">
                        <mat-label>{{ 'COMMONS.USERNAME' | translate }}</mat-label>
                        <input matInput type="text" formControlName="username" placeholder="{{ 'COMMONS.USERNAME_HINT' | translate }}">
                    </mat-form-field>
                    <mat-form-field class="w-full" [hideRequiredMarker]="true" appearance="outline">
                        <mat-label>{{ 'COMMONS.PASSWORD' | translate }}</mat-label>
                        <input matInput type="password" formControlName="password" placeholder="{{ 'COMMONS.PASSWORD_HINT' | translate }}">
                    </mat-form-field>
                    <div class="w-full" [style.padding-top.px]="20">
                        <button class="w-full" mat-flat-button [disabled]="authForm.invalid" (click)="onLogin()">{{ 'COMMONS.LOGIN' | translate }}</button>
                    </div>
                </form>
            </mat-card-content>
            <mat-card-actions class="w-full flex-grow-0 flex flex-row justify-center items-center">
                <button class="flex-grow-0 mx-2" mat-icon-button (click)="onOauth2Login('github')">
                    <mat-icon svgIcon="github"></mat-icon>
                </button>
                <button class="flex-grow-0 mx-2" mat-icon-button (click)="onOauth2Login('google')">
                    <mat-icon svgIcon="google"></mat-icon>
                </button>
                <button class="flex-grow-0 mx-2" mat-icon-button (click)="onOauth2Login('twitter')">
                    <mat-icon svgIcon="twitter"></mat-icon>
                </button>
                <button class="flex-grow-0 mx-2" mat-icon-button (click)="onOauth2Login('facebook')">
                    <mat-icon svgIcon="facebook"></mat-icon>
                </button>
                <button class="flex-grow-0 mx-2" mat-icon-button (click)="onOauth2Login('linkedin')">
                    <mat-icon svgIcon="linkedin"></mat-icon>
                </button>
            </mat-card-actions>
            <mat-card-footer class="w-full flex-grow-0 flex flex-row justify-center items-center" [style.height.px]="36">
                <cs-copyright class="text-xs"></cs-copyright>
            </mat-card-footer>
        </mat-card>
    </div>
</div>
