import {Component} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {FileUploaderService, FileUploaderUploadProgressEvent} from "../../../../../file-uploader/file-uploader.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {NgxFileDropEntry} from "ngx-file-drop";

@Component({
    selector: 'cs-me-audio-create-dialog',
    standalone: false,
    templateUrl: './me-audio-create-dialog.component.html',
    styleUrls: ['./me-audio-create-dialog.component.scss']
})
export class MeAudioCreateDialogComponent {
    protected accepts: string = ['.mp3'].join(',');

    protected fileUploaderUploadProgressEvents: FileUploaderUploadProgressEvent[] = [];

    constructor(
        private dialogRef: MatDialogRef<MeAudioCreateDialogComponent>,
        private fileUploaderService: FileUploaderService
    ) {
        this.fileUploaderService.onAudioFilesChanged().pipe(takeUntilDestroyed()).subscribe((files: File[]) => {
            this.fileUploaderUploadProgressEvents = this.fileUploaderUploadProgressEvents.filter((event: FileUploaderUploadProgressEvent) => {
                return files.includes(event.file);
            });
            files.filter((file: File) => {
                return !this.fileUploaderUploadProgressEvents.map((fileUploaderUploadProgressEvent: FileUploaderUploadProgressEvent) => {
                    return fileUploaderUploadProgressEvent.file;
                }).includes(file);
            }).forEach((file: File) => {
                this.fileUploaderUploadProgressEvents.push({
                    file: file,
                    progress: 0
                });
            });
        });
        this.fileUploaderService.onAudioFileUploadProgressChanged().pipe(takeUntilDestroyed()).subscribe((event: FileUploaderUploadProgressEvent) => {
            this.fileUploaderUploadProgressEvents.filter((fileUploaderUploadProgressEvent: FileUploaderUploadProgressEvent) => {
                return fileUploaderUploadProgressEvent.file === event.file;
            }).forEach((fileUploaderUploadProgressEvent: FileUploaderUploadProgressEvent) => {
                fileUploaderUploadProgressEvent.progress = event.progress;
            });
        });
    }

    protected onDropped(fileDropEntries: NgxFileDropEntry[]): void {
        for (const droppedFile of fileDropEntries) {
            if (droppedFile.fileEntry.isFile) {
                const fileEntry: FileSystemFileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    this.fileUploaderService.addAudioFile(file);
                });
            }
        }
    }

    protected onClose(): void {
        this.dialogRef.close();
    }

}
