<mat-card class="w-full h-full">
    <mat-card-header class="w-full flex-grow-0">
        <mat-icon fontIcon="cloud_upload"></mat-icon>{{ 'Core.Component.Media.Book.Dialog.MeBookCreateDialog.TITLE' | translate }}
    </mat-card-header>
    <mat-card-content class="w-full flex-grow h-0">
        <form class="w-full" [formGroup]="bookForm">
            <mat-form-field class="w-full flex-grow-0" appearance="outline">
                <mat-label>{{ 'COMMONS.NAME' | translate }}</mat-label>
                <input matInput type="text" placeholder="{{ 'COMMONS.NAME_HINT' | translate }}" formControlName="name">
            </mat-form-field>
            <mat-form-field class="w-full flex-grow-0" appearance="outline">
                <mat-label>{{ 'COMMONS.DESCRIPTION' | translate }}</mat-label>
                <input matInput type="text" placeholder="{{ 'COMMONS.DESCRIPTION_HINT' | translate }}" formControlName="description">
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>{{ 'COMMONS.SCOPE' | translate }}</mat-label>
                <mat-select formControlName="visibleScope">
                    <mat-option [value]="VisibleScope.Private">{{ 'ENUMERATION.VisibleScope.Private' | translate }}</mat-option>
                    <mat-option [value]="VisibleScope.Public">{{ 'ENUMERATION.VisibleScope.Public' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>{{ 'COMMONS.TYPE' | translate }}</mat-label>
                <mat-select formControlName="type">
                    <mat-option [value]="BookType.Level_1">{{ 'ENUMERATION.Book.Type.Level_1' | translate }}</mat-option>
                    <mat-option [value]="BookType.Level_2">{{ 'ENUMERATION.Book.Type.Level_2' | translate }}</mat-option>
                    <mat-option [value]="BookType.Level_3">{{ 'ENUMERATION.Book.Type.Level_3' | translate }}</mat-option>
                    <mat-option [value]="BookType.Level_4">{{ 'ENUMERATION.Book.Type.Level_4' | translate }}</mat-option>
                    <mat-option [value]="BookType.Level_5">{{ 'ENUMERATION.Book.Type.Level_5' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </mat-card-content>
    <mat-card-actions class="w-full flex flex-row justify-end items-center">
        <button class="me-1" mat-flat-button [disabled]="!bookForm.valid" (click)="onSubmit()"><mat-icon fontIcon="check"></mat-icon>{{ 'COMMONS.CONFIRM' | translate }}</button>
        <button class="ms-1" mat-flat-button (click)="onClose()"><mat-icon fontIcon="close"></mat-icon>{{ 'COMMONS.CANCEL' | translate }}</button>
    </mat-card-actions>
</mat-card>
