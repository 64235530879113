import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {mergeMap, Observable} from 'rxjs';
import {SystemValueService} from '../service/data/god/system-value.service';
import {SystemValue4CustomConfiguration} from '../model/data/persist/jpa/entity/system-value';
import {TranslateService} from '@ngx-translate/core';
import {Language} from "../model/lanuage";

@Injectable({
    providedIn: 'root',
})
export class CustomizationResolver implements Resolve<any> {

    constructor(
        private customizationService: SystemValueService,
        private translateService: TranslateService,
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return this.customizationService.loadCustomConfiguration().pipe(mergeMap((customization: SystemValue4CustomConfiguration) => {
            const language: Language = this.obtainBrowserLanguage();
            return this.translateService.currentLoader.getTranslation(language).pipe(mergeMap(() => {
                console.info(`success to load custom config and set default language: ${language}`);
                this.translateService.setDefaultLang(language);
                return this.translateService.use(language);
            }));
        }));
    }

    private obtainBrowserLanguage(): Language {
        const browserLang: string = this.translateService.getBrowserLang() ?? Language.enUS;
        return Object.values(Language).filter((lang: string) => {
            return lang.includes(browserLang);
        })[0] as Language;
    }

}
