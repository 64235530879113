import {Component, OnDestroy, OnInit} from '@angular/core';
import {HomePageComponentService} from "../../home-page/home-page.component.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {PermissionMode} from "../../../core/service/data/permission-mode";
import {CommonModule} from "@angular/common";
import {CsCommonsModule} from "../../../core/module/commons/cs.commons.module";
import {PublicBookService} from "../../../core/service/data/public/public-book.service";
import {EnhancementMediaCollection} from "../../../core/model/data/persist/jpa/entity/media-collection";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {PermissionService} from "../../../core/service/data/god/permission.service";
import {DialogService} from "../../../core/module/component/dialog/dialog.service";
import {ToastService} from "../../../core/module/component/toast/toast.service";
import {CsMediaModule} from "../../../core/module/component/media/cs.media.module";
import {EnhancementBook} from "../../../core/model/data/persist/jpa/entity/enhancement/enhancement-book";

@Component({
    selector: 'cs-book-detail-page',
    standalone: true,
    templateUrl: './home-book-detail-page.component.html',
    styleUrls: ['./home-book-detail-page.component.scss'],
    imports: [
        CommonModule,
        TranslateModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        CsCommonsModule,
        CsMediaModule,
    ]
})
export class HomeBookDetailPageComponent implements OnInit, OnDestroy {
    protected readonly PermissionMode = PermissionMode;

    protected book: EnhancementBook | undefined;

    constructor(
        private homePageComponentService: HomePageComponentService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private permissionService: PermissionService,
        private dialogService: DialogService,
        private toastService: ToastService,
        private publicBookService: PublicBookService,
    ) {
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe((params: Params) => {
            const id: number = params['id'] as number;
            if (id) {
                this.load(id);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.book) {
            this.load(this.book.id);
        }
    }

    private load(id: number): void {
        this.publicBookService.load(id).subscribe((book: EnhancementBook) => {
            this.onBookChange(book);
        });
    }

    protected onBookChange(book: EnhancementBook): void {
        this.book = book;
        this.homePageComponentService.notifyBookChanged(this.book);
    }

    protected onMediaCollectionClick(mediaCollection: EnhancementMediaCollection): void {
        this.router.navigate([`/media-collection/${mediaCollection.id}`]).then();
    }

}
