import {AbstractMediaFile} from "./abstract-media-file";

export enum ImageResolution {
    Origin = 'Origin',
    Nano = 'Nano',
    Minute = 'Minute',
    Tiny = 'Tiny',
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large',
    Giant = 'Giant',
    Huge = 'Huge',
    Gigantic = 'Gigantic'
}

export interface ImageFile extends AbstractMediaFile {
    resolutions: ImageResolution[];
    palette: string[];
}
