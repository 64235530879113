<div class="w-full">
    <mat-form-field class="w-full" appearance="outline">
        <input matInput type="text" [formControl]="keyword" placeholder="{{ 'Page.MePage.MeAudioPage.MeAudioListPage.TITLE' | translate }}" (keyup.enter)="loadNext(true)">
        <button matSuffix mat-icon-button (click)="loadNext(true)">
            <mat-icon fontIcon="search"></mat-icon>
        </button>
    </mat-form-field>
</div>
<cs-audio-grid-viewer class="w-full" [permissionMode]="PermissionMode.Me" [medias]="pageContent.values()" (mediaClick)="onAudioClick($event)"></cs-audio-grid-viewer>

<button class="fixed" [style.bottom.px]="16" [style.right.px]="16" mat-fab (click)="onCreate()">
    <mat-icon>add</mat-icon>
</button>
