import {Component} from '@angular/core';
import {EnhancementImage} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-image";
import {MediaCardComponent} from "../../media/media-card/media-card.component";

@Component({
    selector: 'cs-image-card',
    standalone: false,
    templateUrl: '../../media/media-card/media-card.component.html',
    styleUrls: ['../../media/media-card/media-card.component.scss']
})
export class ImageCardComponent extends MediaCardComponent<EnhancementImage> {

}
