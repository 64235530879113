import {Component, EventEmitter, Input, Output} from '@angular/core';
import {EnhancementMediaCollection} from "../../../../../model/data/persist/jpa/entity/media-collection";
import {PermissionMode} from "../../../../../service/data/permission-mode";
import {ImageType, ImageTypeAspectRatioTable} from "../../../../../model/data/persist/jpa/entity/image";

@Component({
    selector: 'cs-media-collection-grid-viewer',
    standalone: false,
    templateUrl: './media-collection-grid-viewer.component.html',
    styleUrls: ['./media-collection-grid-viewer.component.scss']
})
export class MediaCollectionGridViewerComponent {
    protected readonly ImageTypeAspectTable = ImageTypeAspectRatioTable;
    protected readonly ImageType = ImageType;

    @Input()
    public permissionMode: PermissionMode = PermissionMode.God;
    @Input()
    public mediaCollections: EnhancementMediaCollection[] = [];
    @Output()
    public mediaCollectionClick: EventEmitter<EnhancementMediaCollection> = new EventEmitter<EnhancementMediaCollection>();

    protected onImageClick(mediaCollection: EnhancementMediaCollection): void {
        this.mediaCollectionClick.next(mediaCollection);
    }

}
