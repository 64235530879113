import {NgModule} from '@angular/core';
import {CopyrightComponent} from "./copyright.component";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        CopyrightComponent
    ],
    exports: [
        CopyrightComponent
    ]
})
export class CsCopyrightModule {
}
