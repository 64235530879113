<mat-card class="w-full h-28 sm:h-28 md:h-24 lg:h-24 xl:h-24 2xl:h-24" appearance="outlined" *ngIf="audio">
    <cs-image-background class="w-full flex-grow h-0" [image]="audio.coverImage" [resolution]="ImageResolution.Tiny" [size]="ImageBackgroundSize.Cover" [blur]="true" [dark]="true">
        <div class="w-full h-full flex flex-col justify-start items-stretch">
            <div class="w-full px-3 flex sm:flex md:hidden lg:hidden xl:hidden 2xl:hidden flex-col justify-center items-stretch" [style.height.px]="48">
                <mat-slider [min]="0" [max]="1" [step]="0.01">
                    <input matSliderThumb [value]="audioPlayerService.time() / audioPlayerService.duration()" (valueChange)="audioPlayerService.seek($event * audioPlayerService.duration())">
                </mat-slider>
            </div>
            <div class="w-full h-full flex flex-row justify-between items-center" [style.padding-left.px]="12" [style.padding-right.px]="12">
                <div class="flex-grow-0 h-full flex flex-row justify-center items-center">
                    <cs-image class="bg-cover border rounded shadow" [style.width.px]="48" [style.height.px]="48" [image]="audio.coverImage" [resolution]="ImageResolution.Tiny"></cs-image>
                </div>
                <div class="flex-grow-0 px-2 h-full flex flex-row justify-center items-center">
                    <button class="mx-1" mat-icon-button [disabled]="!audioPlayerService.hasPrevious()" (click)="audioPlayerService.playPrevious()" *ngIf="!single">
                        <mat-icon class="text-white" fontIcon="arrow-left"></mat-icon>
                    </button>
                    <button class="mx-1" mat-icon-button [disabled]="!audioPlayerService.isReady()" (click)="audioPlayerService.toggle()" *ngIf="audioPlayerService.isPaused()">
                        <mat-icon class="text-white" fontIcon="play_circle"></mat-icon>
                    </button>
                    <button class="mx-1" mat-icon-button [disabled]="!audioPlayerService.isReady()" (click)="audioPlayerService.toggle()" *ngIf="!audioPlayerService.isPaused()">
                        <mat-icon class="text-white" fontIcon="pause_circle"></mat-icon>
                    </button>
                    <button class="mx-1" mat-icon-button [disabled]="!audioPlayerService.hasNext()" (click)="audioPlayerService.playNext()" *ngIf="!single">
                        <mat-icon class="text-white" fontIcon="arrow-right"></mat-icon>
                    </button>
                </div>
                <div class="flex-grow-0 h-full flex flex-row justify-start items-center">
                    <span class="text-base font-mono text-white">{{ audioPlayerService.time() | lyricsTime: 2 :'second' }}/{{ audioPlayerService.duration() | lyricsTime: 2 :'second' }}</span>
                </div>
                <div class="flex-grow h-full w-0">
                    <div class="w-full h-full px-3 hidden sm:hidden md:flex lg:flex xl:flex 2xl:flex flex-row justify-start items-center">
                        <mat-slider class="w-full" [min]="0" [max]="1" [step]="0.01">
                            <input matSliderThumb [value]="audioPlayerService.time() / audioPlayerService.duration()" (valueChange)="audioPlayerService.seek($event * audioPlayerService.duration())">
                        </mat-slider>
                    </div>
                </div>
                <div class="flex-grow-0 h-full flex flex-row justify-end items-center">
                    <button class="flex-grow-0 mx-1" mat-icon-button (click)="audioPlayerService.toggleLyricsVisibility()" *ngIf="audioPlayerService.canToggleLyricsVisibility()">
                        <mat-icon class="text-white" [ngClass]="audioPlayerService.isShowLyrics() ? 'active' : ''" fontIcon="lyrics"></mat-icon>
                    </button>
                    <button class="flex-grow-0 mx-1" mat-icon-button (click)="audioPlayerService.togglePlayMode()" *ngIf="!single && audioPlayerService.playMode() === PlayMode.SingleCycle">
                        <mat-icon class="text-white" fontIcon="repeat"></mat-icon>
                    </button>
                    <button class="flex-grow-0 mx-1" mat-icon-button (click)="audioPlayerService.togglePlayMode()" *ngIf="!single && audioPlayerService.playMode() === PlayMode.ListLoop">
                        <mat-icon class="text-white" fontIcon="swap"></mat-icon>
                    </button>
                    <button class="flex-grow-0 mx-1" mat-icon-button (click)="audioPlayerService.togglePlayMode()" *ngIf="!single && audioPlayerService.playMode() === PlayMode.Random">
                        <mat-icon class="text-white" fontIcon="shuffle"></mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </cs-image-background>
</mat-card>
