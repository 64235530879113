export enum UserEventName {
    Online = 'Online',
    Offline = 'Offline',

    ImageHandleOnSuccess = 'ImageHandleOnSuccess',
    ImageHandleOnError = 'ImageHandleOnError',
    AudioHandleOnSuccess = 'AudioHandleOnSuccess',
    AudioHandleOnError = 'AudioHandleOnError',
    VideoHandleOnSuccess = 'VideoHandleOnSuccess',
    VideoHandleOnError = 'VideoHandleOnError',
}
