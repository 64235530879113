import {ModuleWithProviders, NgModule} from '@angular/core';
import {FileUploaderService} from "./file-uploader.service";

@NgModule()
export class CsFileUploaderModule {

    static forRoot(): ModuleWithProviders<CsFileUploaderModule> {
        return {
            ngModule: CsFileUploaderModule,
            providers: [
                {
                    provide: FileUploaderService
                }
            ]
        };
    }

}
