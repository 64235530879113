<div class="w-full h-full flex flex-col justify-start items-stretch" *ngIf="mediaCollection">
    <mat-card class="w-full flex-grow h-0 m-0">
        <cs-image-background class="w-full flex-grow h-0 cursor-pointer" [image]="mediaCollection.coverImage" [resolution]="ImageResolution.Small" (click)="onMediaCollectionClick()">
            <div class="w-100 h-100 position-relative">
                <div class="w-full px-1 pt-2 absolute flex flex-row justify-start items-start text-lg" [style.top.px]="0">
                    <div class="flex-grow flex flex-row justify-start items-end" [style.height.px]="18">
                        @if(PermissionMode.Me === permissionMode || PermissionMode.God === permissionMode) {
                            @if (VisibleScope.Private === mediaCollection.visibleScope) {
                                <mat-icon class="text-xl text-red-600 active" fontIcon="lock"></mat-icon>
                            }
                        }
                    </div>
                    <div class="flex-grow-0 flex flex-row justify-start items-end font-thin" [style.height.px]="18">
                    </div>
                </div>

                <div class="w-full px-1 pb-1 absolute flex flex-row justify-start items-end bg-gradient-to-t from-black to-transparent" [style.height.px]="36" [style.bottom.px]="0">
                    <div class="flex-grow flex flex-row justify-start items-end" [style.height.px]="18">
                        <div class="flex-grow-0 h-full inline-flex flex-row justify-start items-center">
                            <mat-icon class="text-xl text-red-600" [ngClass]="mediaCollection.likedByCurrentUser ? 'active' : ''" fontIcon="favorite"></mat-icon>
                            <span class="ms-1 text-sm font-thin" [ngClass]="mediaCollection.likedByCurrentUser ? 'text-danger' : 'text-gray-100'"><strong>{{mediaCollection.likedCount}}</strong></span>
                        </div>
                    </div>
                    <div class="flex-grow-0 flex flex-row justify-start items-center" [style.height.px]="18">
                        <span class="px-1 rounded bg-black text-white">{{ 'Core.Component.MediaCollection.COUNT' | translate: { value: mediaCollection.count } }}</span>
                    </div>
                </div>
            </div>
        </cs-image-background>
    </mat-card>
    <div class="w-full flex-grow-0 mt-1 flex flex-row justify-start items-stretch" [style.height.px]="72">
        <div class="flex-grow-0 h-full flex flex-row justify-start items-start">
            <cs-enhancement-avatar [user]="mediaCollection.enhancementUser"></cs-enhancement-avatar>
        </div>
        <div class="flex-grow h-full w-0 ms-2 flex flex-col justify-start items-start">
            <div class="w-full flex-grow-0 flex flex-row justify-start items-center" [style.height.px]="28">
                <span class="truncate" [style.font-size.rem]="1"><strong>{{ mediaCollection | mediaCollectionNameTranslate }}</strong></span>
            </div>
            <div class="w-full flex-grow h-0 flex flex-row justify-start items-center">
                <cs-username class="w-full h-auto" [user]="mediaCollection.enhancementUser"></cs-username>
            </div>
            <div class="w-full flex-grow h-0 flex flex-row justify-start items-center">
                <span class="inline-flex flex-row justify-start items-baseline text-xs"><span>{{ 10 | count }}{{ 'COMMONS.VIEWS' | translate }}</span><span class="px-1">·</span><span>{{ mediaCollection.createTime | sinceNow: 1 }}</span></span>
            </div>
        </div>
    </div>
</div>
