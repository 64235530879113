import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SortablePagination} from "../../../model/commons/sortablePagination";
import {Page} from "../../../model/commons/page";
import {Service} from '../service';
import {MediaUpdateRequest} from "../../../model/request/media-update-request";
import {MediaBindTag} from "../../../model/data/persist/jpa/entity/media-bind-tag";
import {MediaBindMediaCollection} from "../../../model/data/persist/jpa/entity/media-bind-media-collection";
import {MediaBindLikedUser} from "../../../model/data/persist/jpa/entity/media-bind-liked-user";
import {EnhancementComment} from "../../../model/data/persist/jpa/entity/comment";
import {CommentCreateRequest} from "../../../model/request/comment-create-request";
import {MediaBindComment} from "../../../model/data/persist/jpa/entity/media-bind-comment";
import {EnhancementMedia} from "../../../model/data/persist/jpa/entity/enhancement/enhancement-media";

@Injectable({
    providedIn: 'root'
})
export class MeMediaService {

    constructor(
        private http: HttpClient
    ) {
    }

    page(pagination: SortablePagination): Observable<Page<EnhancementMedia>> {
        return this.http.get<Page<EnhancementMedia>>(`${Service.Media.prefixUrl}/me/media${pagination.toRequestParameter()}`);
    }

    load(id: number): Observable<EnhancementMedia> {
        return this.http.get<EnhancementMedia>(`${Service.Media.prefixUrl}/me/media/${id}`);
    }

    update(id: number, request: MediaUpdateRequest): Observable<EnhancementMedia> {
        return this.http.put<EnhancementMedia>(`${Service.Media.prefixUrl}/me/media/${id}`, request);
    }

    delete(id: number): Observable<EnhancementMedia> {
        return this.http.delete<EnhancementMedia>(`${Service.Media.prefixUrl}/me/media/${id}`);
    }

    bindTag(id: number, tag: string): Observable<MediaBindTag> {
        return this.http.post<MediaBindTag>(`${Service.Media.prefixUrl}/me/media/${id}/tag/${tag}`, {});
    }

    unbindTag(id: number, tag: string): Observable<MediaBindTag> {
        return this.http.delete<MediaBindTag>(`${Service.Media.prefixUrl}/me/media/${id}/tag/${tag}`, {});
    }

    bindMediaCollection(id: number, mediaCollectionId: number): Observable<MediaBindMediaCollection> {
        return this.http.post<MediaBindMediaCollection>(`${Service.Media.prefixUrl}/me/media/${id}/collection/${mediaCollectionId}`, {});
    }

    unbindMediaCollection(id: number, mediaCollectionId: number): Observable<MediaBindMediaCollection> {
        return this.http.delete<MediaBindMediaCollection>(`${Service.Media.prefixUrl}/me/media/${id}/collection/${mediaCollectionId}`, {});
    }

    like(id: number): Observable<MediaBindLikedUser> {
        return this.http.post<MediaBindLikedUser>(`${Service.Media.prefixUrl}/me/media/${id}/like`, {});
    }

    unlike(id: number): Observable<MediaBindLikedUser> {
        return this.http.delete<MediaBindLikedUser>(`${Service.Media.prefixUrl}/me/media/${id}/like`, {});
    }

    listComment(id: number): Observable<EnhancementComment[]> {
        return this.http.get<EnhancementComment[]>(`${Service.Media.prefixUrl}/me/media/${id}/comment/all`);
    }

    pageComment(id: number): Observable<Page<EnhancementComment>> {
        return this.http.get<Page<EnhancementComment>>(`${Service.Media.prefixUrl}/me/media/${id}/comment`);
    }

    createComment(id: number, request: CommentCreateRequest): Observable<MediaBindComment> {
        return this.http.post<MediaBindComment>(`${Service.Media.prefixUrl}/me/media/${id}/comment`, request);
    }

}
