<mat-card class="w-full h-full" appearance="outlined" *ngIf="media">
    <mat-card-content class="w-full flex-grow h-0">
        <form class="w-full" [formGroup]="mediaForm">
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>{{ 'COMMONS.NAME' | translate }}</mat-label>
                <input matInput type="text" placeholder="{{ 'COMMONS.NAME_HINT' | translate }}" formControlName="name">
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>{{ 'COMMONS.DESCRIPTION' | translate }}</mat-label>
                <textarea matInput [rows]="3" type="text" placeholder="{{ 'COMMONS.DESCRIPTION_HINT' | translate }}" formControlName="description"></textarea>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>{{ 'COMMONS.SCOPE' | translate }}</mat-label>
                <mat-select formControlName="visibleScope">
                    <mat-option [value]="VisibleScope.Private">{{ 'ENUMERATION.VisibleScope.Private' | translate }}</mat-option>
                    <mat-option [value]="VisibleScope.Public">{{ 'ENUMERATION.VisibleScope.Public' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </form>
        @if (mediaCollections.length) {
            <form class="w-full mb-4 rounded border border-solid grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4" [formGroup]="mediaCollectionsForm">
                @for (mediaCollection of mediaCollections; track mediaCollection.id) {
                    <mat-checkbox class="my-2 me-2" [formControlName]="mediaCollection.id" (change)="onMediaCollectionChange(mediaCollection, $event)">{{ mediaCollection | mediaCollectionNameTranslate }}</mat-checkbox>
                }
            </form>
        }
        <form class="w-full" [formGroup]="mediaTagsForm">
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>{{ 'COMMONS.TAG' | translate }}</mat-label>
                <mat-chip-grid #tagChipGrid formControlName="tags">
                    @for (tag of mediaTagsForm.get('tags')?.value; track tag) {
                        <mat-chip-row (removed)="removeTag($event)">{{ tag }}<button matChipRemove><mat-icon fontIcon="close"></mat-icon></button></mat-chip-row>
                    }
                </mat-chip-grid>
                <input type="text" placeholder="{{ 'COMMONS.TAG_HINT' | translate }}" [matChipInputFor]="tagChipGrid" (matChipInputTokenEnd)="addTag($event)">
            </mat-form-field>
        </form>
<!--        <mat-form-field class="w-full" appearance="outline">-->
<!--            <mat-label>{{ 'COMMONS.ARTIST' | translate }}</mat-label>-->
<!--            <mat-chip-grid #artistChipGrid>-->
<!--                @for (tag of tags; track tag) {-->
<!--                    <mat-chip-row (removed)="removeTag($event)">{{ tag }}<button matChipRemove><mat-icon fontIcon="close"></mat-icon></button></mat-chip-row>-->
<!--                }-->
<!--            </mat-chip-grid>-->
<!--            <input [matChipInputFor]="artistChipGrid" (matChipInputTokenEnd)="addTag($event)">-->
<!--        </mat-form-field>-->
        <ng-content></ng-content>
    </mat-card-content>
    <mat-card-actions class="w-full flex flex-row justify-end items-center">
        <button class="me-1" mat-flat-button [disabled]="!mediaForm.valid" (click)="onSubmit()"><mat-icon fontIcon="check"></mat-icon>{{ 'COMMONS.CONFIRM' | translate }}</button>
        <button class="ms-1" mat-flat-button (click)="onClose()"><mat-icon fontIcon="close"></mat-icon>{{ 'COMMONS.CANCEL' | translate }}</button>
    </mat-card-actions>
</mat-card>
