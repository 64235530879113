import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BookArticleUpdateRequest} from "../../../model/request/book-article-update-request";
import {Service} from '../service';
import {EnhancementArticle} from "../../../model/data/persist/jpa/entity/enhancement/enhancement-article";

@Injectable({
    providedIn: 'root'
})
export class MeArticleService {

    constructor(
        private http: HttpClient
    ) {
    }

    update(id: number, request: BookArticleUpdateRequest): Observable<EnhancementArticle> {
        return this.http.put<EnhancementArticle>(`${Service.Media.prefixUrl}/me/media/article/${id}`, request);
    }

}
