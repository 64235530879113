import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CommentUpdateRequest} from "../../../model/request/comment-update-request";
import {CommentReplyUpdateRequest} from "../../../model/request/comment-reply-update-request";
import {CommentReplyCreateRequest} from "../../../model/request/comment-reply-create-request";
import {EnhancementComment} from "../../../model/data/persist/jpa/entity/comment";
import {CommentReply} from "../../../model/data/persist/jpa/entity/comment-reply";
import {SortablePagination} from "../../../model/commons/sortablePagination";
import {Page} from "../../../model/commons/page";
import {Service} from '../service';

@Injectable({
    providedIn: 'root'
})
export class MeCommentService {

    constructor(
        private http: HttpClient
    ) {
    }

    page(pagination: SortablePagination): Observable<Page<EnhancementComment>> {
        return this.http.get<Page<EnhancementComment>>(`${Service.Media.prefixUrl}/me/comment${pagination.toRequestParameter()}`);
    }

    load(id: number): Observable<EnhancementComment> {
        return this.http.get<EnhancementComment>(`${Service.Media.prefixUrl}/me/comment/${id}`);
    }

    update(id: number, request: CommentUpdateRequest): Observable<EnhancementComment> {
        return this.http.put<EnhancementComment>(`${Service.Media.prefixUrl}/me/comment/${id}`, request);
    }

    delete(id: number): Observable<EnhancementComment> {
        return this.http.delete<EnhancementComment>(`${Service.Media.prefixUrl}/me/comment/${id}`);
    }

    bindLikedUser(id: number): Observable<EnhancementComment> {
        return this.http.post<EnhancementComment>(`${Service.Media.prefixUrl}/me/comment/${id}/like`, '');
    }

    unbindLikedUser(id: number): Observable<EnhancementComment> {
        return this.http.delete<EnhancementComment>(`${Service.Media.prefixUrl}/me/comment/${id}/like`);
    }

    listCommentReplay(id: number): Observable<CommentReply[]> {
        return this.http.get<CommentReply[]>(`${Service.Media.prefixUrl}/me/comment/${id}/reply/all`);
    }

    createCommentReplay(id: number, request: CommentReplyCreateRequest): Observable<CommentReply> {
        return this.http.post<CommentReply>(`${Service.Media.prefixUrl}/me/comment/${id}/reply`, request);
    }

    updateCommentReplay(id: number, request: CommentReplyUpdateRequest): Observable<CommentReply> {
        return this.http.put<CommentReply>(`${Service.Media.prefixUrl}/me/comment/reply/${id}`, request);
    }

    deleteCommentReplay(id: number): Observable<CommentReply> {
        return this.http.delete<CommentReply>(`${Service.Media.prefixUrl}/me/comment/reply/${id}`);
    }

}
