import {BehaviorSubject, distinctUntilChanged, filter, Observable, Subject} from "rxjs";

export abstract class AbstractPageComponentService {
    private scrollToTopSubject: Subject<boolean> = new BehaviorSubject<boolean>(true);
    private scrollToBottomSubject: Subject<boolean> = new Subject<boolean>();

    onScrollToTop(): Observable<boolean> {
        return this.scrollToTopSubject.asObservable().pipe(distinctUntilChanged(), filter((value: boolean) => {
            return value;
        }));
    }

    onScrollToBottom(): Observable<boolean> {
        return this.scrollToBottomSubject.asObservable().pipe(distinctUntilChanged(), filter((value: boolean) => {
            return value;
        }));
    }

    notifyScrolling(): void {
        Promise.resolve().then(() => {
            this.scrollToTopSubject.next(false);
            this.scrollToBottomSubject.next(false);
        });
    }

    notifyScrollToTop(): void {
        Promise.resolve().then(() => {
            this.scrollToTopSubject.next(true);
        });
    }

    notifyScrollToBottom(): void {
        Promise.resolve().then(() => {
            this.scrollToBottomSubject.next(true);
        });
    }

}
