import {Component, Input} from '@angular/core';
import {EnhancementUser, SimpleMe, SimpleUser} from "../../../model/data/persist/jpa/entity/user";

@Component({
    selector: 'cs-username',
    standalone: false,
    templateUrl: './username.component.html',
    styleUrls: ['./username.component.scss']
})
export class UsernameComponent {

    @Input()
    public user: SimpleMe | SimpleUser | EnhancementUser | undefined;

    @Input()
    public timestamp: Date | undefined;

}
