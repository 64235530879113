<div class="w-full h-full keen-slider" #sliderRef>
    @if (audio) {
        @for (line of audio.lyrics.lines; track $index) {
            <div class="w-full keen-slider__slide" [style.height.px]="48">
                @if (currentSlideIndex === $index) {
                    <div class="w-full h-full flex flex-row justify-center items-center cursor-pointer" (click)="onMoveToCurrentLyricsLine()">
                        <span class="text-3xl text-red-700 select-none"><strong>{{line.value}}</strong></span>
                    </div>
                } @else {
                    <div class="w-full h-full flex flex-row justify-center items-center cursor-pointer">
                        <span class="text-xl text-white select-none">{{ line.value }}</span>
                    </div>
                }
            </div>
        }
    }
</div>
