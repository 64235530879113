import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MediaCategory} from "../../../../../model/data/persist/jpa/entity/media";
import {PermissionMode} from "../../../../../service/data/permission-mode";
import {EnhancementMediaCollection} from "../../../../../model/data/persist/jpa/entity/media-collection";
import {Page, PageContent} from "../../../../../model/commons/page";
import {SortablePagination} from "../../../../../model/commons/sortablePagination";
import {PublicMediaCollectionService} from "../../../../../service/data/public/public-media-collection.service";
import {MeMediaCollectionService} from "../../../../../service/data/me/me-media-collection.service";
import {EnhancementImage} from "../../../../../model/data/persist/jpa/entity/enhancement/enhancement-image";
import {EnhancementAudio} from "../../../../../model/data/persist/jpa/entity/enhancement/enhancement-audio";
import {EnhancementVideo} from "../../../../../model/data/persist/jpa/entity/enhancement/enhancement-video";
import {EnhancementMedia} from "../../../../../model/data/persist/jpa/entity/enhancement/enhancement-media";

@Component({
    selector: 'cs-media-collection-viewer',
    standalone: false,
    templateUrl: './media-collection-viewer.component.html',
    styleUrl: './media-collection-viewer.component.scss'
})
export class MediaCollectionViewerComponent implements OnInit {
    protected readonly PermissionMode = PermissionMode;
    protected readonly MediaCategory = MediaCategory;

    protected isLoading: boolean = true;
    protected pagination: SortablePagination = new SortablePagination(0, 24, [
        {
            name: 'media.name',
            direction: 'asc'
        }
    ]);
    protected page: Page<EnhancementMedia> | undefined;
    protected pageContent: PageContent<EnhancementMedia> = new PageContent<EnhancementMedia>((media: EnhancementMedia) => {
        return media.id;
    });

    protected selectedMedia: EnhancementMedia | undefined;

    @Input()
    public permissionMode: PermissionMode = PermissionMode.God;

    @Input()
    public mediaCollection: EnhancementMediaCollection | undefined;
    @Output()
    public mediaCollectionChange: EventEmitter<EnhancementMediaCollection> = new EventEmitter<EnhancementMediaCollection>();

    @Output()
    public mediaCollectionClick: EventEmitter<EnhancementMediaCollection> = new EventEmitter<EnhancementMediaCollection>();

    constructor(
        private publicMediaCollectionService: PublicMediaCollectionService,
        private meMediaCollectionService: MeMediaCollectionService,
    ) {
    }

    ngOnInit(): void {
        this.loadNext(true);
    }

    protected loadNext(reset: boolean = false): void {
        if (this.mediaCollection) {
            if (reset) {
                this.pagination.reset();
                this.page = undefined;
                this.pageContent.reset();
            }

            if (!this.page || this.page && !this.page.last) {
                this.isLoading = true;
                if (this.permissionMode === PermissionMode.Public) {
                    this.publicMediaCollectionService.pageBinding(this.mediaCollection.id, this.pagination).subscribe((page: Page<EnhancementMedia>) => {
                        this.pagination.page++;

                        this.page = page;
                        this.pageContent.addAll(this.page.content);

                        this.isLoading = false;

                        if (!this.pageContent.isEmpty() && !this.selectedMedia) {
                            this.selectedMedia = this.pageContent.get(0);
                        }
                    });
                } else if (this.permissionMode === PermissionMode.Me) {

                } else {

                }
            }
        }
    }

    protected onMediaClick(media: EnhancementMedia): void {
        this.selectedMedia = media;
    }

    protected onMediaCollectionClick(mediaCollection: EnhancementMediaCollection): void {
        this.mediaCollectionClick.next(mediaCollection);
    }

    protected canNext(): boolean {
        if (this.selectedMedia) {
            const last: EnhancementMedia | undefined = this.pageContent.last();
            if (last) {
                return this.selectedMedia.id !== last.id;
            }
        }
        return false;
    }

    protected next(): void {
        if (this.canNext()) {
            if (this.selectedMedia) {
                const index: number = this.pageContent.indexOf(this.selectedMedia);
                if (this.pageContent.size() - index < 3) {
                    this.loadNext(false);
                }

                const nextMedia: EnhancementMedia | undefined = this.pageContent.get(index + 1);
                if (nextMedia) {
                    this.selectedMedia = nextMedia;
                }
            }
        }
    }

    protected canPrevious(): boolean {
        if (this.selectedMedia) {
            const first: EnhancementMedia | undefined = this.pageContent.first();
            if (first) {
                return this.selectedMedia.id !== first.id;
            }
        }
        return false;
    }

    protected previous(): void {
        if (this.canPrevious()) {
            if (this.selectedMedia) {
                const index: number = this.pageContent.indexOf(this.selectedMedia);

                const previousMedia: EnhancementMedia | undefined = this.pageContent.get(index - 1);
                if (previousMedia) {
                    this.selectedMedia = previousMedia;
                }
            }
        }
    }

    protected toImage(media: EnhancementMedia): EnhancementImage {
        return media as EnhancementImage;
    }

    protected toImages(pageContent: PageContent<EnhancementMedia>): EnhancementImage[] {
        return pageContent.values() as EnhancementImage[];
    }

    protected toAudio(media: EnhancementMedia): EnhancementAudio {
        return media as EnhancementAudio;
    }

    protected toAudios(pageContent: PageContent<EnhancementMedia>): EnhancementAudio[] {
        return pageContent.values() as EnhancementAudio[];
    }

    protected toVideo(media: EnhancementMedia): EnhancementVideo {
        return media as EnhancementVideo;
    }

    protected toVideos(pageContent: PageContent<EnhancementMedia>): EnhancementVideo[] {
        return pageContent.values() as EnhancementVideo[];
    }

}
