import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
    EnhancementMediaCollection,
    MediaCollectionNames
} from "../../../model/data/persist/jpa/entity/media-collection";

@Pipe({
    name: 'mediaCollectionNameTranslate'
})
export class MediaCollectionNameTranslatePipe implements PipeTransform {

    constructor(
        private translateService: TranslateService
    ) {
    }

    transform(mediaCollection: EnhancementMediaCollection): string {
        if (MediaCollectionNames.includes(mediaCollection.name)) {
            return this.translateService.instant(`CONSTANT.MediaCollection.${mediaCollection.name}`, {
                value: mediaCollection.enhancementUser.nickname ? mediaCollection.enhancementUser.nickname : mediaCollection.enhancementUser.name
            });
        } else {
            return mediaCollection.name;
        }
    }

}
