import {ModuleWithProviders, NgModule} from '@angular/core';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {ToastService} from "./toast.service";

@NgModule({
    imports: [
        MatSnackBarModule,
    ]
})
export class CsToastModule {

    static forRoot(): ModuleWithProviders<CsToastModule> {
        return {
            ngModule: CsToastModule,
            providers: [
                {
                    provide: ToastService
                }
            ]
        };
    }

}
