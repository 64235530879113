import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PermissionMode} from '../../../../../../service/data/permission-mode';
import {MediaFileStatus} from '../../../../../../model/data/persist/jpa/entity/abstract-media-file';
import {ImageResolution} from '../../../../../../model/data/persist/jpa/entity/image-file';
import {VisibleScope} from '../../../../../../model/data/persist/jpa/entity/visible-scope';
import {MediaCategory} from "../../../../../../model/data/persist/jpa/entity/media";
import {EnhancementMedia} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-media";

@Component({
    selector: 'cs-media-card',
    standalone: false,
    templateUrl: './media-card.component.html',
    styleUrls: ['./media-card.component.scss']
})
export class MediaCardComponent<Media extends EnhancementMedia> {
    protected readonly PermissionMode = PermissionMode;
    protected readonly ImageResolution = ImageResolution;
    protected readonly MediaFileStatus = MediaFileStatus;
    protected readonly VisibleScope = VisibleScope;
    protected readonly MediaCategory = MediaCategory;

    @Input()
    public permissionMode: PermissionMode = PermissionMode.God;
    @Input()
    public media: Media | undefined;
    @Output()
    public mediaClick: EventEmitter<Media> = new EventEmitter<Media>();

    protected onMediaClick(): void {
        if (this.media) {
            this.mediaClick.next(this.media);
        }
    }

}
