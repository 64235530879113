import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MillisecondFormatterPipe} from "./millisecond-formatter.pipe";
import {timer} from "rxjs";

@Pipe({
    name: 'sinceNow',
    pure: false
})
export class SinceNowFormatterPipe implements PipeTransform {
    private readonly millisecondFormatterPipe: MillisecondFormatterPipe;
    private readonly TIMEOUT: number = 1000;

    private formattedDate: string = '';

    private subscriptionTimestamp: Date | undefined;

    constructor(
        private translateService: TranslateService
    ) {
        this.millisecondFormatterPipe = new MillisecondFormatterPipe(this.translateService);
    }

    transform(date: number | string | Date, accuracy: number = 2): string {
        if (this.subscriptionTimestamp) {
            if (Date.now() - this.subscriptionTimestamp.getTime() > this.TIMEOUT) {
                this.subscriptionTimestamp = new Date();
                if (typeof date === 'number' || typeof date === 'string') {
                    timer(this.TIMEOUT).subscribe(() => {
                        this.formattedDate = this.millisecondFormatterPipe.transform(Date.now() - new Date(date).getTime(), accuracy, 'second');
                    });
                } else {
                    timer(this.TIMEOUT).subscribe(() => {
                        this.formattedDate = this.millisecondFormatterPipe.transform(Date.now() - date.getTime(), accuracy, 'second');
                    });
                }
            }
        } else {
            this.subscriptionTimestamp = new Date();
            if (typeof date === 'number' || typeof date === 'string') {
                this.formattedDate = this.millisecondFormatterPipe.transform(Date.now() - new Date(date).getTime(), accuracy, 'second');
            } else {
                this.formattedDate = this.millisecondFormatterPipe.transform(Date.now() - date.getTime(), accuracy, 'second');
            }
        }
        return this.formattedDate;
    }

}
