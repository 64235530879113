import {Component, HostBinding, Input} from '@angular/core';
import {Image} from "../../../../../model/data/persist/jpa/entity/image";
import {ImageResolution} from "../../../../../model/data/persist/jpa/entity/image-file";
import {DomSanitizer, SafeStyle} from "@angular/platform-browser";
import {MediaFileStatus} from "../../../../../model/data/persist/jpa/entity/abstract-media-file";
import {EnhancementImage} from "../../../../../model/data/persist/jpa/entity/enhancement/enhancement-image";

@Component({
    selector: 'cs-image',
    standalone: false,
    template: '<ng-content></ng-content>',
    styleUrls: ['./image.component.scss']
})
export class ImageComponent {

    @Input()
    public image: Image | EnhancementImage | number | undefined;
    @Input()
    public resolution: ImageResolution = ImageResolution.Nano;
    @Input()
    public palette: boolean = true;

    constructor(
        private sanitizer: DomSanitizer
    ) {
    }

    @HostBinding(`style.background-image`)
    private get backgroundImage(): SafeStyle {
        if (this.image) {
            if (typeof this.image === 'number') {
                return this.sanitizer.bypassSecurityTrustStyle(`url(/service-media/stream/image/${this.image}/resolution/${this.resolution})`);
            } else {
                if (this.image.imageFile) {
                    if (this.image.imageFile.status === MediaFileStatus.HandledOnSuccess) {
                        return this.sanitizer.bypassSecurityTrustStyle(`url(/service-media/stream/image/${this.image.id}/resolution/${this.resolution})`);
                    } else {
                        return this.sanitizer.bypassSecurityTrustStyle(`url(/assets/image/default/image-not-found.png)`);
                    }
                } else {
                    return this.sanitizer.bypassSecurityTrustStyle(`url(/service-media/stream/image/${this.image.id}/resolution/${this.resolution})`);
                }
            }
        } else {
            return this.sanitizer.bypassSecurityTrustStyle(`url(/assets/image/default/image-not-found.png)`);
        }
    }

    @HostBinding(`style.background-color`)
    private get backgroundColor(): SafeStyle {
        if (this.image) {
            if (this.palette) {
                if (typeof this.image === 'number') {
                    return this.sanitizer.bypassSecurityTrustStyle('');
                } else {
                    if (this.image.imageFile) {
                        if (this.image.imageFile.palette.length) {
                            return this.sanitizer.bypassSecurityTrustStyle(this.image.imageFile.palette[0]);
                        } else {
                            return this.sanitizer.bypassSecurityTrustStyle('');
                        }
                    } else {
                        return this.sanitizer.bypassSecurityTrustStyle('');
                    }
                }
            } else {
                return this.sanitizer.bypassSecurityTrustStyle('');
            }
        } else {
            return this.sanitizer.bypassSecurityTrustStyle('');
        }
    }
}
