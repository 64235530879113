import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MediaCategory} from "../../../../../../model/data/persist/jpa/entity/media";
import {MeMediaService} from "../../../../../../service/data/me/me-media.service";
import {MediaBindLikedUser} from "../../../../../../model/data/persist/jpa/entity/media-bind-liked-user";
import {PublicCommentService} from "../../../../../../service/data/public/public-comment.service";
import {MeCommentService} from "../../../../../../service/data/me/me-comment.service";
import {PermissionMode} from "../../../../../../service/data/permission-mode";
import {PublicMediaService} from "../../../../../../service/data/public/public-media.service";
import {EnhancementMediaCollection} from "../../../../../../model/data/persist/jpa/entity/media-collection";
import {EnhancementMedia} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-media";
import {ImageResolution} from "../../../../../../model/data/persist/jpa/entity/image-file";
import {ImageBackgroundSize} from "../../../../../commons/component/image/image-background/image-background.component";
import {AudioPlayerService} from "../../media-audio/audio-player/audio-player.service";

@Component({
    selector: 'cs-media-viewer',
    standalone: false,
    templateUrl: './media-viewer.component.html',
    styleUrl: './media-viewer.component.scss'
})
export class MediaViewerComponent<Media extends EnhancementMedia> {
    protected readonly ImageResolution = ImageResolution;
    protected readonly ImageBackgroundSize = ImageBackgroundSize;
    protected readonly MediaCategory = MediaCategory;

    protected MEDIA_CONTROL_BAR_BOTTOMS: { [key in MediaCategory]: string } = {
        Image: 'bottom-2',
        Audio: 'bottom-28 sm:bottom-28 md:bottom-24 lg:bottom-24 xl:bottom-24 2xl:bottom-24',
        Video: 'bottom-12',
        Book: 'bottom-2',
        Article: 'bottom-2'
    };

    @Input()
    public permissionMode: PermissionMode = PermissionMode.God;

    @Input()
    public control: boolean = false;

    @Input()
    public media: Media | undefined;
    @Output()
    public mediaChange: EventEmitter<Media> = new EventEmitter<Media>();

    @Output()
    public mediaCollectionClick: EventEmitter<EnhancementMediaCollection> = new EventEmitter<EnhancementMediaCollection>();

    constructor(
        protected audioPlayerService: AudioPlayerService,
        private publicMediaService: PublicMediaService,
        private publicCommentService: PublicCommentService,
        private meMediaService: MeMediaService,
        private meCommentService: MeCommentService,
    ) {
    }

    protected onToggleLike(): void {
        if (this.media) {
            if (this.media.likedByCurrentUser) {
                this.meMediaService.unlike(this.media.id).subscribe((bind: MediaBindLikedUser) => {
                    this.updateMedia();
                });
            } else {
                this.meMediaService.like(this.media.id).subscribe((bind: MediaBindLikedUser) => {
                    this.updateMedia();
                });
            }
        }
    }

    protected onUpdateCollection(): void {
    }

    protected onToggleOverlay(): void {
    }

    protected onToggleCommentOverlay(): void {
    }

    protected onToggleCollectionOverlay(): void {
    }

    protected onMediaCollectionClick(mediaCollection: EnhancementMediaCollection): void {
        this.mediaCollectionClick.next(mediaCollection);
    }

    private updateMedia(): void {
    }

}
