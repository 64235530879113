<mat-card class="w-full h-full" appearance="outlined">
    <div class="w-full flex-grow-0 flex flex-row justify-start items-center" [style.height.px]="48">
        <div class="flex-grow h-full w-0 flex flex-row justify-start items-center overflow-x-auto">
            <button class="mx-1" mat-icon-button (click)="onHeadingClick()" *ngIf="enableHeading">
                <mat-icon fontIcon="format_size"></mat-icon>
            </button>
            <button class="mx-1" mat-icon-button (click)="onBoldClick()">
                <mat-icon fontIcon="format_bold"></mat-icon>
            </button>
            <button class="mx-1" mat-icon-button (click)="onItalicClick()">
                <mat-icon fontIcon="format_italic"></mat-icon>
            </button>
            <button class="mx-1" mat-icon-button (click)="onSlashClick()">
                <mat-icon fontIcon="format_clear"></mat-icon>
            </button>
            <button class="mx-1" mat-icon-button (click)="onHighLightClick()">
                <mat-icon fontIcon="auto_fix_high"></mat-icon>
            </button>
            <button class="mx-1" mat-icon-button (click)="onUnorderedListClick()">
                <mat-icon fontIcon="format_list_numbered"></mat-icon>
            </button>
            <button class="mx-1" mat-icon-button (click)="onOrderedListClick()">
                <mat-icon fontIcon="format_list_bulleted"></mat-icon>
            </button>
            <button class="mx-1" mat-icon-button (click)="onQuoteClick()">
                <mat-icon fontIcon="format_quote"></mat-icon>
            </button>
            <button class="mx-1" mat-icon-button (click)="onCodeClick()">
                <mat-icon fontIcon="code"></mat-icon>
            </button>
            <button class="mx-1" mat-icon-button (click)="onImageClick()">
                <mat-icon fontIcon="image"></mat-icon>
            </button>
        </div>
        <div class="flex-grow-0 h-full flex flex-row justify-end items-center">
            <ng-content></ng-content>
        </div>
    </div>
    <mat-card class="w-full flex-grow h-0 border-x-0 border-b-0 rounded-none" appearance="outlined">
        <div class="w-full flex-grow h-0 flex flex-row justify-start items-stretch">
            <div class="flex-grow h-full w-0" [style.background-color]="'#1e1e1e'">
                <div #editor class="w-full h-full"></div>
            </div>
            <div class="flex-grow h-full w-0 overflow-y-auto">
                <cs-markdown-viewer class="w-full h-full" [value]="value"></cs-markdown-viewer>
            </div>
        </div>
    </mat-card>
</mat-card>
