<div class="inline-flex shadow cursor-pointer overflow-hidden" [style.width.px]="40" [style.height.px]="40" [style.border-radius.%]="50">
    <ng-container *ngIf="user">
        <div class="w-full h-full" *ngIf="user.avatarId">
            <cs-image class="w-full h-full bg-no-repeat bg-center bg-cover" [image]="user.avatarId" [resolution]="ImageResolution.Minute"></cs-image>
        </div>
        <div class="w-full h-full flex flex-row justify-center items-center" *ngIf="!user.avatarId">
            <span class="text-xs"><strong>{{ user.nickname.substring(0, 1).toUpperCase() }}</strong></span>
        </div>
    </ng-container>
</div>
