import {Injectable} from '@angular/core';
import {
    HTTP_INTERCEPTORS,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {ToastService} from "./core/module/component/toast/toast.service";

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {

    constructor(
        private toastService: ToastService,
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError((response: HttpErrorResponse) => {
            if (response.status === 400) {
                this.toastService.show('COMMONS.FAIL_CONTENT_400');
            } else if (response.status === 401) {
                this.toastService.show('COMMONS.FAIL_CONTENT_401');
            } else if (response.status === 403) {
                this.toastService.show('COMMONS.FAIL_CONTENT_403');
            } else if (response.status === 404) {
                this.toastService.show('COMMONS.FAIL_CONTENT_404');
            } else if (`${response.status}`.startsWith('5')) {
                this.toastService.show('COMMONS.FAIL_CONTENT_5XX');
            } else {
                this.toastService.show('COMMONS.FAIL');
            }
            return throwError(() => {
                return response;
            });
        }));
    }

}

export const APP_HTTP_INTERCEPTOR = {
    provide: HTTP_INTERCEPTORS,
    useClass: AppHttpInterceptor,
    multi: true
};
