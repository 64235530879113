import {Component, inject, Inject, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {VisibleScope} from "../../../../../../../model/data/persist/jpa/entity/visible-scope";
import {MeImageService} from "../../../../../../../service/data/me/me-image.service";
import {ImageResolution} from "../../../../../../../model/data/persist/jpa/entity/image-file";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PermissionMode} from "../../../../../../../service/data/permission-mode";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
    ImageBackgroundSize
} from '../../../../../../commons/component/image/image-background/image-background.component';
import {PermissionService} from "../../../../../../../service/data/god/permission.service";
import {ToastService} from "../../../../../toast/toast.service";
import {DialogService} from "../../../../../dialog/dialog.service";
import {EnhancementImage} from "../../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-image";
import {EnhancementMedia} from "../../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-media";

@Component({
    selector: 'cs-me-image-edit-dialog',
    standalone: false,
    templateUrl: './me-image-edit-dialog.component.html',
    styleUrls: ['./me-image-edit-dialog.component.scss']
})
export class MeImageEditDialogComponent implements OnInit {
    protected readonly ImageBackgroundSize = ImageBackgroundSize;
    protected readonly ImageResolution = ImageResolution;
    protected readonly VisibleScope = VisibleScope;
    protected readonly PermissionMode = PermissionMode;

    protected imageForm: FormGroup = inject(FormBuilder).group({
        name: ['', Validators.required],
        description: ['', Validators.required],
        visibleScope: [VisibleScope.Private, Validators.required]
    });

    protected readonly MORE_MENU: string = `${MeImageEditDialogComponent.name}_MORE_MENU`;
    protected readonly MORE_MENU_STATISTICS: string = `${MeImageEditDialogComponent.name}_MORE_MENU_STATISTICS`;
    protected readonly MORE_MENU_DELETE: string = `${MeImageEditDialogComponent.name}_MORE_MENU_DELETE`;

    protected image: EnhancementImage | undefined;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any = {},
        private dialogRef: MatDialogRef<MeImageEditDialogComponent>,
        private translateService: TranslateService,
        private permissionService: PermissionService,
        private dialogService: DialogService,
        private toastService: ToastService,
        private meImageService: MeImageService) {
        this.image = this.data.image;
        // this.menuService.onItemClick().pipe(takeUntilDestroyed(), filter((bag: NbMenuBag) => {
        //     return bag.tag === this.MORE_MENU && bag.item.data['type'] === this.MORE_MENU_STATISTICS;
        // })).subscribe(() => {
        //     this.dialogService.open(MeImageStatisticsViewDialogComponent, {
        //         hasBackdrop: true,
        //         closeOnBackdropClick: false,
        //         closeOnEsc: true,
        //         hasScroll: false,
        //         autoFocus: true,
        //         data: {
        //             media-image: this.media-image
        //         }
        //     });
        // });
        // this.menuService.onItemClick().pipe(takeUntilDestroyed(), filter((bag: NbMenuBag) => {
        //     return bag.tag === this.MORE_MENU && bag.item.data['type'] === this.MORE_MENU_DELETE;
        // })).subscribe(() => {
        //     this.dialogService.open(ConfirmDialogComponent, {
        //         hasBackdrop: true,
        //         closeOnBackdropClick: false,
        //         closeOnEsc: true,
        //         hasScroll: false,
        //         autoFocus: true
        //     }).onClose.subscribe((result: boolean) => {
        //         if (result) {
        //             if (this.media-image) {
        //                 this.meImageService.delete(this.media-image.id).subscribe(() => {
        //                     this.close();
        //                     this.toastService.show(this.translateService.instant('COMMONS.SUCCESS_CONTENT'), this.translateService.instant('COMMONS.SUCCESS'), {
        //                         status: 'success',
        //                         duration: 2500
        //                     });
        //                 });
        //             }
        //         }
        //     });
        // });
    }

    ngOnInit(): void {
        if (this.image) {
            const nameFiled: AbstractControl<string, string> | null = this.imageForm.get('name');
            if (nameFiled) {
                nameFiled.patchValue(this.image.name);
            }

            const descriptionFiled: AbstractControl<string, string> | null = this.imageForm.get('description');
            if (descriptionFiled) {
                descriptionFiled.patchValue(this.image.description);
            }

            const visibleScopeFiled: AbstractControl<VisibleScope, VisibleScope> | null = this.imageForm.get('visibleScope');
            if (visibleScopeFiled) {
                visibleScopeFiled.patchValue(this.image.visibleScope);
            }

            if (!this.image.updatable) {
                this.imageForm.disable();
            }
        }
    }

    close(): void {
        this.dialogRef.close();
    }

    protected onSubmit(media: EnhancementMedia): void {
        this.meImageService.load(media.id).subscribe((image: EnhancementImage) => {
            this.image = image;

            this.dialogRef.close(this.image);
        });
    }

    protected onClose(media: EnhancementMedia | undefined): void {
        if (media) {
            this.meImageService.load(media.id).subscribe((image: EnhancementImage) => {
                this.image = image;

                this.dialogRef.close(this.image);
            });
        } else {
            this.close();
        }
    }

}
