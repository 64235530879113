import {Pipe, PipeTransform} from '@angular/core';
import {EnhancementBook} from '../../../../model/data/persist/jpa/entity/enhancement/enhancement-book';
import {EnhancementMedia} from "../../../../model/data/persist/jpa/entity/enhancement/enhancement-media";

@Pipe({
    name: 'mediaToBook'
})
export class MediaToBookPipe implements PipeTransform {

    transform(media: EnhancementMedia): EnhancementBook {
        return media as EnhancementBook;
    }

}
