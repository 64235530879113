import {Component, inject, OnInit} from '@angular/core';
import {SystemValueService} from '../../../core/service/data/god/system-value.service';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {
    SystemValue4CustomConfiguration,
    SystemValue4MailConfiguration
} from '../../../core/model/data/persist/jpa/entity/system-value';
import {Theme} from '../../../core/model/theme';
import {Language} from '../../../core/model/lanuage';
import {NgxPermissionsService} from 'ngx-permissions';
import {PermissionName} from '../../../core/model/data/persist/jpa/entity/permission';
import {Service} from "../../../core/service/data/service";
import {ToastService} from "../../../core/module/component/toast/toast.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DialogService} from "../../../core/module/component/dialog/dialog.service";
import {CommonModule} from "@angular/common";
import {AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatCardModule} from "@angular/material/card";
import {CsCommonsModule} from "../../../core/module/commons/cs.commons.module";
import {CdkListboxModule} from "@angular/cdk/listbox";
import {MatSelectModule} from "@angular/material/select";

@Component({
    selector: 'cs-me-configuration-page',
    standalone: true,
    templateUrl: './me-configuration-page.component.html',
    styleUrls: ['./me-configuration-page.component.scss'],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        CdkListboxModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatCardModule,
        CsCommonsModule,
    ]
})
export class MeConfigurationPageComponent implements OnInit {
    protected readonly PermissionName = PermissionName;
    protected readonly Theme = Theme;
    protected readonly Language = Language;
    protected readonly Service = Service;

    protected editable: boolean = false;

    protected systemValue4CustomConfigurationForm: FormGroup = inject(FormBuilder).group({
        name: ['', [Validators.required]],
        description: ['', [Validators.required]],
    });
    protected systemValue4MailConfigurationForm: FormGroup = inject(FormBuilder).group({
        enable: [false, [Validators.required]],
        host: ['', [Validators.required]],
        port: [53, [Validators.required, Validators.min(0), Validators.max(65535)]],
        username: ['', [Validators.required]],
        password: ['', [Validators.required]],
        smtpAuth: [false, [Validators.required]],
        smtpStartTlsEnable: [false, [Validators.required]],
        smtpStartTlsRequired: [false, [Validators.required]]
    });

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private permissionsService: NgxPermissionsService,
        private toastService: ToastService,
        private dialogService: DialogService,
        private systemValueService: SystemValueService
    ) {
    }

    ngOnInit(): void {
        this.loadCustomConfiguration();
        this.loadMailConfiguration();
    }

    private loadCustomConfiguration(): void {
        this.systemValueService.loadCustomConfiguration().subscribe((systemValue4CustomConfiguration: SystemValue4CustomConfiguration) => {
            const nameField: AbstractControl<string, string> | null = this.systemValue4MailConfigurationForm.get('name');
            if (nameField) {
                nameField.patchValue(systemValue4CustomConfiguration.name);
            }

            const descriptionField: AbstractControl<string, string> | null = this.systemValue4MailConfigurationForm.get('description');
            if (descriptionField) {
                descriptionField.patchValue(systemValue4CustomConfiguration.description);
            }
        });
    }

    protected submitCustomConfiguration(): void {
        if (this.systemValue4CustomConfigurationForm) {
            this.systemValueService.setCustomConfiguration(this.systemValue4CustomConfigurationForm.value).subscribe(() => {
                this.toastService.show('COMMONS.SUCCESS');
            });
        }
    }

    private loadMailConfiguration(): void {
        this.systemValueService.loadMailConfiguration().subscribe((systemValue4MailConfiguration: SystemValue4MailConfiguration) => {
            const enableField: AbstractControl<boolean, boolean> | null = this.systemValue4MailConfigurationForm.get('enable');
            if (enableField) {
                enableField.patchValue(systemValue4MailConfiguration.enable);
            }

            const hostField: AbstractControl<string, string> | null = this.systemValue4MailConfigurationForm.get('host');
            if (hostField) {
                hostField.patchValue(systemValue4MailConfiguration.host);
            }

            const portField: AbstractControl<number, number> | null = this.systemValue4MailConfigurationForm.get('port');
            if (portField) {
                portField.patchValue(systemValue4MailConfiguration.port);
            }

            const usernameField: AbstractControl<string, string> | null = this.systemValue4MailConfigurationForm.get('username');
            if (usernameField) {
                usernameField.patchValue(systemValue4MailConfiguration.host);
            }

            const passwordField: AbstractControl<string, string> | null = this.systemValue4MailConfigurationForm.get('password');
            if (passwordField) {
                passwordField.patchValue(systemValue4MailConfiguration.host);
            }

            const smtpAuthField: AbstractControl<boolean, boolean> | null = this.systemValue4MailConfigurationForm.get('smtpAuth');
            if (smtpAuthField) {
                smtpAuthField.patchValue(systemValue4MailConfiguration.properties['mail.smtp.auth']);
            }

            const smtpStartTlsEnableField: AbstractControl<boolean, boolean> | null = this.systemValue4MailConfigurationForm.get('smtpStartTlsEnable');
            if (smtpStartTlsEnableField) {
                smtpStartTlsEnableField.patchValue(systemValue4MailConfiguration.properties['mail.smtp.starttls.enable']);
            }

            const smtpStartTlsRequiredField: AbstractControl<boolean, boolean> | null = this.systemValue4MailConfigurationForm.get('smtpStartTlsRequired');
            if (smtpStartTlsRequiredField) {
                smtpStartTlsRequiredField.patchValue(systemValue4MailConfiguration.properties['mail.smtp.starttls.required']);
            }
        });
    }

    protected submitMailConfiguration(): void {
        if (this.systemValue4MailConfigurationForm) {
            this.systemValueService.setMailConfiguration(this.systemValue4MailConfigurationForm.value).subscribe(() => {
                this.toastService.show('COMMONS.SUCCESS');
            });
        }
    }

    protected testMailConfiguration(): void {
        this.systemValueService.testMailConfiguration().subscribe(resp => {
            this.toastService.show('COMMONS.SUCCESS');
        });
    }

}
