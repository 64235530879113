import Konva from "konva";
import {ElementRef} from "@angular/core";
import {EnhancementAudio} from "../../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-audio";

export abstract class AudioPlayerVisualizer {
    private readonly stage: Konva.Stage;

    constructor(
        private target: ElementRef<HTMLDivElement>
    ) {
        this.stage = new Konva.Stage({
            container: this.target.nativeElement,
            width: this.target.nativeElement.clientWidth,
            height: this.target.nativeElement.clientHeight
        });
    }

    protected obtainState(): Konva.Stage {
        return this.stage;
    }

    protected obtainCenterX(): number {
        return this.target.nativeElement.clientWidth / 2;
    }

    protected obtainCenterY(): number {
        return this.target.nativeElement.clientHeight / 2;
    }

    onCreate(): void {
        this.onInflate(this.stage)
    }

    onDestroy(): void {
        this.stage.destroy();
    }

    updateAudio(audio: EnhancementAudio): void {
        this.onUpdateAudio(audio);
    }

    updateData(bin: Uint8Array): void {
        this.onUpdateData(bin);
    }

    start(audio: EnhancementAudio): void {
        this.onStarted(audio);
    }

    pause(audio: EnhancementAudio): void {
        this.onPaused(audio);
    }

    resume(audio: EnhancementAudio): void {
        this.onResumed(audio);
    }

    end(audio: EnhancementAudio): void {
        this.onEnded(audio);
    }

    resize(): void {
        this.onResize();
    }

    protected abstract onInflate(stage: Konva.Stage): void;

    protected abstract onUpdateAudio(audio: EnhancementAudio): void;

    protected abstract onUpdateData(bin: Uint8Array): void;

    protected abstract onStarted(audio: EnhancementAudio): void;

    protected abstract onPaused(audio: EnhancementAudio): void;

    protected abstract onResumed(audio: EnhancementAudio): void;

    protected abstract onEnded(audio: EnhancementAudio): void;

    protected onResize(): void {
        if (this.target) {
            if (this.stage) {
                this.stage.width(this.target.nativeElement.clientWidth);
                this.stage.height(this.target.nativeElement.clientHeight);
            }
        }
    }

}
