@if (mainItem) {
    @if (mainItem.span >= columns.value) {
        <div class="w-full" [style.height.px]="((height | async) ?? 0) * columns.value + extraHeight" [style.margin-bottom.px]="gutter">
            <ng-container [ngTemplateOutlet]="mainItem.template"></ng-container>
        </div>
    }

    <mat-grid-list class="w-full" [cols]="columns | async" [rowHeight]="(height | async) ?? 0" [gutterSize]="gutter.toString()">
        @if (mainItem.span < columns.value) {
            <mat-grid-tile [colspan]="mainItem.span" [rowspan]="mainItem.span">
                <ng-container [ngTemplateOutlet]="mainItem.template"></ng-container>
            </mat-grid-tile>
        }

        @for (item of items; track item.track) {
            @if ($index < (Math.max(Math.floor((mainItem.span * mainItem.span + items.length) / columns.value) * columns.value, mainItem.span * columns.value) - mainItem.span * mainItem.span)) {
                <mat-grid-tile>
                    <ng-container [ngTemplateOutlet]="item.template"></ng-container>
                </mat-grid-tile>
            }
        }
    </mat-grid-list>
} @else {
    <mat-grid-list class="w-full" [cols]="columns | async" [rowHeight]="(height | async) ?? 0" [gutterSize]="gutter.toString()">
        @for (item of items; track item.track) {
            <mat-grid-tile>
                <ng-container [ngTemplateOutlet]="item.template"></ng-container>
            </mat-grid-tile>
        }
    </mat-grid-list>
}
