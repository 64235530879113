import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SimpleMe} from "../../../../../../model/data/persist/jpa/entity/user";
import {EnhancementComment} from "../../../../../../model/data/persist/jpa/entity/comment";
import {NgxPermissionsService} from "ngx-permissions";
import {TranslateService} from "@ngx-translate/core";
import {PermissionMode} from "../../../../../../service/data/permission-mode";
import {SortablePagination} from "../../../../../../model/commons/sortablePagination";
import {Page, PageContent} from "../../../../../../model/commons/page";
import {ToastService} from "../../../../toast/toast.service";
import {DialogService} from "../../../../dialog/dialog.service";
import {MeService} from "../../../../../../service/data/me/me.service";
import {MeMediaService} from "../../../../../../service/data/me/me-media.service";
import {PublicMediaService} from "../../../../../../service/data/public/public-media.service";
import {MediaBindComment} from "../../../../../../model/data/persist/jpa/entity/media-bind-comment";
import {Subscription} from "rxjs";
import {CommentReplyDialogComponent} from "../dialog/comment-reply-dialog/comment-reply-dialog.component";
import {EnhancementMedia} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-media";

@Component({
    selector: 'cs-comment-card',
    standalone: false,
    templateUrl: './comment-card.component.html',
    styleUrls: ['./comment-card.component.scss']
})
export class CommentCardComponent implements OnInit, OnDestroy {
    protected readonly PermissionMode = PermissionMode;

    private meSubscription: Subscription | undefined;
    protected me: SimpleMe | undefined;

    protected publicCommentPagination: SortablePagination = new SortablePagination(0, 16);
    protected publicCommentPage: Page<EnhancementComment> | undefined;
    protected publicCommentPageContent: PageContent<EnhancementComment> = new PageContent<EnhancementComment>((comment: EnhancementComment) => {
        return comment.id;
    }, (left: EnhancementComment, right: EnhancementComment) => {
        return new Date(right.createTime).getTime() - new Date(left.createTime).getTime();
    });

    protected myCommentPageContent: PageContent<EnhancementComment> = new PageContent<EnhancementComment>((comment: EnhancementComment) => {
        return comment.id;
    });

    protected commentForm: {
        value: string;
    } = {
        value: ''
    };

    protected readonly MORE_MENU: string = `${CommentCardComponent.name}_MORE_MENU`;
    protected readonly MORE_MENU_DELETE: string = `${CommentCardComponent.name}_MORE_MENU_DELETE`;

    @Input()
    public permissionMode: PermissionMode = PermissionMode.God;

    @Input()
    public media: EnhancementMedia | undefined;
    @Output()
    public commentChange: EventEmitter<never> = new EventEmitter<never>();
    @Output()
    public subjectLikeChange: EventEmitter<never> = new EventEmitter<never>();
    @Output()
    public subjectUnlikeChange: EventEmitter<never> = new EventEmitter<never>();
    @Output()
    public subjectCollectionChange: EventEmitter<never> = new EventEmitter<never>();

    constructor(
        private translateService: TranslateService,
        private permissionsService: NgxPermissionsService,
        private toastService: ToastService,
        private dialogService: DialogService,
        private meService: MeService,
        private meMediaService: MeMediaService,
        private publicMediaService: PublicMediaService,
    ) {
    }

    ngOnInit(): void {
        this.meSubscription = this.meService.onMeChanged().subscribe((me: SimpleMe | undefined) => {
            this.me = me;

            if (this.me) {
                this.listMyComment();
            }
        });

        this.loadNextBrowserComment();
    }

    ngOnDestroy(): void {
        if (this.meSubscription) {
            this.meSubscription.unsubscribe();
        }
    }

    private listMyComment(): void {
        if (this.media) {
            this.meMediaService.listComment(this.media.id).subscribe((comments: EnhancementComment[]) => {
                this.myCommentPageContent.addAll(comments);
                this.publicCommentPageContent.addAll(comments);
            });
        }
    }

    protected loadNextBrowserComment(): void {
        if (!this.publicCommentPage || this.publicCommentPage && !this.publicCommentPage.last) {
            if (this.media) {
                this.publicMediaService.pageComment(this.media.id, this.publicCommentPagination).subscribe((page: Page<EnhancementComment>) => {
                    this.publicCommentPagination.page++;

                    this.publicCommentPage = page;
                    this.publicCommentPageContent.addAll(this.publicCommentPage.content);
                });
            }
        }
    }

    protected onToggleLikeSubject(): void {
        if (this.media) {
            if (this.media.likedByCurrentUser) {
                this.onUnlikeSubject();
            } else {
                this.onLikeSubject();
            }
        }
    }

    protected onLikeSubject(): void {
        this.subjectLikeChange.emit();
    }

    protected onUnlikeSubject(): void {
        this.subjectUnlikeChange.emit();
    }

    protected onUpdateSubjectCollection(): void {
        this.subjectCollectionChange.emit();
    }

    private onCommentChange(): void {
        this.commentChange.emit();
    }

    protected onComment(): void {
        if (this.media) {
            this.meMediaService.createComment(this.media.id, {
                value: this.commentForm.value
            }).subscribe((bind: MediaBindComment) => {
                this.commentForm = {
                    value: ''
                };

                this.onCommentChange();

                this.listMyComment();
            });
        }
    }

    protected onDeleteComment(comment: EnhancementComment) {
        // this.meMediaService.delete(comment.id).subscribe((comment: EnhancementComment) => {
        //     this.onCommentChange();
        //
        //     this.myCommentPageContent.remove(comment);
        //     this.publicCommentPageContent.remove(comment);
        // });
    }

    protected toggleLikeComment(comment: EnhancementComment): void {
        if (comment.likedByCurrentUser) {
            this.onUnlikedComment(comment);
        } else {
            this.onLikeComment(comment);
        }
    }

    protected onLikeComment(comment: EnhancementComment): void {
        // this.meCommentService.bindLikedUser(comment.id).subscribe((enhancementComment: EnhancementComment) => {
        //     this.myCommentPageContent.update(enhancementComment);
        //     this.publicCommentPageContent.update(enhancementComment);
        // });
    }

    protected onUnlikedComment(comment: EnhancementComment): void {
        // this.meCommentService.unbindLikedUser(comment.id).subscribe((enhancementComment: EnhancementComment) => {
        //     this.myCommentPageContent.update(enhancementComment);
        //     this.publicCommentPageContent.update(enhancementComment);
        // });
    }

    protected onReplyComment(comment: EnhancementComment): void {
        this.dialogService.open<CommentReplyDialogComponent, EnhancementComment>(CommentReplyDialogComponent, {
            comment: comment
        }).afterClosed().subscribe((comment: EnhancementComment | undefined) => {
            if (comment) {
                this.myCommentPageContent.update(comment);
                this.publicCommentPageContent.update(comment);
            }
        });
    }

}
