import {Component, OnDestroy, OnInit} from '@angular/core';
import {SortablePagination} from "../../../../core/model/commons/sortablePagination";
import {Page, PageContent} from "../../../../core/model/commons/page";
import {PermissionMode} from "../../../../core/service/data/permission-mode";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxPermissionsService} from "ngx-permissions";
import {HomePageComponentService} from "../../home-page.component.service";
import {PublicImageService} from "../../../../core/service/data/public/public-image.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {CommonModule} from "@angular/common";
import {CsCommonsModule} from "../../../../core/module/commons/cs.commons.module";
import {CsMediaModule} from "../../../../core/module/component/media/cs.media.module";
import {EnhancementImage} from "../../../../core/model/data/persist/jpa/entity/enhancement/enhancement-image";
import {OnAttach, OnDetach} from "../../../../core/module/commons/route/enhanced-router-outlet";
import {ImageType, ImageTypeAspectRatioTable} from "../../../../core/model/data/persist/jpa/entity/image";
import {EnhancementMediaCollection} from "../../../../core/model/data/persist/jpa/entity/media-collection";
import {MeService} from "../../../../core/service/data/me/me.service";
import {PublicMediaCollectionService} from "../../../../core/service/data/public/public-media-collection.service";
import {SimpleMe} from "../../../../core/model/data/persist/jpa/entity/user";
import {Duration} from "../../../../core/model/data/utils/time-range";
import {MatIconModule} from "@angular/material/icon";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MediaCategory} from "../../../../core/model/data/persist/jpa/entity/media";

@Component({
    selector: 'cs-home-image-discovery-page',
    standalone: true,
    templateUrl: './home-image-discovery-page.component.html',
    styleUrls: ['./home-image-discovery-page.component.scss'],
    imports: [
        CommonModule,
        MatIconModule,
        MatToolbarModule,
        CsCommonsModule,
        CsMediaModule,
    ]
})
export class HomeImageDiscoveryPageComponent implements OnInit, OnDestroy, OnAttach, OnDetach {
    protected readonly PermissionMode = PermissionMode;
    protected readonly ImageTypeAspectTable = ImageTypeAspectRatioTable;
    protected readonly ImageType = ImageType;

    protected hottestImageInDayPagination: SortablePagination = new SortablePagination(0, 12);
    protected hottestImageInDayPage: Page<EnhancementImage> | undefined;
    protected hottestImageInDayPageContent: PageContent<EnhancementImage> = new PageContent<EnhancementImage>((media: EnhancementImage) => {
        return media.id;
    });

    protected hottestImageInWeekPagination: SortablePagination = new SortablePagination(0, 6);
    protected hottestImageInWeekPage: Page<EnhancementImage> | undefined;
    protected hottestImageInWeekPageContent: PageContent<EnhancementImage> = new PageContent<EnhancementImage>((media: EnhancementImage) => {
        return media.id;
    });

    protected hottestMediaCollectionInWeekPagination: SortablePagination = new SortablePagination(0, 6);
    protected hottestMediaCollectionInWeekPage: Page<EnhancementMediaCollection> | undefined;
    protected hottestMediaCollectionInWeekPageContent: PageContent<EnhancementMediaCollection> = new PageContent<EnhancementMediaCollection>((media: EnhancementMediaCollection) => {
        return media.id;
    });

    protected recommendImagePagination: SortablePagination = new SortablePagination(0, 6);
    protected recommendImagePage: Page<EnhancementImage> | undefined;
    protected recommendImagePageContent: PageContent<EnhancementImage> = new PageContent<EnhancementImage>((media: EnhancementImage) => {
        return media.id;
    });

    private isLogin: boolean = false;

    constructor(
        private homeComponentService: HomePageComponentService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private permissionsService: NgxPermissionsService,
        private translateService: TranslateService,
        private meService: MeService,
        private publicImageService: PublicImageService,
        private publicMediaCollectionService: PublicMediaCollectionService
    ) {
        this.homeComponentService.onScrollToBottom().pipe(takeUntilDestroyed()).subscribe(() => {
            this.loadNextRecommendationImage();
        });

        this.meService.onMeChanged().pipe(takeUntilDestroyed()).subscribe((me: SimpleMe | undefined) => {
            const isLogin: boolean = !!me;
            if (this.isLogin !== isLogin) {
                this.isLogin = isLogin;
                // this.loadNextRecommendation(true);
            }
        });
    }

    ngOnInit(): void {
        this.loadNextHottestImageInDay();
        this.loadNextHottestImageInWeek()
        this.loadNextHottestMediaCollectionInWeek();
        // this.loadNextRecommendation();
    }

    ngOnAttach(): void {
        this.homeComponentService.hideAudioControlBar();
    }

    ngOnDetach(): void {
    }

    ngOnDestroy(): void {
    }

    private loadNextHottestImageInDay(reset: boolean = false): void {
        if (reset) {
            this.hottestImageInDayPagination.reset();
            this.hottestImageInDayPage = undefined;
            this.hottestImageInDayPageContent.reset();
        }

        if (!this.hottestImageInDayPage || this.hottestImageInDayPage && !this.hottestImageInDayPage.last) {
            this.publicImageService.pageHottest(this.hottestImageInDayPagination, Duration.OneDay).subscribe((page: Page<EnhancementImage>) => {
                this.hottestImageInDayPagination.page++;

                this.hottestImageInDayPage = page;
                this.hottestImageInDayPageContent.addAll(this.hottestImageInDayPage.content);
            });
        }
    }

    private loadNextHottestImageInWeek(reset: boolean = false): void {
        if (reset) {
            this.hottestImageInWeekPagination.reset();
            this.hottestImageInWeekPage = undefined;
            this.hottestImageInWeekPageContent.reset();
        }

        if (!this.hottestImageInWeekPage || this.hottestImageInWeekPage && !this.hottestImageInWeekPage.last) {
            this.publicImageService.pageHottest(this.hottestImageInWeekPagination, Duration.OneWeek).subscribe((page: Page<EnhancementImage>) => {
                this.hottestImageInWeekPagination.page++;

                this.hottestImageInWeekPage = page;
                this.hottestImageInWeekPageContent.addAll(this.hottestImageInWeekPage.content);
            });
        }
    }

    private loadNextHottestMediaCollectionInWeek(reset: boolean = false): void {
        if (reset) {
            this.hottestMediaCollectionInWeekPagination.reset();
            this.hottestMediaCollectionInWeekPage = undefined;
            this.hottestMediaCollectionInWeekPageContent.reset();
        }

        if (!this.hottestMediaCollectionInWeekPage || this.hottestMediaCollectionInWeekPage && !this.hottestMediaCollectionInWeekPage.last) {
            this.publicMediaCollectionService.pageHottestByCategory(MediaCategory.Image, this.hottestMediaCollectionInWeekPagination, Duration.OneWeek).subscribe((page: Page<EnhancementMediaCollection>) => {
                this.hottestMediaCollectionInWeekPagination.page++;

                this.hottestMediaCollectionInWeekPage = page;
                this.hottestMediaCollectionInWeekPageContent.addAll(this.hottestMediaCollectionInWeekPage.content);
            });
        }
    }

    private loadNextRecommendationImage(reset: boolean = false): void {
        if (reset) {
            this.recommendImagePagination.reset();
            this.recommendImagePage = undefined;
            this.recommendImagePageContent.reset();
        }

        if (!this.recommendImagePage || this.recommendImagePage && !this.recommendImagePage.last) {
            this.publicImageService.pageRecommendation(this.recommendImagePagination).subscribe((page: Page<EnhancementImage>) => {
                this.recommendImagePagination.page++;

                this.recommendImagePage = page;
                this.recommendImagePageContent.addAll(this.recommendImagePage.content);
            });
        }
    }

    protected onImageClick(image: EnhancementImage): void {
        this.router.navigate([`/image/${image.id}`]).then();
    }

    protected onMediaCollectionClick(mediaCollection: EnhancementMediaCollection): void {
        this.router.navigate([`/media-collection/${mediaCollection.id}`]).then();
    }

}

