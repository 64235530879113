import {Route} from "@angular/router";
import {MeResolver} from "../../core/resolver/me.resolver";
import {HomePageComponent} from "./home-page.component";
import {DiscoveryPageComponent} from "./discovery-page/discovery-page.component";
import {HomeImageListPageComponent} from "./home-image-page/home-image-list-page/home-image-list-page.component";
import {HomeAudioListPageComponent} from "./home-audio-page/home-audio-list-page/home-audio-list-page.component";
import {HomeVideoListPageComponent} from "./home-video-page/home-video-list-page/home-video-list-page.component";
import {HomeBookListPageComponent} from "./home-book-page/home-book-list-page/home-book-list-page.component";
import {HomeImagePageComponent} from "./home-image-page/home-image-page.component";
import {HomeAudioPageComponent} from "./home-audio-page/home-audio-page.component";
import {HomeVideoPageComponent} from "./home-video-page/home-video-page.component";
import {HomeBookPageComponent} from "./home-book-page/home-book-page.component";
import {
    HomeImageDiscoveryPageComponent
} from "./home-image-page/home-image-discovery-page/home-image-discovery-page.component";
import {
    HomeAudioDiscoveryPageComponent
} from "./home-audio-page/home-audio-discovery-page/home-audio-discovery-page.component";
import {
    HomeVideoDiscoveryPageComponent
} from "./home-video-page/home-video-discovery-page/home-video-discovery-page.component";
import {
    HomeBookDiscoveryPageComponent
} from "./home-book-page/home-book-discovery-page/home-book-discovery-page.component";

export const homePageRoute: Route = {
    path: '',
    component: HomePageComponent,
    resolve: [
        MeResolver
    ],
    children: [
        {
            path: 'discovery',
            component: DiscoveryPageComponent,
            data: {
                reuse: true
            }
        },
        {
            path: 'image',
            component: HomeImagePageComponent,
            children: [
                {
                    path: '',
                    component: HomeImageDiscoveryPageComponent,
                    data: {
                        reuse: true
                    }
                },
                {
                    path: 'list',
                    component: HomeImageListPageComponent,
                    data: {
                        reuse: true
                    }
                },
            ]
        },
        {
            path: 'audio',
            component: HomeAudioPageComponent,
            children: [
                {
                    path: '',
                    component: HomeAudioDiscoveryPageComponent,
                    data: {
                        reuse: true
                    }
                },
                {
                    path: 'list',
                    component: HomeAudioListPageComponent,
                    data: {
                        reuse: true
                    }
                },
            ]
        },
        {
            path: 'video',
            component: HomeVideoPageComponent,
            children: [
                {
                    path: '',
                    component: HomeVideoDiscoveryPageComponent,
                    data: {
                        reuse: true
                    }
                },
                {
                    path: 'list',
                    component: HomeVideoListPageComponent,
                    data: {
                        reuse: true
                    }
                },
            ]
        },
        {
            path: 'book',
            component: HomeBookPageComponent,
            children: [
                {
                    path: '',
                    component: HomeBookDiscoveryPageComponent,
                    data: {
                        reuse: true
                    }
                },
                {
                    path: 'list',
                    component: HomeBookListPageComponent,
                    data: {
                        reuse: true
                    }
                }
            ]
        }
    ]
};
