import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SortablePagination} from "../../../model/commons/sortablePagination";
import {Page} from "../../../model/commons/page";
import {BookIndex} from "../../../model/data/persist/jpa/entity/book";
import {SortMethod} from "../../../model/sort-method";
import {Service} from '../service';
import {EnhancementBook} from "../../../model/data/persist/jpa/entity/enhancement/enhancement-book";
import {EnhancementArticle} from "../../../model/data/persist/jpa/entity/enhancement/enhancement-article";
import {Duration} from "../../../model/data/utils/time-range";
import {EnhancementVideo} from "../../../model/data/persist/jpa/entity/enhancement/enhancement-video";

@Injectable({
    providedIn: 'root'
})
export class PublicBookService {

    constructor(
        private http: HttpClient
    ) {
    }

    page(sortMethod: SortMethod, pagination: SortablePagination): Observable<Page<EnhancementBook>> {
        return this.http.get<Page<EnhancementBook>>(`${Service.Media.prefixUrl}/public/media/book${pagination.toRequestParameter()}&sortMethod=${sortMethod}`);
    }

    pageHottest(pagination: SortablePagination, duration?: Duration): Observable<Page<EnhancementBook>> {
        if (duration) {
            return this.http.get<Page<EnhancementBook>>(`${Service.Media.prefixUrl}/public/media/book/hottest${pagination.toRequestParameter()}&duration=${duration}`);
        } else {
            return this.http.get<Page<EnhancementBook>>(`${Service.Media.prefixUrl}/public/media/book/hottest${pagination.toRequestParameter()}`);
        }
    }

    load(id: number): Observable<EnhancementBook> {
        return this.http.get<EnhancementBook>(`${Service.Media.prefixUrl}/public/media/book/${id}`);
    }

    listIndex(id: number, bookIndexId?: number): Observable<BookIndex[]> {
        if (bookIndexId) {
            return this.http.get<BookIndex[]>(`${Service.Media.prefixUrl}/public/media/book/${id}/index/${bookIndexId}/all`);
        } else {
            return this.http.get<BookIndex[]>(`${Service.Media.prefixUrl}/public/media/book/${id}/index/all`);
        }
    }

    listArticle(id: number, bookIndexId: number): Observable<EnhancementArticle[]> {
        return this.http.get<EnhancementArticle[]>(`${Service.Media.prefixUrl}/public/media/book/${id}/index/${bookIndexId}/article/all`);
    }

    pageRecommendation(pagination: SortablePagination): Observable<Page<EnhancementBook>> {
        return this.http.get<Page<EnhancementBook>>(`${Service.Search.prefixUrl}/public/media/book/recommendation${pagination.toRequestParameter()}`);
    }

}
