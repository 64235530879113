import {Route} from "@angular/router";
import {CustomizationResolver} from "../../core/resolver/customization.resolver";
import {MeAuthResolver} from "../../core/resolver/me.auth.resolver";
import {AuthPageComponent} from "./auth-page.component";
import {LoginPageComponent} from "./login-page/login-page.component";
import {MeResolver} from "../../core/resolver/me.resolver";
import {LogoutPageComponent} from "./logout-page/logout-page.component";

export const authPageRoute: Route =  {
    path: 'auth',
    component: AuthPageComponent,
    children: [
        {
            path: 'login',
            component: LoginPageComponent,
            resolve: [
                MeResolver
            ]
        },
        {
            path: 'logout',
            component: LogoutPageComponent,
            resolve: [
                MeAuthResolver
            ]
        }
    ]
};
