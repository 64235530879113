import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import KeenSlider, {KeenSliderInstance} from "keen-slider";
import {interval, Subscription} from "rxjs";
import {ImageResolution} from '../../../../../../model/data/persist/jpa/entity/image-file';
import {ImageBackgroundSize} from '../../../../../commons/component/image/image-background/image-background.component';
import {EnhancementMedia} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-media";
import {MediaCategory} from "../../../../../../model/data/persist/jpa/entity/media";

@Component({
    selector: 'cs-media-gallery',
    standalone: false,
    templateUrl: './media-gallery.component.html',
    styleUrl: './media-gallery.component.scss'
})
export class MediaGalleryComponent<Media extends EnhancementMedia> implements OnInit, AfterViewInit, OnDestroy {
    protected readonly ImageBackgroundSize = ImageBackgroundSize;
    protected readonly ImageResolution = ImageResolution;
    protected readonly MediaCategory = MediaCategory;

    @ViewChild("sliderRef")
    private sliderRef: ElementRef<HTMLElement> | undefined;
    private slider: KeenSliderInstance | undefined;
    private mutationObserver: MutationObserver = new MutationObserver((mutations: MutationRecord[]) => {
        if (this.slider) {
            this.slider.update(this.slider.options);
        }
        this.onMediaChange(0);
    });
    private resizeObserver: ResizeObserver = new ResizeObserver((mutations: ResizeObserverEntry[]) => {
        if (this.slider) {
            this.slider.update(this.slider.options);
        }
    });

    private scrollIntervalDuration: number = 5000;
    private scrollIntervalSubscription: Subscription | undefined;

    private scrollPause: boolean = false;

    protected currentMedia: Media | undefined;

    @Input()
    public extraHeight: number = 0;

    @Input()
    public medias: Media[] = [];
    @Output()
    public mediaChange: EventEmitter<Media> = new EventEmitter<Media>();
    @Output()
    public mediaClick: EventEmitter<Media> = new EventEmitter<Media>();

    ngOnInit(): void {
        this.onMediaChange(0);
    }

    ngAfterViewInit(): void {
        if (this.sliderRef) {
            this.slider = new KeenSlider(this.sliderRef.nativeElement, {
                loop: true,
                vertical: false,
                created: () => {
                    if (this.sliderRef) {
                        this.mutationObserver.observe(this.sliderRef.nativeElement, {
                            childList: true
                        });
                        this.resizeObserver.observe(this.sliderRef.nativeElement);

                        this.sliderRef.nativeElement.addEventListener("mouseover", () => {
                            this.onPauseScroll();
                        })
                        this.sliderRef.nativeElement.addEventListener("mouseout", () => {
                            this.onResumeScroll();
                        })
                    }
                    this.subscribeScrollInterval();
                },
                destroyed: () => {
                    this.mutationObserver.disconnect();
                    this.resizeObserver.disconnect();
                },
                slideChanged: (instance: KeenSliderInstance) => {
                    this.onMediaChange(instance.track.details.rel);
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeScrollInterval();
        if (this.slider) {
            this.slider.destroy();
        }
    }

    protected changeMedia(media: Media): void {
        const index: number = this.medias.indexOf(media);
        if (index >= 0) {
            if (this.slider) {
                this.slider.moveToIdx(index);
                this.subscribeScrollInterval();
            }
        }
    }

    protected next(): void {
        if (this.slider && this.slider.slides.length) {
            this.slider.next();
        }
    }

    protected previous(): void {
        if (this.slider) {
            this.slider.prev();
        }
    }

    protected onPauseScroll(): void {
        this.scrollPause = true;
        this.unsubscribeScrollInterval();
    }

    protected onResumeScroll(): void {
        this.scrollPause = false;
        this.subscribeScrollInterval();
    }

    protected onMediaClick(media: Media) {
        this.mediaClick.emit(media);
    }

    private subscribeScrollInterval(): void {
        this.unsubscribeScrollInterval();
        this.scrollIntervalSubscription = interval(this.scrollIntervalDuration).subscribe(() => {
            if (this.slider && this.slider.slides.length > 1) {
                if (!this.scrollPause) {
                    this.slider.next();
                }
            }
        });
    }

    private unsubscribeScrollInterval(): void {
        if (this.scrollIntervalSubscription) {
            this.scrollIntervalSubscription.unsubscribe();
        }
    }

    private onMediaChange(index: number): void {
        if (index < this.medias.length) {
            this.currentMedia = this.medias[index];
            this.mediaChange.emit(this.currentMedia);
        }
    }

}
