import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserProfile} from '../../../core/model/data/persist/jpa/entity/user-profile';
import {UserProfileUpdateRequest} from '../../../core/model/request/user-profile-update-request';
import {Language} from '../../../core/model/lanuage';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Theme} from '../../../core/model/theme';
import {MeService} from "../../../core/service/data/me/me.service";
import {SimpleMe} from "../../../core/model/data/persist/jpa/entity/user";
import {ImageResolution} from "../../../core/model/data/persist/jpa/entity/image-file";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatCardModule} from "@angular/material/card";
import {CsCommonsModule} from "../../../core/module/commons/cs.commons.module";
import {MePageComponentService} from "../me-page.component.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxPermissionsService} from "ngx-permissions";
import {ToastService} from "../../../core/module/component/toast/toast.service";
import {DialogService} from "../../../core/module/component/dialog/dialog.service";
import {ThemeService} from "../../../core/module/component/theme/theme.service";
import {
    MeImageSelectDialogComponent
} from "../../../core/module/component/media/media/media-image/dialog/me-image-select-dialog/me-image-select-dialog.component";
import {MediaCollectionName} from "../../../core/model/data/persist/jpa/entity/media-collection";
import {MatSelectModule} from "@angular/material/select";
import {EnhancementImage} from "../../../core/model/data/persist/jpa/entity/enhancement/enhancement-image";

@Component({
    selector: 'cs-me-profile-page',
    standalone: true,
    templateUrl: './me-profile-page.component.html',
    styleUrls: ['./me-profile-page.component.scss'],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatCardModule,
        CsCommonsModule,
    ]
})
export class MeProfilePageComponent implements OnInit, OnDestroy {
    protected readonly ImageResolution = ImageResolution;
    protected readonly Language = Language;
    protected readonly Theme = Theme;

    protected me: SimpleMe | undefined;
    protected userProfileUpdateRequest: UserProfileUpdateRequest | undefined;

    constructor(
        protected mePageComponentService: MePageComponentService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private permissionsService: NgxPermissionsService,
        private toastService: ToastService,
        private dialogService: DialogService,
        private themeService: ThemeService,
        private meService: MeService,
    ) {
        this.meService.onMeChanged().pipe(takeUntilDestroyed()).subscribe((me: SimpleMe | undefined) => {
            this.me = me;
        });
    }

    ngOnInit(): void {
        this.meService.profile().subscribe((userProfile: UserProfile) => {
            this.userProfileUpdateRequest = {
                language: userProfile.language,
                theme: userProfile.theme,
                mail: userProfile.mail
            };
        });
    }

    ngOnDestroy(): void {
        this.meService.profile().subscribe((userProfile: UserProfile) => {
            console.info('check theme and language due to leave profile page');
            this.themeService.changeTheme(userProfile.theme);
            this.translateService.use(userProfile.language);
        });
    }

    protected changeLanguage(): void {
        if (this.userProfileUpdateRequest) {
            this.translateService.use(this.userProfileUpdateRequest.language);
        }
    }

    protected changeTheme(): void {
        if (this.userProfileUpdateRequest) {
            this.themeService.changeTheme(this.userProfileUpdateRequest.theme);
        }
    }

    protected onChangeAvatar(): void {
        this.dialogService.open<MeImageSelectDialogComponent, EnhancementImage[]>(MeImageSelectDialogComponent, {
            single: true,
            scopes: [
                MediaCollectionName.DEFAULT_AVATAR_IMAGE_COLLECTION
            ]
        }).afterClosed().subscribe((images: EnhancementImage[] | undefined) => {
            if (images && images.length) {
                this.meService.updateProfileAvatar(images[0].id).subscribe((userProfile: UserProfile) => {
                    this.meService.me().subscribe(() => {
                        console.info('reload me due to avatar changed');
                    });
                });
            }
        });
    }

    protected onSubmit(): void {
        if (this.userProfileUpdateRequest) {
            this.meService.updateProfile(this.userProfileUpdateRequest).subscribe((userProfile: UserProfile) => {
                console.info('change theme and language due to profile updated');
                this.themeService.changeTheme(userProfile.theme);
                this.translateService.use(userProfile.language);

                this.toastService.show('COMMONS.SUCCESS');
            });
        }
    }

}
