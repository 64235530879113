import {Component, inject, Inject, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {VisibleScope} from "../../../../../../../model/data/persist/jpa/entity/visible-scope";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PermissionService} from "../../../../../../../service/data/god/permission.service";
import {ToastService} from "../../../../../toast/toast.service";
import {DialogService} from "../../../../../dialog/dialog.service";
import {MeArticleService} from "../../../../../../../service/data/me/me-article.service";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {EnhancementArticle} from "../../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-article";

@Component({
    selector: 'cs-me-article-edit-dialog',
    standalone: false,
    templateUrl: './me-article-edit-dialog.component.html',
    styleUrls: ['./me-article-edit-dialog.component.scss']
})
export class MeArticleEditDialogComponent implements OnInit {
    protected readonly VisibleScope = VisibleScope;

    protected article: EnhancementArticle | undefined;

    protected articleForm: FormGroup = inject(FormBuilder).group({
        name: ['', Validators.required],
        description: ['', Validators.required],
        visibleScope: [VisibleScope.Private, Validators.required]
    });
    protected articleFormHelper: {
        content: string;
        draftContent: string;
    } = {
        content: '',
        draftContent: ''
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any = {},
        private dialogRef: MatDialogRef<MeArticleEditDialogComponent>,
        private translateService: TranslateService,
        private permissionService: PermissionService,
        private dialogService: DialogService,
        private toastService: ToastService,
        private meArticleService: MeArticleService) {
        this.article = this.data.article;
        // this.menuService.onItemClick().pipe(takeUntilDestroyed(), filter((bag: NbMenuBag) => {
        //     return bag.tag === this.MORE_MENU && bag.item.data['type'] === this.MORE_MENU_STATISTICS;
        // })).subscribe(() => {
        //     this.dialogService.open(BookStatisticsViewDialogComponent, {
        //         hasBackdrop: true,
        //         closeOnBackdropClick: false,
        //         closeOnEsc: true,
        //         hasScroll: false,
        //         autoFocus: true,
        //         data: {
        //             media-book: this.media-book
        //         }
        //     });
        // });
        // this.menuService.onItemClick().pipe(takeUntilDestroyed(), filter((bag: NbMenuBag) => {
        //     return bag.tag === this.MORE_MENU && bag.item.data['type'] === this.MORE_MENU_DELETE;
        // })).subscribe(() => {
        //     this.dialogService.open(ConfirmDialogComponent, {
        //         hasBackdrop: true,
        //         closeOnBackdropClick: false,
        //         closeOnEsc: true,
        //         hasScroll: false,
        //         autoFocus: true
        //     }).onClose.subscribe((result: boolean) => {
        //         if (result) {
        //             if (this.media-book) {
        //                 this.meBookService.delete(this.media-book.id).subscribe(() => {
        //                     this.close();
        //                     this.toastService.show(this.translateService.instant('COMMONS.SUCCESS_CONTENT'), this.translateService.instant('COMMONS.SUCCESS'), {
        //                         status: 'success',
        //                         duration: 2500
        //                     });
        //                 });
        //             }
        //         }
        //     });
        // });
    }

    ngOnInit(): void {
        if (this.article) {
            const nameFiled: AbstractControl<string, string> | null = this.articleForm.get('name');
            if (nameFiled) {
                nameFiled.patchValue(this.article.name);
            }

            const descriptionFiled: AbstractControl<string, string> | null = this.articleForm.get('description');
            if (descriptionFiled) {
                descriptionFiled.patchValue(this.article.description);
            }

            const visibleScopeFiled: AbstractControl<VisibleScope, VisibleScope> | null = this.articleForm.get('visibleScope');
            if (visibleScopeFiled) {
                visibleScopeFiled.patchValue(this.article.visibleScope);
            }

            const contentFiled: AbstractControl<string, string> | null = this.articleForm.get('content');
            if (contentFiled) {
                contentFiled.patchValue(this.article.content);
            }

            const draftContentFiled: AbstractControl<string, string> | null = this.articleForm.get('draftContent');
            if (draftContentFiled) {
                draftContentFiled.patchValue(this.article.draftContent);
            }
        }
        this.onResume();
    }

    protected onResume(): void {
        if (this.article) {
            this.articleFormHelper.content = this.article.content;
            this.articleFormHelper.draftContent = this.article.draftContent;
        }
    }

    protected onSubmitDraft(): void {
        if (this.article) {
            this.meArticleService.update(this.article.id, {...this.articleForm.value, ...this.articleFormHelper}).subscribe((article: EnhancementArticle) => {
                this.toastService.show('COMMONS.SUCCESS');
            });
        }
    }

    protected onSubmit(): void {
        if (this.article) {
            this.articleFormHelper.content = this.articleFormHelper.draftContent;
            this.meArticleService.update(this.article.id, {...this.articleForm.value, ...this.articleFormHelper}).subscribe((article: EnhancementArticle) => {
                this.dialogRef.close(article);
            });
        }
    }

    protected onClose(): void {
        this.dialogRef.close();
    }

}
