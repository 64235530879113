import {Pipe, PipeTransform} from '@angular/core';

const FILE_SIZE_UNITS: string[] = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const FILE_SIZE_UNITS_LONG: string[] = ['Bytes', 'Kilobytes', 'Megabytes', 'Gigabytes', 'Pettabytes', 'Exabytes', 'Zettabytes', 'Yottabytes'];

@Pipe({
    name: 'filesize'
})
export class FileSizeFormatterPipe implements PipeTransform {
    transform(sizeInBytes: number, longForm: boolean = false): string {
        if (sizeInBytes) {
            const units: string[] = longForm ? FILE_SIZE_UNITS_LONG : FILE_SIZE_UNITS;

            let power: number = Math.round(Math.log(sizeInBytes) / Math.log(1024));
            power = Math.min(power, units.length - 1);

            const size: number = sizeInBytes / Math.pow(1024, power); // size in new units
            const formattedSize: number = Math.round(size * 100) / 100; // keep up to 2 decimals
            const unit: string = units[power];

            return `${formattedSize} ${unit}`;
        } else {
            return '0';
        }
    }
}
