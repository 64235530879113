import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {CsCommonsModule} from "../../commons/cs.commons.module";
import {MediaUpdateCardComponent} from "./media/commons/media-update-card/media-update-card.component";
import {MatChipsModule} from "@angular/material/chips";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {NgxFileDropModule} from "ngx-file-drop";
import {CsAvatarModule} from "../avatar/cs.avatar.module";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSliderModule} from "@angular/material/slider";
import {CsUsernameModule} from "../username/cs.username.module";
import {VideoPlayerComponent} from "./media/media-video/video-player/video-player.component";
import {VideoCardComponent} from "./media/media-video/video-card/video-card.component";
import {VideoGridViewerComponent} from "./media/media-video/video-grid-viewer/video-grid-viewer.component";
import {VideoViewerComponent} from "./media/media-video/video-viewer/video-viewer.component";
import {
    MeVideoCreateDialogComponent
} from "./media/media-video/dialog/me-video-create-dialog/me-video-create-dialog.component";
import {
    MeVideoEditDialogComponent
} from "./media/media-video/dialog/me-video-edit-dialog/me-video-edit-dialog.component";
import {
    MeVideoSelectDialogComponent
} from "./media/media-video/dialog/me-video-select-dialog/me-video-select-dialog.component";
import {ImageCardComponent} from "./media/media-image/image-card/image-card.component";
import {ImageGridViewerComponent} from "./media/media-image/image-grid-viewer/image-grid-viewer.component";
import {ImageViewerComponent} from "./media/media-image/image-viewer/image-viewer.component";
import {
    DroppedFileImageComponent
} from "./media/media-image/dialog/me-image-create-dialog/dropped-file-image.component";
import {
    MeImageCropperDialogComponent
} from "./media/media-image/dialog/me-image-cropper-dialog/me-image-cropper-dialog.component";
import {
    MeImageCreateDialogComponent
} from "./media/media-image/dialog/me-image-create-dialog/me-image-create-dialog.component";
import {
    MeImageEditDialogComponent
} from "./media/media-image/dialog/me-image-edit-dialog/me-image-edit-dialog.component";
import {
    MeImageSelectDialogComponent
} from "./media/media-image/dialog/me-image-select-dialog/me-image-select-dialog.component";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {CsEditorModule} from "../editor/cs.editor.module";
import {AudioPlayerComponent} from "./media/media-audio/audio-player/audio-player.component";
import {
    AudioPlayerVisualizationComponent
} from "./media/media-audio/audio-player-visualization/audio-player-visualization.component";
import {AudioPlayerLyricsComponent} from "./media/media-audio/audio-player-lyrics/audio-player-lyrics.component";
import {AudioCardComponent} from "./media/media-audio/audio-card/audio-card.component";
import {AudioGridViewerComponent} from "./media/media-audio/audio-grid-viewer/audio-grid-viewer.component";
import {AudioViewerComponent} from "./media/media-audio/audio-viewer/audio-viewer.component";
import {
    MeAudioCreateDialogComponent
} from "./media/media-audio/dialog/me-audio-create-dialog/me-audio-create-dialog.component";
import {
    MeAudioEditDialogComponent
} from "./media/media-audio/dialog/me-audio-edit-dialog/me-audio-edit-dialog.component";
import {
    MeAudioSelectDialogComponent
} from "./media/media-audio/dialog/me-audio-select-dialog/me-audio-select-dialog.component";
import {AudioPlayerService} from "./media/media-audio/audio-player/audio-player.service";
import {OverlayModule} from "@angular/cdk/overlay";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatListModule} from "@angular/material/list";
import {BookCardComponent} from "./media/media-book/book-card/book-card.component";
import {BookGridViewerComponent} from "./media/media-book/book-grid-viewer/book-grid-viewer.component";
import {BookViewerComponent} from "./media/media-book/book-viewer/book-viewer.component";
import {
    MeBookCreateDialogComponent
} from "./media/media-book/dialog/me-book-create-dialog/me-book-create-dialog.component";
import {MeBookEditDialogComponent} from "./media/media-book/dialog/me-book-edit-dialog/me-book-edit-dialog.component";
import {
    MeArticleEditDialogComponent
} from "./media/media-book/dialog/me-article-edit-dialog/me-article-edit-dialog.component";
import {
    MeBookSelectDialogComponent
} from "./media/media-book/dialog/me-book-select-dialog/me-book-select-dialog.component";
import {CommentCardComponent} from "./media/media-comment/comment-card/comment-card.component";
import {
    CommentReplyDialogComponent
} from "./media/media-comment/dialog/comment-reply-dialog/comment-reply-dialog.component";
import {MediaViewerComponent} from "./media/media/media-viewer/media-viewer.component";
import {MediaCollectionCardComponent} from "./media-collection/media-collection-card/media-collection-card.component";
import {
    MediaCollectionGridViewerComponent
} from "./media-collection/media-collection-grid-viewer/media-collection-grid-viewer.component";
import {
    MeMediaCollectionCreateDialogComponent
} from "./media-collection/dialog/me-media-collection-create-dialog/me-media-collection-create-dialog.component";
import {
    MeMediaCollectionEditDialogComponent
} from "./media-collection/dialog/me-media-collection-edit-dialog/me-media-collection-edit-dialog.component";
import {
    MediaCollectionViewerComponent
} from "./media-collection/media-collection-viewer/media-collection-viewer.component";
import {MatMenuModule} from "@angular/material/menu";
import {MediaGalleryComponent} from "./media/media/media-gallery/media-gallery.component";
import {MediaCardComponent} from "./media/media/media-card/media-card.component";
import {CdkListboxModule} from "@angular/cdk/listbox";
import {ImageGalleryComponent} from "./media/media-image/image-gallery/image-gallery.component";
import {BookPlayerComponent} from "./media/media-book/book-player/book-player.component";
import {MediaGridViewerComponent} from "./media/media/media-grid-viewer/media-grid-viewer.component";
import {AudioGalleryComponent} from "./media/media-audio/audio-gallery/audio-gallery.component";
import {VideoGalleryComponent} from "./media/media-video/video-gallery/video-gallery.component";
import {BookGalleryComponent} from "./media/media-book/book-gallery/book-gallery.component";
import {ImageCropperComponent} from "ngx-image-cropper";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        OverlayModule,
        CdkListboxModule,
        TranslateModule,
        NgxFileDropModule,
        ImageCropperComponent,
        MatToolbarModule,
        MatSidenavModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatListModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatChipsModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSliderModule,
        CsCommonsModule,
        CsAvatarModule,
        CsUsernameModule,
        CsEditorModule
    ],
    declarations: [
        /****************************************
         * Media
         ****************************************/
        MediaUpdateCardComponent,

        MediaCardComponent,
        MediaGridViewerComponent,
        MediaViewerComponent,
        MediaGalleryComponent,

        /****************************************
         * Image
         ****************************************/
        ImageCardComponent,
        ImageGridViewerComponent,
        ImageViewerComponent,
        ImageGalleryComponent,

        DroppedFileImageComponent,

        MeImageCropperDialogComponent,
        MeImageCreateDialogComponent,
        MeImageEditDialogComponent,
        MeImageSelectDialogComponent,

        /****************************************
         * Audio
         ****************************************/
        AudioPlayerComponent,
        AudioPlayerVisualizationComponent,
        AudioPlayerLyricsComponent,
        AudioCardComponent,
        AudioGridViewerComponent,
        AudioViewerComponent,
        AudioGalleryComponent,

        MeAudioCreateDialogComponent,
        MeAudioEditDialogComponent,
        MeAudioSelectDialogComponent,

        /****************************************
         * Video
         ****************************************/
        VideoPlayerComponent,
        VideoCardComponent,
        VideoGridViewerComponent,
        VideoViewerComponent,
        VideoGalleryComponent,

        MeVideoCreateDialogComponent,
        MeVideoEditDialogComponent,
        MeVideoSelectDialogComponent,

        /****************************************
         * Book
         ****************************************/
        BookCardComponent,
        BookGridViewerComponent,
        BookPlayerComponent,
        BookViewerComponent,
        BookGalleryComponent,

        MeBookCreateDialogComponent,
        MeBookEditDialogComponent,
        MeArticleEditDialogComponent,
        MeBookSelectDialogComponent,

        /****************************************
         * Comment
         ****************************************/
        CommentCardComponent,
        CommentReplyDialogComponent,

        /****************************************
         * MediaCollection
         ****************************************/
        MediaCollectionCardComponent,
        MediaCollectionGridViewerComponent,
        MediaCollectionViewerComponent,

        MeMediaCollectionCreateDialogComponent,
        MeMediaCollectionEditDialogComponent,
    ],
    exports: [
        /****************************************
         * Media
         ****************************************/
        MediaCardComponent,
        MediaGridViewerComponent,
        MediaViewerComponent,
        MediaGalleryComponent,

        /****************************************
         * Image
         ****************************************/
        ImageCardComponent,
        ImageGridViewerComponent,
        ImageViewerComponent,
        ImageGalleryComponent,

        MeImageCreateDialogComponent,
        MeImageEditDialogComponent,
        MeImageSelectDialogComponent,

        /****************************************
         * Audio
         ****************************************/
        AudioPlayerComponent,
        AudioPlayerVisualizationComponent,
        AudioPlayerLyricsComponent,
        AudioCardComponent,
        AudioGridViewerComponent,
        AudioViewerComponent,
        AudioGalleryComponent,

        MeAudioCreateDialogComponent,
        MeAudioEditDialogComponent,
        MeAudioSelectDialogComponent,

        /****************************************
         * Video
         ****************************************/
        VideoPlayerComponent,
        VideoCardComponent,
        VideoGridViewerComponent,
        VideoViewerComponent,
        VideoGalleryComponent,

        MeVideoCreateDialogComponent,
        MeVideoEditDialogComponent,
        MeVideoSelectDialogComponent,

        /****************************************
         * Book
         ****************************************/
        BookCardComponent,
        BookGridViewerComponent,
        BookPlayerComponent,
        BookViewerComponent,
        BookGalleryComponent,

        MeBookCreateDialogComponent,
        MeBookEditDialogComponent,
        MeArticleEditDialogComponent,
        MeBookSelectDialogComponent,

        /****************************************
         * Comment
         ****************************************/
        CommentCardComponent,
        CommentReplyDialogComponent,

        /****************************************
         * MediaCollection
         ****************************************/
        MediaCollectionCardComponent,
        MediaCollectionGridViewerComponent,
        MediaCollectionViewerComponent,

        MeMediaCollectionCreateDialogComponent,
        MeMediaCollectionEditDialogComponent,
    ]
})
export class CsMediaModule {

    static forRoot(): ModuleWithProviders<CsMediaModule> {
        return {
            ngModule: CsMediaModule,
            providers: [
                {
                    provide: AudioPlayerService
                }
            ]
        };
    }

}
