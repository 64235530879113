<cs-grid-layout class="w-full" [aspectRatio]="ImageTypeAspectRatioTable[imageType]" [gutter]="8" [extraHeight]="72">
    @for (media of medias; track media.id) {
        <ng-template csGridLayoutItem [track]="media.id">
            <cs-media-card class="w-full h-full" [media]="media" (mediaClick)="onMediaClick($event)">
                @if (selectable) {
                    @if (selectedMediaIds.includes(media.id)) {
                        <cs-image-background class="w-full h-full" [image]="media | mediaToCoverImage" [resolution]="ImageResolution.Small" [blur]="true" [dark]="true">
                            <div class="w-full h-full flex flex-col justify-center items-center">
                                @if (selectableSingle) {
                                    <button mat-mini-fab><mat-icon fontIcon="check"></mat-icon></button>
                                } @else {
                                    <button mat-mini-fab>{{ selectedMediaIds.indexOf(media.id) + 1 }}</button>
                                }
                            </div>
                        </cs-image-background>
                    }
                }
            </cs-media-card>
        </ng-template>
    }
</cs-grid-layout>
