import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BookNames} from "../../../model/data/persist/jpa/entity/book";
import {MediaCategory} from "../../../model/data/persist/jpa/entity/media";
import {EnhancementMedia} from "../../../model/data/persist/jpa/entity/enhancement/enhancement-media";

@Pipe({
    name: 'bookNameTranslate'
})
export class BookNameTranslatePipe implements PipeTransform {

    constructor(
        private translateService: TranslateService
    ) {
    }

    transform(book: EnhancementMedia): string {
        if (book.category === MediaCategory.Book && BookNames.includes(book.name)) {
            return this.translateService.instant(`CONSTANT.Book.${book.name}`, {
                value: book.enhancementUser.nickname ? book.enhancementUser.nickname : book.enhancementUser.name
            });
        } else {
            return book.name;
        }
    }

}
