import {JpaAuditIdentifiable4Md5} from "../jpa-audit-identifiable-4-md5";

export interface AbstractMediaFile extends JpaAuditIdentifiable4Md5 {
    status: MediaFileStatus;
    remark: string;
    tags: string[];
}

export enum MediaFileStatus {
    Unhandled = 'Unhandled',
    Handling = 'Handling',
    HandledOnSuccess = 'HandledOnSuccess',
    HandledOnError = 'HandledOnError'
}
