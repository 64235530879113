import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SortablePagination} from "../../../model/commons/sortablePagination";
import {Page} from "../../../model/commons/page";
import {Service} from '../service';
import {EnhancementMediaCollection} from "../../../model/data/persist/jpa/entity/media-collection";
import {EnhancementMedia} from "../../../model/data/persist/jpa/entity/enhancement/enhancement-media";
import {Duration} from "../../../model/data/utils/time-range";
import {MediaCategory} from "../../../model/data/persist/jpa/entity/media";

@Injectable({
    providedIn: 'root'
})
export class PublicMediaCollectionService {

    constructor(
        private http: HttpClient
    ) {
    }

    page(pagination: SortablePagination): Observable<Page<EnhancementMediaCollection>> {
        return this.http.get<Page<EnhancementMediaCollection>>(`${Service.Media.prefixUrl}/public/media-collection${pagination.toRequestParameter()}`);
    }

    pageHottest(pagination: SortablePagination, duration?: Duration): Observable<Page<EnhancementMediaCollection>> {
        if (duration) {
            return this.http.get<Page<EnhancementMediaCollection>>(`${Service.Media.prefixUrl}/public/media-collection/hottest${pagination.toRequestParameter()}&duration=${duration}`);
        } else {
            return this.http.get<Page<EnhancementMediaCollection>>(`${Service.Media.prefixUrl}/public/media-collection/hottest${pagination.toRequestParameter()}`);
        }
    }

    pageHottestByCategory(category: MediaCategory, pagination: SortablePagination, duration?: Duration): Observable<Page<EnhancementMediaCollection>> {
        if (duration) {
            return this.http.get<Page<EnhancementMediaCollection>>(`${Service.Media.prefixUrl}/public/media-collection/category/${category}/hottest${pagination.toRequestParameter()}&duration=${duration}`);
        } else {
            return this.http.get<Page<EnhancementMediaCollection>>(`${Service.Media.prefixUrl}/public/media-collection/category/${category}/hottest${pagination.toRequestParameter()}`);
        }
    }

    load(id: number): Observable<EnhancementMediaCollection> {
        return this.http.get<EnhancementMediaCollection>(`${Service.Media.prefixUrl}/public/media-collection/${id}`);
    }

    pageBinding(id: number, pagination: SortablePagination): Observable<Page<EnhancementMedia>> {
        return this.http.get<Page<EnhancementMedia>>(`${Service.Media.prefixUrl}/public/media-collection/${id}/binding${pagination.toRequestParameter()}`);
    }

}
