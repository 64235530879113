export class Service {
    private readonly serviceName: string;
    private readonly servicePrefixUrl: string;
    private readonly serviceSwagger: boolean;

    static Core: Service = new Service('Core', '/service-core', true);
    static Event: Service = new Service('Event', '/service-event', true);
    static Media: Service = new Service('Media', '/service-media', true);
    static Monitor: Service = new Service('Monitor', '/service-monitor', false);
    static Schedule: Service = new Service('Schedule', '/service-schedule', true);
    static Search: Service = new Service('Search', '/service-search', true);

    static Values: Service[] = [Service.Core, Service.Event, Service.Media, Service.Monitor, Service.Schedule, Service.Search];

    constructor(serviceName: string, servicePrefixUrl: string, serviceSwagger: boolean) {
        this.serviceName = serviceName;
        this.servicePrefixUrl = servicePrefixUrl;
        this.serviceSwagger = serviceSwagger;
    }

    get name(): string {
        return this.serviceName;
    }

    get prefixUrl(): string {
        return this.servicePrefixUrl;
    }

    get swagger(): boolean {
        return this.serviceSwagger;
    }
}
