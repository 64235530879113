import {Component, OnDestroy, OnInit} from '@angular/core';
import {SortablePagination} from "../../../../core/model/commons/sortablePagination";
import {Page, PageContent} from "../../../../core/model/commons/page";
import {PermissionMode} from "../../../../core/service/data/permission-mode";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxPermissionsService} from "ngx-permissions";
import {HomePageComponentService} from "../../home-page.component.service";
import {PublicAudioService} from "../../../../core/service/data/public/public-audio.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {CommonModule} from "@angular/common";
import {CsCommonsModule} from "../../../../core/module/commons/cs.commons.module";
import {CsMediaModule} from "../../../../core/module/component/media/cs.media.module";
import {EnhancementAudio} from "../../../../core/model/data/persist/jpa/entity/enhancement/enhancement-audio";
import {OnAttach, OnDetach} from "../../../../core/module/commons/route/enhanced-router-outlet";
import {ImageType, ImageTypeAspectRatioTable} from "../../../../core/model/data/persist/jpa/entity/image";
import {EnhancementMediaCollection} from "../../../../core/model/data/persist/jpa/entity/media-collection";
import {MeService} from "../../../../core/service/data/me/me.service";
import {PublicMediaCollectionService} from "../../../../core/service/data/public/public-media-collection.service";
import {SimpleMe} from "../../../../core/model/data/persist/jpa/entity/user";
import {Duration} from "../../../../core/model/data/utils/time-range";
import {MediaCategory} from "../../../../core/model/data/persist/jpa/entity/media";
import {MatIconModule} from "@angular/material/icon";
import {MatToolbarModule} from "@angular/material/toolbar";

@Component({
    selector: 'cs-home-audio-discovery-page',
    standalone: true,
    templateUrl: './home-audio-discovery-page.component.html',
    styleUrls: ['./home-audio-discovery-page.component.scss'],
    imports: [
        CommonModule,
        MatIconModule,
        MatToolbarModule,
        CsCommonsModule,
        CsMediaModule
    ]
})
export class HomeAudioDiscoveryPageComponent implements OnInit, OnDestroy, OnAttach, OnDetach {
    protected readonly PermissionMode = PermissionMode;
    protected readonly ImageTypeAspectTable = ImageTypeAspectRatioTable;
    protected readonly ImageType = ImageType;

    protected hottestAudioInDayPagination: SortablePagination = new SortablePagination(0, 12);
    protected hottestAudioInDayPage: Page<EnhancementAudio> | undefined;
    protected hottestAudioInDayPageContent: PageContent<EnhancementAudio> = new PageContent<EnhancementAudio>((media: EnhancementAudio) => {
        return media.id;
    });

    protected hottestAudioInWeekPagination: SortablePagination = new SortablePagination(0, 6);
    protected hottestAudioInWeekPage: Page<EnhancementAudio> | undefined;
    protected hottestAudioInWeekPageContent: PageContent<EnhancementAudio> = new PageContent<EnhancementAudio>((media: EnhancementAudio) => {
        return media.id;
    });

    protected hottestMediaCollectionInWeekPagination: SortablePagination = new SortablePagination(0, 6);
    protected hottestMediaCollectionInWeekPage: Page<EnhancementMediaCollection> | undefined;
    protected hottestMediaCollectionInWeekPageContent: PageContent<EnhancementMediaCollection> = new PageContent<EnhancementMediaCollection>((media: EnhancementMediaCollection) => {
        return media.id;
    });

    protected recommendAudioPagination: SortablePagination = new SortablePagination(0, 6);
    protected recommendAudioPage: Page<EnhancementAudio> | undefined;
    protected recommendAudioPageContent: PageContent<EnhancementAudio> = new PageContent<EnhancementAudio>((media: EnhancementAudio) => {
        return media.id;
    });

    private isLogin: boolean = false;

    constructor(
        private homeComponentService: HomePageComponentService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private permissionsService: NgxPermissionsService,
        private translateService: TranslateService,
        private meService: MeService,
        private publicAudioService: PublicAudioService,
        private publicMediaCollectionService: PublicMediaCollectionService
    ) {
        this.homeComponentService.onScrollToBottom().pipe(takeUntilDestroyed()).subscribe(() => {
            this.loadNextRecommendationAudio();
        });

        this.meService.onMeChanged().pipe(takeUntilDestroyed()).subscribe((me: SimpleMe | undefined) => {
            const isLogin: boolean = !!me;
            if (this.isLogin !== isLogin) {
                this.isLogin = isLogin;
                // this.loadNextRecommendation(true);
            }
        });
    }

    ngOnInit(): void {
        this.loadNextHottestAudioInDay();
        this.loadNextHottestAudioInWeek()
        this.loadNextHottestMediaCollectionInWeek();
        // this.loadNextRecommendation();
    }

    ngOnAttach(): void {
        this.homeComponentService.hideAudioControlBar();
    }

    ngOnDetach(): void {
    }

    ngOnDestroy(): void {
    }

    private loadNextHottestAudioInDay(reset: boolean = false): void {
        if (reset) {
            this.hottestAudioInDayPagination.reset();
            this.hottestAudioInDayPage = undefined;
            this.hottestAudioInDayPageContent.reset();
        }

        if (!this.hottestAudioInDayPage || this.hottestAudioInDayPage && !this.hottestAudioInDayPage.last) {
            this.publicAudioService.pageHottest(this.hottestAudioInDayPagination, Duration.OneDay).subscribe((page: Page<EnhancementAudio>) => {
                this.hottestAudioInDayPagination.page++;

                this.hottestAudioInDayPage = page;
                this.hottestAudioInDayPageContent.addAll(this.hottestAudioInDayPage.content);
            });
        }
    }

    private loadNextHottestAudioInWeek(reset: boolean = false): void {
        if (reset) {
            this.hottestAudioInWeekPagination.reset();
            this.hottestAudioInWeekPage = undefined;
            this.hottestAudioInWeekPageContent.reset();
        }

        if (!this.hottestAudioInWeekPage || this.hottestAudioInWeekPage && !this.hottestAudioInWeekPage.last) {
            this.publicAudioService.pageHottest(this.hottestAudioInWeekPagination, Duration.OneWeek).subscribe((page: Page<EnhancementAudio>) => {
                this.hottestAudioInWeekPagination.page++;

                this.hottestAudioInWeekPage = page;
                this.hottestAudioInWeekPageContent.addAll(this.hottestAudioInWeekPage.content);
            });
        }
    }

    private loadNextHottestMediaCollectionInWeek(reset: boolean = false): void {
        if (reset) {
            this.hottestMediaCollectionInWeekPagination.reset();
            this.hottestMediaCollectionInWeekPage = undefined;
            this.hottestMediaCollectionInWeekPageContent.reset();
        }

        if (!this.hottestMediaCollectionInWeekPage || this.hottestMediaCollectionInWeekPage && !this.hottestMediaCollectionInWeekPage.last) {
            this.publicMediaCollectionService.pageHottestByCategory(MediaCategory.Audio, this.hottestMediaCollectionInWeekPagination, Duration.OneWeek).subscribe((page: Page<EnhancementMediaCollection>) => {
                this.hottestMediaCollectionInWeekPagination.page++;

                this.hottestMediaCollectionInWeekPage = page;
                this.hottestMediaCollectionInWeekPageContent.addAll(this.hottestMediaCollectionInWeekPage.content);
            });
        }
    }

    private loadNextRecommendationAudio(reset: boolean = false): void {
        if (reset) {
            this.recommendAudioPagination.reset();
            this.recommendAudioPage = undefined;
            this.recommendAudioPageContent.reset();
        }

        if (!this.recommendAudioPage || this.recommendAudioPage && !this.recommendAudioPage.last) {
            this.publicAudioService.pageRecommendation(this.recommendAudioPagination).subscribe((page: Page<EnhancementAudio>) => {
                this.recommendAudioPagination.page++;

                this.recommendAudioPage = page;
                this.recommendAudioPageContent.addAll(this.recommendAudioPage.content);
            });
        }
    }

    protected onAudioClick(audio: EnhancementAudio): void {
        this.router.navigate([`/audio/${audio.id}`]).then();
    }

    protected onMediaCollectionClick(mediaCollection: EnhancementMediaCollection): void {
        this.router.navigate([`/media-collection/${mediaCollection.id}`]).then();
    }

}

