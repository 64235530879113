import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {CsCommonsModule} from "../../commons/cs.commons.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LyricsEditorComponent} from "./lyrics-editor/lyrics-editor.component";
import {MarkdownEditorComponent} from "./markdown-editor/markdown-editor.component";
import {MarkdownModule} from "ngx-markdown";
import {MarkdownViewerComponent} from "./markdown-viewer/markdown-viewer.component";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MarkdownModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        CsCommonsModule,
    ],
    declarations: [
        LyricsEditorComponent,
        MarkdownEditorComponent,
        MarkdownViewerComponent
    ],
    exports: [
        LyricsEditorComponent,
        MarkdownEditorComponent,
        MarkdownViewerComponent
    ]
})
export class CsEditorModule {
}
