<mat-card class="w-full h-full" appearance="outlined">
    <mat-card-header class="w-full flex flex-row justify-start items-center">
        <mat-form-field class="w-full" appearance="outline">
            <mat-label>{{ 'COMMONS.KEYWORD' | translate }}</mat-label>
            <input matInput placeholder="{{ 'COMMONS.KEYWORD_HINT' | translate }}" [formControl]="searchFormControl" (keydown.enter)="paging(0)">
            <button matSuffix mat-icon-button (click)="paging(0)">
                <mat-icon fontIcon="search"></mat-icon>
            </button>
        </mat-form-field>
    </mat-card-header>
    @if (page) {
        <mat-card-content class="w-full flex-grow h-0">
            <table class="w-full" mat-table [dataSource]="page.content">
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef>{{ 'COMMONS.TYPE' | translate }}</th>
                    <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                </ng-container>
                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef>{{ 'COMMONS.USERNAME' | translate }}</th>
                    <td mat-cell *matCellDef="let element"> {{element.user}} </td>
                </ng-container>
                <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef>{{ 'COMMONS.LOG' | translate }}</th>
                    <td mat-cell *matCellDef="let element"> {{element.value}} </td>
                </ng-container>
                <ng-container matColumnDef="createTime">
                    <th mat-header-cell *matHeaderCellDef>{{ 'COMMONS.CREATE_TIME' | translate }}</th>
                    <td mat-cell *matCellDef="let element"> {{ element.createTime | date: 'yyyy-MM-dd HH:mm:ss' }} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="['type', 'user', 'value', 'createTime']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['type', 'user', 'value', 'createTime']"></tr>
            </table>
        </mat-card-content>
        <mat-card-footer class="w-full flex-grow-0">
            <mat-paginator [pageIndex]="page.number" [length]="page.totalElements" [pageSize]="pagination.itemsPerPage" [pageSizeOptions]="[5, 10, 25, 100]" (page)="paging($event.pageIndex)"></mat-paginator>
        </mat-card-footer>
    }
</mat-card>
