import {Component} from '@angular/core';
import {UserService} from '../../../core/service/data/god/user.service';
import {Router} from '@angular/router';
import {SystemValueService} from '../../../core/service/data/god/system-value.service';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {SystemValue4CustomConfiguration} from '../../../core/model/data/persist/jpa/entity/system-value';
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {MeService} from "../../../core/service/data/me/me.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {CommonModule} from "@angular/common";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {CsCopyrightModule} from "../../../core/module/component/copyright/cs.copyright.module";

@Component({
    selector: 'cs-login-page',
    standalone: true,
    templateUrl: './login-page.component.html',
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        CsCopyrightModule
    ],
    styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent {
    protected customConfiguration: SystemValue4CustomConfiguration | undefined;

    protected authForm: FormGroup = new FormGroup({
        username: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
    });

    constructor(
        private router: Router,
        // private themeService: NbThemeService,
        private translateService: TranslateService,
        private meService: MeService,
        private userService: UserService,
        private systemValueService: SystemValueService
    ) {
        this.systemValueService.onCustomConfigurationChanged().pipe(takeUntilDestroyed()).subscribe((customConfiguration: SystemValue4CustomConfiguration | undefined) => {
            this.customConfiguration = customConfiguration;
        });
    }

    protected onLogin(): void {
        this.userService.login(this.authForm.get('username')?.value, this.authForm.get('password')?.value).subscribe(() => {
            this.router.navigate(['/']).then();
        });
    }

    protected onOauth2Login(provider: string): void {
        window.location.href = `/service-core/oauth2/authorization/${provider}-idp`;
    }

}
