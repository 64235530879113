import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'count',
    pure: false
})
export class CountFormatterPipe implements PipeTransform {

    constructor(
        private translateService: TranslateService
    ) {
    }

    private format(num: number, pre: number): string {
        return (Math.floor(num * Math.pow(10, pre)) / Math.pow(10, pre)).toFixed(pre);
    }

    transform(count: number): string {
        if (count < 1000) {
            return `${count}`;
        } else if (count >= 1000 && count < 10000) {
            return this.translateService.instant('COMMONS.NUMBER_THOUSANDS', {
                value: this.format(count / 1000, 1)
            });
        } else if (count >= 10000 && count < 1000000) {
            return this.translateService.instant('COMMONS.NUMBER_TENS_THOUSANDS', {
                value: this.format(count / 10000, 1)
            });
        } else if (count >= 1000000 && count < 10000000) {
            return this.translateService.instant('COMMONS.NUMBER_MILLIONS', {
                value: this.format(count / 1000000, 1)
            });
        } else if (count >= 10000000 && count < 100000000) {
            return this.translateService.instant('COMMONS.NUMBER_TENS_MILLIONS', {
                value: this.format(count / 10000000, 1)
            });
        } else {
            return this.translateService.instant('COMMONS.NUMBER_HUNDREDS_MILLIONS', {
                value: this.format(count / 100000000, 1)
            });
        }
    }

}
