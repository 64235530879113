import {Component} from '@angular/core';
import {CsCommonsModule} from "../../../core/module/commons/cs.commons.module";

@Component({
    selector: 'cs-me-user-page',
    standalone: true,
    templateUrl: './me-user-page.component.html',
    styleUrls: ['./me-user-page.component.scss'],
    imports: [
        CsCommonsModule
    ],
})
export class MeUserPageComponent {

}
