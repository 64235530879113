import {Component} from '@angular/core';
import {EnhancementBook} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-book";
import {MediaCardComponent} from "../../media/media-card/media-card.component";

@Component({
    selector: 'cs-book-card',
    standalone: false,
    templateUrl: '../../media/media-card/media-card.component.html',
    styleUrls: ['../../media/media-card/media-card.component.scss']
})
export class BookCardComponent extends MediaCardComponent<EnhancementBook> {

}
