import {Component} from '@angular/core';
import {MediaGalleryComponent} from "../../media/media-gallery/media-gallery.component";
import {EnhancementAudio} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-audio";

@Component({
    selector: 'cs-audio-gallery',
    standalone: false,
    templateUrl: '../../media/media-gallery/media-gallery.component.html',
    styleUrls: ['../../media/media-gallery/media-gallery.component.scss']
})
export class AudioGalleryComponent extends MediaGalleryComponent<EnhancementAudio>  {

}
