import {Pipe, PipeTransform} from '@angular/core';
import {Image} from "../../../model/data/persist/jpa/entity/image";
import {EnhancementImage} from "../../../model/data/persist/jpa/entity/enhancement/enhancement-image";
import {EnhancementMedia} from "../../../model/data/persist/jpa/entity/enhancement/enhancement-media";
import {MediaCategory} from "../../../model/data/persist/jpa/entity/media";
import {EnhancementAudio} from "../../../model/data/persist/jpa/entity/enhancement/enhancement-audio";
import {EnhancementVideo} from "../../../model/data/persist/jpa/entity/enhancement/enhancement-video";
import {EnhancementBook} from "../../../model/data/persist/jpa/entity/enhancement/enhancement-book";

@Pipe({
    name: 'mediaColor'
})
export class MediaColorPipe implements PipeTransform {

    transform(media: EnhancementMedia): string {
        let image: Image | EnhancementImage | undefined;
        switch (media.category) {
            case MediaCategory.Image:
                image = image = (media as EnhancementImage);
                break;
            case MediaCategory.Audio:
                image = (media as EnhancementAudio).coverImage;
                break;
            case MediaCategory.Video:
                image = (media as EnhancementVideo).coverImage;
                break;
            case MediaCategory.Book:
                image = (media as EnhancementBook).coverImage;
                break;
            case MediaCategory.Article:
                break;
        }
        if (image) {
            return image.imageFile.palette[0];
        } else {
            return '#000000';
        }
    }

}
