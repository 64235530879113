import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SortablePagination} from "../../../model/commons/sortablePagination";
import {Page} from "../../../model/commons/page";
import {BookIndex} from "../../../model/data/persist/jpa/entity/book";
import {BookUpdateRequest} from "../../../model/request/book-update-request";
import {BookCreateRequest} from "../../../model/request/book-create-request";
import {Service} from '../service';
import {EnhancementBook} from "../../../model/data/persist/jpa/entity/enhancement/enhancement-book";
import {EnhancementArticle} from "../../../model/data/persist/jpa/entity/enhancement/enhancement-article";

@Injectable({
    providedIn: 'root'
})
export class MeBookService {

    constructor(
        private http: HttpClient
    ) {
    }

    page(pagination: SortablePagination): Observable<Page<EnhancementBook>> {
        return this.http.get<Page<EnhancementBook>>(`${Service.Media.prefixUrl}/me/media/book${pagination.toRequestParameter()}`);
    }

    pageByMediaCollection(mediaCollectionId: number, pagination: SortablePagination): Observable<Page<EnhancementBook>> {
        return this.http.get<Page<EnhancementBook>>(`${Service.Media.prefixUrl}/me/media/book/collection/${mediaCollectionId}${pagination.toRequestParameter()}`);
    }

    load(id: number): Observable<EnhancementBook> {
        return this.http.get<EnhancementBook>(`${Service.Media.prefixUrl}/me/media/book/${id}`);
    }

    create(request: BookCreateRequest): Observable<EnhancementBook> {
        return this.http.post<EnhancementBook>(`${Service.Media.prefixUrl}/me/media/book`, request);
    }

    update(id: number, request: BookUpdateRequest): Observable<EnhancementBook> {
        return this.http.put<EnhancementBook>(`${Service.Media.prefixUrl}/me/media/book/${id}`, request);
    }

    updateCoverImage(id: number, coverImageId: number): Observable<EnhancementBook> {
        return this.http.put<EnhancementBook>(`${Service.Media.prefixUrl}/me/media/book/${id}/cover/${coverImageId}`, '');
    }

    delete(id: number): Observable<EnhancementBook> {
        return this.http.delete<EnhancementBook>(`${Service.Media.prefixUrl}/me/media/book/${id}`);
    }

    search(keyword: string, pagination: SortablePagination): Observable<Page<EnhancementBook>> {
        if (keyword) {
            return this.http.get<Page<EnhancementBook>>(`${Service.Search.prefixUrl}/me/media/book${pagination.toRequestParameter()}&keyword=${keyword}`);
        } else {
            return this.http.get<Page<EnhancementBook>>(`${Service.Search.prefixUrl}/me/media/book${pagination.toRequestParameter()}`);
        }
    }

    listIndex(id: number, bookIndexId?: number): Observable<BookIndex[]> {
        if (bookIndexId) {
            return this.http.get<BookIndex[]>(`${Service.Media.prefixUrl}/me/media/book/${id}/index/${bookIndexId}/all`);
        }  else {
            return this.http.get<BookIndex[]>(`${Service.Media.prefixUrl}/me/media/book/${id}/index/all`);
        }
    }

    createIndex(id: number, bookIndexId?: number): Observable<BookIndex> {
        if (bookIndexId) {
            return this.http.post<BookIndex>(`${Service.Media.prefixUrl}/me/media/book/${id}/index/${bookIndexId}`, '');
        } else {
            return this.http.post<BookIndex>(`${Service.Media.prefixUrl}/me/media/book/${id}/index`, '');
        }
    }

    listArticle(id: number, bookIndexId: number): Observable<EnhancementArticle[]> {
        return this.http.get<EnhancementArticle[]>(`${Service.Media.prefixUrl}/me/media/book/${id}/index/${bookIndexId}/article/all`);
    }

    createArticle(id: number, bookIndexId: number): Observable<EnhancementArticle> {
        return this.http.post<EnhancementArticle>(`${Service.Media.prefixUrl}/me/media/book/${id}/index/${bookIndexId}/article`, '');
    }

}
