<mat-card class="w-full h-full" appearance="outlined">
    <mat-card-header class="w-full flex-grow-0">
        <mat-icon fontIcon="cloud_upload"></mat-icon>{{ 'Core.Component.Media.Video.Dialog.MeVideoSelectDialog.TITLE' | translate }}
    </mat-card-header>
    <mat-card-header class="w-full flex-grow-0">
        <div class="w-full h-full flex flex-row justify-between items-center">
            <mat-form-field appearance="outline">
                <mat-select [(ngModel)]="selectedMediaCollection" (selectionChange)="loadNext(true)">
                    @for (mediaCollection of mediaCollections; track mediaCollection.id) {
                        <mat-option [value]="mediaCollection">{{ mediaCollection | mediaCollectionNameTranslate }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <button mat-flat-button (click)="onAdd()" *ngIf="canAdd()"><mat-icon fontIcon="add"></mat-icon>{{ 'COMMONS.ADD' | translate }}</button>
        </div>
    </mat-card-header>
    <mat-card-content class="w-full flex-grow h-0">
        <cs-video-grid-viewer class="w-full" [permissionMode]="PermissionMode.Me" [selectable]="true" [selectableSingle]="single" [medias]="pageContent.values()" (selectedMediasChange)="onSelectedVideosChange($event)"></cs-video-grid-viewer>
    </mat-card-content>
    <mat-card-actions class="w-full flex flex-row justify-end items-center">
        <button class="me-1" mat-flat-button [disabled]="!selectedVideos.length" (click)="onSubmit()"><mat-icon fontIcon="check"></mat-icon>{{ 'COMMONS.CONFIRM' | translate }}</button>
        <button class="ms-1" mat-flat-button (click)="onClose()"><mat-icon fontIcon="close"></mat-icon>{{ 'COMMONS.CANCEL' | translate }}</button>
    </mat-card-actions>
</mat-card>
