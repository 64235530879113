import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {UsernameComponent} from "./username.component";
import {MatIconModule} from "@angular/material/icon";
import {CsCommonsModule} from "../../commons/cs.commons.module";

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        CsCommonsModule,
    ],
    declarations: [
        UsernameComponent
    ],
    exports: [
        UsernameComponent
    ]
})
export class CsUsernameModule {
}
