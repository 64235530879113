import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, catchError, Observable, Subject, throwError} from 'rxjs';
import {SimpleMe} from '../../../model/data/persist/jpa/entity/user';
import {MyPasswordUpdateRequest} from '../../../model/request/my-password-update-request';
import {UserProfile} from '../../../model/data/persist/jpa/entity/user-profile';
import {UserProfileUpdateRequest} from '../../../model/request/user-profile-update-request';
import {tap} from 'rxjs/operators';
import {NgxPermissionsService} from 'ngx-permissions';
import {Permission, PermissionName} from '../../../model/data/persist/jpa/entity/permission';
import {TranslateService} from '@ngx-translate/core';
import {SystemValueService} from '../god/system-value.service';
import {Image, ImageType} from "../../../model/data/persist/jpa/entity/image";
import {Role} from "../../../model/data/persist/jpa/entity/role";
import {Service} from "../service";
import {Client, IMessage} from "@stomp/stompjs";
import {ThemeService} from "../../../module/component/theme/theme.service";
import {EventCategory} from "../../../model/data/message-bus/event-category";
import {UserEventName} from "../../../model/data/message-bus/event-name";
import {Audio} from "../../../model/data/persist/jpa/entity/audio";
import {Video} from "../../../model/data/persist/jpa/entity/video";
import {environment} from "../../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class MeService {
    private meSubject: BehaviorSubject<SimpleMe | undefined> = new BehaviorSubject<SimpleMe | undefined>(undefined);
    private meProfileSubject: BehaviorSubject<UserProfile | undefined> = new BehaviorSubject<UserProfile | undefined>(undefined);

    private imageSubject: Subject<Image> = new Subject<Image>();
    private avatarImageSubject: Subject<Image> = new Subject<Image>();
    private mediaCoverImageSubject: Subject<Image> = new Subject<Image>();
    private mediaCollectionCoverImageSubject: Subject<Image> = new Subject<Image>();
    private audioSubject: Subject<Audio> = new Subject<Audio>();
    private videoSubject: Subject<Video> = new Subject<Video>();

    private readonly client: Client | undefined;

    constructor(
        private http: HttpClient,
        private permissionsService: NgxPermissionsService,
        private systemValueService: SystemValueService,
        private themeService: ThemeService,
        private translateService: TranslateService) {
        this.client = new Client({
            brokerURL: environment.webSocket.brokerURL,
            debug: (msg: string) => {
                console.log(msg);
            },
            onConnect: () => {
                console.log('onConnect');
                if (this.client) {
                    this.client.subscribe(`/user/topic/${EventCategory.User}_${UserEventName.Online}_@me`, (message: IMessage) => {
                        console.log(JSON.parse(message.body));
                    });
                    this.client.subscribe(`/user/topic/${EventCategory.User}_${UserEventName.ImageHandleOnSuccess}_@me`, (message: IMessage) => {
                        const image: Image = JSON.parse(message.body) as Image;
                        if (image.type === ImageType.Image) {
                            this.imageSubject.next(image);
                        } else if (image.type === ImageType.AvatarImage) {
                            this.avatarImageSubject.next(image);
                        } else if (image.type === ImageType.MediaCoverImage) {
                            this.mediaCoverImageSubject.next(image);
                        } else if (image.type === ImageType.MediaCollectionCoverImage) {
                            this.mediaCollectionCoverImageSubject.next(image);
                        }
                    });
                    this.client.subscribe(`/user/topic/${EventCategory.User}_${UserEventName.AudioHandleOnSuccess}_@me`, (message: IMessage) => {
                        const audio: Audio = JSON.parse(message.body) as Audio;
                        this.audioSubject.next(audio);
                    });
                    this.client.subscribe(`/user/topic/${EventCategory.User}_${UserEventName.VideoHandleOnSuccess}_@me`, (message: IMessage) => {
                        const video: Video = JSON.parse(message.body) as Video;
                        this.videoSubject.next(video);
                    });
                }
            },
            onWebSocketClose: (event) => {
                console.log('onWebSocketClose');
                console.log(event);
            },
            onWebSocketError: (error) => {
                console.log('onWebSocketError');
                console.log(error);
            }
        });
        this.client.activate();
    }

    onMeChanged(): Observable<SimpleMe | undefined> {
        return this.meSubject.asObservable();
    }

    onMeProfileChanged(): Observable<UserProfile | undefined> {
        return this.meProfileSubject.asObservable();
    }

    onImageChanged(): Observable<Image> {
        return this.imageSubject.asObservable();
    }

    onAvatarImageChanged(): Observable<Image> {
        return this.avatarImageSubject.asObservable();
    }

    onMediaCoverImageChanged(): Observable<Image> {
        return this.mediaCoverImageSubject.asObservable();
    }

    onMediaCollectionCoverImageChanged(): Observable<Image> {
        return this.mediaCollectionCoverImageSubject.asObservable();
    }

    onAudioChanged(): Observable<Audio> {
        return this.audioSubject.asObservable();
    }

    onVideoChanged(): Observable<Video> {
        return this.videoSubject.asObservable();
    }

    hasAnyPermissions(permissions: PermissionName[]): boolean {
        const user: SimpleMe | undefined = this.meSubject.getValue();
        if (user) {
            return user.roles.flatMap((role: Role) => {
                return role.permissions;
            }).map((permission: Permission) => {
                return permission.name as PermissionName;
            }).filter((permission: PermissionName) => {
                return permissions.includes(permission);
            }).length > 0;
        } else {
            return false;
        }
    }

    hasPermissions(permissions: PermissionName[]): boolean {
        const user: SimpleMe | undefined = this.meSubject.getValue();
        if (user) {
            return new Set(user.roles.flatMap((role: Role) => {
                return role.permissions;
            }).map((permission: Permission) => {
                return permission.name as PermissionName;
            }).filter((permission: PermissionName) => {
                return permissions.includes(permission);
            })).size === permissions.length;
        } else {
            return false;
        }
    }

    me(): Observable<SimpleMe> {
        return this.http.get<SimpleMe>(`${Service.Core.prefixUrl}/me`).pipe(tap((me: SimpleMe) => {
            this.permissionsService.flushPermissions();
            me.roles.flatMap(role => role.permissions).forEach((permission: Permission) => {
                this.permissionsService.addPermission(permission.name);
            });
            this.meSubject.next(me);
        }), catchError((response: HttpErrorResponse) => {
            if (response.status === 401) {
                this.meSubject.next(undefined);
            }
            return throwError(() => {
                return response;
            });
        }));
    }

    updatePassword(request: MyPasswordUpdateRequest): Observable<SimpleMe> {
        return this.http.put<SimpleMe>(`${Service.Core.prefixUrl}/me/password`, request);
    }

    profile(): Observable<UserProfile> {
        return this.http.get<UserProfile>(`${Service.Core.prefixUrl}/me/profile`).pipe(tap((meProfile: UserProfile) => {
            this.meProfileSubject.next(meProfile);
        }), catchError((response: HttpErrorResponse) => {
            if (response.status === 401) {
                this.meSubject.next(undefined);
            }
            return throwError(() => {
                return response;
            });
        }));;
    }

    updateProfile(request: UserProfileUpdateRequest): Observable<UserProfile> {
        return this.http.put<UserProfile>(`${Service.Core.prefixUrl}/me/profile`, request);
    }

    updateProfileAvatar(avatarId: number): Observable<UserProfile> {
        return this.http.put<UserProfile>(`${Service.Media.prefixUrl}/me/profile/avatar/${avatarId}`, '');
    }
}
