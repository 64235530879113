import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {catchError, mergeMap, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {MeService} from '../service/data/me/me.service';
import {UserProfile} from '../model/data/persist/jpa/entity/user-profile';
import {TranslateService} from '@ngx-translate/core';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';
import {ThemeService} from "../module/component/theme/theme.service";
import {LoginPageComponent} from "../../page/auth-page/login-page/login-page.component";
import {CustomizationResolver} from "./customization.resolver";
import {HomePageComponent} from "../../page/home-page/home-page.component";

@Injectable({
    providedIn: 'root'
})
export class MeResolver implements Resolve<any> {

    constructor(
        private customizationResolver: CustomizationResolver,
        private router: Router,
        private meService: MeService,
        private themeService: ThemeService,
        private translateService: TranslateService
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return this.customizationResolver.resolve(route, state).pipe(mergeMap(() => {
            return this.meService.me().pipe(tap(() => {
                console.info(`success to load me`);
            }), mergeMap(() => {
                return this.meService.profile();
            }), mergeMap((meProfile: UserProfile) => {
                return this.translateService.currentLoader.getTranslation(meProfile.language).pipe(mergeMap(() => {
                    console.info(`success to load user profile and set default theme: ${meProfile.theme}, default language: ${meProfile.language}`);
                    this.themeService.changeTheme(meProfile.theme);
                    return this.translateService.use(meProfile.language);
                }));
            }), mergeMap(() => {
                if (route.component === LoginPageComponent) {
                    return fromPromise(this.router.navigate(['/discovery']));
                } else {
                    return fromPromise(Promise.resolve());
                }
            }), catchError(() => {
                return fromPromise(Promise.resolve());
            }));
        }));
    }

}
