import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'imageResolutionTranslate'
})
export class ImageResolutionTranslatePipe implements PipeTransform {

    constructor(
        private translateService: TranslateService
    ) {
    }

    transform(resolution: string): string {
        return this.translateService.instant(`ENUMERATION.ImageResolution.${resolution}`);
    }

}
