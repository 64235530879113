import {Pipe, PipeTransform} from '@angular/core';
import {EnhancementImage} from "../../../../model/data/persist/jpa/entity/enhancement/enhancement-image";
import {EnhancementMedia} from "../../../../model/data/persist/jpa/entity/enhancement/enhancement-media";

@Pipe({
    name: 'mediaToImage'
})
export class MediaToImagePipe implements PipeTransform {

    transform(media: EnhancementMedia): EnhancementImage {
        return media as EnhancementImage;
    }

}
