@if (media) {
    <mat-drawer-container class="w-full h-full" [autosize]="true" [hasBackdrop]="true">
        <mat-drawer #collectionDrawer class="w-10/12 sm:w-10/12 md:w-2/3 lg:w-1/2 xl:w-1/2 2xl:w-1/2" mode="over" position="start">
            <cs-media-collection-grid-viewer class="w-full" [mediaCollections]="media.mediaCollections" (mediaCollectionClick)="onMediaCollectionClick($event)"></cs-media-collection-grid-viewer>
        </mat-drawer>
        <mat-drawer #commentDrawer class="w-10/12 sm:w-10/12 md:w-2/3 lg:w-1/2 xl:w-1/2 2xl:w-1/2 p-1" mode="over" position="end">
            <cs-comment-card class="w-full h-full" [media]="media"></cs-comment-card>
        </mat-drawer>
        <mat-drawer-content>
            <div class="w-full h-full relative">
                <div class="w-full h-full absolute overflow-y-auto">
                    @switch (media.category){
                        @case (MediaCategory.Image) {
                            <cs-image-background class="w-full h-full" [image]="media | mediaToCoverImage" [resolution]="ImageResolution.Gigantic" [size]="ImageBackgroundSize.Cover" [blur]="true" [dark]="true">
                                <cs-image class="w-full h-full bg-no-repeat bg-center bg-contain" [image]="media | mediaToCoverImage" [resolution]="ImageResolution.Gigantic" [palette]="false"></cs-image>
                            </cs-image-background>
                        }
                        @case (MediaCategory.Audio) {
                            <div class="w-full h-full flex flex-col justify-start items-stretch">
                                <mat-card class="w-full flex-grow h-0" appearance="outlined">
                                    <cs-image-background class="w-full h-full absolute" [size]="ImageBackgroundSize.Cover" [image]="media | mediaToCoverImage" [resolution]="ImageResolution.Tiny" [blur]="true" [dark]="true">
                                        <div class="w-full h-full">
                                            @if (audioPlayerService.isShowLyrics()) {
                                                <cs-audio-player-lyrics class="w-full h-full"></cs-audio-player-lyrics>
                                            } @else {
                                                <cs-audio-player-visualization class="w-full h-full"></cs-audio-player-visualization>
                                            }
                                        </div>
                                    </cs-image-background>
                                </mat-card>
                                <cs-audio-player class="w-full flex-grow-0"></cs-audio-player>
                            </div>
                        }
                        @case (MediaCategory.Video) {
                            <cs-video-player class="w-full h-full" [video]="media | mediaToVideo"></cs-video-player>
                        }
                        @case (MediaCategory.Book) {
                            <cs-book-player class="w-full h-full" [permissionMode]="permissionMode" [book]="media | mediaToBook"></cs-book-player>
                        }
                        @case (MediaCategory.Article) {
                        }
                    }
                </div>
                <div class="w-auto h-auto absolute inline-flex flex-col justify-start items-center" [ngClass]="MEDIA_CONTROL_BAR_BOTTOMS[media.category]" [style.width.px]="48" [style.right.px]="8">
                    <button class="flex-grow-0 mb-3" mat-fab (click)="onToggleLike()">
                        <mat-icon class="text-red-600" [ngClass]="media.likedByCurrentUser ? 'active' : ''" fontIcon="favorite"></mat-icon>
                    </button>
                    <button class="flex-grow-0 mb-3" mat-fab (click)="onUpdateCollection()">
                        <mat-icon class="text-orange-400" [ngClass]="media.collectedByCurrentUser ? 'active' : ''" fontIcon="kid_star"></mat-icon>
                    </button>
                    <button class="flex-grow-0 mb-3" mat-fab (click)="commentDrawer.toggle()">
                        <mat-icon class="text-blue-600" [ngClass]="media.commentedByCurrentUser ? 'active' : ''" fontIcon="comment"></mat-icon>
                    </button>
                    <button class="flex-grow-0 mb-3" mat-fab (click)="collectionDrawer.toggle()" *ngIf="media.mediaCollections.length">
                        <mat-icon fontIcon="folder"></mat-icon>
                    </button>
                </div>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
} @else {
    <h1>Not Found</h1>
}
