import {Component} from '@angular/core';
import {MediaGalleryComponent} from "../../media/media-gallery/media-gallery.component";
import {EnhancementBook} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-book";

@Component({
    selector: 'cs-book-gallery',
    standalone: false,
    templateUrl: '../../media/media-gallery/media-gallery.component.html',
    styleUrls: ['../../media/media-gallery/media-gallery.component.scss']
})
export class BookGalleryComponent extends MediaGalleryComponent<EnhancementBook>  {

}
