import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EnhancementComment} from "../../../model/data/persist/jpa/entity/comment";
import {CommentReply} from "../../../model/data/persist/jpa/entity/comment-reply";
import {SortablePagination} from "../../../model/commons/sortablePagination";
import {Page} from "../../../model/commons/page";
import {Service} from '../service';

@Injectable({
    providedIn: 'root'
})
export class PublicCommentService {

    constructor(
        private http: HttpClient
    ) {
    }

    load(id: number): Observable<EnhancementComment> {
        return this.http.get<EnhancementComment>(`${Service.Media.prefixUrl}/public/comment/${id}`);
    }

    pageCommentReply(id: number, pagination: SortablePagination): Observable<Page<CommentReply>> {
        return this.http.get<Page<CommentReply>>(`${Service.Media.prefixUrl}/public/comment/${id}/reply${pagination.toRequestParameter()}`);
    }

}
