<mat-card class="w-full h-full">
    <mat-card-header class="w-full flex-grow-0">
        <mat-icon fontIcon="article"></mat-icon>{{ 'Core.Component.Media.Book.Dialog.MeArticleEditDialog.TITLE' | translate }}
    </mat-card-header>
    <mat-card-content class="w-full flex-grow h-0">
        <form class="w-full h-full flex flex-col justify-start items-stretch" [formGroup]="articleForm">
            <div class="w-full flex-grow-0 flex flex-row justify-between items-center">
                <mat-form-field class="flex-grow-0" appearance="outline">
                    <input matInput placeholder="{{ 'COMMONS.NAME_HINT' | translate }}" formControlName="name">
                </mat-form-field>
                <mat-form-field class="flex-grow-0" appearance="outline">
                    <mat-select class="w-100" formControlName="visibleScope">
                        <mat-option [value]="VisibleScope.Private">{{ 'ENUMERATION.VisibleScope.Private' | translate }}</mat-option>
                        <mat-option [value]="VisibleScope.Public">{{ 'ENUMERATION.VisibleScope.Public' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <mat-form-field class="w-full flex-grow-0" appearance="outline">
                <input matInput placeholder="{{ 'COMMONS.DESCRIPTION_HINT' | translate }}" formControlName="description">
            </mat-form-field>
            <cs-markdown-editor class="w-full flex-grow h-0" [(value)]="articleFormHelper.draftContent">
                <button class="mx-1" mat-icon-button [disabled]="articleFormHelper.draftContent === articleFormHelper.content" (click)="onResume()">
                    <mat-icon fontIcon="history"></mat-icon>
                </button>
                <button class="mx-1" mat-button [disabled]="articleFormHelper.draftContent === articleFormHelper.content" (click)="onSubmitDraft()">
                    {{ 'Core.Component.Media.Book.Dialog.MeArticleEditDialog.SAVE_DRAFT' | translate }}
                </button>
            </cs-markdown-editor>
        </form>
    </mat-card-content>
    <mat-card-actions class="w-full flex flex-row justify-end items-center">
        <button class="me-1" mat-flat-button [disabled]="!articleForm.valid" (click)="onSubmit()"><mat-icon fontIcon="check"></mat-icon>{{ 'COMMONS.CONFIRM' | translate }}</button>
        <button class="ms-1" mat-flat-button (click)="onClose()"><mat-icon fontIcon="close"></mat-icon>{{ 'COMMONS.CANCEL' | translate }}</button>
    </mat-card-actions>
</mat-card>
