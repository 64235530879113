<mat-card class="w-full h-full">
    <mat-card-header class="w-full flex-grow-0">
        <mat-icon fontIcon="music_note"></mat-icon>{{ 'Core.Component.Media.Audio.Dialog.MeAudioEditDialog.TITLE' | translate }}
    </mat-card-header>
    <mat-card-content class="w-full flex-grow h-0 grid grid-rows-12 grid-cols-12 gap-1" *ngIf="audio">
        <div class="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-8 xl:col-span-8 2xl:col-span-9 row-span-6 sm:row-span-6 md:row-span-12 lg:row-span-12 xl:row-span-12 2xl:row-span-12">
            <div class="w-full h-full flex flex-col justify-start items-stretch">
                @if (editLyrics) {
                    <mat-card class="w-full flex-grow h-0" appearance="outlined">
                        <mat-card-actions class="w-full flex-grow-0 px-1 flex flex-row justify-between items-center">
                            <div class="flex-grow h-full w-0 inline-flex flex-row justify-start items-center">
                                <button class="flex-grow-0 me-1" mat-flat-button (click)="onViewLyrics()">
                                    <mat-icon fontIcon="arrow_back_2"></mat-icon>{{ 'COMMONS.BACK' | translate }}
                                </button>
                            </div>
                            <div class="flex-grow-0 h-full inline-flex flex-row justify-start items-center">
                                @if (editLyricsByFile) {
                                    <button class="flex-grow-0 me-1" mat-flat-button (click)="onEditLyricsByLine()">
                                        <mat-icon fontIcon="lyrics"></mat-icon>{{ 'COMMONS.LYRICS' | translate }}
                                    </button>
                                } @else {
                                    <button class="flex-grow-0 me-1" mat-flat-button (click)="onEditLyricsByFile()">
                                        <mat-icon fontIcon="edit_document"></mat-icon>{{ 'COMMONS.LYRICS' | translate }}
                                    </button>
                                }
                                <button class="flex-grow-0 ms-1" mat-flat-button (click)="onSubmitLyrics()">
                                    <mat-icon fontIcon="check"></mat-icon>{{ 'COMMONS.CONFIRM' | translate }}
                                </button>
                            </div>
                        </mat-card-actions>
                        @if (editLyricsByFile) {
                            <cs-lyrics-editor class="w-full h-full" [(value)]="audioLyricsForm.file"></cs-lyrics-editor>
                        } @else {
                            <mat-card-actions class="w-full flex-grow-0 px-0 py-1 flex flex-row justify-end items-center">
                                <mat-button-toggle-group class="flex-grow-0 ms-1" [(ngModel)]="rewindDuration">
                                    @for (duration of [100, 200, 500]; track duration) {
                                        <mat-button-toggle [value]="duration" (click)="rewindDuration = duration">{{ duration | millisecond: 1 }}</mat-button-toggle>
                                    }
                                </mat-button-toggle-group>
                            </mat-card-actions>
                            <mat-card-content class="w-full flex-grow h-0">
                                <div class="w-full" cdkListbox>
                                    @for (line of audioLyricsForm.lines; track $index) {
                                        <div class="w-full" [style.height.px]="50">
                                            <div class="w-full h-full my-1 flex flex-row justify-start items-center">
                                                <mat-form-field class="flex-grow h-full w-0" appearance="outline">
                                                    <input matInput [(ngModel)]="line.value" (click)="onEditLyricsLine(line)">
                                                </mat-form-field>
                                                <div class="flex-grow-0 h-full flex flex-row justify-end items-center">
                                                    <span class="flex-grow-0 px-1 font-mono text-sm">{{ line.time | lyricsTime: 3 }}</span>
                                                    <button class="flex-grow-0" mat-icon-button (click)="onBackwardLyricsLineDuration(line)">
                                                        <mat-icon fontIcon="text_select_move_back_word"></mat-icon>
                                                    </button>
                                                    <button class="flex-grow-0" mat-icon-button (click)="onForwardLyricsLineDuration(line)">
                                                        <mat-icon fontIcon="text_select_move_forward_word"></mat-icon>
                                                    </button>
                                                    <button class="flex-grow-0" mat-icon-button (click)="onBackwardLyricsLineTime(line)">
                                                        <mat-icon fontIcon="fast_rewind"></mat-icon>
                                                    </button>
                                                    <button class="flex-grow-0" mat-icon-button (click)="onForwardLyricsLineTime(line)">
                                                        <mat-icon fontIcon="fast_forward"></mat-icon>
                                                    </button>
                                                    <button class="flex-grow-0" mat-icon-button (click)="onAddLyricsLines($index + 1)">
                                                        <mat-icon fontIcon="add"></mat-icon>
                                                    </button>
                                                    <button class="flex-grow-0" mat-icon-button [disabled]="!audioLyricsForm.lines.length" (click)="onRemoveLyricsLines($index)">
                                                        <mat-icon fontIcon="remove"></mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </mat-card-content>
                        }
                    </mat-card>
                } @else {
                    <mat-card class="w-full flex-grow h-0" appearance="outlined">
                        <div class="w-full h-full relative">
                            <cs-image-background class="w-full h-full absolute" [size]="ImageBackgroundSize.Cover" [image]="audio.coverImage" [resolution]="ImageResolution.Tiny" [blur]="true" [dark]="true">
                                <div class="w-full h-full">
                                    @if (audioPlayerService.isShowLyrics()) {
                                        <cs-audio-player-lyrics class="w-full h-full"></cs-audio-player-lyrics>
                                    } @else {
                                        <cs-audio-player-visualization class="w-full h-full"></cs-audio-player-visualization>
                                    }
                                </div>
                            </cs-image-background>
                            <div class="w-full absolute flex flex-row justify-end items-start">
                                <button class="flex-grow-0 m-1" mat-flat-button (click)="onEditLyrics()">
                                    <mat-icon fontIcon="lyrics"></mat-icon>{{ 'COMMONS.LYRICS' | translate }}
                                </button>
                            </div>
                        </div>
                    </mat-card>
                }
                <cs-audio-player class="w-full flex-grow-0 mt-1"></cs-audio-player>
            </div>
        </div>
        <div class="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-4 2xl:col-span-3 row-span-6 sm:row-span-6 md:row-span-12 lg:row-span-12 xl:row-span-12 2xl:row-span-12">
            <cs-media-update-card class="w-full h-full" [media]="audio" (submit)="onSubmit($event)" (close)="onClose($event)">
                <mat-card class="w-full aspect-16/10" appearance="outlined">
                    <cs-image-background class="w-full h-full" [image]="audio.coverImage" [resolution]="ImageResolution.Large" [dark]="true">
                        <div class="w-full h-full flex flex-row justify-center items-center">
                            <button mat-flat-button (click)="onChangeCoverImage()">
                                <mat-icon fontIcon="replace_image"></mat-icon>{{ 'COMMONS.CHANGE' | translate }}
                            </button>
                        </div>
                    </cs-image-background>
                </mat-card>
                <div class="w-full mt-1 flex flex-row justify-start items-start flex-wrap">
                    @for (bitrate of audio.audioFile.bitrates; track bitrate) {
                        <span class="px-1 py-0.5 ms-1 mt-1 text-white rounded bg-blue-400">{{ bitrate }}</span>
                    }
                </div>
                <div class="w-full mt-1 flex flex-row justify-start items-start flex-wrap">
                    @for (tag of audio.audioFile.tags; track tag) {
                        <span class="px-1 py-0.5 ms-1 mt-1 text-white rounded bg-gray-500">{{ tag }}</span>
                    }
                </div>
            </cs-media-update-card>
        </div>
    </mat-card-content>
</mat-card>
