<mat-card class="w-full h-24 mb-4" appearance="outlined">
    <mat-card-header class="w-full flex-grow-0">
        <mat-icon fontIcon="function"></mat-icon><span class="ms-2 inline-flex flex-row justify-start items-center">API</span>
    </mat-card-header>
    <mat-card-content class="w-full flex-grow h-0 flex flex-row justify-start items-center overflow-x-auto">
        @for (service of Service.Values; track service.name) {
            @if (service === Service.Monitor) {
                <a class="me-2" mat-button [href]="service.prefixUrl + '/wallboard'" target="_blank">{{ service.name }}</a>
            } @else {
                <a class="me-2" mat-button [href]="service.prefixUrl + '/swagger-ui/index.html'" target="_blank">{{ service.name }}</a>
            }
        }
    </mat-card-content>
</mat-card>

<mat-card class="w-full mb-4" appearance="outlined">
    <mat-card-header class="w-full flex-grow-0">
        <mat-icon fontIcon="email"></mat-icon><span class="ms-2 inline-flex flex-row justify-start items-center">{{ 'Page.MePage.ConfigurationPage.MAIL_INTEGRATION' | translate }}</span>
    </mat-card-header>
    <mat-card-content class="w-full flex-grow-0">
        <form class="w-full" [formGroup]="systemValue4MailConfigurationForm">
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>{{ 'COMMONS.AVAILABILITY' | translate }}</mat-label>
                <mat-select formControlName="enable">
                    <mat-option [value]="true">{{ 'COMMONS.ENABLE' | translate }}</mat-option>
                    <mat-option [value]="false">{{ 'COMMONS.DISABLE' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>{{ 'COMMONS.ADDRESS' | translate }}</mat-label>
                <input matInput type="text" placeholder="{{ 'COMMONS.ADDRESS_HINT' | translate }}" formControlName="host">
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>{{ 'COMMONS.PORT' | translate }}</mat-label>
                <input matInput type="number" step="1" [min]="0" [max]="65535" placeholder="{{ 'COMMONS.PORT_HINT' | translate }}" formControlName="port">
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>{{ 'COMMONS.MAIL' | translate }}</mat-label>
                <input matInput type="mail" placeholder="{{ 'COMMONS.MAIL_HINT' | translate }}" formControlName="username">
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>{{ 'COMMONS.PASSWORD' | translate }}</mat-label>
                <input matInput type="password" placeholder="{{ 'COMMONS.PASSWORD_HINT' | translate }}" formControlName="password">
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>mail.smtp.auth</mat-label>
                <mat-select formControlName="smtpAuth">
                    <mat-option [value]="true">{{ 'COMMONS.ENABLE' | translate }}</mat-option>
                    <mat-option [value]="false">{{ 'COMMONS.DISABLE' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>mail.smtp.starttls.enable</mat-label>
                <mat-select formControlName="smtpStartTlsEnable">
                    <mat-option [value]="true">{{ 'COMMONS.ENABLE' | translate }}</mat-option>
                    <mat-option [value]="false">{{ 'COMMONS.DISABLE' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>mail.smtp.starttls.required</mat-label>
                <mat-select formControlName="smtpStartTlsRequired">
                    <mat-option [value]="true">{{ 'COMMONS.ENABLE' | translate }}</mat-option>
                    <mat-option [value]="false">{{ 'COMMONS.DISABLE' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </mat-card-content>
    <mat-card-actions class="w-full flex-grow-0 flex flex-row justify-end items-center">
        <button class="flex-grow-0 me-1" mat-flat-button [disabled]="!systemValue4MailConfigurationForm.valid" (click)="submitMailConfiguration()"><mat-icon fontIcon="bug_report"></mat-icon>{{ 'COMMONS.TEST' | translate }}</button>
        <button class="flex-grow-0 ms-1" mat-flat-button [disabled]="!systemValue4MailConfigurationForm.valid || !systemValue4MailConfigurationForm.dirty" (click)="submitMailConfiguration()"><mat-icon fontIcon="check"></mat-icon>{{ 'COMMONS.CONFIRM' | translate }}</button>
    </mat-card-actions>
</mat-card>

<mat-card class="w-full mb-4" appearance="outlined">
    <mat-card-header class="w-full flex-grow-0">
        <mat-icon fontIcon="palette"></mat-icon><span class="ms-2 inline-flex flex-row justify-start items-center">{{ 'Page.MePage.ConfigurationPage.CUSTOM' | translate }}</span>
    </mat-card-header>
    <mat-card-content class="w-full flex-grow-0">
        <form class="w-full" [formGroup]="systemValue4CustomConfigurationForm">
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>{{ 'COMMONS.NAME' | translate }}</mat-label>
                <input matInput type="mail" placeholder="{{ 'COMMONS.NAME_HINT' | translate }}" formControlName="name">
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>{{ 'COMMONS.DESCRIPTION' | translate }}</mat-label>
                <input matInput type="password" placeholder="{{ 'COMMONS.DESCRIPTION_HINT' | translate }}" formControlName="description">
            </mat-form-field>
        </form>
    </mat-card-content>
    <mat-card-actions class="w-full flex-grow-0 flex flex-row justify-end items-center">
        <button class="flex-grow-0" mat-flat-button [disabled]="!systemValue4CustomConfigurationForm.valid || !systemValue4CustomConfigurationForm.dirty" (click)="submitCustomConfiguration()"><mat-icon fontIcon="check"></mat-icon>{{ 'COMMONS.CONFIRM' | translate }}</button>
    </mat-card-actions>
</mat-card>
