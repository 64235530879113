import {Component, Inject, OnInit} from '@angular/core';
import {BookIndex, BookName} from "../../../../../../../model/data/persist/jpa/entity/book";
import {TranslateService} from "@ngx-translate/core";
import {VisibleScope} from "../../../../../../../model/data/persist/jpa/entity/visible-scope";
import {MeBookService} from "../../../../../../../service/data/me/me-book.service";
import {PermissionMode} from "../../../../../../../service/data/permission-mode";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PermissionService} from "../../../../../../../service/data/god/permission.service";
import {ToastService} from "../../../../../toast/toast.service";
import {DialogService} from "../../../../../dialog/dialog.service";
import {
    ImageBackgroundSize
} from "../../../../../../commons/component/image/image-background/image-background.component";
import {ImageResolution} from "../../../../../../../model/data/persist/jpa/entity/image-file";
import {
    MeImageSelectDialogComponent
} from "../../../media-image/dialog/me-image-select-dialog/me-image-select-dialog.component";
import {MediaCollectionName} from "../../../../../../../model/data/persist/jpa/entity/media-collection";
import {fadeInOnEnterAnimation, slideInLeftOnEnterAnimation} from "angular-animations";
import {MeArticleEditDialogComponent} from "../me-article-edit-dialog/me-article-edit-dialog.component";
import {EnhancementArticle} from "../../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-article";
import {EnhancementImage} from "../../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-image";
import {EnhancementBook} from "../../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-book";
import {EnhancementMedia} from "../../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-media";

@Component({
    selector: 'cs-me-book-edit-dialog',
    standalone: false,
    templateUrl: './me-book-edit-dialog.component.html',
    styleUrls: ['./me-book-edit-dialog.component.scss'],
    animations: [
        slideInLeftOnEnterAnimation({
            duration: 96
        }),
        fadeInOnEnterAnimation({
            duration: 512
        })
    ]
})
export class MeBookEditDialogComponent implements OnInit {
    protected readonly ImageBackgroundSize = ImageBackgroundSize;
    protected readonly ImageResolution = ImageResolution;
    protected readonly VisibleScope = VisibleScope;
    protected readonly PermissionMode = PermissionMode;

    protected book: EnhancementBook | undefined;
    protected bookIndexesLayers: BookIndex[][] = [];
    protected selectedBookIndexes: BookIndex[] = [];
    protected articles: EnhancementArticle[] = [];
    protected selectedArticle: EnhancementArticle | undefined;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any = {},
        private dialogRef: MatDialogRef<MeBookEditDialogComponent>,
        private translateService: TranslateService,
        private permissionService: PermissionService,
        private dialogService: DialogService,
        private toastService: ToastService,
        private meBookService: MeBookService) {
        this.book = this.data.book;
        // this.menuService.onItemClick().pipe(takeUntilDestroyed(), filter((bag: NbMenuBag) => {
        //     return bag.tag === this.MORE_MENU && bag.item.data['type'] === this.MORE_MENU_STATISTICS;
        // })).subscribe(() => {
        //     this.dialogService.open(BookStatisticsViewDialogComponent, {
        //         hasBackdrop: true,
        //         closeOnBackdropClick: false,
        //         closeOnEsc: true,
        //         hasScroll: false,
        //         autoFocus: true,
        //         data: {
        //             media-book: this.media-book
        //         }
        //     });
        // });
        // this.menuService.onItemClick().pipe(takeUntilDestroyed(), filter((bag: NbMenuBag) => {
        //     return bag.tag === this.MORE_MENU && bag.item.data['type'] === this.MORE_MENU_DELETE;
        // })).subscribe(() => {
        //     this.dialogService.open(ConfirmDialogComponent, {
        //         hasBackdrop: true,
        //         closeOnBackdropClick: false,
        //         closeOnEsc: true,
        //         hasScroll: false,
        //         autoFocus: true
        //     }).onClose.subscribe((result: boolean) => {
        //         if (result) {
        //             if (this.media-book) {
        //                 this.meBookService.delete(this.media-book.id).subscribe(() => {
        //                     this.close();
        //                     this.toastService.show(this.translateService.instant('COMMONS.SUCCESS_CONTENT'), this.translateService.instant('COMMONS.SUCCESS'), {
        //                         status: 'success',
        //                         duration: 2500
        //                     });
        //                 });
        //             }
        //         }
        //     });
        // });
    }

    ngOnInit(): void {
        if (this.book) {
            this.bookIndexesLayers.push([this.book.bookIndex]);
            this.selectedBookIndexes.push(this.book.bookIndex);
            this.listIndex(1);
        }
    }

    protected createIndex(i: number): void {
        if (this.book && i - 1 >= 0 && this.selectedBookIndexes[i - 1]) {
            this.meBookService.createIndex(this.book.id, this.selectedBookIndexes[i - 1].id).subscribe((bookIndex: BookIndex) => {
                this.listIndex(i);
            });
        }
    }

    protected listIndex(i: number): void {
        if (this.book && this.selectedBookIndexes[i - 1]) {
            if (!this.selectedBookIndexes[i - 1].leaf) {
                this.meBookService.listIndex(this.book.id, this.selectedBookIndexes[i - 1].id).subscribe((bookIndexes: BookIndex[]) => {
                    this.bookIndexesLayers.splice(this.selectedBookIndexes[i - 1].layer + 1, 1, bookIndexes);
                });
            }
        }
    }

    protected listArticle(bookIndex: BookIndex): void {
        if (this.book) {
            if (bookIndex.leaf) {
                this.meBookService.listArticle(this.book.id, bookIndex.id).subscribe((articles: EnhancementArticle[]) => {
                    this.articles = articles;
                });
            }
        }
    }

    protected onCreateArticle(bookIndex: BookIndex): void {
        if (this.book) {
            this.meBookService.createArticle(this.book.id, bookIndex.id).subscribe((article: EnhancementArticle) => {
                this.listArticle(bookIndex)
            });
            ;
        }
    }

    protected onEditArticle(): void {
        if (this.selectedArticle) {
            this.dialogService.open<MeArticleEditDialogComponent, EnhancementArticle>(MeArticleEditDialogComponent, {
                article: this.selectedArticle
            }).afterClosed().subscribe((article: EnhancementArticle | undefined) => {
                if (article) {
                    this.selectedArticle = article;
                    this.listArticle(this.selectedBookIndexes[this.selectedBookIndexes.length - 1]);
                }
            });
        }
    }

    protected onSelectIndex(bookIndex: BookIndex): void {
        if (this.selectedBookIndexes[bookIndex.layer] !== bookIndex) {
            this.selectedBookIndexes.splice(bookIndex.layer, 1, bookIndex);
            this.selectedArticle = undefined;
            if (bookIndex.leaf) {
                this.listArticle(bookIndex);
            } else {
                this.selectedBookIndexes = this.selectedBookIndexes.slice(0, bookIndex.layer + 1);
                this.bookIndexesLayers = this.bookIndexesLayers.slice(0, bookIndex.layer + 1);
                this.listIndex(bookIndex.layer + 1);
            }
        }
    }

    protected onSelectArticle(article: EnhancementArticle): void {
        this.selectedArticle = article;
    }

    protected onChangeCoverImage(): void {
        this.dialogService.open<MeImageSelectDialogComponent, EnhancementImage[]>(MeImageSelectDialogComponent, {
            single: true,
            scopes: [
                MediaCollectionName.DEFAULT_MEDIA_COVER_IMAGE_COLLECTION
            ]
        }).afterClosed().subscribe((images: EnhancementImage[] | undefined) => {
            if (images && images.length) {
                if (this.book) {
                    this.meBookService.updateCoverImage(this.book.id, images[0].id).subscribe((book: EnhancementBook) => {
                        this.book = book;
                    });
                }
            }
        });
    }

    protected onSubmit(media: EnhancementMedia) {
        this.meBookService.load(media.id).subscribe((book: EnhancementBook) => {
            this.book = book;

            this.dialogRef.close(book);
        });
    }

    protected onClose(media: EnhancementMedia | undefined): void {
        if (media) {
            this.meBookService.load(media.id).subscribe((book: EnhancementBook) => {
                this.book = book;

                this.dialogRef.close(book);
            });
        } else {
            this.dialogRef.close(this.book);
        }
    }

    protected readonly BookName = BookName;
}
