import {Component, Input, OnInit} from '@angular/core';
import {AudioPlayerService, PlayMode} from "./audio-player.service";
import {ImageResolution} from '../../../../../../model/data/persist/jpa/entity/image-file';
import {ImageBackgroundSize} from '../../../../../commons/component/image/image-background/image-background.component';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {EnhancementAudio} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-audio";

@Component({
    selector: 'cs-audio-player',
    standalone: false,
    templateUrl: './audio-player.component.html',
    styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent implements OnInit {
    protected readonly ImageResolution = ImageResolution;
    protected readonly ImageBackgroundSize = ImageBackgroundSize;
    protected readonly PlayMode = PlayMode;

    protected audio: EnhancementAudio | undefined;

    @Input()
    public single: boolean = true;

    constructor(
        protected audioPlayerService: AudioPlayerService
    ) {
        this.audioPlayerService.onPlayingAudioChanged().pipe(takeUntilDestroyed()).subscribe((audio: EnhancementAudio | undefined) => {
            this.audio = audio;
        });
    }

    ngOnInit(): void {
        if (this.single) {
            this.audioPlayerService.setPlayMode(PlayMode.SingleCycle);
        }
    }

}
