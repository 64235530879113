import {Component, Input} from '@angular/core';
import {ImageResolution} from '../../../model/data/persist/jpa/entity/image-file';
import {EnhancementUser, SimpleMe, SimpleUser} from "../../../model/data/persist/jpa/entity/user";

@Component({
    selector: 'cs-avatar',
    standalone: false,
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
    protected readonly ImageResolution = ImageResolution;

    @Input()
    public user: SimpleMe | SimpleUser | EnhancementUser | undefined;

}
