import {
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import {GridLayoutItemDirective} from "./grid-layout-item.directive";
import {BehaviorSubject} from "rxjs";
import {GridLayoutLeaveBlankZoneDirective} from "./grid-layout-leave-blank-zone.directive";

@Component({
    selector: 'cs-grid-layout',
    standalone: false,
    templateUrl: './grid-layout.component.html',
    styleUrls: ['./grid-layout.component.scss']
})
export class GridLayoutComponent implements OnInit, OnDestroy, OnChanges {
    protected readonly Math = Math;

    private resizeObserver: ResizeObserver = new ResizeObserver((mutations: ResizeObserverEntry[]) => {
        this.layout();
    });

    protected columns: BehaviorSubject<number> = new BehaviorSubject(1);
    protected height: BehaviorSubject<number> = new BehaviorSubject(0);

    @ContentChild(GridLayoutLeaveBlankZoneDirective)
    protected mainItem: GridLayoutLeaveBlankZoneDirective | undefined;

    @ContentChildren(GridLayoutItemDirective)
    protected items: GridLayoutItemDirective[] = [];

    @Input()
    public aspectRatio: number = 1;
    @Input()
    public gutter: number = 8;
    @Input()
    public extraHeight: number = 0;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
    ) {
    }

    ngOnInit(): void {
        this.resizeObserver.observe(this.elementRef.nativeElement);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.layout();
    }

    ngOnDestroy(): void {
        this.resizeObserver.disconnect();
    }

    private layout(): void {
        this.columns.next(Math.max(1, Math.floor(this.elementRef.nativeElement.clientWidth / 256)));
        const width: number = Math.round(this.elementRef.nativeElement.clientWidth / this.columns.value - this.gutter / 2);
        const height: number = Math.round(width / this.aspectRatio + this.extraHeight);
        this.height.next(height);
    }

}
