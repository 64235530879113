import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SortablePagination} from '../../../model/commons/sortablePagination';
import {Observable} from 'rxjs';
import {Log} from '../../../model/data/search/es/entity/log';
import {Page} from '../../../model/commons/page';
import {Service} from '../service';

@Injectable({
    providedIn: 'root'
})
export class LogService {

    constructor(private http: HttpClient) {
    }

    search(keyword: string, pagination: SortablePagination): Observable<Page<Log>> {
        if (keyword) {
            return this.http.get<Page<Log>>(`${Service.Search.prefixUrl}/log${pagination.toRequestParameter()}&keyword=${keyword}`);
        } else {
            return this.http.get<Page<Log>>(`${Service.Search.prefixUrl}/log${pagination.toRequestParameter()}`);
        }
    }

}
