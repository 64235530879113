import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'cs-lyrics-editor',
    standalone: false,
    templateUrl: './lyrics-editor.component.html',
    styleUrls: ['./lyrics-editor.component.scss']
})
export class LyricsEditorComponent {

    protected option: any = {
        automaticLayout: true,
        wordWrap: true,
        fontSize: 14,
        minimap: {
            enabled: false
        },
        folding: false,
        theme: 'vs-dark',
        // language: 'lyrics',
        readOnly: false
    };

    @Input()
    public value: string = '';
    @Output()
    public valueChange: EventEmitter<string> = new EventEmitter<string>();

}
