import {Pipe, PipeTransform} from '@angular/core';
import {EnhancementArticle} from "../../../../model/data/persist/jpa/entity/enhancement/enhancement-article";
import {EnhancementMedia} from "../../../../model/data/persist/jpa/entity/enhancement/enhancement-media";

@Pipe({
    name: 'mediaToArticle'
})
export class MediaToArticlePipe implements PipeTransform {

    transform(media: EnhancementMedia): EnhancementArticle {
        return media as EnhancementArticle;
    }

}
