import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChange,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import Plyr from "plyr";
import * as DashJs from "dashjs";
import {MediaPlayerClass} from "dashjs";
import {EnhancementVideo} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-video";

@Component({
    selector: 'cs-video-player',
    standalone: false,
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit, OnDestroy, OnChanges {
    private dash: MediaPlayerClass = DashJs.MediaPlayer().create();

    @ViewChild('target', {static: true})
    private target: ElementRef<HTMLVideoElement> | undefined;

    private player: Plyr | undefined;

    @Input()
    public video: EnhancementVideo | undefined;

    @Input()
    public autoPlay: boolean = false;
    @Input()
    public muted: boolean = false;

    ngOnInit(): void {
        if (this.target && this.video) {
            this.player = new Plyr(this.target.nativeElement, {
                autoplay: this.autoPlay,
                controls: [
                    'play-large', // The large play button in the center
                    'play', // Play/pause playback
                    'progress', // The progress bar and scrubber for playback and buffering
                    'current-time', // The current time of playback
                    'duration', // The full duration of the media
                    'mute', // Toggle mute
                    'volume', // Volume control
                    'captions', // Toggle captions
                    'settings', // Settings menu
                    'airplay', // Airplay (currently Safari only)
                    'fullscreen', // Toggle fullscreen
                ]
            });
            this.dash.on('streamInitialized', () => {
                if (this.player) {
                    if (this.player.duration < 300) {
                        this.player.loop = true;
                    } else {
                        this.player.loop = false;
                    }

                    this.player.muted = this.muted;
                    if (this.muted) {
                        this.player.volume = 0;
                    } else {
                        this.player.volume = 1;
                    }

                    if (this.video) {
                        this.player.setPreviewThumbnails({
                            enabled: true,
                            src: `/service-media/stream/video/${this.video.id}/preview.vtt`
                        });
                    }
                }
            });
        }
    }

    ngOnDestroy(): void {
        if (this.player) {
            this.dash.destroy();
            this.player.destroy();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const videoChange: SimpleChange = changes['video'];
        if (videoChange) {
            if (videoChange.isFirstChange() || (videoChange.previousValue as EnhancementVideo).id !== (videoChange.currentValue as EnhancementVideo).id) {
                if (this.video && this.target) {
                    this.dash.initialize(this.target.nativeElement, `/service-media/stream/video/${this.video.id}/manifest`, this.autoPlay);
                }
            }
        }
    }
}
