import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {LyricsLine} from "../../../../../../../model/data/persist/jpa/entity/audio";
import {TranslateService} from "@ngx-translate/core";
import {VisibleScope} from "../../../../../../../model/data/persist/jpa/entity/visible-scope";
import {MeAudioService} from "../../../../../../../service/data/me/me-audio.service";
import {PermissionMode} from "../../../../../../../service/data/permission-mode";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PermissionService} from "../../../../../../../service/data/god/permission.service";
import {ToastService} from "../../../../../toast/toast.service";
import {DialogService} from "../../../../../dialog/dialog.service";
import {
    ImageBackgroundSize
} from "../../../../../../commons/component/image/image-background/image-background.component";
import {ImageResolution} from "../../../../../../../model/data/persist/jpa/entity/image-file";
import {AudioPlayerService, PlaybackTime} from "../../audio-player/audio-player.service";
import {
    MeImageSelectDialogComponent
} from "../../../media-image/dialog/me-image-select-dialog/me-image-select-dialog.component";
import {MediaCollectionName} from "../../../../../../../model/data/persist/jpa/entity/media-collection";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {EnhancementAudio} from "../../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-audio";
import {EnhancementImage} from "../../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-image";
import {EnhancementMedia} from "../../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-media";

@Component({
    selector: 'cs-me-audio-edit-dialog',
    standalone: false,
    templateUrl: './me-audio-edit-dialog.component.html',
    styleUrls: ['./me-audio-edit-dialog.component.scss']
})
export class MeAudioEditDialogComponent implements OnInit, OnDestroy {
    protected readonly ImageBackgroundSize = ImageBackgroundSize;
    protected readonly ImageResolution = ImageResolution;
    protected readonly VisibleScope = VisibleScope;
    protected readonly PermissionMode = PermissionMode;

    protected rewindDuration: number = 200;
    protected editLyrics: boolean = false;
    protected editLyricsByFile: boolean = false;
    protected selectedLyricsLine: LyricsLine | undefined;

    protected audioLyricsForm: {
        file: string;
        lines: LyricsLine[];
    } = {
        file: '',
        lines: []
    };

    protected readonly option: any = {
        automaticLayout: true,
        wordWrap: true,
        fontSize: 14,
        minimap: {
            enabled: false
        },
        folding: false,
        component: 'vs-dark',
        language: 'markdown',
        readOnly: false
    };

    protected audio: EnhancementAudio | undefined;

    constructor(
        protected audioPlayerService: AudioPlayerService,
        @Inject(MAT_DIALOG_DATA) private data: any = {},
        private dialogRef: MatDialogRef<MeAudioEditDialogComponent>,
        private translateService: TranslateService,
        private permissionService: PermissionService,
        private dialogService: DialogService,
        private toastService: ToastService,
        private meAudioService: MeAudioService) {
        this.audio = this.data.audio;

        this.audioPlayerService.onPlaybackTimeChanged().pipe(takeUntilDestroyed()).subscribe((playbackTime: PlaybackTime) => {
            if (this.editLyrics) {
                if (this.editLyrics) {
                    if (this.selectedLyricsLine) {
                        if (playbackTime.time < this.selectedLyricsLine.time / 1000 || playbackTime.time >= (this.selectedLyricsLine.time + this.selectedLyricsLine.duration) / 1000) {
                            this.audioPlayerService.seek(this.selectedLyricsLine.time / 1000);
                        }
                    }
                }
            }
        });
        // this.menuService.onItemClick().pipe(takeUntilDestroyed(), filter((bag: NbMenuBag) => {
        //     return bag.tag === this.MORE_MENU && bag.item.data['type'] === this.MORE_MENU_STATISTICS;
        // })).subscribe(() => {
        //     this.dialogService.open(AudioStatisticsViewDialogComponent, {
        //         hasBackdrop: true,
        //         closeOnBackdropClick: false,
        //         closeOnEsc: true,
        //         hasScroll: false,
        //         autoFocus: true,
        //         data: {
        //             media-audio: this.media-audio
        //         }
        //     });
        // });
        // this.menuService.onItemClick().pipe(takeUntilDestroyed(), filter((bag: NbMenuBag) => {
        //     return bag.tag === this.MORE_MENU && bag.item.data['type'] === this.MORE_MENU_DELETE;
        // })).subscribe(() => {
        //     this.dialogService.open(ConfirmDialogComponent, {
        //         hasBackdrop: true,
        //         closeOnBackdropClick: false,
        //         closeOnEsc: true,
        //         hasScroll: false,
        //         autoFocus: true
        //     }).onClose.subscribe((result: boolean) => {
        //         if (result) {
        //             if (this.media-audio) {
        //                 this.meAudioService.delete(this.media-audio.id).subscribe(() => {
        //                     this.close();
        //                     this.toastService.show(this.translateService.instant('COMMONS.SUCCESS_CONTENT'), this.translateService.instant('COMMONS.SUCCESS'), {
        //                         status: 'success',
        //                         duration: 2500
        //                     });
        //                 });
        //             }
        //         }
        //     });
        // });
    }

    ngOnInit(): void {
        if (this.audio) {
            this.audioPlayerService.play(this.audio);

            if (this.audio.lyrics.lines.length) {
                this.audioLyricsForm.lines = [...this.audio.lyrics.lines];
            } else {
                this.audioLyricsForm.lines = [{
                    value: '',
                    time: 0,
                    duration: 1000
                }];
            }
        }
    }

    ngOnDestroy(): void {
        this.audioPlayerService.pause();
    }

    close(): void {
        this.dialogRef.close();
    }

    protected onViewLyrics(): void {
        this.editLyrics = false;
        this.editLyricsByFile = false;
        this.selectedLyricsLine = undefined;
        this.audioPlayerService.setPlaybackRate(1);
    }

    protected onEditLyrics(): void {
        this.editLyrics = true;
        this.audioPlayerService.setPlaybackRate(0.85);
    }

    protected onEditLyricsByLine(): void {
        this.editLyricsByFile = false;
    }

    protected onEditLyricsByFile(): void {
        this.editLyricsByFile = true;
    }

    protected onEditLyricsLine(line: LyricsLine): void {
        this.selectedLyricsLine = line;
    }

    protected onBackwardLyricsLines(): void {
        this.audioLyricsForm.lines.forEach((line: LyricsLine) => {
            line.time -= this.rewindDuration;
        });
    }

    protected onBackwardLyricsLineTime(line: LyricsLine): void {
        this.onEditLyricsLine(line);
        line.time -= this.rewindDuration;
        this.audioLyricsForm.lines = this.audioLyricsForm.lines.sort((left: LyricsLine, right: LyricsLine) => {
            return left.time - right.time;
        });
        this.audioPlayerService.seek(line.time / 1000);
    }

    protected onForwardLyricsLineTime(line: LyricsLine): void {
        this.onEditLyricsLine(line);
        line.time += this.rewindDuration;
        this.audioLyricsForm.lines = this.audioLyricsForm.lines.sort((left: LyricsLine, right: LyricsLine) => {
            return left.time - right.time;
        });
        this.audioPlayerService.seek(line.time / 1000);
    }

    protected onBackwardLyricsLineDuration(line: LyricsLine): void {
        this.onEditLyricsLine(line);
        line.duration -= this.rewindDuration;
        this.audioPlayerService.seek(line.time / 1000);
    }

    protected onForwardLyricsLineDuration(line: LyricsLine): void {
        this.onEditLyricsLine(line);
        line.duration += this.rewindDuration;
        this.audioPlayerService.seek(line.time / 1000);
    }

    protected onForwardLyricsLines(): void {
        this.audioLyricsForm.lines.forEach((line: LyricsLine) => {
            line.time += this.rewindDuration;
        });
    }

    protected onAddLyricsLines(index: number): void {
        this.audioLyricsForm.lines.splice(index, 0, {
            time: this.selectedLyricsLine ? this.selectedLyricsLine.time + this.selectedLyricsLine.duration : this.audioPlayerService.time() * 1000,
            duration: 3000,
            value: ''
        });
        this.selectedLyricsLine = this.audioLyricsForm.lines[0];
    }

    protected onRemoveLyricsLines(index: number): void {
        this.audioLyricsForm.lines = this.audioLyricsForm.lines.filter((line: LyricsLine, i: number) => {
            return i !== index;
        });
    }

    protected onSubmitLyrics(): void {
        if (this.audio) {
            if (this.editLyricsByFile) {
                this.meAudioService.convertLyricsByFile(this.audio.id, this.audioLyricsForm.file).subscribe((lines: LyricsLine[]) => {
                    this.audioLyricsForm.lines = lines;

                    this.onEditLyricsByLine();
                });
            } else {
                this.meAudioService.updateLyrics(this.audio.id, {
                    lines: this.audioLyricsForm.lines
                }).subscribe((audio: EnhancementAudio) => {
                    this.audio = audio;

                    this.audioPlayerService.play(this.audio, true);

                    this.onViewLyrics();
                });
            }
        }
    }

    protected onChangeCoverImage(): void {
        this.dialogService.open<MeImageSelectDialogComponent, EnhancementImage[]>(MeImageSelectDialogComponent, {
            single: true,
            scopes: [
                MediaCollectionName.DEFAULT_MEDIA_COVER_IMAGE_COLLECTION
            ]
        }).afterClosed().subscribe((images: EnhancementImage[] | undefined) => {
            if (images && images.length) {
                if (this.audio) {
                    this.meAudioService.updateCoverImage(this.audio.id, images[0].id).subscribe((audio: EnhancementAudio) => {
                        this.audio = audio;

                        this.audioPlayerService.play(this.audio, true);
                    });
                }
            }
        });
    }

    protected onSubmit(media: EnhancementMedia) {
        this.meAudioService.load(media.id).subscribe((audio: EnhancementAudio) => {
            this.audio = audio;

            this.dialogRef.close(audio);
        });
    }

    protected onClose(media: EnhancementMedia | undefined): void {
        if (media) {
            this.meAudioService.load(media.id).subscribe((audio: EnhancementAudio) => {
                this.audio = audio;

                this.dialogRef.close(audio);
            });
        } else {
            this.close();
        }
    }

}
