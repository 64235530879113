@if (audio) {
    <div class="w-full h-full flex flex-col justify-start items-stretch">
        <mat-toolbar class="w-full flex-grow-0 flex flex-row justify-between items-center">
            <div class="flex-grow-0 h-full flex flex-row justify-start items-center">
                <button mat-icon-button csBack defaultBack="/audio"><mat-icon fontIcon="arrow_back"></mat-icon></button>
            </div>
            <div class="flex-grow h-full w-0 px-8 flex flex-row justify-center items-center">
                <span class="text-base truncate"><strong>{{ audio.name }}</strong></span>
            </div>
            <div class="flex-grow-0 h-full flex flex-row justify-end items-center">
                <button mat-icon-button><mat-icon fontIcon="share"></mat-icon></button>
            </div>
        </mat-toolbar>
        <cs-media-viewer class="w-full flex-grow h-0" [permissionMode]="PermissionMode.Public" [media]="audio" [control]="true" (mediaCollectionClick)="onMediaCollectionClick($event)"></cs-media-viewer>
    </div>
}
