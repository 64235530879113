<mat-card class="w-full h-full">
    <mat-card-header class="w-full flex-grow-0">
        <mat-icon fontIcon="book"></mat-icon>{{ 'Core.Component.Media.Book.Dialog.MeBookEditDialog.TITLE' | translate }}
    </mat-card-header>
    <mat-card-content class="w-full flex-grow h-0 grid grid-rows-12 grid-cols-12 gap-1" *ngIf="book">
        <div class="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-8 xl:col-span-8 2xl:col-span-9 row-span-6 sm:row-span-6 md:row-span-12 lg:row-span-12 xl:row-span-12 2xl:row-span-12">
            <mat-card class="w-full h-full" appearance="outlined">
                <div class="w-full flex-grow flex flex-row justify-start items-stretch overflow-x-auto">
                    @for(bookIndexes of bookIndexesLayers; track $index) {
                        @if ($index > 0) {
                            <mat-card [@slideInLeftOnEnter] [@fadeInOnEnter] class="w-96 h-full flex-shrink-0 rounded-none border-0 border-r" appearance="outlined">
                                <div class="w-full flex-grow-0 ps-4 pe-2 py-2 flex flex-row justify-between items-center">
                                    <div class="flex-grow h-full flex flex-row justify-start items-center">
                                        @if (selectedBookIndexes[$index]) {
                                            @if (book.name === BookName.DEFAULT_BOOK) {
                                                @switch (selectedBookIndexes[$index].layer) {
                                                    @case (1) { {{ selectedBookIndexes[$index].createTime | date: 'yyyy' }} }
                                                    @case (2) { {{ selectedBookIndexes[$index].createTime | date: 'MM' }} }
                                                    @case (3) { {{ selectedBookIndexes[$index].createTime | date: 'dd' }} }
                                                }
                                            } @else {
                                                <span><strong>{{ selectedBookIndexes[$index].name }}</strong></span>
                                            }
                                        }
                                    </div>
                                    <div class="flex-grow-0 h-full flex flex-row justify-start items-center">
                                        <button mat-icon-button (click)="createIndex($index)"><mat-icon fontIcon="add"></mat-icon></button>
                                        <button mat-icon-button><mat-icon fontIcon="remove"></mat-icon></button>
                                    </div>
                                </div>
                                <mat-selection-list class="w-full flex-grow h-0 p-0 overflow-y-auto" [multiple]="false">
                                    @for(bookIndex of bookIndexes; track bookIndex.id) {
                                        <mat-list-option class="w-full" [value]="bookIndex.id" (click)="onSelectIndex(bookIndex)">
                                            @if (book.name === BookName.DEFAULT_BOOK) {
                                                @switch (bookIndex.layer) {
                                                    @case (1) { {{ bookIndex.createTime | date: 'yyyy' }} }
                                                    @case (2) { {{ bookIndex.createTime | date: 'MM' }} }
                                                    @case (3) { {{ bookIndex.createTime | date: 'dd' }} }
                                                }
                                            } @else {
                                                {{ bookIndex.name }}
                                            }
                                        </mat-list-option>
                                    }
                                </mat-selection-list>
                            </mat-card>
                        }
                    }
                    @if (selectedBookIndexes[selectedBookIndexes.length - 1] && selectedBookIndexes[selectedBookIndexes.length - 1].leaf) {
                        <mat-card [@slideInLeftOnEnter] class="w-96 h-full flex-shrink-0 rounded-none border-0 border-r" appearance="outlined">
                            <div class="w-full flex-grow-0 ps-4 pe-2 py-2 flex flex-row justify-between items-center">
                                <div class="flex-grow h-full flex flex-row justify-start items-center">
                                    <span><strong>{{ 'COMMONS.ARTICLE' | translate }}</strong></span>
                                </div>
                                <div class="flex-grow-0 h-full flex flex-row justify-start items-center">
                                    <button mat-icon-button (click)="onEditArticle()" *ngIf="selectedArticle"><mat-icon fontIcon="edit_note"></mat-icon></button>
                                    <button mat-icon-button (click)="onCreateArticle(selectedBookIndexes[selectedBookIndexes.length - 1])"><mat-icon fontIcon="add"></mat-icon></button>
                                    <button mat-icon-button><mat-icon fontIcon="remove"></mat-icon></button>
                                </div>
                            </div>
                            <mat-selection-list class="w-full flex-grow h-0 p-0 overflow-y-auto" [multiple]="false">
                                @for(article of articles; track article.id) {
                                    <mat-list-option class="w-full" [value]="article.id" (click)="onSelectArticle(article)" (dblclick)="onEditArticle()">{{ article.name }}</mat-list-option>
                                }
                            </mat-selection-list>
                        </mat-card>
                    }
                </div>
            </mat-card>
        </div>
        <div class="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-4 2xl:col-span-3 row-span-6 sm:row-span-6 md:row-span-12 lg:row-span-12 xl:row-span-12 2xl:row-span-12">
            <cs-media-update-card class="w-full h-full" [media]="book" (submit)="onSubmit($event)" (close)="onClose($event)">
                <mat-card class="w-full aspect-16/10" appearance="outlined">
                    <cs-image-background class="w-full h-full" [image]="book.coverImage" [resolution]="ImageResolution.Large" [dark]="true">
                        <div class="w-full h-full flex flex-row justify-center items-center">
                            <button mat-flat-button (click)="onChangeCoverImage()">
                                <mat-icon fontIcon="replace_image"></mat-icon>{{ 'COMMONS.CHANGE' | translate }}
                            </button>
                        </div>
                    </cs-image-background>
                </mat-card>
            </cs-media-update-card>
        </div>
    </mat-card-content>
</mat-card>
