@if (mediaCollection) {
    <div class="w-full h-full flex flex-col justify-start items-stretch">
        <mat-toolbar class="w-full flex-grow-0 flex flex-row justify-between items-center">
            <div class="w-24 h-full flex flex-row justify-start items-center">
                <button class="flex-grow-0" mat-icon-button [disabled]="!canPrevious()" (click)="previous()">
                    <mat-icon fontIcon="arrow_circle_left"></mat-icon>
                </button>
                <button class="flex-grow-0" mat-icon-button [disabled]="!canNext()" (click)="next()">
                    <mat-icon fontIcon="arrow_circle_right"></mat-icon>
                </button>
            </div>
            <div class="flex-grow h-full w-0 px-8 flex flex-row justify-center items-center">
                @if (selectedMedia) {
                    <span class="text-base truncate"><strong>{{ selectedMedia.name }}</strong></span>
                }
            </div>
            <div class="w-24 h-full flex flex-row justify-end items-center">
                <button class="flex-grow-0" mat-icon-button (click)="collectionDrawer.toggle()">
                    <mat-icon fontIcon="list"></mat-icon>
                </button>
            </div>
        </mat-toolbar>
        <mat-drawer-container class="w-full flex-grow h-0" [autosize]="true" [hasBackdrop]="true">
            <mat-drawer #collectionDrawer class="w-10/12 sm:w-10/12 md:w-2/3 lg:w-1/2 xl:w-1/2 2xl:w-1/2" mode="over" position="end" csScrollTracker (scrollToBottom)="loadNext()">
                <cs-media-grid-viewer class="w-full" [permissionMode]="permissionMode" [medias]="toImages(pageContent)" (mediaClick)="onMediaClick($event)"></cs-media-grid-viewer>
            </mat-drawer>
            <mat-drawer-content>
                <div class="w-full h-full">
                    @if (selectedMedia) {
                        <cs-media-viewer class="w-full h-full" [permissionMode]="permissionMode" [media]="selectedMedia" [control]="false" (mediaCollectionClick)="onMediaCollectionClick($event)"></cs-media-viewer>
                    }
                </div>
            </mat-drawer-content>
        </mat-drawer-container>
    </div>
} @else {
    <h1>Not Found</h1>
}
