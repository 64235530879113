<mat-card class="w-full h-full">
    <mat-card-header class="w-full flex-grow-0">
        <mat-icon fontIcon="cloud_upload"></mat-icon>{{ 'Core.Component.Media.Video.Dialog.MeVideoCreateDialog.TITLE' | translate }}
    </mat-card-header>
    <mat-card-content class="w-full flex-grow h-0">
        @if (fileUploaderUploadProgressEvents.length) {
            <cs-grid-layout class="w-full" [aspectRatio]="1" [extraHeight]="0" [gutter]="8">
                @for (fileUploaderUploadProgressEvent of fileUploaderUploadProgressEvents; track fileUploaderUploadProgressEvent.file.name) {
                    <ng-template csGridLayoutItem [track]="fileUploaderUploadProgressEvent.file.name">
                        <mat-card class="w-full h-full" appearance="outlined">
                            <mat-card-content class="w-full flex-grow h-0 flex flex-col justify-center items-center">
                                <mat-progress-spinner mode="determinate" [value]="fileUploaderUploadProgressEvent.progress * 100"></mat-progress-spinner>
                                <div class="w-full h-auto mt-3 flex flex-col justify-center items-center">
                                    <span class="text-sm text-center truncate text-wrap">{{ fileUploaderUploadProgressEvent.file.name }}</span>
                                </div>
                                <div class="w-full h-auto mt-3 flex flex-col justify-center items-center">
                                    <span class="text-sm font-bold">{{ fileUploaderUploadProgressEvent.file.size | filesize }}</span>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </ng-template>
                }
            </cs-grid-layout>
        } @else {
            <ngx-file-drop class="w-full h-full" dropZoneClassName="drop-zone" [multiple]="true" [directory]="false" [accept]="accepts" (onFileDrop)="onDropped($event)">
                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                    <mat-card class="w-full h-full cursor-pointer" appearance="outlined" (click)="openFileSelector()">
                        <mat-icon class="w-full h-full text-9xl" fontIcon="upload"></mat-icon>
                    </mat-card>
                </ng-template>
            </ngx-file-drop>
        }
    </mat-card-content>
    <mat-card-actions class="w-full flex flex-row justify-end items-center" [style.height.px]="40">
        <button class="w-full" mat-flat-button (click)="onClose()"><mat-icon fontIcon="close"></mat-icon>{{ 'COMMONS.CLOSE' | translate }}</button>
    </mat-card-actions>
</mat-card>
