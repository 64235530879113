import {Component, OnDestroy, OnInit} from '@angular/core';
import {HomePageComponentService} from "../home-page.component.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxPermissionsService} from "ngx-permissions";
import {TranslateService} from "@ngx-translate/core";
import {MeService} from "../../../core/service/data/me/me.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {SimpleMe} from "../../../core/model/data/persist/jpa/entity/user";
import {PermissionMode} from '../../../core/service/data/permission-mode';
import {SortablePagination} from "../../../core/model/commons/sortablePagination";
import {Page, PageContent} from "../../../core/model/commons/page";
import {PublicMediaService} from "../../../core/service/data/public/public-media.service";
import {CommonModule} from "@angular/common";
import {ImageType, ImageTypeAspectRatioTable} from "../../../core/model/data/persist/jpa/entity/image";
import {CsCommonsModule} from "../../../core/module/commons/cs.commons.module";
import {CsMediaModule} from "../../../core/module/component/media/cs.media.module";
import {Duration} from "../../../core/model/data/utils/time-range";
import {EnhancementMedia} from "../../../core/model/data/persist/jpa/entity/enhancement/enhancement-media";
import {MediaCategory} from "../../../core/model/data/persist/jpa/entity/media";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {OnAttach, OnDetach} from "../../../core/module/commons/route/enhanced-router-outlet";
import {EnhancementMediaCollection} from "../../../core/model/data/persist/jpa/entity/media-collection";
import {PublicMediaCollectionService} from "../../../core/service/data/public/public-media-collection.service";

@Component({
    selector: 'cs-discovery-page',
    standalone: true,
    templateUrl: './discovery-page.component.html',
    styleUrls: ['./discovery-page.component.scss'],
    imports: [
        CommonModule,
        MatIconModule,
        MatToolbarModule,
        CsCommonsModule,
        CsMediaModule
    ]
})
export class DiscoveryPageComponent implements OnInit, OnDestroy, OnAttach, OnDetach {
    protected readonly PermissionMode = PermissionMode;
    protected readonly ImageTypeAspectTable = ImageTypeAspectRatioTable;
    protected readonly ImageType = ImageType;

    protected hottestMediaInDayPagination: SortablePagination = new SortablePagination(0, 12);
    protected hottestMediaInDayPage: Page<EnhancementMedia> | undefined;
    protected hottestMediaInDayPageContent: PageContent<EnhancementMedia> = new PageContent<EnhancementMedia>((media: EnhancementMedia) => {
        return media.id;
    });

    protected hottestMediaInWeekPagination: SortablePagination = new SortablePagination(0, 6);
    protected hottestMediaInWeekPage: Page<EnhancementMedia> | undefined;
    protected hottestMediaInWeekPageContent: PageContent<EnhancementMedia> = new PageContent<EnhancementMedia>((media: EnhancementMedia) => {
        return media.id;
    });

    protected hottestMediaCollectionInWeekPagination: SortablePagination = new SortablePagination(0, 6);
    protected hottestMediaCollectionInWeekPage: Page<EnhancementMediaCollection> | undefined;
    protected hottestMediaCollectionInWeekPageContent: PageContent<EnhancementMediaCollection> = new PageContent<EnhancementMediaCollection>((media: EnhancementMediaCollection) => {
        return media.id;
    });

    protected recommendMediaPagination: SortablePagination = new SortablePagination(0, 6);
    protected recommendMediaPage: Page<EnhancementMedia> | undefined;
    protected recommendMediaPageContent: PageContent<EnhancementMedia> = new PageContent<EnhancementMedia>((media: EnhancementMedia) => {
        return media.id;
    });

    private isLogin: boolean = false;

    constructor(
        private homeComponentService: HomePageComponentService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private permissionsService: NgxPermissionsService,
        private translateService: TranslateService,
        private meService: MeService,
        private publicMediaService: PublicMediaService,
        private publicMediaCollectionService: PublicMediaCollectionService
    ) {
        this.homeComponentService.onScrollToBottom().pipe(takeUntilDestroyed()).subscribe(() => {
            this.loadNextRecommendationMedia();
        });

        this.meService.onMeChanged().pipe(takeUntilDestroyed()).subscribe((me: SimpleMe | undefined) => {
            const isLogin: boolean = !!me;
            if (this.isLogin !== isLogin) {
                this.isLogin = isLogin;
                // this.loadNextRecommendation(true);
            }
        });
    }

    ngOnInit(): void {
        this.loadNextHottestMediaInDay();
        this.loadNextHottestMediaInWeek()
        this.loadNextHottestMediaCollectionInWeek();
        // this.loadNextRecommendation();
    }

    ngOnAttach(): void {
        this.homeComponentService.hideAudioControlBar();
    }

    ngOnDetach(): void {
    }

    ngOnDestroy(): void {
    }

    private loadNextHottestMediaInDay(reset: boolean = false): void {
        if (reset) {
            this.hottestMediaInDayPagination.reset();
            this.hottestMediaInDayPage = undefined;
            this.hottestMediaInDayPageContent.reset();
        }

        if (!this.hottestMediaInDayPage || this.hottestMediaInDayPage && !this.hottestMediaInDayPage.last) {
            this.publicMediaService.pageHottest(this.hottestMediaInDayPagination, Duration.OneDay).subscribe((page: Page<EnhancementMedia>) => {
                this.hottestMediaInDayPagination.page++;

                this.hottestMediaInDayPage = page;
                this.hottestMediaInDayPageContent.addAll(this.hottestMediaInDayPage.content);
            });
        }
    }

    private loadNextHottestMediaInWeek(reset: boolean = false): void {
        if (reset) {
            this.hottestMediaInWeekPagination.reset();
            this.hottestMediaInWeekPage = undefined;
            this.hottestMediaInWeekPageContent.reset();
        }

        if (!this.hottestMediaInWeekPage || this.hottestMediaInWeekPage && !this.hottestMediaInWeekPage.last) {
            this.publicMediaService.pageHottest(this.hottestMediaInWeekPagination, Duration.OneWeek).subscribe((page: Page<EnhancementMedia>) => {
                this.hottestMediaInWeekPagination.page++;

                this.hottestMediaInWeekPage = page;
                this.hottestMediaInWeekPageContent.addAll(this.hottestMediaInWeekPage.content);
            });
        }
    }

    private loadNextHottestMediaCollectionInWeek(reset: boolean = false): void {
        if (reset) {
            this.hottestMediaCollectionInWeekPagination.reset();
            this.hottestMediaCollectionInWeekPage = undefined;
            this.hottestMediaCollectionInWeekPageContent.reset();
        }

        if (!this.hottestMediaCollectionInWeekPage || this.hottestMediaCollectionInWeekPage && !this.hottestMediaCollectionInWeekPage.last) {
            this.publicMediaCollectionService.pageHottest(this.hottestMediaCollectionInWeekPagination, Duration.OneWeek).subscribe((page: Page<EnhancementMediaCollection>) => {
                this.hottestMediaCollectionInWeekPagination.page++;

                this.hottestMediaCollectionInWeekPage = page;
                this.hottestMediaCollectionInWeekPageContent.addAll(this.hottestMediaCollectionInWeekPage.content);
            });
        }
    }

    private loadNextRecommendationMedia(reset: boolean = false): void {
        if (reset) {
            this.recommendMediaPagination.reset();
            this.recommendMediaPage = undefined;
            this.recommendMediaPageContent.reset();
        }

        if (!this.recommendMediaPage || this.recommendMediaPage && !this.recommendMediaPage.last) {
            this.publicMediaService.pageRecommendation(this.recommendMediaPagination).subscribe((page: Page<EnhancementMedia>) => {
                this.recommendMediaPagination.page++;

                this.recommendMediaPage = page;
                this.recommendMediaPageContent.addAll(this.recommendMediaPage.content);
            });
        }
    }

    protected onMediaClick(media: EnhancementMedia): void {
        switch (media.category) {
            case MediaCategory.Image:
                this.router.navigate([`/image/${media.id}`]).then();
                break;
            case MediaCategory.Audio:
                this.router.navigate([`/audio/${media.id}`]).then();
                break;
            case MediaCategory.Video:
                this.router.navigate([`/video/${media.id}`]).then();
                break;
            case MediaCategory.Book:
                this.router.navigate([`/book/${media.id}`]).then();
                break;
            case MediaCategory.Article:
                break;
        }
    }

    protected onMediaCollectionClick(mediaCollection: EnhancementMediaCollection): void {
        this.router.navigate([`/media-collection/${mediaCollection.id}`]).then();
    }

}
