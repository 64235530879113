import {Component, inject, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {VisibleScope} from "../../../../../../../model/data/persist/jpa/entity/visible-scope";
import {MeBookService} from '../../../../../../../service/data/me/me-book.service';
import {BookType} from "../../../../../../../model/data/persist/jpa/entity/book";
import {ToastService} from "../../../../../toast/toast.service";
import {TranslateService} from "@ngx-translate/core";
import {EnhancementBook} from "../../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-book";

@Component({
    selector: 'cs-me-book-create-dialog',
    standalone: false,
    templateUrl: './me-book-create-dialog.component.html',
    styleUrls: ['./me-book-create-dialog.component.scss']
})
export class MeBookCreateDialogComponent {
    protected readonly VisibleScope = VisibleScope;
    protected readonly BookType = BookType;

    protected bookForm: FormGroup = inject(FormBuilder).group({
        name: ['', Validators.required],
        description: ['', Validators.required],
        visibleScope: [VisibleScope.Private, Validators.required],
        type: [BookType.Level_1, Validators.required]
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any = {},
        private dialogRef: MatDialogRef<MeBookCreateDialogComponent>,
        private toastService: ToastService,
        private translateService: TranslateService,
        private meBookService: MeBookService
    ) {
    }

    protected onSubmit(): void {
        this.meBookService.create(this.bookForm.value).subscribe((book: EnhancementBook) => {
            this.toastService.show('COMMONS.SUCCESS');
            this.dialogRef.close(book);
        });
    }

    protected onClose(): void {
        this.dialogRef.close();
    }

}
