import {EnhancementUser, SimpleUser} from "./user";
import {VisibleScope} from "./visible-scope";
import {JpaAuditIdentifiable4Long} from "../jpa-audit-identifiable-4-long";
import {Image, ImageType} from "./image";
import {MediaCategory} from "./media";

export enum MediaCollectionName {
    DEFAULT_IMAGE_COLLECTION = 'DEFAULT_IMAGE_COLLECTION',
    DEFAULT_AVATAR_IMAGE_COLLECTION = 'DEFAULT_AVATAR_IMAGE_COLLECTION',
    DEFAULT_MEDIA_COVER_IMAGE_COLLECTION = 'DEFAULT_MEDIA_COVER_IMAGE_COLLECTION',
    DEFAULT_MEDIA_COLLECTION_COVER_IMAGE_COLLECTION = 'DEFAULT_MEDIA_COLLECTION_COVER_IMAGE_COLLECTION',

    DEFAULT_AUDIO_COLLECTION = 'DEFAULT_AUDIO_COLLECTION',

    DEFAULT_VIDEO_COLLECTION = 'DEFAULT_VIDEO_COLLECTION',

    DEFAULT_BOOK_COLLECTION = 'DEFAULT_BOOK_COLLECTION',

    DEFAULT_ARTICLE_COLLECTION = 'DEFAULT_ARTICLE_COLLECTION'
}

export const MediaCollectionNames: string[] = Object.values(MediaCollectionName);

export const MediaCollectionImageTypeTable: Record<MediaCollectionName, ImageType> = {
    DEFAULT_IMAGE_COLLECTION: ImageType.Image,
    DEFAULT_AVATAR_IMAGE_COLLECTION : ImageType.AvatarImage,
    DEFAULT_MEDIA_COVER_IMAGE_COLLECTION : ImageType.MediaCoverImage,
    DEFAULT_MEDIA_COLLECTION_COVER_IMAGE_COLLECTION : ImageType.MediaCollectionCoverImage,

    DEFAULT_AUDIO_COLLECTION : ImageType.MediaCoverImage,

    DEFAULT_VIDEO_COLLECTION : ImageType.MediaCoverImage,

    DEFAULT_BOOK_COLLECTION: ImageType.MediaCoverImage,

    DEFAULT_ARTICLE_COLLECTION: ImageType.MediaCoverImage
};

export interface MediaCollection extends JpaAuditIdentifiable4Long {
    simpleUser: SimpleUser;
    category: MediaCategory;
    name: string;
    description: string;
    visibleScope: VisibleScope;
    systemReserved: boolean;
    defaultCollection: boolean;
    deletable: boolean;
    updatable: boolean;
}

export interface EnhancementMediaCollection {
    id: number;

    createTime: Date;
    updateTime: Date;

    name: string;
    description: string;

    coverImage: Image | undefined;

    visibleScope: VisibleScope;

    systemReserved: boolean;
    defaultCollection: boolean;
    deletable: boolean;
    updatable: boolean;

    enhancementUser: EnhancementUser;

    category: MediaCategory;

    likedCount: number;

    likedByCurrentUser: boolean;

    count: number;
}
