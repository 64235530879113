import {Injectable} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class ToastService {

    constructor(
        private snackBar: MatSnackBar,
        private translateService: TranslateService
    ) {
    }

    show(message: string, interpolateParams?: Object): void {
        this.snackBar.open(this.translateService.instant(message, interpolateParams), '', {
            duration: 2500
        });
    }

}
