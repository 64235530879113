import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SortablePagination} from "../../../model/commons/sortablePagination";
import {Page} from "../../../model/commons/page";
import {AudioUpdateRequest} from "../../../model/request/audio-update-request";
import {AudioLyricsUpdateRequest} from "../../../model/request/audio-lyrics-update-request";
import {Service} from '../service';
import {EnhancementAudio} from "../../../model/data/persist/jpa/entity/enhancement/enhancement-audio";
import {LyricsLine} from "../../../model/data/persist/jpa/entity/audio";

@Injectable({
    providedIn: 'root'
})
export class MeAudioService {

    constructor(
        private http: HttpClient
    ) {
    }

    upload(file: File): Observable<HttpEvent<EnhancementAudio>> {
        const formData: FormData = new FormData();
        formData.append("file", file);
        return this.http.post<EnhancementAudio>(`${Service.Media.prefixUrl}/me/media/audio/upload`, formData, {
            reportProgress: true,
            observe: "events"
        });
    }

    page(pagination: SortablePagination): Observable<Page<EnhancementAudio>> {
        return this.http.get<Page<EnhancementAudio>>(`${Service.Media.prefixUrl}/me/media/audio${pagination.toRequestParameter()}`);
    }

    pageByMediaCollection(mediaCollectionId: number, pagination: SortablePagination): Observable<Page<EnhancementAudio>> {
        return this.http.get<Page<EnhancementAudio>>(`${Service.Media.prefixUrl}/me/media/audio/collection/${mediaCollectionId}${pagination.toRequestParameter()}`);
    }

    load(id: number): Observable<EnhancementAudio> {
        return this.http.get<EnhancementAudio>(`${Service.Media.prefixUrl}/me/media/audio/${id}`);
    }

    update(id: number, request: AudioUpdateRequest): Observable<EnhancementAudio> {
        return this.http.put<EnhancementAudio>(`${Service.Media.prefixUrl}/me/media/audio/${id}`, request);
    }

    updateCoverImage(id: number, coverImageId: number): Observable<EnhancementAudio> {
        return this.http.put<EnhancementAudio>(`${Service.Media.prefixUrl}/me/media/audio/${id}/cover/${coverImageId}`, '');
    }

    convertLyricsByFile(id: number, value: string): Observable<LyricsLine[]> {
        return this.http.post<LyricsLine[]>(`${Service.Media.prefixUrl}/me/media/audio/${id}/lyrics/file`, value);
    }

    updateLyrics(id: number, request: AudioLyricsUpdateRequest): Observable<EnhancementAudio> {
        return this.http.put<EnhancementAudio>(`${Service.Media.prefixUrl}/me/media/audio/${id}/lyrics`, request);
    }

    delete(id: number): Observable<EnhancementAudio> {
        return this.http.delete<EnhancementAudio>(`${Service.Media.prefixUrl}/me/media/audio/${id}`);
    }

    search(keyword: string, pagination: SortablePagination): Observable<Page<EnhancementAudio>> {
        if (keyword) {
            return this.http.get<Page<EnhancementAudio>>(`${Service.Search.prefixUrl}/me/media/audio${pagination.toRequestParameter()}&keyword=${keyword}`);
        } else {
            return this.http.get<Page<EnhancementAudio>>(`${Service.Search.prefixUrl}/me/media/audio${pagination.toRequestParameter()}`);
        }
    }

}
