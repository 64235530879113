import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {VisibleScope} from "../../../../../../model/data/persist/jpa/entity/visible-scope";
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ToastService} from "../../../../toast/toast.service";
import {MeMediaService} from "../../../../../../service/data/me/me-media.service";
import {TranslateService} from "@ngx-translate/core";
import {PermissionService} from "../../../../../../service/data/god/permission.service";
import {DialogService} from "../../../../dialog/dialog.service";
import {Tag} from "../../../../../../model/data/persist/jpa/entity/tag";
import {MatChipEvent, MatChipInputEvent} from "@angular/material/chips";
import {MediaBindTag} from "../../../../../../model/data/persist/jpa/entity/media-bind-tag";
import {BookNameTranslatePipe} from "../../../../../commons/pipe/book-name-translate.pipe";
import {MeMediaCollectionService} from "../../../../../../service/data/me/me-media-collection.service";
import {EnhancementMediaCollection} from "../../../../../../model/data/persist/jpa/entity/media-collection";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {MediaBindMediaCollection} from "../../../../../../model/data/persist/jpa/entity/media-bind-media-collection";
import {EnhancementBook} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-book";
import {EnhancementMedia} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-media";
import {MediaCategory} from "../../../../../../model/data/persist/jpa/entity/media";

@Component({
    selector: 'cs-media-update-card',
    standalone: false,
    templateUrl: './media-update-card.component.html',
    styleUrl: './media-update-card.component.scss'
})
export class MediaUpdateCardComponent implements OnInit {
    protected readonly VisibleScope = VisibleScope;

    protected mediaForm: FormGroup = inject(FormBuilder).group({
        name: ['', Validators.required],
        description: ['', Validators.required],
        visibleScope: [VisibleScope.Private, Validators.required]
    });

    protected mediaCollectionsForm: FormGroup = inject(FormBuilder).group({});

    protected mediaTagsForm: FormGroup = inject(FormBuilder).group({
        tags: [[]]
    });

    protected mediaCollections: EnhancementMediaCollection[] = [];

    @Input()
    public media: EnhancementMedia | undefined;

    @Output()
    public submit: EventEmitter<EnhancementMedia> = new EventEmitter<EnhancementMedia>();
    @Output()
    public close: EventEmitter<EnhancementMedia | undefined> = new EventEmitter<EnhancementMedia | undefined>();

    constructor(
        private bookNameTranslatePipe: BookNameTranslatePipe,
        private translateService: TranslateService,
        private permissionService: PermissionService,
        private dialogService: DialogService,
        private toastService: ToastService,
        private meMediaService: MeMediaService,
        private meMediaCollectionService: MeMediaCollectionService,
    ) {
    }

    ngOnInit(): void {
        if (this.media) {
            const nameFiled: AbstractControl<string, string> | null = this.mediaForm.get('name');
            if (nameFiled) {
                if (this.media.category === MediaCategory.Book) {
                    nameFiled.patchValue(this.bookNameTranslatePipe.transform(this.media as EnhancementBook));
                } else {
                    nameFiled.patchValue(this.media.name);
                }
            }

            const descriptionFiled: AbstractControl<string, string> | null = this.mediaForm.get('description');
            if (descriptionFiled) {
                descriptionFiled.patchValue(this.media.description);
            }

            const visibleScopeFiled: AbstractControl<VisibleScope, VisibleScope> | null = this.mediaForm.get('visibleScope');
            if (visibleScopeFiled) {
                visibleScopeFiled.patchValue(this.media.visibleScope);
            }

            const tagsFiled: AbstractControl<string[], string[]> | null = this.mediaTagsForm.get('tags');
            if (tagsFiled) {
                tagsFiled.patchValue(this.media.tags.map((tag: Tag) => {
                    return tag.name;
                }));
            }

            if (!this.media.updatable) {
                this.mediaForm.disable();
                this.mediaTagsForm.disable();
            }

            this.meMediaCollectionService.listByCategory(this.media.category).subscribe((mediaCollections: EnhancementMediaCollection[]) => {
                this.mediaCollections = mediaCollections.filter((mediaCollection: EnhancementMediaCollection) => {
                    return !mediaCollection.systemReserved;
                });
                this.mediaCollections.forEach((mediaCollection: EnhancementMediaCollection) => {
                    if (this.media) {
                        this.mediaCollectionsForm.addControl(`${mediaCollection.id}`, new FormControl(this.media.mediaCollections.filter((existMediaCollection: EnhancementMediaCollection) => {
                            return existMediaCollection.id === mediaCollection.id;
                        }).length > 0, [Validators.required]));
                    }
                });
            });
        }
    }

    protected addTag(event: MatChipInputEvent): void {
        if (this.media && event.value) {
            const tagsFiled: AbstractControl<string[], string[]> | null = this.mediaTagsForm.get('tags');
            if (tagsFiled) {
                if (!tagsFiled.value.includes(event.value)) {
                    this.meMediaService.bindTag(this.media.id, event.value).subscribe((bind: MediaBindTag) => {
                        tagsFiled.patchValue([...tagsFiled.value, event.value]);
                        this.toastService.show('COMMONS.SUCCESS');
                    });
                }
            }
        }
    }

    protected onMediaCollectionChange(mediaCollection: EnhancementMediaCollection, change: MatCheckboxChange): void {
        if (this.media) {
            if (change.checked) {
                this.meMediaService.bindMediaCollection(this.media.id, mediaCollection.id).subscribe((bind: MediaBindMediaCollection) => {
                    this.toastService.show('COMMONS.SUCCESS');
                });
            } else {
                this.meMediaService.unbindMediaCollection(this.media.id, mediaCollection.id).subscribe((bind: MediaBindMediaCollection) => {
                    this.toastService.show('COMMONS.SUCCESS');
                });
            }
        }
    }

    protected removeTag(event: MatChipEvent): void {
        if (this.media) {
            const tagsFiled: AbstractControl<string[], string[]> | null = this.mediaTagsForm.get('tags');
            if (tagsFiled) {
                if (tagsFiled.value.includes(event.chip.value)) {
                    this.meMediaService.unbindTag(this.media.id, event.chip.value).subscribe((bind: MediaBindTag) => {
                        tagsFiled.patchValue(tagsFiled.value.filter((tag: string) => {
                            return tag !== event.chip.value;
                        }));
                        this.toastService.show('COMMONS.SUCCESS');
                    });
                }
            }
        }
    }

    protected onClose(): void {
        if (this.mediaTagsForm.dirty) {
            if (this.media) {
                this.meMediaService.load(this.media.id).subscribe((media: EnhancementMedia) => {
                    this.close.next(media);
                });
            }
        } else {
            this.close.next(undefined);
        }
    }

    protected onSubmit(): void {
        if (this.media) {
            this.meMediaService.update(this.media.id, this.mediaForm.value).subscribe((media: EnhancementMedia) => {
                this.toastService.show('COMMONS.SUCCESS');
                this.submit.next(media);
            });
        }
    }

}
