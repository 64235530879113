<div class="w-full h-full flex flex-col justify-start items-stretch">
    <mat-toolbar class="w-full flex-grow-0 sticky top-0 flex flex-row justify-between items-center" [style.z-index]="2">
        <div class="flex-grow-0 h-full flex flex-row justify-start items-center">
            <button mat-icon-button (click)="sidenav.toggle()"><mat-icon fontIcon="menu"></mat-icon></button>
            <span class="ms-2 text-lg font-mono select-none">Courser Studio</span>
        </div>
        <div class="flex-grow h-full w-0 flex flex-row justify-around items-center">
    <!--        <input [placeholder]="systemPageComponentService.getSearchPlaceHolder() | async">-->
    <!--        <mat-form-field appearance="outline" subscriptSizing="dynamic">-->
    <!--            <input matInput type="text" formControlName="username" placeholder="{{ 'COMMONS.USERNAME_HINT' | translate }}">-->
    <!--            <mat-icon matSuffix>search</mat-icon>-->
    <!--        </mat-form-field>-->
        </div>
        <div class="flex-grow-0 h-full flex flex-row justify-end items-center">
            <cs-avatar [user]="me" [matMenuTriggerFor]="menu"></cs-avatar>
            <mat-menu #menu>
                @for (menuItem of userMenuItems; track menuItem.title) {
                    @if (!menuItem.hidden) {
                        <button mat-menu-item [routerLink]="menuItem.link">
                            <mat-icon matListItemIcon [fontIcon]="menuItem.icon"></mat-icon>
                            <span matListItemTitle>{{ menuItem.title }}</span>
                        </button>
                    }
                }
            </mat-menu>
        </div>
    </mat-toolbar>
    <mat-sidenav-container class="w-full flex-grow h-0" [autosize]="true">
        <mat-sidenav class="w-64" #sidenav [mode]="sidenavMode" [fixedInViewport]="true" [opened]="sidenavOpen">
            <mat-toolbar class="w-full"></mat-toolbar>
            <mat-nav-list>
                @for (menuItem of menuItems; track menuItem.title) {
                    @if (!menuItem.hidden) {
                        <mat-list-item [routerLink]="menuItem.link" routerLinkActive="mdc-list-item--activated">
                            <mat-icon matListItemIcon [fontIcon]="menuItem.icon"></mat-icon>
                            <span matListItemTitle>{{ menuItem.title }}</span>
                        </mat-list-item>
                    }
                }
            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content class="p-2" csScrollTracker (scrollToBottom)="systemPageComponentService.notifyScrollToBottom()">
            <enhanced-router-outlet></enhanced-router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
