import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy, UrlSegment} from "@angular/router";

export class AppRoutesReuseStrategy implements RouteReuseStrategy {
    private static readonly CACHE: Map<string, DetachedRouteHandle> = new Map<string, DetachedRouteHandle>();

    clearCachedRoute(key: string): void {
        const handle: DetachedRouteHandle | undefined = AppRoutesReuseStrategy.CACHE.get(key);
        if (handle) {
            (handle as any).componentRef.destroy();
        }
        AppRoutesReuseStrategy.CACHE.delete(key);
    }

    clearCacheOnNewUrl(url: string): void {
        AppRoutesReuseStrategy.CACHE.forEach((val, key) => {
            if (url.indexOf(key) === -1) {
                this.clearCachedRoute(key);
            }
        });
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig && JSON.stringify(future.params) === JSON.stringify(curr.params);
    }

    // 获取存储路由
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        const url: string = this.getFullRouteURL(route);
        if (route.data['reuse'] && AppRoutesReuseStrategy.CACHE.has(url)) {
            const handle: DetachedRouteHandle | undefined = AppRoutesReuseStrategy.CACHE.get(url);
            return handle ? handle : null;
        } else {
            return null;
        }
    }

    // 是否允许复用路由
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return Boolean(route.data['reuse']);
    }

    // 当路由离开时会触发，存储路由
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        const url: string = this.getFullRouteURL(route);
        AppRoutesReuseStrategy.CACHE.set(url, handle);
    }

    //  是否允许还原路由
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        const url: string = this.getFullRouteURL(route);
        return Boolean(route.data['reuse']) && AppRoutesReuseStrategy.CACHE.has(url);
    }

    // 获取当前路由url
    private getFullRouteURL(route: ActivatedRouteSnapshot): string {
        let fullRouteUrlPath: string[] = [];
        route.pathFromRoot.forEach((item: ActivatedRouteSnapshot) => {
            fullRouteUrlPath = fullRouteUrlPath.concat(this.getRouteUrlPath(item));
        });
        return `/${fullRouteUrlPath.join("/")}`;
    }

    private getRouteUrlPath(route: ActivatedRouteSnapshot): string[] {
        return route.url.map((urlSegment: UrlSegment) => urlSegment.path);
    }
}

export const APP_ROUTES_REUSE_STRATEGY = {
    provide: RouteReuseStrategy,
    useClass: AppRoutesReuseStrategy
}
