import {Component, Input} from '@angular/core';
import {Image} from "../../../../../model/data/persist/jpa/entity/image";
import {ImageResolution} from "../../../../../model/data/persist/jpa/entity/image-file";
import {EnhancementImage} from "../../../../../model/data/persist/jpa/entity/enhancement/enhancement-image";

export enum ImageBackgroundSize {
    Cover = 'Cover',
    Contain = 'Contain'
}

@Component({
    selector: 'cs-image-background',
    standalone: false,
    templateUrl: './image-background.component.html',
    styleUrls: ['./image-background.component.scss']
})
export class ImageBackgroundComponent {
    protected readonly ImageBackgroundSize = ImageBackgroundSize;

    @Input()
    public image: Image | EnhancementImage | undefined;
    @Input()
    public resolution: ImageResolution = ImageResolution.Nano;
    @Input()
    public blur: boolean = false;
    @Input()
    public dark: boolean = false;
    @Input()
    public size: ImageBackgroundSize = ImageBackgroundSize.Cover;

}
