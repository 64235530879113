import {Component, Inject} from '@angular/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ImageResolution} from "../../../../../../../model/data/persist/jpa/entity/image-file";
import {
    ImageBackgroundSize
} from "../../../../../../commons/component/image/image-background/image-background.component";
import {ImageType, ImageTypeAspectRatioTable} from "../../../../../../../model/data/persist/jpa/entity/image";

@Component({
    selector: 'cs-me-image-cropper-dialog',
    standalone: false,
    templateUrl: './me-image-cropper-dialog.component.html',
    styleUrls: ['./me-image-cropper-dialog.component.scss']
})
export class MeImageCropperDialogComponent {
    protected readonly ImageResolution = ImageResolution;
    protected readonly ImageBackgroundSize = ImageBackgroundSize;
    protected readonly ImageTypeAspectTable = ImageTypeAspectRatioTable;
    protected readonly ImageType = ImageType;

    protected croppedImage: File | undefined;
    protected croppedImageUrl: string | null | undefined;

    protected type: ImageType = ImageType.Image;
    protected image: File | undefined;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any = {},
        private dialogRef: MatDialogRef<MeImageCropperDialogComponent>,
    ) {
        this.type = this.data.type ?? ImageType.Image;
        this.image = data.image;
    }

    protected imageCropped(event: ImageCroppedEvent): void {
        if (this.image) {
            if (event.blob !== null && event.blob !== undefined) {
                this.croppedImage = new File([event.blob as Blob], this.image.name);
                this.croppedImageUrl = event.objectUrl;
            }
        }
    }

    protected onClose(): void {
        this.dialogRef.close();
    }

    protected onSubmit(): void {
        this.dialogRef.close(this.croppedImage);
    }

}
