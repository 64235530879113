import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SortablePagination} from '../../../model/commons/sortablePagination';
import {Observable} from 'rxjs';
import {Role} from '../../../model/data/persist/jpa/entity/role';
import {Page} from '../../../model/commons/page';
import {RoleCreateRequest} from '../../../model/request/role-create-request';
import {RoleUpdateRequest} from '../../../model/request/role-update-request';
import {Service} from "../service";

@Injectable({
    providedIn: 'root'
})
export class RoleService {

    constructor(private http: HttpClient) {
    }

    list(): Observable<Role[]> {
        return this.http.get<Role[]>(`${Service.Core.prefixUrl}/role/all`);
    }

    page(pagination: SortablePagination): Observable<Page<Role>> {
        return this.http.get<Page<Role>>(`${Service.Core.prefixUrl}/role${pagination.toRequestParameter()}`);
    }

    search(keyword: string, pagination: SortablePagination): Observable<Page<Role>> {
        return this.http.get<Page<Role>>(`${Service.Search.prefixUrl}/role${pagination.toRequestParameter()}&keyword=${keyword}`);
    }

    load(id: number): Observable<Role> {
        return this.http.get<Role>(`${Service.Core.prefixUrl}/role/${id}`);
    }

    create(request: RoleCreateRequest): Observable<Role> {
        return this.http.post<Role>(`${Service.Core.prefixUrl}/role`, request);
    }

    update(id: number, role: RoleUpdateRequest): Observable<Role> {
        return this.http.put<Role>(`${Service.Core.prefixUrl}/role/${id}`, role);
    }

    delete(id: number): Observable<Role> {
        return this.http.delete<Role>(`${Service.Core.prefixUrl}/role/${id}`);
    }

}
