import {Component} from '@angular/core';

@Component({
    selector: 'cs-default-page',
    standalone: true,
    templateUrl: './default-page.component.html',
    styleUrls: ['./default-page.component.scss']
})
export class DefaultPageComponent {

}
