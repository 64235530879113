import {Component} from '@angular/core';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {LoadingBarHttpClientModule} from "@ngx-loading-bar/http-client";
import {LoadingBarRouterModule} from "@ngx-loading-bar/router";
import {LoadingBarModule} from "@ngx-loading-bar/core";
import {CsCommonsModule} from "./core/module/commons/cs.commons.module";

@Component({
    selector: 'cs-root',
    standalone: true,
    imports: [
        CsCommonsModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        LoadingBarModule
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent {

    constructor(
        private sanitizer: DomSanitizer,
        private iconRegistry: MatIconRegistry,
    ) {
        this.iconRegistry.setDefaultFontSetClass('material-symbols-rounded', 'mat-ligature-font');
        this.iconRegistry.addSvgIcon('github', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/image/icon/github-brands-solid.svg'));
        this.iconRegistry.addSvgIcon('google', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/image/icon/google-brands-solid.svg'));
        this.iconRegistry.addSvgIcon('twitter', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/image/icon/twitter-brands-solid.svg'));
        this.iconRegistry.addSvgIcon('facebook', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/image/icon/facebook-brands-solid.svg'));
        this.iconRegistry.addSvgIcon('linkedin', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/image/icon/linkedin-brands-solid.svg'));
    }

}
