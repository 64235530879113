import {Pipe, PipeTransform} from '@angular/core';
import {EnhancementVideo} from "../../../../model/data/persist/jpa/entity/enhancement/enhancement-video";
import {EnhancementMedia} from "../../../../model/data/persist/jpa/entity/enhancement/enhancement-media";

@Pipe({
    name: 'mediaToVideo'
})
export class MediaToVideoPipe implements PipeTransform {

    transform(media: EnhancementMedia): EnhancementVideo {
        return media as EnhancementVideo;
    }

}
