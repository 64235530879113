import {Component, Inject, OnInit} from '@angular/core';
import {PermissionName} from '../../../../../../../model/data/persist/jpa/entity/permission';
import {VisibleScope} from '../../../../../../../model/data/persist/jpa/entity/visible-scope';
import {EnhancementMediaCollection} from '../../../../../../../model/data/persist/jpa/entity/media-collection';
import {SortablePagination} from '../../../../../../../model/commons/sortablePagination';
import {Page, PageContent} from "../../../../../../../model/commons/page";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MeService} from "../../../../../../../service/data/me/me.service";
import {MeBookService} from "../../../../../../../service/data/me/me-book.service";
import {MeMediaCollectionService} from "../../../../../../../service/data/me/me-media-collection.service";
import {MediaCategory} from "../../../../../../../model/data/persist/jpa/entity/media";
import {TranslateService} from "@ngx-translate/core";
import {PermissionService} from "../../../../../../../service/data/god/permission.service";
import {DialogService} from "../../../../../dialog/dialog.service";
import {ToastService} from "../../../../../toast/toast.service";
import {MeBookCreateDialogComponent} from "../me-book-create-dialog/me-book-create-dialog.component";
import {EnhancementBook} from "../../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-book";
import {PermissionMode} from "../../../../../../../service/data/permission-mode";

@Component({
    selector: 'cs-me-book-select-dialog',
    standalone: false,
    templateUrl: './me-book-select-dialog.component.html',
    styleUrls: ['./me-book-select-dialog.component.scss']
})
export class MeBookSelectDialogComponent implements OnInit {
    protected readonly PermissionMode = PermissionMode;
    protected readonly PermissionName = PermissionName;
    protected readonly VisibleScope = VisibleScope;

    protected mediaCollections: EnhancementMediaCollection[] = [];
    protected selectedMediaCollection: EnhancementMediaCollection | undefined;

    protected pagination: SortablePagination = new SortablePagination(0, 24, [
        {
            name: 'createTime',
            direction: 'desc'
        }
    ]);
    protected page: Page<EnhancementBook> | undefined;
    protected pageContent: PageContent<EnhancementBook> = new PageContent<EnhancementBook>((book: EnhancementBook) => {
        return book.id;
    }, (left: EnhancementBook, right: EnhancementBook) => {
        return new Date(right.createTime).getTime() - new Date(left.createTime).getTime();
    });

    protected selectedBooks: EnhancementBook[] = [];

    protected single: boolean = true;
    protected systemReserved: boolean = false;
    protected defaultCollection: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any = {},
        private dialogRef: MatDialogRef<MeBookSelectDialogComponent>,
        private translateService: TranslateService,
        private permissionService: PermissionService,
        private dialogService: DialogService,
        private toastService: ToastService,
        private meService: MeService,
        private meBookService: MeBookService,
        private meMediaCollectionService: MeMediaCollectionService
    ) {
        this.single = !!this.data.single;
        this.systemReserved = !!this.data.systemReserved;
        this.defaultCollection = !!this.data.defaultCollection;
    }

    ngOnInit(): void {
        this.meMediaCollectionService.listByCategory(MediaCategory.Book).subscribe((mediaCollections: EnhancementMediaCollection[]) => {
            this.mediaCollections = mediaCollections.filter((mediaCollection: EnhancementMediaCollection) => {
                if (this.systemReserved) {
                    if (this.defaultCollection) {
                        return mediaCollection.systemReserved && mediaCollection.defaultCollection;
                    } else {
                        return mediaCollection.systemReserved;
                    }
                } else {
                    if (this.defaultCollection) {
                        return mediaCollection.defaultCollection;
                    } else {
                        return true;
                    }
                }
            });
            if (this.mediaCollections.length) {
                this.selectedMediaCollection = this.mediaCollections[0];
                this.loadNext();
            }
        });
    }

    protected loadNext(reset: boolean = false): void {
        if (this.selectedMediaCollection) {
            if (reset) {
                this.pagination.reset();
                this.page = undefined;
                this.pageContent.reset();
            }

            if (!this.page || this.page && !this.page.last) {
                this.meBookService.pageByMediaCollection(this.selectedMediaCollection.id, this.pagination).subscribe((page: Page<EnhancementBook>) => {
                    this.pagination.page++;

                    this.page = page;
                    this.pageContent.addAll(this.page.content);
                });
            }
        }
    }

    protected onSelectedBooksChange(selectedBooks: EnhancementBook[]) {
        this.selectedBooks = selectedBooks;
    }

    protected canAdd(): boolean {
        if (this.selectedMediaCollection) {
            return this.selectedMediaCollection.systemReserved;
        }
        return false;
    }

    protected onAdd(): void {
        if (this.selectedMediaCollection) {
            this.dialogService.open(MeBookCreateDialogComponent);
        }
    }

    protected onSubmit(): void {
        this.dialogRef.close(this.selectedBooks);
    }

    protected onClose(): void {
        this.dialogRef.close();
    }

}
