<mat-card class="w-full">
    <mat-card-content class="w-full h-32 flex flex-col justify-start items-center">
        <div class="w-full flex flex-row justify-center items-center" [style.height.px]="96">
            <mat-icon class="w-auto h-auto text-orange-500" [style.font-size.rem]="3.5" fontIcon="error"></mat-icon>
        </div>
        <div class="w-full mt-1 flex flex-row justify-center items-center" [style.height.px]="48">
            <span class="text-sm">{{ 'Core.Component.Dialog.ConfirmDialog.MESSAGE' | translate }}</span>
        </div>
    </mat-card-content>
    <mat-card-actions class="w-full flex-grow-0 flex flex-row justify-center items-center" [style.height.px]="40">
        <button class="me-1" mat-flat-button (click)="confirm()"><mat-icon fontIcon="check"></mat-icon>{{ 'COMMONS.CONFIRM' | translate }}</button>
        <button class="ms-1" mat-flat-button (click)="close()"><mat-icon fontIcon="close"></mat-icon>{{ 'COMMONS.CANCEL' | translate }}</button>
    </mat-card-actions>
</mat-card>
