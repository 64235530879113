import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {AvatarComponent} from "./avatar.component";
import {EnhancementAvatarComponent} from "./enhancement-avatar/enhancement-avatar.component";
import {CsUsernameModule} from "../username/cs.username.module";
import {TranslateModule} from "@ngx-translate/core";
import {MatCardModule} from "@angular/material/card";
import {CsCommonsModule} from "../../commons/cs.commons.module";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        MatCardModule,
        CsCommonsModule,
        CsUsernameModule
    ],
    declarations: [
        AvatarComponent,
        EnhancementAvatarComponent,
    ],
    exports: [
        AvatarComponent,
        EnhancementAvatarComponent,
    ]
})
export class CsAvatarModule {
}
