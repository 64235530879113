import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CountFormatterPipe} from "./pipe/count-formatter.pipe";
import {LyricsTimeFormatterPipe} from "./pipe/lyrics-time-formatter.pipe";
import {MillisecondFormatterPipe} from "./pipe/millisecond-formatter.pipe";
import {SinceNowFormatterPipe} from "./pipe/since-now-formatter.pipe";
import {DebounceClickDirective} from "./directive/debounce-click.directive";
import {DebounceKeyupDirective} from "./directive/debounce-keyup.directive";
import {ScrollTrackerDirective} from "./directive/scroll-tracker.directive";
import {EnhancedRouterOutlet} from "./route/enhanced-router-outlet";
import {BackDirective} from "./directive/back.directive";
import {TranslateModule} from "@ngx-translate/core";
import {FileSizeFormatterPipe} from "./directive/file-size-formatter.directive";
import {MediaCollectionNameTranslatePipe} from "./pipe/media-collection-name-translate.pipe";
import {ImageResolutionTranslatePipe} from "./pipe/image-resolution-translate.pipe";
import {ImageComponent} from "./component/image/image/image.component";
import {ImageBackgroundComponent} from "./component/image/image-background/image-background.component";
import {MatGridListModule} from "@angular/material/grid-list";
import {GridLayoutItemDirective} from "./component/layout/grid-layout/grid-layout-item.directive";
import {GridLayoutComponent} from "./component/layout/grid-layout/grid-layout.component";
import {BookNameTranslatePipe} from "./pipe/book-name-translate.pipe";
import {GridLayoutLeaveBlankZoneDirective} from "./component/layout/grid-layout/grid-layout-leave-blank-zone.directive";
import {MediaToImagePipe} from "./pipe/media/media-to-image.pipe";
import {MediaToAudioPipe} from "./pipe/media/media-to-audio.pipe";
import {MediaToVideoPipe} from "./pipe/media/media-to-video.pipe";
import {MediaToBookPipe} from "./pipe/media/media-to-book.pipe";
import {MediaToArticlePipe} from "./pipe/media/media-to-article.pipe";
import {MediaInvertColorPipe} from "./pipe/media-invert-color.pipe";
import {MediaColorPipe} from "./pipe/media-color.pipe";
import {MediaToCoverImagePipe} from "./pipe/media/media-to-cover-image.pipe";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        MatGridListModule,

        /****************************************
         * Others
         ****************************************/
        EnhancedRouterOutlet,

        /****************************************
         * Directive
         ****************************************/
        DebounceClickDirective,
        DebounceKeyupDirective,
        ScrollTrackerDirective,
        BackDirective,

        /****************************************
         * Pipe
         ****************************************/
        CountFormatterPipe,
        LyricsTimeFormatterPipe,
        MillisecondFormatterPipe,
        SinceNowFormatterPipe,
        FileSizeFormatterPipe,
        MediaColorPipe,
        MediaInvertColorPipe,

        MediaCollectionNameTranslatePipe,
        BookNameTranslatePipe,
        ImageResolutionTranslatePipe,

        MediaToCoverImagePipe,

        MediaToImagePipe,
        MediaToAudioPipe,
        MediaToVideoPipe,
        MediaToBookPipe,
        MediaToArticlePipe,

        /****************************************
         * Component
         ****************************************/
        GridLayoutItemDirective,
        GridLayoutLeaveBlankZoneDirective,
    ],
    declarations: [
        /****************************************
         * Component
         ****************************************/
        GridLayoutComponent,

        ImageComponent,
        ImageBackgroundComponent,
    ],
    exports: [
        /****************************************
         * Others
         ****************************************/
        EnhancedRouterOutlet,

        /****************************************
         * Directive
         ****************************************/
        DebounceClickDirective,
        DebounceKeyupDirective,
        ScrollTrackerDirective,
        BackDirective,

        /****************************************
         * Pipe
         ****************************************/
        CountFormatterPipe,
        LyricsTimeFormatterPipe,
        MillisecondFormatterPipe,
        SinceNowFormatterPipe,
        FileSizeFormatterPipe,
        MediaColorPipe,
        MediaInvertColorPipe,

        MediaCollectionNameTranslatePipe,
        BookNameTranslatePipe,
        ImageResolutionTranslatePipe,

        MediaToCoverImagePipe,

        MediaToImagePipe,
        MediaToAudioPipe,
        MediaToVideoPipe,
        MediaToBookPipe,
        MediaToArticlePipe,

        /****************************************
         * Component
         ****************************************/
        GridLayoutItemDirective,
        GridLayoutLeaveBlankZoneDirective,
        GridLayoutComponent,

        ImageComponent,
        ImageBackgroundComponent,
    ],
    providers: [
        CountFormatterPipe,
        LyricsTimeFormatterPipe,
        MillisecondFormatterPipe,
        SinceNowFormatterPipe,
        FileSizeFormatterPipe,
        MediaColorPipe,
        MediaInvertColorPipe,

        MediaCollectionNameTranslatePipe,
        BookNameTranslatePipe,
        ImageResolutionTranslatePipe,

        MediaToCoverImagePipe,

        MediaToImagePipe,
        MediaToAudioPipe,
        MediaToVideoPipe,
        MediaToBookPipe,
        MediaToArticlePipe,
    ]
})
export class CsCommonsModule {
}
