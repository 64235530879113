import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../core/service/data/god/user.service';
import {Router} from '@angular/router';
import {NgxPermissionsService} from "ngx-permissions";

@Component({
    selector: 'cs-logout-page',
    standalone: true,
    templateUrl: './logout-page.component.html',
    styleUrls: ['./logout-page.component.scss']
})
export class LogoutPageComponent implements OnInit {

    constructor(
        private router: Router,
        private permissionsService: NgxPermissionsService,
        private userService: UserService,
    ) {
    }

    ngOnInit(): void {
        this.userService.logout().subscribe(response => {
            this.permissionsService.flushPermissions();
            this.router.navigate(['/']).then();
        });
    }

}
