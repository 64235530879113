import {JpaAuditIdentifiable4Long} from "../jpa-audit-identifiable-4-long";

export interface Permission extends JpaAuditIdentifiable4Long {
    name: string;
    description: string;
}

export enum PermissionName {
    PERMISSION_VIEW = 'permission:view',
    ROLE_VIEW = 'role:view',
    ROLE_EDIT = 'role:edit',
    USER_VIEW = 'user:view',
    USER_EDIT = 'user:edit',
    LOG_VIEW = 'log:view',
    EVENT_VIEW = 'event:view',
    SYSTEM_VALUE_VIEW = 'system-value:view',
    SYSTEM_VALUE_EDIT = 'system-value:edit',
    GEO_VIEW = 'geo:view',

    IMAGE_CATEGORY_EDIT = 'image-category:edit',
    IMAGE_VIEW = 'image:view',
    IMAGE_EDIT = 'image:edit',
    IMAGE_COLLECTION_VIEW = 'image-collection:view',
    IMAGE_COLLECTION_EDIT = 'image-collection:edit',
    IMAGE_FILE_UPLOAD_SELF = 'image-file:upload:self',
    IMAGE_EDIT_SELF = 'image:edit:self',
    IMAGE_COLLECTION_EDIT_SELF = 'image-collection:edit:self',
    AUDIO_CATEGORY_EDIT = 'audio-category:edit',
    AUDIO_VIEW = 'audio:view',
    AUDIO_EDIT = 'audio:edit',
    AUDIO_COLLECTION_VIEW = 'audio-collection:view',
    AUDIO_COLLECTION_EDIT = 'audio-collection:edit',
    AUDIO_FILE_UPLOAD_SELF = 'audio-file:upload:self',
    AUDIO_EDIT_SELF = 'audio:edit:self',
    AUDIO_COLLECTION_EDIT_SELF = 'audio-collection:edit:self',
    VIDEO_CATEGORY_EDIT = 'video-category:edit',
    VIDEO_VIEW = 'video:view',
    VIDEO_EDIT = 'video:edit',
    VIDEO_COLLECTION_VIEW = 'video-collection:view',
    VIDEO_COLLECTION_EDIT = 'video-collection:edit',
    VIDEO_FILE_UPLOAD_SELF = 'video-file:upload:self',
    VIDEO_EDIT_SELF = 'video:edit:self',
    VIDEO_COLLECTION_EDIT_SELF = 'video-collection:edit:self',
    BOOK_CATEGORY_EDIT = 'book-category:edit',
    BOOK_VIEW = 'book:view',
    BOOK_EDIT = 'book:edit',
    BOOK_COLLECTION_VIEW = 'book-collection:view',
    BOOK_COLLECTION_EDIT = 'book-collection:edit',
    BOOK_EDIT_SELF = 'book:edit:self',
    BOOK_COLLECTION_EDIT_SELF = 'book-collection:edit:self',

    COMMENT_VIEW = 'comment:view',
    COMMENT_EDIT = 'comment:edit',
    COMMENT_EDIT_SELF = 'comment:edit:self',

    ADVERTISEMENT_VIEW = 'advertisement:view',
    ADVERTISEMENT_EDIT = 'advertisement:edit',
    ADVERTISEMENT_EDIT_SELF = 'advertisement:edit:self',

    MONITOR = 'monitor'
}
