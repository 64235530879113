import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'millisecond'
})
export class MillisecondFormatterPipe implements PipeTransform {
    private readonly units: string[];

    constructor(
        private translateService: TranslateService
    ) {
        this.units  = [
            this.translateService.instant('COMMONS.YEARS'),
            this.translateService.instant('COMMONS.DAYS'),
            this.translateService.instant('COMMONS.HOURS'),
            this.translateService.instant('COMMONS.MINUTES'),
            this.translateService.instant('COMMONS.SECONDS'),
            this.translateService.instant('COMMONS.MILLISECOND')
        ];
    }

    transform(value: number, accuracy: number = 2, smallestUnit: 'year' | 'day' | 'hour' | 'minute' | 'second' | 'millisecond' = 'millisecond'): string {
        let values: number[] = []
        switch (smallestUnit) {
            case "year":
                values = [
                    Math.trunc(value / 1000 / 60 / 60 / 24 / 365.25)
                ];
                break;
            case "day":
                values = [
                    Math.trunc(value / 1000 / 60 / 60 / 24 / 365.25),
                    Math.trunc(value / 1000 / 60 / 60 / 24)
                ];
                break;
            case "hour":
                values = [
                    Math.trunc(value / 1000 / 60 / 60 / 24 / 365.25),
                    Math.trunc(value / 1000 / 60 / 60 / 24),
                    Math.trunc(value / 1000 / 60 / 60 % 24)
                ];
                break;
            case "minute":
                values = [
                    Math.trunc(value / 1000 / 60 / 60 / 24 / 365.25),
                    Math.trunc(value / 1000 / 60 / 60 / 24),
                    Math.trunc(value / 1000 / 60 / 60 % 24),
                    Math.trunc(value / 1000 / 60 % 60)
                ];
                break;
            case "second":
                values = [
                    Math.trunc(value / 1000 / 60 / 60 / 24 / 365.25),
                    Math.trunc(value / 1000 / 60 / 60 / 24),
                    Math.trunc(value / 1000 / 60 / 60 % 24),
                    Math.trunc(value / 1000 / 60 % 60),
                    Math.trunc(value / 1000 % 60)
                ];
                break;
            case "millisecond":
                values = [
                    Math.trunc(value / 1000 / 60 / 60 / 24 / 365.25),
                    Math.trunc(value / 1000 / 60 / 60 / 24),
                    Math.trunc(value / 1000 / 60 / 60 % 24),
                    Math.trunc(value / 1000 / 60 % 60),
                    Math.trunc(value / 1000 % 60),
                    Math.trunc(value % 1000)
                ];
                break;
        }

        for (let i: number = 0; i < values.length; i++) {
            if (values[i]) {
                let retValue: string = '';
                for (let j: number = i, count: number = 0; j < values.length; j++, count++) {
                    if (count >= accuracy) {
                        break;
                    } else {
                        retValue += `${values[j]}${this.units[j]}`;
                    }
                }
                return retValue;
            }
        }
        return '';
    }

}
