@if (media) {
    <div class="w-full h-full flex flex-col justify-start items-stretch">
        <mat-card class="w-full flex-grow h-0 m-0">
            <cs-image-background class="w-full flex-grow h-0 cursor-pointer" [image]="media | mediaToCoverImage" [resolution]="ImageResolution.Small" (click)="onMediaClick()">
                <div class="w-full h-full relative">
                    @switch (media.category){
                        @case (MediaCategory.Image) {
                            @if (MediaFileStatus.Handling === (media | mediaToImage).imageFile.status) {
                                <div class="w-full h-full absolute flex flex-row justify-center items-center">
                                    <mat-progress-spinner mode="indeterminate" [strokeWidth]="4" [diameter]="64"></mat-progress-spinner>
                                </div>
                            }
                        }
                        @case (MediaCategory.Audio) {
                            @if (MediaFileStatus.Handling === (media | mediaToAudio).audioFile.status) {
                                <div class="w-full h-full absolute flex flex-row justify-center items-center">
                                    <mat-progress-spinner mode="indeterminate" [strokeWidth]="4" [diameter]="64"></mat-progress-spinner>
                                </div>
                            }
                        }
                        @case (MediaCategory.Video) {
                            @if (media | mediaToCoverImage) {
                                <div #thumbnailContainer class="w-full h-full bg-cover"
                                     (mouseenter)="thumbnailContainer.style.backgroundImage = 'url(/service-media/stream/video/' + media.id + '/animated-thumbnail)'"
                                     (mouseleave)="thumbnailContainer.style.backgroundImage = ''">
                                </div>
                            } @else {
                                <div #thumbnailContainer class="w-full h-full bg-cover"
                                     [style.background-image]="'url(/service-media/stream/video/' + media.id + '/thumbnail)'"
                                     (mouseenter)="thumbnailContainer.style.backgroundImage = 'url(/service-media/stream/video/' + media.id + '/animated-thumbnail)'"
                                     (mouseleave)="thumbnailContainer.style.backgroundImage = 'url(/service-media/stream/video/' + media.id + '/thumbnail)'">
                                </div>
                            }

                            @if (MediaFileStatus.Handling === (media | mediaToVideo).videoFile.status) {
                                <div class="w-full h-full absolute flex flex-row justify-center items-center">
                                    <mat-progress-spinner mode="indeterminate" [strokeWidth]="4" [diameter]="64"></mat-progress-spinner>
                                </div>
                            }
                        }
                    }

                    @if(PermissionMode.Me === permissionMode || PermissionMode.God === permissionMode) {
                        <div class="w-full px-1 pt-2 absolute flex flex-row justify-start items-start text-lg" [style.top.px]="0">
                            <div class="flex-grow flex flex-row justify-start items-end" [style.height.px]="18">
                                @if (VisibleScope.Private === media.visibleScope) {
                                    <mat-icon class="text-xl text-red-600 active" fontIcon="lock"></mat-icon>
                                }
                            </div>
                            <div class="flex-grow-0 flex flex-row justify-start items-end font-thin" [style.height.px]="18">
                            </div>
                        </div>
                    }

                    <div class="w-full px-1 pb-1 absolute flex flex-row justify-start items-end bg-gradient-to-t from-black to-transparent" [style.height.px]="36" [style.bottom.px]="0">
                        <div class="flex-grow flex flex-row justify-start items-end font-thin" [style.height.px]="18">
                            <div class="flex-grow-0 h-full inline-flex flex-row justify-start items-center">
                                <mat-icon class="text-xl text-red-600" [ngClass]="media.likedByCurrentUser ? 'active' : ''" fontIcon="favorite"></mat-icon>
                                <span class="text-base ms-1" [ngClass]="media.likedByCurrentUser ? 'text-red-600' : 'text-white'"><strong>{{media.likedCount}}</strong></span>
                            </div>
                            <div class="flex-grow-0 h-full ms-3 inline-flex flex-row justify-start items-center">
                                <mat-icon class="text-xl text-orange-400" [ngClass]="media.collectedByCurrentUser ? 'active' : ''" fontIcon="photo_library"></mat-icon>
                                <span class="text-base ms-1" [ngClass]="media.collectedByCurrentUser ? 'text-orange-400' : 'text-white'"><strong>{{media.collectedCount}}</strong></span>
                            </div>
                            <div class="flex-grow-0 h-full ms-3 inline-flex flex-row justify-start items-center">
                                <mat-icon class="text-xl text-blue-600" [ngClass]="media.commentedByCurrentUser ? 'active' : ''" fontIcon="comment"></mat-icon>
                                <span class="text-base ms-1" [ngClass]="media.commentedByCurrentUser ? 'text-blue-600' : 'text-white'"><strong>{{media.commentedCount}}</strong></span>
                            </div>
                        </div>
                        <div class="flex-grow-0 flex flex-row justify-start items-end font-thin" [style.height.px]="18">
                            @switch (media.category){
                                @case (MediaCategory.Audio) {
                                    <span class="px-1 rounded bg-black text-white">{{(media | mediaToAudio).audioFile.duration | millisecond: 1}}</span>
                                }
                                @case (MediaCategory.Video) {
                                    <span class="px-1 rounded bg-black text-white">{{(media | mediaToVideo).videoFile.duration | millisecond: 1}}</span>
                                }
                            }
                        </div>
                    </div>

                    <div class="w-full h-full absolute">
                        <ng-content></ng-content>
                    </div>
                </div>
            </cs-image-background>
        </mat-card>
        <div class="w-full flex-grow-0 mt-1 flex flex-row justify-start items-stretch" [style.height.px]="72">
            <div class="flex-grow-0 h-full flex flex-row justify-start items-start">
                <cs-enhancement-avatar [user]="media.enhancementUser"></cs-enhancement-avatar>
            </div>
            <div class="flex-grow h-full w-0 ms-2 flex flex-col justify-start items-start">
                <div class="w-full flex-grow-0 flex flex-row justify-start items-center" [style.height.px]="28">
                    <span class="truncate" [style.font-size.rem]="1"><strong>{{ media | bookNameTranslate }}</strong></span>
                </div>
                <div class="w-full flex-grow h-0 flex flex-row justify-start items-center">
                    <cs-username class="w-full h-auto" [user]="media.enhancementUser"></cs-username>
                </div>
                <div class="w-full flex-grow h-0 flex flex-row justify-start items-center">
                    <span class="inline-flex flex-row justify-start items-baseline text-xs"><span>{{ media.views | count }}{{ 'COMMONS.VIEWS' | translate }}</span><span class="px-1">·</span><span>{{ media.createTime | sinceNow: 1 }}</span></span>
                </div>
            </div>
        </div>
    </div>
}
