<div class="w-full">
    <mat-form-field class="w-full" appearance="outline">
        <input matInput type="text" [formControl]="keyword" placeholder="{{ 'Page.MePage.UserPage.UserListPage.SEARCH_HINT' | translate }}" (keyup.enter)="loadNext(true)">
        <button matSuffix mat-icon-button (click)="loadNext(true)">
            <mat-icon fontIcon="search"></mat-icon>
        </button>
    </mat-form-field>
</div>
<cs-grid-layout class="w-full" [aspectRatio]="16/9" [extraHeight]="48" [gutter]="8">
    @for (user of pageContent.values(); track user.id) {
        <ng-template csGridLayoutItem [track]="user.id">
            <mat-card class="w-full h-full" appearance="outlined">
                <mat-card-header class="w-full flex-grow-0">
                    <span><strong>{{ user.name }}</strong></span>
                </mat-card-header>
                <mat-card-content class="w-full flex-grow h-0">
                    @for (role of user.roles; track role.id) {
                        <span class="text-xs rounded border border-solid"> {{ role.name }}</span>
                    }
                </mat-card-content>
                <mat-card-footer class="w-full flex-grow-0">
                    <div class="w-full flex flex-row justify-end items-center">
                        <span class="text-sm">{{ 'COMMONS.UPDATE_TIME' | translate }}: {{ user.createTime | date: 'yyyy-MM-dd HH:mm:ss' }}</span>
                    </div>
                </mat-card-footer>
                <mat-card-actions class="w-full flex-grow-0 flex flex-row justify-end items-center">
                    <button class="flex-grow-0 me-1" mat-flat-button (click)="onEdit(user)"><mat-icon fontIcon="edit"></mat-icon>{{ 'COMMONS.EDIT' | translate }}</button>
                    <button class="flex-grow-0 ms-1" mat-flat-button [disabled]="!user.deletable" (click)="onDelete(user)"><mat-icon fontIcon="delete"></mat-icon>{{ 'COMMONS.DELETE' | translate }}</button>
                </mat-card-actions>
            </mat-card>
        </ng-template>
    }
</cs-grid-layout>

<button class="fixed" [style.bottom.px]="16" [style.right.px]="16" mat-fab (click)="onCreate()">
    <mat-icon>add</mat-icon>
</button>
