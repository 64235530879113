import {Component} from '@angular/core';
import {EnhancementVideo} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-video";
import {MediaGridViewerComponent} from "../../media/media-grid-viewer/media-grid-viewer.component";

@Component({
    selector: 'cs-video-grid-viewer',
    standalone: false,
    templateUrl: '../../media/media-grid-viewer/media-grid-viewer.component.html',
    styleUrls: ['../../media/media-grid-viewer/media-grid-viewer.component.scss']
})
export class VideoGridViewerComponent extends MediaGridViewerComponent<EnhancementVideo> {

}
