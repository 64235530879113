import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {PublicImageService} from "../../../core/service/data/public/public-image.service";
import {PermissionMode} from "../../../core/service/data/permission-mode";
import {ToastService} from "../../../core/module/component/toast/toast.service";
import {DialogService} from "../../../core/module/component/dialog/dialog.service";
import {EnhancementMediaCollection} from "../../../core/model/data/persist/jpa/entity/media-collection";
import {CommonModule} from "@angular/common";
import {CsCommonsModule} from "../../../core/module/commons/cs.commons.module";
import {ImageResolution} from '../../../core/model/data/persist/jpa/entity/image-file';
import {
    ImageBackgroundSize
} from '../../../core/module/commons/component/image/image-background/image-background.component';
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {HomePageComponentService} from "../../home-page/home-page.component.service";
import {PermissionService} from "../../../core/service/data/god/permission.service";
import {CsMediaModule} from "../../../core/module/component/media/cs.media.module";
import {EnhancementImage} from "../../../core/model/data/persist/jpa/entity/enhancement/enhancement-image";

@Component({
    selector: 'cs-image-detail-page',
    standalone: true,
    templateUrl: './image-detail-page.component.html',
    styleUrls: ['./image-detail-page.component.scss'],
    imports: [
        CommonModule,
        TranslateModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        CsCommonsModule,
        CsMediaModule,
    ]
})
export class ImageDetailPageComponent implements OnInit, OnDestroy {
    protected readonly PermissionMode = PermissionMode;
    protected readonly ImageBackgroundSize = ImageBackgroundSize;
    protected readonly ImageResolution = ImageResolution;

    protected image: EnhancementImage | undefined;

    constructor(
        protected homePageComponentService: HomePageComponentService,
        private router: Router,/**/
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private permissionService: PermissionService,
        private dialogService: DialogService,
        private toastService: ToastService,
        private publicImageService: PublicImageService
    ) {
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe((params: Params) => {
            const id: number = params['id'] as number;
            if (id) {
                this.load(id);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.image) {
            this.load(this.image.id);
        }
    }

    private load(id: number): void {
        this.publicImageService.load(id).subscribe((image: EnhancementImage) => {
            this.onImageChange(image);
        });
    }

    protected onImageChange(image: EnhancementImage): void {
        this.image = image;
        this.homePageComponentService.notifyImageChanged(this.image);
    }

    protected onMediaCollectionClick(mediaCollection: EnhancementMediaCollection): void {
        this.router.navigate([`/media-collection/${mediaCollection.id}`]).then();
    }

}
