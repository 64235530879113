import {Directive, Input, TemplateRef} from '@angular/core';

@Directive({
    selector: '[csGridLayoutItem]'
})
export class GridLayoutItemDirective {

    readonly template: TemplateRef<any>;

    @Input({required: true})
    track!: number | string;

    constructor(private templateRef: TemplateRef<any>) {
        this.template = this.templateRef;
    }

}
