import {Component, OnDestroy, OnInit} from '@angular/core';
import {SortablePagination} from "../../../../core/model/commons/sortablePagination";
import {Page, PageContent} from "../../../../core/model/commons/page";
import {PermissionMode} from "../../../../core/service/data/permission-mode";
import {SortMethod} from "../../../../core/model/sort-method";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxPermissionsService} from "ngx-permissions";
import {ToastService} from "../../../../core/module/component/toast/toast.service";
import {DialogService} from "../../../../core/module/component/dialog/dialog.service";
import {FileUploaderService} from "../../../../core/module/component/file-uploader/file-uploader.service";
import {HomePageComponentService} from "../../home-page.component.service";
import {PublicImageService} from "../../../../core/service/data/public/public-image.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {CommonModule} from "@angular/common";
import {CsCommonsModule} from "../../../../core/module/commons/cs.commons.module";
import {CsMediaModule} from "../../../../core/module/component/media/cs.media.module";
import {EnhancementImage} from "../../../../core/model/data/persist/jpa/entity/enhancement/enhancement-image";

@Component({
    selector: 'cs-home-image-list-page-page',
    standalone: true,
    templateUrl: './home-image-list-page.component.html',
    styleUrls: ['./home-image-list-page.component.scss'],
    imports: [
        CommonModule,
        TranslateModule,
        CsCommonsModule,
        CsMediaModule,
    ]
})
export class HomeImageListPageComponent implements OnInit, OnDestroy {
    protected readonly PermissionMode = PermissionMode;

    protected isLoading: boolean = true;

    protected sortMethod: SortMethod = SortMethod.Default;
    // protected timeFilter: TimeFilter | undefined;
    // protected time: Time | undefined;
    protected pagination: SortablePagination = new SortablePagination(0, 24);
    protected page: Page<EnhancementImage> | undefined;
    protected pageContent: PageContent<EnhancementImage> = new PageContent<EnhancementImage>((image: EnhancementImage) => {
        return image.id;
    });

    constructor(
        private homeDiscoveryPageComponentService: HomePageComponentService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private permissionsService: NgxPermissionsService,
        private toastService: ToastService,
        private dialogService: DialogService,
        private fileUploaderService: FileUploaderService,
        private publicImageService: PublicImageService
    ) {
        this.homeDiscoveryPageComponentService.onScrollToBottom().pipe(takeUntilDestroyed()).subscribe(() => {
            this.loadNext();
        });

        this.homeDiscoveryPageComponentService.onImageChanged().pipe(takeUntilDestroyed()).subscribe((image: EnhancementImage) => {
            this.pageContent.update(image);
        });
    }

    ngOnInit(): void {
        this.loadNext(true);
    }

    ngOnDestroy(): void {
    }

    private loadNext(reset: boolean = false): void {
        if (reset) {
            this.pagination.reset();
            this.page = undefined;
            this.pageContent.reset();
        }

        if (!this.page || this.page && !this.page.last) {
            this.isLoading = true;
            this.publicImageService.page(this.sortMethod, this.pagination).subscribe((page: Page<EnhancementImage>) => {
                this.pagination.page++;

                this.page = page;
                this.pageContent.addAll(this.page.content);

                this.isLoading = false;
            });
        }
    }

    protected onImageClick(image: EnhancementImage): void {
        this.router.navigate([`/image/${image.id}`]).then();
    }

}

