import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SortablePagination} from "../../../model/commons/sortablePagination";
import {Page} from "../../../model/commons/page";
import {Service} from '../service';
import {EnhancementComment} from "../../../model/data/persist/jpa/entity/comment";
import {Duration} from "../../../model/data/utils/time-range";
import {EnhancementMedia} from "../../../model/data/persist/jpa/entity/enhancement/enhancement-media";

@Injectable({
    providedIn: 'root'
})
export class PublicMediaService {

    constructor(
        private http: HttpClient
    ) {
    }

    page(pagination: SortablePagination): Observable<Page<EnhancementMedia>> {
        return this.http.get<Page<EnhancementMedia>>(`${Service.Media.prefixUrl}/public/media${pagination.toRequestParameter()}`);
    }

    pageHottest(pagination: SortablePagination, duration?: Duration): Observable<Page<EnhancementMedia>> {
        if (duration) {
            return this.http.get<Page<EnhancementMedia>>(`${Service.Media.prefixUrl}/public/media/hottest${pagination.toRequestParameter()}&duration=${duration}`);
        } else {
            return this.http.get<Page<EnhancementMedia>>(`${Service.Media.prefixUrl}/public/media/hottest${pagination.toRequestParameter()}`);
        }
    }

    load(id: number): Observable<EnhancementMedia> {
        return this.http.get<EnhancementMedia>(`${Service.Media.prefixUrl}/public/media/${id}`);
    }

    pageByMediaCollection(collectionId: number, pagination: SortablePagination): Observable<Page<EnhancementMedia>> {
        return this.http.get<Page<EnhancementMedia>>(`${Service.Media.prefixUrl}/public/media/collection/${collectionId}${pagination.toRequestParameter()}`);
    }

    pageComment(id: number, pagination: SortablePagination): Observable<Page<EnhancementComment>> {
        return this.http.get<Page<EnhancementComment>>(`${Service.Media.prefixUrl}/public/media/${id}/comment${pagination.toRequestParameter()}`);
    }

    pageRecommendation(pagination: SortablePagination): Observable<Page<EnhancementMedia>> {
        return this.http.get<Page<EnhancementMedia>>(`${Service.Search.prefixUrl}/public/media/recommendation${pagination.toRequestParameter()}`);
    }

}
