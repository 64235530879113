<cs-grid-layout class="w-full mb-5" [aspectRatio]="ImageTypeAspectTable[ImageType.MediaCoverImage]" [gutter]="8" [extraHeight]="72">
    <ng-template csGridLayoutLeaveBlankZone [span]="2">
        <cs-book-gallery class="w-full h-full" [extraHeight]="144" [medias]="hottestBookInWeekPageContent.values()" (mediaClick)="onBookClick($event)"></cs-book-gallery>
    </ng-template>
    @for (media of hottestBookInDayPageContent.values(); track media) {
        <ng-template csGridLayoutItem [track]="media.id">
            <cs-book-card class="w-full h-full" [permissionMode]="PermissionMode.Public" [media]="media" (mediaClick)="onBookClick($event)"></cs-book-card>
        </ng-template>
    }
</cs-grid-layout>

<div class="w-full mb-5">
    <mat-toolbar class="w-full  p-0 flex flex-row justify-between items-center">
        <div class="flex-grow-0 flex flex-row justify-start items-center">
            <mat-icon class="active text-red-500" fontIcon="local_fire_department"></mat-icon>
            <span class="px-2 text-base"><strong>热门收藏</strong></span>
        </div>
    </mat-toolbar>
    <cs-media-collection-grid-viewer class="w-full" [permissionMode]="PermissionMode.Public" [mediaCollections]="hottestMediaCollectionInWeekPageContent.values()" (mediaCollectionClick)="onMediaCollectionClick($event)"></cs-media-collection-grid-viewer>
</div>

<div class="w-full mb-5">
    <mat-toolbar class="w-full my-5 p-0 flex flex-row justify-between items-center">
        <div class="flex-grow-0 flex flex-row justify-start items-center">
            <mat-icon class="active text-red-500" fontIcon="recommend"></mat-icon>
            <span class="px-2 text-base"><strong>为您推荐</strong></span>
        </div>
    </mat-toolbar>
    <cs-book-grid-viewer class="w-full" [permissionMode]="PermissionMode.Public" [imageType]="ImageType.Image" [medias]="recommendBookPageContent.values()" (mediaClick)="onBookClick($event)"></cs-book-grid-viewer>
</div>
