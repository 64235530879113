import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {
    SystemValue4CustomConfiguration,
    SystemValue4MailConfiguration
} from '../../../model/data/persist/jpa/entity/system-value';
import {
    SystemValue4CustomConfigurationUpdateRequest,
    SystemValue4MailConfigurationUpdateRequest
} from '../../../model/request/system-value-request';
import {tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {Language} from '../../../model/lanuage';
import {Service} from "../service";

@Injectable({
    providedIn: 'root'
})
export class SystemValueService {

    private customConfigurationSubject: BehaviorSubject<SystemValue4CustomConfiguration | undefined> = new BehaviorSubject<SystemValue4CustomConfiguration | undefined>(undefined);

    constructor(
        private http: HttpClient,
        private translateService: TranslateService) {
        this.translateService.addLangs([Language.zhCN, Language.enUS, Language.jaJP]);
    }

    onCustomConfigurationChanged(): Observable<SystemValue4CustomConfiguration | undefined> {
        return this.customConfigurationSubject.asObservable();
    }

    loadCustomConfiguration(): Observable<SystemValue4CustomConfiguration> {
        return this.http.get<SystemValue4CustomConfiguration>(`${Service.Core.prefixUrl}/system/value/custom`).pipe(tap((customConfiguration: SystemValue4CustomConfiguration) => {
            this.customConfigurationSubject.next(customConfiguration);
        }));
    }

    setCustomConfiguration(request: SystemValue4CustomConfigurationUpdateRequest): Observable<SystemValue4CustomConfiguration> {
        return this.http.put<SystemValue4CustomConfiguration>(`${Service.Core.prefixUrl}/system/value/custom`, request);
    }

    loadMailConfiguration(): Observable<SystemValue4MailConfiguration> {
        return this.http.get<SystemValue4MailConfiguration>(`${Service.Core.prefixUrl}/system/value/mail`);
    }

    setMailConfiguration(request: SystemValue4MailConfigurationUpdateRequest): Observable<SystemValue4MailConfiguration> {
        return this.http.put<SystemValue4MailConfiguration>(`${Service.Core.prefixUrl}/system/value/mail`, request);
    }

    testMailConfiguration(): Observable<any> {
        return this.http.get('/mail/system/value/mail/test');
    }

}
