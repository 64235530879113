import {Injectable} from '@angular/core';
import {AbstractPageComponentService} from "../abstract-page-component-service";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {EnhancementMediaCollection} from "../../core/model/data/persist/jpa/entity/media-collection";
import {EnhancementImage} from "../../core/model/data/persist/jpa/entity/enhancement/enhancement-image";
import {EnhancementAudio} from "../../core/model/data/persist/jpa/entity/enhancement/enhancement-audio";
import {EnhancementVideo} from "../../core/model/data/persist/jpa/entity/enhancement/enhancement-video";
import {EnhancementBook} from "../../core/model/data/persist/jpa/entity/enhancement/enhancement-book";

@Injectable({
    providedIn: 'root'
})
export class HomePageComponentService extends AbstractPageComponentService {

    private imageChangedSubject: Subject<EnhancementImage> = new Subject<EnhancementImage>();
    private audioChangedSubject: Subject<EnhancementAudio> = new Subject<EnhancementAudio>();
    private videoChangedSubject: Subject<EnhancementVideo> = new Subject<EnhancementVideo>();
    private bookChangedSubject: Subject<EnhancementBook> = new Subject<EnhancementBook>();
    private mediaCollectionChangedSubject: Subject<EnhancementMediaCollection> = new Subject<EnhancementMediaCollection>();

    private audioControlBarSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);

    onImageChanged(): Observable<EnhancementImage> {
        return this.imageChangedSubject.asObservable();
    }

    notifyImageChanged(image: EnhancementImage): void {
        this.imageChangedSubject.next(image);
    }

    onAudioChanged(): Observable<EnhancementAudio> {
        return this.audioChangedSubject.asObservable();
    }

    notifyAudioChanged(audio: EnhancementAudio): void {
        this.audioChangedSubject.next(audio);
    }

    onVideoChanged(): Observable<EnhancementVideo> {
        return this.videoChangedSubject.asObservable();
    }

    notifyVideoChanged(video: EnhancementVideo): void {
        this.videoChangedSubject.next(video);
    }

    onBookChanged(): Observable<EnhancementBook> {
        return this.bookChangedSubject.asObservable();
    }

    notifyBookChanged(book: EnhancementBook): void {
        this.bookChangedSubject.next(book);
    }

    onMediaCollectionChanged(): Observable<EnhancementMediaCollection> {
        return this.mediaCollectionChangedSubject.asObservable();
    }

    notifyMediaCollectionChanged(mediaCollection: EnhancementMediaCollection): void {
        this.mediaCollectionChangedSubject.next(mediaCollection);
    }

    isShowAudioControlBar(): Observable<boolean> {
        return this.audioControlBarSubject.asObservable();
    }

    showAudioControlBar(): void {
        Promise.resolve().then(() => {
            this.audioControlBarSubject.next(true);
        });
    }

    hideAudioControlBar(): void {
        Promise.resolve().then(() => {
            this.audioControlBarSubject.next(false);
            // this.audioPlayerService.pause();
        });
    }
}
