<mat-card class="w-full h-full" appearance="outlined">
    <mat-card-header class="w-full flex-grow-0">
        <mat-icon fontIcon="kid_star"></mat-icon><span class="ms-2 inline-flex flex-row justify-start items-center">{{ ''| translate }}</span>
    </mat-card-header>
    <mat-card-content class="w-full flex-grow h-0">
        <form class="w-full" [formGroup]="mediaCollectionForm">
            <mat-form-field class="w-full flex-grow-0" appearance="outline">
                <mat-label>{{ 'COMMONS.NAME' | translate }}</mat-label>
                <input matInput type="text" placeholder="{{ 'COMMONS.NAME_HINT' | translate }}" formControlName="name">
            </mat-form-field>
            <mat-form-field class="w-full flex-grow-0" appearance="outline">
                <mat-label>{{ 'COMMONS.DESCRIPTION' | translate }}</mat-label>
                <input matInput type="text" placeholder="{{ 'COMMONS.DESCRIPTION_HINT' | translate }}" formControlName="description">
            </mat-form-field>
            <mat-form-field class="w-full flex-grow-0" appearance="outline">
                <mat-label>{{ 'COMMONS.TYPE' | translate }}</mat-label>
                <mat-select formControlName="category">
                    <mat-option [value]="MediaCategory.Image">{{ 'ENUMERATION.Media.Category.Image' | translate }}</mat-option>
                    <mat-option [value]="MediaCategory.Audio">{{ 'ENUMERATION.Media.Category.Audio' | translate }}</mat-option>
                    <mat-option [value]="MediaCategory.Video">{{ 'ENUMERATION.Media.Category.Video' | translate }}</mat-option>
                    <mat-option [value]="MediaCategory.Book">{{ 'ENUMERATION.Media.Category.Book' | translate }}</mat-option>
                    <mat-option [value]="MediaCategory.Article">{{ 'ENUMERATION.Media.Category.Article' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </mat-card-content>
    <mat-card-actions class="w-full flex-grow-0 flex flex-row justify-end items-center">
        <button class="me-1" mat-flat-button [disabled]="!mediaCollectionForm.valid" (click)="submit()"><mat-icon fontIcon="check"></mat-icon>{{ 'COMMONS.CONFIRM' | translate }}</button>
        <button class="ms-1" mat-flat-button (click)="onClose()"><mat-icon fontIcon="close"></mat-icon>{{ 'COMMONS.CANCEL' | translate }}</button>
    </mat-card-actions>
</mat-card>
