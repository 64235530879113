import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NgxFileDropEntry} from "ngx-file-drop";
import {FileUploaderService, FileUploaderUploadProgressEvent} from "../../../../../file-uploader/file-uploader.service";
import {ImageType} from "../../../../../../../model/data/persist/jpa/entity/image";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {DialogService} from "../../../../../dialog/dialog.service";
import {MeImageCropperDialogComponent} from "../me-image-cropper-dialog/me-image-cropper-dialog.component";

@Component({
    selector: 'cs-me-image-create-dialog',
    standalone: false,
    templateUrl: './me-image-create-dialog.component.html',
    styleUrls: ['./me-image-create-dialog.component.scss']
})
export class MeImageCreateDialogComponent {
    protected accepts: string = ['.png', '.jpg', '.jpeg'].join(',');

    protected files: File[] = [];

    protected fileUploaderUploadProgressEvents: FileUploaderUploadProgressEvent[] = [];

    protected type: ImageType = ImageType.Image;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any = {},
        private dialogRef: MatDialogRef<MeImageCreateDialogComponent>,
        private fileUploaderService: FileUploaderService,
        private dialogService: DialogService
    ) {
        this.type = this.data.type ?? ImageType.Image;

        this.fileUploaderService.onImageFilesChanged().pipe(takeUntilDestroyed()).subscribe((files: File[]) => {
            this.fileUploaderUploadProgressEvents = this.fileUploaderUploadProgressEvents.filter((event: FileUploaderUploadProgressEvent) => {
                return files.includes(event.file);
            });
            files.filter((file: File) => {
                return !this.fileUploaderUploadProgressEvents.map((fileUploaderUploadProgressEvent: FileUploaderUploadProgressEvent) => {
                    return fileUploaderUploadProgressEvent.file;
                }).includes(file);
            }).forEach((file: File) => {
                this.fileUploaderUploadProgressEvents.push({
                    file: file,
                    progress: 0
                });
            });
        });
        this.fileUploaderService.onImageFileUploadProgressChanged().pipe(takeUntilDestroyed()).subscribe((event: FileUploaderUploadProgressEvent) => {
            this.fileUploaderUploadProgressEvents.filter((fileUploaderUploadProgressEvent: FileUploaderUploadProgressEvent) => {
                return fileUploaderUploadProgressEvent.file === event.file;
            }).forEach((fileUploaderUploadProgressEvent: FileUploaderUploadProgressEvent) => {
                fileUploaderUploadProgressEvent.progress = event.progress;
            });
        });
    }

    protected onDropped(fileDropEntries: NgxFileDropEntry[]): void {
        for (const droppedFile of fileDropEntries) {
            if (droppedFile.fileEntry.isFile) {
                const fileEntry: FileSystemFileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    this.files.push(file);
                });
            }
        }
    }

    protected onEditImage(file: File): void {
        this.dialogService.open<MeImageCropperDialogComponent, File>(MeImageCropperDialogComponent, {
            image: file,
            type: this.type
        }).afterClosed().subscribe((image: File | undefined) => {
            if (image) {
                this.files = this.files.filter((file: File) => {
                    return file.name !== image.name;
                });
                this.files.push(image);
            }
        });
    }

    protected onSubmit(): void {
        this.files.forEach((file: File) => {
            this.fileUploaderService.addImageFile(file, this.type);
        });
        this.files = [];
    }

    protected onClose(): void {
        this.dialogRef.close();
    }

}
