export interface Sort {
    name: string;
    direction: 'asc' | 'desc';
}

export class Pagination {
    public page: number;
    public itemsPerPage: number;

    constructor(page: number, itemsPerPage: number) {
        this.page = page;
        this.itemsPerPage = itemsPerPage;
    }

    reset(): void {
        this.page = 0;
    }

    toRequestParameter(): string {
        return `?page=${this.page}&size=${this.itemsPerPage}`;
    }
}

export class SortablePagination extends Pagination {
    public sorts: Sort[] = [];

    constructor(page: number, itemsPerPage: number, sorts: Sort[] = []) {
        super(page, itemsPerPage);
        this.sorts = sorts;
    }

    override toRequestParameter(): string {
        if (this.sorts.length) {
            return `${super.toRequestParameter()}&${this.sorts.map((sort: Sort) => `sort=${sort.name},${sort.direction}`).join('&')}`;
        } else {
            return super.toRequestParameter();
        }
    }
}

