import {Component, inject, OnInit} from '@angular/core';
import {SortablePagination} from "../../../../core/model/commons/sortablePagination";
import {Page, PageContent} from "../../../../core/model/commons/page";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {MeImageService} from "../../../../core/service/data/me/me-image.service";
import {ActivatedRoute, Router, RouterModule} from "@angular/router";
import {NgxPermissionsService} from "ngx-permissions";
import {PermissionName} from '../../../../core/model/data/persist/jpa/entity/permission';
import {PermissionMode} from "../../../../core/service/data/permission-mode";
import {CommonModule} from "@angular/common";
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {CsCommonsModule} from "../../../../core/module/commons/cs.commons.module";
import {MePageComponentService} from "../../me-page.component.service";
import {DialogService} from "../../../../core/module/component/dialog/dialog.service";
import {
    MeImageCreateDialogComponent
} from "../../../../core/module/component/media/media/media-image/dialog/me-image-create-dialog/me-image-create-dialog.component";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {ToastService} from "../../../../core/module/component/toast/toast.service";
import {
    MeImageEditDialogComponent
} from "../../../../core/module/component/media/media/media-image/dialog/me-image-edit-dialog/me-image-edit-dialog.component";
import {MeService} from "../../../../core/service/data/me/me.service";
import {
    FileUploaderImageUploadFinishEvent,
    FileUploaderService
} from "../../../../core/module/component/file-uploader/file-uploader.service";
import {CsMediaModule} from "../../../../core/module/component/media/cs.media.module";
import {EnhancementImage} from "../../../../core/model/data/persist/jpa/entity/enhancement/enhancement-image";
import {Image} from "../../../../core/model/data/persist/jpa/entity/image";

@Component({
    selector: 'cs-me-image-list-page',
    standalone: true,
    templateUrl: './me-image-list-page.component.html',
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatGridListModule,
        CsCommonsModule,
        CsMediaModule
    ],
    styleUrls: ['./me-image-list-page.component.scss']
})
export class MeImageListPageComponent implements OnInit {
    protected readonly PermissionName = PermissionName;
    protected readonly PermissionMode = PermissionMode;

    private pagination: SortablePagination = new SortablePagination(0, 25, [
        {
            name: 'createTime',
            direction: 'desc'
        }
    ]);
    private page: Page<EnhancementImage> | undefined;

    protected keyword: FormControl = inject(FormBuilder).control('', []);
    protected pageContent: PageContent<EnhancementImage> = new PageContent<EnhancementImage>((image: EnhancementImage) => {
        return image.id;
    }, (left: EnhancementImage, right: EnhancementImage) => {
        return new Date(right.createTime).getTime() - new Date(left.createTime).getTime();
    });

    constructor(
        protected mePageComponentService: MePageComponentService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private permissionsService: NgxPermissionsService,
        private toastService: ToastService,
        private dialogService: DialogService,
        private fileUploaderService: FileUploaderService,
        private meService: MeService,
        private meImageService: MeImageService
    ) {
        this.mePageComponentService.onScrollToBottom().pipe(takeUntilDestroyed()).subscribe(() => {
            this.loadNext();
        });
        this.fileUploaderService.onImageUploadFinished().pipe(takeUntilDestroyed()).subscribe((event: FileUploaderImageUploadFinishEvent) => {
            this.pageContent.add(event.image);
        });
        this.meService.onImageChanged().pipe(takeUntilDestroyed()).subscribe((image: Image) => {
            this.meImageService.load(image.id).subscribe((handledImage: EnhancementImage) => {
                this.pageContent.update(handledImage);

                this.toastService.show('COMMONS.SUCCESS');
            });
        });
    }

    ngOnInit(): void {
        this.loadNext(true);
    }

    protected loadNext(reset: boolean = false): void {
        if (reset) {
            this.pagination.reset();
            this.page = undefined;
            this.pageContent.reset();
        }

        if (!this.page || this.page && !this.page.last) {
            if (this.keyword.value) {
                this.meImageService.search(this.keyword.value, this.pagination).subscribe((page: Page<EnhancementImage>) => {
                    this.pagination.page++;

                    this.page = page;
                    this.pageContent.addAll(this.page.content);
                });
            } else {
                this.meImageService.page(this.pagination).subscribe((page: Page<EnhancementImage>) => {
                    this.pagination.page++;

                    this.page = page;
                    this.pageContent.addAll(this.page.content);
                });
            }
        }
    }

    protected onCreate(): void {
        this.dialogService.open(MeImageCreateDialogComponent);
    }

    protected onImageClick(image: EnhancementImage): void {
        this.dialogService.open<MeImageEditDialogComponent, EnhancementImage>(MeImageEditDialogComponent, {
            image: image
        }).afterClosed().subscribe((image: EnhancementImage | undefined) => {
            if (image) {
                this.pageContent.update(image);
            }
        });
    }

}
