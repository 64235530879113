import {Component, inject, Inject, OnInit} from '@angular/core';
import {RoleService} from '../../../../../core/service/data/god/role.service';
import {UserService} from '../../../../../core/service/data/god/user.service';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Role} from "../../../../../core/model/data/persist/jpa/entity/role";
import {User} from "../../../../../core/model/data/persist/jpa/entity/user";
import {AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CommonModule} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ToastService} from "../../../../../core/module/component/toast/toast.service";

@Component({
    selector: 'cs-me-user-edit-dialog',
    standalone: true,
    templateUrl: './me-user-edit-dialog.component.html',
    styleUrls: ['./me-user-edit-dialog.component.scss'],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule
    ]
})
export class MeUserEditDialogComponent implements OnInit {
    protected isLoading: boolean = true;

    protected roles: Role[] = [];

    protected userForm: FormGroup = inject(FormBuilder).group({
        name: ['', Validators.required],
        password: ['', Validators.required],
        roles: [[]]
    });

    protected user: User | undefined;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any = {},
        private dialogRef: MatDialogRef<MeUserEditDialogComponent>,
        private translateService: TranslateService,
        private roleService: RoleService,
        private toastService: ToastService,
        private userService: UserService,
    ) {
        this.user = this.data.user;
    }

    ngOnInit(): void {
        if (this.user) {
            this.loadEditMode();
        } else {
            this.loadCreateMode();
        }
    }

    close(): void {
        this.dialogRef.close(false);
    }

    protected loadCreateMode(): void {
        this.roleService.list().subscribe((roles: Role[]) => {
            this.roles = roles;

            this.isLoading = false;
        });
    }

    protected loadEditMode(): void {
        this.roleService.list().subscribe((roles: Role[]) => {
            this.roles = roles;

            if (this.user) {
                const nameFiled: AbstractControl<string, string> | null = this.userForm.get('name');
                if (nameFiled) {
                    nameFiled.patchValue(this.user.name);
                }

                const descriptionFiled: AbstractControl<string, string> | null = this.userForm.get('password');
                if (descriptionFiled) {
                    descriptionFiled.patchValue(this.user.password);
                }

                const rolesFiled: AbstractControl<number[], number[]> | null = this.userForm.get('roles');
                if (rolesFiled) {
                    rolesFiled.patchValue(this.user.roles.map((role: Role) => {
                        return role.id;
                    }));
                }

                if (!this.user.updatable) {
                    this.userForm.disable();
                }
            }

            this.isLoading = false;
        });
    }

    protected isSelectedRole(role: Role): boolean {
        const rolesFiled: AbstractControl<number[], number[]> | null = this.userForm.get('roles');
        if (rolesFiled) {
            return rolesFiled.value.filter((selectedRoleId: number) => {
                return selectedRoleId === role.id;
            }).length > 0;
        } else {
            return false;
        }
    }

    protected toggleRole(role: Role): void {
        const rolesFiled: AbstractControl<number[], number[]> | null = this.userForm.get('roles');
        if (rolesFiled) {
            if (this.isSelectedRole(role)) {
                rolesFiled.patchValue(rolesFiled.value.filter((selectedRoleId: number) => {
                    return selectedRoleId !== role.id;
                }));
            } else {
                rolesFiled.patchValue([...rolesFiled.value, role.id]);
            }
        }
    }

    protected submit(): void {
        if (this.user) {
            this.userService.update(this.user.id, this.userForm.value).subscribe((user: User) => {
                this.toastService.show('COMMONS.SUCCESS');
                this.dialogRef.close(user);
            });
        } else {
            this.userService.create(this.userForm.value).subscribe((user: User) => {
                this.toastService.show('COMMONS.SUCCESS');
                this.dialogRef.close(user);
            });
        }
    }

}
