import {Pipe, PipeTransform} from '@angular/core';
import {EnhancementAudio} from "../../../../model/data/persist/jpa/entity/enhancement/enhancement-audio";
import {EnhancementMedia} from "../../../../model/data/persist/jpa/entity/enhancement/enhancement-media";

@Pipe({
    name: 'mediaToAudio'
})
export class MediaToAudioPipe implements PipeTransform {

    transform(media: EnhancementMedia): EnhancementAudio {
        return media as EnhancementAudio;
    }

}
