import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LyricsLine} from "../../../../../../model/data/persist/jpa/entity/audio";
import KeenSlider, {KeenSliderInstance} from "keen-slider";

import {Subscription, timer} from "rxjs";
import {AudioPlayerService, PlaybackTime} from '../audio-player/audio-player.service';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {EnhancementAudio} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-audio";

@Component({
    selector: 'cs-audio-player-lyrics',
    standalone: false,
    templateUrl: './audio-player-lyrics.component.html',
    styleUrls: ['./audio-player-lyrics.component.scss']
})
export class AudioPlayerLyricsComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild("sliderRef", {static: true})
    private sliderRef: ElementRef<HTMLElement> | undefined;
    private slider: KeenSliderInstance | undefined;
    private readonly mutationObserver: MutationObserver = new MutationObserver((mutations: MutationRecord[]) => {
        if (this.slider) {
            this.slider.update(this.slider.options);
        }
    });
    private readonly resizeObserver: ResizeObserver = new ResizeObserver(() => {
        if (this.slider) {
            this.slider.update(this.slider.options);
        }
    });

    private scrollPauseTimeout: number = 3000;
    private scrollPauseTimerSubscription: Subscription | undefined;

    protected scrollPause: boolean = false;

    protected currentSlideIndex: number = 0;

    protected audio: EnhancementAudio | undefined;

    constructor(
        private audioPlayerService: AudioPlayerService
    ) {
        this.audioPlayerService.onPlayingAudioChanged().pipe(takeUntilDestroyed()).subscribe((audio: EnhancementAudio | undefined) => {
            this.audio = audio;
        });
        this.audioPlayerService.onPlaybackTimeChanged().pipe(takeUntilDestroyed()).subscribe((playbackTime: PlaybackTime) => {
            if (!this.scrollPause) {
                if (this.audio) {
                    for (let i: number = this.audio.lyrics.lines.length - 1; i >= 0; i--) {
                        const line: LyricsLine = this.audio.lyrics.lines[i];
                        if (line.time / 1000 <= playbackTime.time) {
                            if (this.slider) {
                                if (this.currentSlideIndex !== i) {
                                    this.slider.moveToIdx(i);
                                }
                            }
                            break;
                        }
                    }
                }
            }
        });
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        if (this.sliderRef) {
            this.slider = new KeenSlider(this.sliderRef.nativeElement, {
                loop: false,
                vertical: true,
                initial: this.currentSlideIndex,
                slides: {
                    origin: "center",
                    perView: 10
                },
                created: () => {
                    if (this.sliderRef) {
                        this.mutationObserver.observe(this.sliderRef.nativeElement, {
                            childList: true,
                        });
                        this.resizeObserver.observe(this.sliderRef.nativeElement);
                    }
                },
                destroyed: () => {
                    this.mutationObserver.disconnect();
                    this.resizeObserver.disconnect();
                },
                slideChanged: (instance: KeenSliderInstance) => {
                    this.currentSlideIndex = instance.track.details.rel;
                }
            }, [(slider: KeenSliderInstance) => {
                slider.on("dragStarted", () => {
                    if (this.scrollPauseTimerSubscription) {
                        this.scrollPauseTimerSubscription.unsubscribe();
                    }
                });
                slider.on('dragChecked', () => {
                    this.scrollPause = true
                });
                slider.on("dragEnded", () => {
                    this.scrollPauseTimerSubscription = timer(this.scrollPauseTimeout).subscribe(() => {
                        this.scrollPause = false;
                    });
                });
            }]);
        }
    }

    ngOnDestroy(): void {
        if (this.slider) {
            this.slider.destroy();
        }
    }

    protected onMoveToCurrentLyricsLine() {
        if (this.scrollPause) {
            if (this.audio) {
                this.audioPlayerService.seek(this.audio.lyrics.lines[this.currentSlideIndex].time / 1000);
            }
        }
    }

}
