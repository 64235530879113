import {Component} from '@angular/core';
import {CsCommonsModule} from "../../../core/module/commons/cs.commons.module";

@Component({
    selector: 'cs-home-video-page',
    standalone: true,
    templateUrl: './home-video-page.component.html',
    styleUrls: ['./home-video-page.component.scss'],
    imports: [
        CsCommonsModule
    ]
})
export class HomeVideoPageComponent {
}
