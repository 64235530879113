import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ImageType, ImageTypeAspectRatioTable} from "../../../../../../model/data/persist/jpa/entity/image";
import {PermissionMode} from "../../../../../../service/data/permission-mode";
import {ImageResolution} from "../../../../../../model/data/persist/jpa/entity/image-file";
import {EnhancementMedia} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-media";

@Component({
    selector: 'cs-media-grid-viewer',
    standalone: false,
    templateUrl: './media-grid-viewer.component.html',
    styleUrls: ['./media-grid-viewer.component.scss']
})
export class MediaGridViewerComponent<Media extends EnhancementMedia> {
    protected readonly ImageResolution = ImageResolution;
    protected readonly ImageType = ImageType;
    protected readonly ImageTypeAspectRatioTable = ImageTypeAspectRatioTable;

    protected selectedMediaIds: number[] = [];

    @Input()
    public permissionMode: PermissionMode = PermissionMode.God;

    @Input()
    public imageType: ImageType = ImageType.Image;

    @Input()
    public medias: Media[] = [];
    @Output()
    public mediaClick: EventEmitter<Media> = new EventEmitter<Media>();

    @Input()
    public selectable: boolean = false;
    @Input()
    public selectableSingle: boolean = false;
    @Output()
    public selectedMediasChange: EventEmitter<Media[]> = new EventEmitter<Media[]>();

    protected onMediaClick(media: Media): void {
        if (this.selectable) {
            if (this.selectableSingle) {
                if (this.selectedMediaIds.includes(media.id)) {
                    this.selectedMediaIds = [];
                } else {
                    this.selectedMediaIds = [media.id];
                }
            } else {
                if (this.selectedMediaIds.includes(media.id)) {
                    this.selectedMediaIds = this.selectedMediaIds.filter((selectedImageId) => {
                        return selectedImageId !== media.id
                    });
                } else {
                    this.selectedMediaIds.push(media.id);
                }
            }

            this.selectedMediasChange.next(this.medias.filter((m: Media) => {
                return this.selectedMediaIds.includes(m.id);
            }));
        }

        this.mediaClick.next(media);
    }

}
