import {Component} from '@angular/core';
import {EnhancementImage} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-image";
import {MediaViewerComponent} from "../../media/media-viewer/media-viewer.component";

@Component({
    selector: 'cs-image-viewer',
    standalone: false,
    templateUrl: '../../media/media-viewer/media-viewer.component.html',
    styleUrls: ['../../media/media-viewer/media-viewer.component.scss']
})
export class ImageViewerComponent extends MediaViewerComponent<EnhancementImage> {

}
