import {Component, inject, OnInit} from '@angular/core';
import {RoleService} from '../../../../core/service/data/god/role.service';
import {SortablePagination} from '../../../../core/model/commons/sortablePagination';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Page, PageContent} from '../../../../core/model/commons/page';
import {Role} from '../../../../core/model/data/persist/jpa/entity/role';
import {PermissionName} from '../../../../core/model/data/persist/jpa/entity/permission';
import {MatCardModule} from "@angular/material/card";
import {CommonModule} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {ToastService} from "../../../../core/module/component/toast/toast.service";
import {MePageComponentService} from "../../me-page.component.service";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {CsCommonsModule} from "../../../../core/module/commons/cs.commons.module";
import {DialogService} from "../../../../core/module/component/dialog/dialog.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxPermissionsService} from "ngx-permissions";
import {MeRoleEditDialogComponent} from "../dialog/me-role-edit-dialog/me-role-edit-dialog.component";

@Component({
    selector: 'cs-me-role-list-page',
    standalone: true,
    templateUrl: './me-role-list-page.component.html',
    styleUrls: ['./me-role-list-page.component.scss'],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        CsCommonsModule,
    ]
})
export class MeRoleListPageComponent implements OnInit {
    protected readonly PermissionName = PermissionName;

    private pagination: SortablePagination = new SortablePagination(0, 25);
    private page: Page<Role> | undefined;

    protected keyword: FormControl = inject(FormBuilder).control('', []);
    protected pageContent: PageContent<Role> = new PageContent<Role>((role: Role) => {
        return role.id;
    });

    constructor(
        protected mePageComponentService: MePageComponentService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private permissionsService: NgxPermissionsService,
        private toastService: ToastService,
        private dialogService: DialogService,
        private roleService: RoleService,
    ) {
        this.mePageComponentService.onScrollToBottom().pipe(takeUntilDestroyed()).subscribe(() => {
            this.loadNext();
        });
    }

    ngOnInit(): void {
        this.loadNext(true);
    }

    protected loadNext(reset: boolean = false): void {
        if (reset) {
            this.pagination.reset();
            this.page = undefined;
            this.pageContent.reset();
        }

        if (!this.page || this.page && !this.page.last) {
            if (this.keyword.value) {
                this.roleService.search(this.keyword.value, this.pagination).subscribe((page: Page<Role>) => {
                    this.pagination.page++;

                    this.page = page;
                    this.pageContent.addAll(this.page.content);
                });
            } else {
                this.roleService.page(this.pagination).subscribe((page: Page<Role>) => {
                    this.pagination.page++;

                    this.page = page;
                    this.pageContent.addAll(this.page.content);
                });
            }
        }
    }

    protected onCreate(): void {
        this.dialogService.open<MeRoleEditDialogComponent, Role>(MeRoleEditDialogComponent).afterClosed().subscribe((role: Role | undefined) => {
            if (role) {
                this.pageContent.add(role);
            }
        });
    }

    protected onEdit(role: Role): void {
        this.dialogService.open<MeRoleEditDialogComponent, Role>(MeRoleEditDialogComponent, {
            role: role
        }).afterClosed().subscribe((role: Role | undefined) => {
            if (role) {
                this.pageContent.update(role);
            }
        });
    }

    protected onDelete(role: Role): void {
        this.dialogService.confirm().afterClosed().subscribe((result: boolean | undefined) => {
            if (result) {
                this.roleService.delete(role.id).subscribe(() => {
                    this.pageContent.remove(role);
                });
            }
        });
    }

}
