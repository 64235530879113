<mat-card class="w-full h-full" *ngIf="media">
    <mat-card-header class="w-full flex-grow-0 flex flex-col justify-start items-stretch">
        <div class="w-full flex flex-row justify-start items-center" [style.height.px]="42">
            <span class="inline-flex flex-row justify-start items-center" [style.font-size.rem]="1.25"><strong>{{ media.name }}</strong></span>
        </div>
        <div class="w-full flex flex-row justify-start items-stretch" [style.height.px]="48">
            <div class="flex-grow-0 h-full flex flex-row justify-start items-center">
                <div class="flex-grow-0 flex flex-col justify-start items-start">
                    <cs-enhancement-avatar class="w-auto h-auto" [user]="media.enhancementUser"></cs-enhancement-avatar>
                </div>
                <div class="flex-grow-0 h-full ms-1 inline-flex flex-col justify-start items-stretch" [style.height.px]="40">
                    <div class="flex-grow-0 inline-flex flex-row justify-start items-center" [style.height.px]="24">
                        <cs-username class="w-auto h-auto" [user]="media.enhancementUser"></cs-username>
                    </div>
                    <div class="flex-grow inline-flex flex-row justify-start items-end">
                        <span class="inline-flex flex-row justify-start items-center" [style.font-size.rem]="0.75">{{ media.enhancementUser.likedCount }}{{ 'COMMONS.FOLLOWED' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="flex-grow h-full w-0 flex flex-row justify-end items-center">
                <button class="me-1" mat-flat-button [ngClass]="media.enhancementUser.likedByCurrentUser ? 'active' : ''" *ngIf="me && media.enhancementUser.id !== me.id">
                    {{ (media.enhancementUser.likedByCurrentUser ? 'COMMONS.UNSUBSCRIBE' : 'COMMONS.SUBSCRIBE') | translate}}
                </button>
                <button class="mx-1" mat-flat-button (click)="onToggleLikeSubject()">
                    <mat-icon class="text-red-600" [ngClass]="media.likedByCurrentUser ? 'active' : ''" fontIcon="favorite"></mat-icon>{{ media.likedCount }}
                </button>
                <button class="ms-1" mat-flat-button (click)="onUpdateSubjectCollection()">
                    <mat-icon class="text-orange-400" [ngClass]="media.likedByCurrentUser ? 'active' : ''" fontIcon="kid_star"></mat-icon>{{ media.collectedCount }}
                </button>
            </div>
        </div>
    </mat-card-header>
    <mat-card-header class="w-full flex-grow-0 flex flex-col justify-start items-stretch">
        <div class="w-full flex flex-row justify-start items-center">
            <span class="inline-flex flex-row justify-start items-center" [style.font-size.rem]="0.875">{{ media.description }}</span>
        </div>
        <div class="w-full flex flex-row justify-between items-center">
            <span class="inline-flex flex-row justify-start items-center" [style.font-size.rem]="0.75">{{ media.views | count }}{{ 'COMMONS.VIEWS' | translate }}</span>
            <span class="inline-flex flex-row justify-start items-center" [style.font-size.rem]="0.75">{{ 'Core.Component.Media.Comment.CommentCard.PUBLISH_TIME' | translate }} {{ media.createTime | date: 'yyyy-MM-dd HH:mm:ss' }}</span>
        </div>
    </mat-card-header>
    <mat-card-content class="w-full flex-grow h-0 p-0" csScrollTracker (scrollToBottom)="loadNextBrowserComment()">
        <div class="w-full px-2 flex flex-row justify-start items-center" [style.height.px]="36">
            <span class="inline-flex flex-row justify-start items-center" [style.font-size.rem]="0.875"><strong>{{ 'Core.Component.Media.Comment.CommentCard.MY_COMMENT_COUNT' | translate: { value: myCommentPageContent.size()} }}</strong></span>
        </div>
        <div class="w-full" cdkListbox>
            @for (comment of myCommentPageContent.values(); track comment.id) {
                <div class="w-full flex-grow-0 p-2 flex flex-row justify-start items-stretch">
                    <div class="flex-grow-0 flex flex-row justify-start items-start">
                        <cs-enhancement-avatar class="w-auto h-auto" [user]="comment.enhancementUser"></cs-enhancement-avatar>
                    </div>
                    <div class="flex-grow ms-1 flex flex-col justify-start items-start">
                        <div class="w-full flex-grow-0 flex flex-row justify-start items-center overflow-hidden" [style.height.px]="20">
                            <cs-username class="w-auto h-auto" [user]="media.enhancementUser" [timestamp]="comment.createTime"></cs-username>
                        </div>
                        <div class="w-full flex-grow-0 flex flex-row justify-start items-center">
                            <span class="inline-flex flex-row justify-start items-center whitespace-pre-wrap" [style.font-size.rem]="0.875">{{ comment.value }}</span>
                        </div>
                        <div class="w-full flex-grow-0 flex flex-row justify-start items-center" [style.height.px]="26" [style.margin-top.px]="12">
                            <div class="flex-grow h-full w-0 flex flex-row justify-start items-center">
                                <div class="h-full flex flex-row justify-start items-center" [style.width.px]="64" [style.margin-left.rem]="-0.25">
                                    <button class="me-1 p-1" mat-icon-button (click)="onReplyComment(comment)">
                                        <mat-icon class="text-lg text-blue-600" [ngClass]="comment.repliedByCurrentUser ? 'active' : ''" fontIcon="add_comment"></mat-icon>
                                    </button>
                                    <span class="text-sm">{{ comment.repliedCount }}</span>
                                </div>
                                <div class="h-full flex flex-row justify-start items-center" [style.width.px]="64">
                                    <button class="me-1 p-1" mat-icon-button (click)="toggleLikeComment(comment)">
                                        <mat-icon class="text-lg text-red-600"  [ngClass]="comment.likedByCurrentUser ? 'active' : ''" fontIcon="favorite"></mat-icon>
                                    </button>
                                    <span class="text-sm">{{ comment.likedCount }}</span>
                                </div>
                            </div>
                            <div class="flex-grow-0 flex flex-row justify-end items-center">
                                <button mat-icon-button [matMenuTriggerFor]="moreMenu">
                                    <mat-icon fontIcon="more_vert"></mat-icon>
                                </button>
                                <mat-menu #moreMenu>
                                    <button mat-menu-item (click)="onDeleteComment(comment)"><mat-icon fontIcon="delete"></mat-icon>{{ 'COMMONS.DELETE' | translate }}</button>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
        <div class="w-full px-2 flex flex-row justify-start items-center" [style.height.px]="36" *ngIf="publicCommentPageContent.size()">
            <span class="inline-flex flex-row justify-start items-center" [style.font-size.rem]="0.875"><strong>{{ 'Core.Component.Media.Comment.CommentCard.COMMENT_COUNT' | translate: { value: publicCommentPageContent.size()} }}</strong></span>
        </div>
        <div class="w-full" cdkListbox>
            @for (comment of publicCommentPageContent.values(); track comment.id) {
                <div class="w-full flex-grow-0 p-2 flex flex-row justify-start items-stretch">
                    <div class="flex-grow-0 flex flex-row justify-start items-start">
                        <cs-enhancement-avatar class="w-auto h-auto" [user]="comment.enhancementUser"></cs-enhancement-avatar>
                    </div>
                    <div class="flex-grow ms-1 flex flex-col justify-start items-start">
                        <div class="w-full flex-grow-0 flex flex-row justify-start items-center overflow-hidden" [style.height.px]="20">
                            <cs-username class="w-auto h-auto" [user]="media.enhancementUser" [timestamp]="comment.createTime"></cs-username>
                        </div>
                        <div class="w-full flex-grow-0 flex flex-row justify-start items-center">
                            <span class="inline-flex flex-row justify-start items-center whitespace-pre-wrap" [style.font-size.rem]="0.875">{{ comment.value }}</span>
                        </div>
                        <div class="w-full flex-grow-0 flex flex-row justify-start items-center" [style.height.px]="26" [style.margin-top.px]="12">
                            <div class="flex-grow h-full w-0 flex flex-row justify-start items-center">
                                <div class="h-full flex flex-row justify-start items-center" [style.width.px]="64" [style.margin-left.rem]="-0.25">
                                    <button class="me-1 p-1" mat-icon-button (click)="onReplyComment(comment)">
                                        <mat-icon class="text-lg text-blue-600" [ngClass]="comment.repliedByCurrentUser ? 'active' : ''" fontIcon="add_comment"></mat-icon>
                                    </button>
                                    <span class="text-sm">{{ comment.repliedCount }}</span>
                                </div>
                                <div class="h-full flex flex-row justify-start items-center" [style.width.px]="64">
                                    <button class="me-1 p-1" mat-icon-button (click)="toggleLikeComment(comment)">
                                        <mat-icon class="text-lg text-red-600"  [ngClass]="comment.likedByCurrentUser ? 'active' : ''" fontIcon="favorite"></mat-icon>
                                    </button>
                                    <span class="text-sm">{{ comment.likedCount }}</span>
                                </div>
                            </div>
                            <div class="flex-grow-0 flex flex-row justify-end items-center">
                                <button mat-icon-button [matMenuTriggerFor]="moreMenu">
                                    <mat-icon fontIcon="more_vert"></mat-icon>
                                </button>
                                <mat-menu #moreMenu>
                                    <button mat-menu-item (click)="onDeleteComment(comment)"><mat-icon fontIcon="delete"></mat-icon>{{ 'COMMONS.DELETE' | translate }}</button>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    </mat-card-content>
    <mat-card-footer class="w-full flex-grow-0 p-2 flex flex-row justify-start items-stretch" *ngIf="me">
        <div class="flex-grow-0 flex flex-col justify-start items-start">
            <cs-avatar class="w-auto h-auto" [user]="me"></cs-avatar>
        </div>
        <div class="flex-grow ms-1 flex flex-col justify-start items-start">
            <mat-form-field class="w-full" appearance="outline">
                <textarea class="w-full" matInput rows="2" [(ngModel)]="commentForm.value"></textarea>
            </mat-form-field>
            <div class="w-full flex flex-row justify-end items-center" [style.height.px]="48">
                <button class="me-1" mat-flat-button [disabled]="!commentForm.value" (click)="commentForm.value = ''">{{ 'COMMONS.CANCEL' | translate }}</button>
                <button class="ms-1" mat-flat-button [disabled]="!commentForm.value" (click)="onComment()">{{ 'COMMONS.COMMENT' | translate }}</button>
            </div>
        </div>
    </mat-card-footer>
</mat-card>
