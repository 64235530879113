import {Route} from "@angular/router";
import {CustomizationResolver} from "../../core/resolver/customization.resolver";
import {MeResolver} from "../../core/resolver/me.resolver";
import {DetailPageComponent} from "./detail-page.component";
import {ImageDetailPageComponent} from "./image-detail-page/image-detail-page.component";
import {AudioDetailPageComponent} from "./audio-detail-page/audio-detail-page.component";
import {VideoDetailPageComponent} from "./video-detail-page/video-detail-page.component";
import {HomeBookDetailPageComponent} from "./book-detail-page/book-detail-page.component";
import {
    MediaCollectionDetailPageComponent
} from "./media-collection-detail-page/media-collection-detail-page.component";

export const detailPageRoutes: Route =  {
    path: '',
    component: DetailPageComponent,
    resolve: [
        CustomizationResolver,
        MeResolver
    ],
    children: [
        {
            path: 'image/:id',
            component: ImageDetailPageComponent
        },
        {
            path: 'audio/:id',
            component: AudioDetailPageComponent
        },
        {
            path: 'video/:id',
            component: VideoDetailPageComponent
        },
        {
            path: 'book/:id',
            component: HomeBookDetailPageComponent
        },
        {
            path: 'media-collection/:id',
            component: MediaCollectionDetailPageComponent
        },
    ]
};
