import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {ConfirmDialogComponent} from "./confirm-dialog/confirm-dialog.component";
import {DialogService} from "./dialog.service";
import {ToastService} from "../toast/toast.service";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
    ],
    declarations: [
        ConfirmDialogComponent
    ],
    providers: [
        DialogService
    ]
})
export class CsDialogModule {

    static forRoot(): ModuleWithProviders<CsDialogModule> {
        return {
            ngModule: CsDialogModule,
            providers: [
                {
                    provide: ToastService
                }
            ]
        };
    }

}
