<mat-card class="w-full h-full" *ngIf="comment">
    <mat-card-header class="w-full flex-grow-0 flex flex-row justify-between items-center">
        <mat-icon ></mat-icon>{{ 'Core.Component.Media.Comment.Dialog.CommentReplyDialog.TITLE' | translate }}
    </mat-card-header>
    <mat-card-header class="w-full flex-grow-0 p-2 flex flex-row justify-start items-stretch">
        <div class="flex-grow-0 flex flex-row justify-start items-start">
            <cs-avatar class="w-auto h-auto" [user]="comment.enhancementUser"></cs-avatar>
        </div>
        <div class="flex-grow ms-1 flex flex-col justify-start items-start">
            <div class="w-full flex-grow-0 flex flex-row justify-start items-center overflow-hidden" [style.height.px]="20">
                <cs-username class="w-auto h-auto" [user]="comment.enhancementUser" [timestamp]="comment.createTime"></cs-username>
            </div>
            <div class="w-full flex-grow-0 flex flex-row justify-start items-center">
                <span class="inline-flex flex-row justify-start items-center text-pre" [style.font-size.rem]="0.875">{{ comment.value }}</span>
            </div>
            <div class="w-full flex-grow-0 flex flex-row justify-start items-center" [style.height.px]="26" [style.margin-top.px]="12">
                <div class="h-full flex flex-row justify-start items-center" [style.width.px]="64">
                    <mat-icon class="m-0"></mat-icon>
                    <span class="px-1" [ngClass]="comment.repliedByCurrentUser ? 'text-info' : 'text-secondary'" *ngIf="comment.repliedCount">{{ comment.repliedCount }}</span>
                </div>
                <div class="h-full flex flex-row justify-start items-center" [style.width.px]="64">
                    <mat-icon class="m-0"></mat-icon>
                    <span class="px-1" [ngClass]="comment.likedByCurrentUser ? 'text-danger' : 'text-secondary'" *ngIf="comment.likedCount">{{ comment.likedCount }}</span>
                </div>
            </div>
        </div>
    </mat-card-header>
    <mat-card-content class="w-full flex-grow h-0 p-0" csScrollTracker (scrollToBottom)="loadNext()">
        <div class="w-full px-2 flex flex-row justify-start items-center" [style.height.px]="36" *ngIf="myCommentReplyPageContent.size()">
            <span class="inline-flex flex-row justify-start items-center" [style.font-size.rem]="0.875"><strong>{{ 'Core.Component.Media.Comment.Dialog.CommentReplyDialog.MY_COMMENT_REPLY_COUNT' | translate: { value: myCommentReplyPageContent.size()} }}</strong></span>
        </div>
        <div class="w-full flex flex-col justify-start items-stretch" cdkListbox>
            @for (myCommentReply of myCommentReplyPageContent.values(); track myCommentReply.id) {
                <div class="w-full flex-grow-0 p-2 flex flex-row justify-start items-stretch">
                    <div class="flex-grow-0 flex flex-row justify-start items-start">
                        <cs-avatar class="w-auto h-auto" [user]="myCommentReply.simpleUser"></cs-avatar>
                    </div>
                    <div class="flex-grow ms-1 flex flex-col justify-start items-start">
                        <div class="w-full flex-grow-0 flex flex-row justify-start items-center overflow-hidden" [style.height.px]="20">
                            <cs-username class="w-auto h-auto" [user]="myCommentReply.simpleUser" [timestamp]="myCommentReply.createTime"></cs-username>
                        </div>
                        <div class="w-full flex-grow-0 flex flex-row justify-start items-center">
                            <span class="inline-flex flex-row justify-start items-center text-pre" [style.font-size.rem]="0.875">{{ myCommentReply.value }}</span>
                        </div>
                    </div>
                </div>
            }
        </div>
        <div class="w-full px-2 flex flex-row justify-start items-center" [style.height.px]="36" *ngIf="publicCommentReplyPageContent.size()">
            <span class="inline-flex flex-row justify-start items-center" [style.font-size.rem]="0.875"><strong>{{ 'Core.Component.Media.Comment.Dialog.CommentReplyDialog.COMMENT_REPLY_COUNT' | translate: { value: publicCommentReplyPageContent.size()} }}</strong></span>
        </div>
        <div class="w-full flex flex-col justify-start items-stretch" cdkListbox>
            @for (commentReply of myCommentReplyPageContent.values(); track commentReply.id) {
                <div class="w-full flex-grow-0 p-2 flex flex-row justify-start items-stretch">
                    <div class="flex-grow-0 flex flex-row justify-start items-start">
                        <cs-avatar class="w-auto h-auto" [user]="commentReply.simpleUser"></cs-avatar>
                    </div>
                    <div class="flex-grow ms-1 flex flex-col justify-start items-start">
                        <div class="w-full flex-grow-0 flex flex-row justify-start items-center overflow-hidden" [style.height.px]="20">
                            <cs-username class="w-auto h-auto" [user]="commentReply.simpleUser" [timestamp]="commentReply.createTime"></cs-username>
                        </div>
                        <div class="w-full flex-grow-0 flex flex-row justify-start items-center">
                            <span class="inline-flex flex-row justify-start items-center text-pre" [style.font-size.rem]="0.875">{{ commentReply.value }}</span>
                        </div>
                    </div>
                </div>
            }
        </div>
    </mat-card-content>
    <mat-card-footer class="w-full flex-grow-0 p-2 flex flex-row justify-start items-stretch" *ngIf="me">
        <div class="flex-grow-0 flex flex-col justify-start items-start">
            <cs-avatar class="w-auto h-auto" [user]="me"></cs-avatar>
        </div>
        <div class="flex-grow ms-1 flex flex-col justify-start items-start">
            <mat-form-field class="w-full" appearance="outline">
                <textarea class="w-full" matInput rows="2" [(ngModel)]="commentReplyForm.value"></textarea>
            </mat-form-field>
            <div class="w-full flex flex-row justify-end items-center" [style.height.px]="48">
                <button class="me-1" mat-flat-button (click)="onClose()">{{ 'COMMONS.CANCEL' | translate }}</button>
                <button class="ms-1" mat-flat-button [disabled]="!commentReplyForm.value" (click)="onReply()">{{ 'COMMONS.REPLY' | translate }}</button>
            </div>
        </div>
    </mat-card-footer>
</mat-card>
