import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SortablePagination} from "../../../model/commons/sortablePagination";
import {Page} from "../../../model/commons/page";
import {SortMethod} from "../../../model/sort-method";
import {Service} from '../service';
import {EnhancementAudio} from "../../../model/data/persist/jpa/entity/enhancement/enhancement-audio";
import {Duration} from "../../../model/data/utils/time-range";

@Injectable({
    providedIn: 'root'
})
export class PublicAudioService {

    constructor(
        private http: HttpClient
    ) {
    }

    page(sortMethod: SortMethod, pagination: SortablePagination): Observable<Page<EnhancementAudio>> {
        return this.http.get<Page<EnhancementAudio>>(`${Service.Media.prefixUrl}/public/media/audio${pagination.toRequestParameter()}&sortMethod=${sortMethod}`);
    }

    pageHottest(pagination: SortablePagination, duration?: Duration): Observable<Page<EnhancementAudio>> {
        if (duration) {
            return this.http.get<Page<EnhancementAudio>>(`${Service.Media.prefixUrl}/public/media/audio/hottest${pagination.toRequestParameter()}&duration=${duration}`);
        } else {
            return this.http.get<Page<EnhancementAudio>>(`${Service.Media.prefixUrl}/public/media/audio/hottest${pagination.toRequestParameter()}`);
        }
    }

    load(id: number): Observable<EnhancementAudio> {
        return this.http.get<EnhancementAudio>(`${Service.Media.prefixUrl}/public/media/audio/${id}`);
    }

    pageRecommendation(pagination: SortablePagination): Observable<Page<EnhancementAudio>> {
        return this.http.get<Page<EnhancementAudio>>(`${Service.Search.prefixUrl}/public/media/audio/recommendation${pagination.toRequestParameter()}`);
    }

}
