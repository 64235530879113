import {Component} from '@angular/core';
import {CsCommonsModule} from "../../core/module/commons/cs.commons.module";

@Component({
    selector: 'cs-detail-page',
    standalone: true,
    templateUrl: './detail-page.component.html',
    styleUrl: './detail-page.component.scss',
    imports: [
        CsCommonsModule,
    ]
})
export class DetailPageComponent {

}
