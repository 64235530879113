import {Media} from "./media";
import {Image} from "./image";
import {JpaAuditIdentifiable4Long} from "../jpa-audit-identifiable-4-long";

export enum BookType {
    Level_1 = 'Level_1',
    Level_2 = 'Level_2',
    Level_3 = 'Level_3',
    Level_4 = 'Level_4',
    Level_5 = 'Level_5'
}

export enum BookName {
    DEFAULT_BOOK = 'DEFAULT_BOOK'
}

export const BookNames: string[] = Object.values(BookName);

export interface BookIndex extends JpaAuditIdentifiable4Long {
    name: string;
    description: string;
    root: boolean;
    leaf: boolean;
    layer: number;
    parent: BookIndex | undefined;
    deletable: boolean;
    updatable: boolean;
}

export interface Book extends Media {
    type: BookType;

    coverImage: Image;

    bookIndex: BookIndex;

}
