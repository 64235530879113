import {Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ImageBackgroundSize} from '../../../../../commons/component/image/image-background/image-background.component';
import {ImageResolution} from '../../../../../../model/data/persist/jpa/entity/image-file';
import {BookIndex, BookName} from "../../../../../../model/data/persist/jpa/entity/book";
import {MeBookService} from "../../../../../../service/data/me/me-book.service";
import {PublicBookService} from "../../../../../../service/data/public/public-book.service";
import {fadeInExpandOnEnterAnimation, fadeOutCollapseOnLeaveAnimation} from "angular-animations";
import {PermissionMode} from "../../../../../../service/data/permission-mode";
import {EnhancementArticle} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-article";
import {EnhancementBook} from "../../../../../../model/data/persist/jpa/entity/enhancement/enhancement-book";

@Component({
    selector: 'cs-book-player',
    standalone: false,
    templateUrl: './book-player.component.html',
    styleUrls: ['./book-player.component.scss'],
    animations: [
        fadeInExpandOnEnterAnimation({
            duration: 256
        }),
        fadeOutCollapseOnLeaveAnimation({
            duration: 128
        })
    ]
})
export class BookPlayerComponent implements OnInit, OnDestroy {
    protected readonly ImageBackgroundSize = ImageBackgroundSize;
    protected readonly ImageResolution = ImageResolution;
    protected readonly BookName = BookName;

    private readonly resizeObserver: ResizeObserver = new ResizeObserver(() => {
        this.onCloseMenu();
    });

    protected menuOpened: boolean = false;
    protected bookIndexesLayers: BookIndex[][] = [];
    protected selectedBookIndexes: BookIndex[] = [];
    protected articles: EnhancementArticle[] = [];
    protected selectedArticle: EnhancementArticle | undefined;

    @Input()
    public permissionMode: PermissionMode = PermissionMode.God;
    @Input()
    public book: EnhancementBook | undefined;

    constructor(
        private elementRef: ElementRef,
        private publicBookService: PublicBookService,
        private meBookService: MeBookService
    ) {
    }

    ngOnInit(): void {
        if (this.book) {
            this.bookIndexesLayers.push([this.book.bookIndex]);
            this.selectedBookIndexes.push(this.book.bookIndex);
            this.listIndex(this.book.bookIndex);
        }

        this.resizeObserver.observe(this.elementRef.nativeElement);
    }

    ngOnDestroy(): void {
        this.resizeObserver.disconnect();
    }

    protected listIndex(bookIndex: BookIndex): void {
        if (this.book) {
            if (bookIndex.leaf) {
                this.listArticle(bookIndex);
            } else {
                if (this.permissionMode === PermissionMode.God) {
                    //TODO
                } else if (this.permissionMode === PermissionMode.Me) {
                    this.meBookService.listIndex(this.book.id, bookIndex.id).subscribe((bookIndexes: BookIndex[]) => {
                        this.bookIndexesLayers.splice(bookIndex.layer + 1, 1, bookIndexes);
                        if (this.bookIndexesLayers[bookIndex.layer + 1] && this.bookIndexesLayers[bookIndex.layer + 1].length) {
                            const nextBookIndex: BookIndex = this.bookIndexesLayers[bookIndex.layer + 1][0];
                            this.selectedBookIndexes.splice(bookIndex.layer + 1, 1, nextBookIndex);
                            this.listIndex(nextBookIndex);
                        }
                    });
                } else if (this.permissionMode === PermissionMode.Public) {
                    this.publicBookService.listIndex(this.book.id, bookIndex.id).subscribe((bookIndexes: BookIndex[]) => {
                        this.bookIndexesLayers.splice(bookIndex.layer + 1, 1, bookIndexes);
                        if (this.bookIndexesLayers[bookIndex.layer + 1] && this.bookIndexesLayers[bookIndex.layer + 1].length) {
                            const nextBookIndex: BookIndex = this.bookIndexesLayers[bookIndex.layer + 1][0];
                            this.selectedBookIndexes.splice(bookIndex.layer + 1, 1, nextBookIndex);
                            this.listIndex(nextBookIndex);
                        }
                    });
                }
            }
        }
    }

    protected listArticle(bookIndex: BookIndex): void {
        if (this.book) {
            if (bookIndex.leaf) {
                if (this.permissionMode === PermissionMode.God) {
                    //TODO
                } else if (this.permissionMode === PermissionMode.Me) {
                    this.meBookService.listArticle(this.book.id, bookIndex.id).subscribe((articles: EnhancementArticle[]) => {
                        this.articles = articles;
                        if (this.articles.length) {
                            this.selectedArticle = this.articles[0];
                        }
                    });
                } else if (this.permissionMode === PermissionMode.Public) {
                    this.publicBookService.listArticle(this.book.id, bookIndex.id).subscribe((articles: EnhancementArticle[]) => {
                        this.articles = articles;
                        if (this.articles.length) {
                            this.selectedArticle = this.articles[0];
                        }
                    });
                }
            }
        }
    }

    protected hasNextArticle(): boolean {
        if (this.selectedArticle) {
            return this.articles.indexOf(this.selectedArticle) < this.articles.length - 1
        } else {
            return false;
        }
    }

    protected onNextArticle(): void {
        if (this.hasNextArticle()) {
            if (this.selectedArticle) {
                this.selectedArticle = this.articles[this.articles.indexOf(this.selectedArticle) + 1];
            }
        }
    }

    protected hasPreviousArticle(): boolean {
        if (this.selectedArticle) {
            return this.articles.indexOf(this.selectedArticle) > 0
        } else {
            return false;
        }
    }

    protected onPreviousArticle(): void {
        if (this.hasPreviousArticle()) {
            if (this.selectedArticle) {
                this.selectedArticle = this.articles[this.articles.indexOf(this.selectedArticle) - 1];
            }
        }
    }

    protected onOpenMenu(): void {
        this.menuOpened = true;
    }

    protected onCloseMenu(): void {
        this.menuOpened = false;
    }

}
