import {Directive, Input, TemplateRef} from '@angular/core';

@Directive({
    selector: '[csGridLayoutLeaveBlankZone]'
})
export class GridLayoutLeaveBlankZoneDirective {

    public readonly template: TemplateRef<any>;

    @Input()
    public span: number = 2;

    constructor(private templateRef: TemplateRef<any>) {
        this.template = this.templateRef;
    }

}
