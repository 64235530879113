import {Component, inject, Inject, OnInit} from '@angular/core';
import {PermissionService} from '../../../../../core/service/data/god/permission.service';
import {RoleService} from '../../../../../core/service/data/god/role.service';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Permission} from "../../../../../core/model/data/persist/jpa/entity/permission";
import {Role} from "../../../../../core/model/data/persist/jpa/entity/role";
import {AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CommonModule} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ToastService} from "../../../../../core/module/component/toast/toast.service";

@Component({
    selector: 'cs-me-role-edit-dialog',
    standalone: true,
    templateUrl: './me-role-edit-dialog.component.html',
    styleUrls: ['./me-role-edit-dialog.component.scss'],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule
    ]
})
export class MeRoleEditDialogComponent implements OnInit {
    protected isLoading: boolean = true;

    protected permissions: Permission[] = [];

    protected roleForm: FormGroup = inject(FormBuilder).group({
        name: ['', Validators.required],
        description: ['', Validators.required],
        permissions: [[]]
    });

    protected role: Role | undefined;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any = {},
        private dialogRef: MatDialogRef<MeRoleEditDialogComponent>,
        private translateService: TranslateService,
        private permissionService: PermissionService,
        private toastService: ToastService,
        private roleService: RoleService,
    ) {
        this.role = this.data.role;
    }

    ngOnInit(): void {
        if (this.role) {
            this.loadEditMode();
        } else {
            this.loadCreateMode();
        }
    }

    close(): void {
        this.dialogRef.close(false);
    }

    protected loadCreateMode(): void {
        this.permissionService.list().subscribe((permissions: Permission[]) => {
            this.permissions = permissions;

            this.isLoading = false;
        });
    }

    protected loadEditMode(): void {
        this.permissionService.list().subscribe((permissions: Permission[]) => {
            this.permissions = permissions;

            if (this.role) {
                const nameFiled: AbstractControl<string, string> | null = this.roleForm.get('name');
                if (nameFiled) {
                    nameFiled.patchValue(this.role.name);
                }

                const descriptionFiled: AbstractControl<string, string> | null = this.roleForm.get('description');
                if (descriptionFiled) {
                    descriptionFiled.patchValue(this.role.description);
                }

                const permissionsFiled: AbstractControl<number[], number[]> | null = this.roleForm.get('permissions');
                if (permissionsFiled) {
                    permissionsFiled.patchValue(this.role.permissions.map((permission: Permission) => {
                        return permission.id;
                    }));
                }

                if (!this.role.updatable) {
                    this.roleForm.disable();
                }
            }

            this.isLoading = false;
        });
    }

    protected isSelectedPermission(permission: Permission): boolean {
        const permissionsFiled: AbstractControl<number[], number[]> | null = this.roleForm.get('permissions');
        if (permissionsFiled) {
            return permissionsFiled.value.filter((selectedPermissionId: number) => {
                return selectedPermissionId === permission.id;
            }).length > 0;
        } else {
            return false;
        }
    }

    protected togglePermission(permission: Permission): void {
        const permissionsFiled: AbstractControl<number[], number[]> | null = this.roleForm.get('permissions');
        if (permissionsFiled) {
            if (this.isSelectedPermission(permission)) {
                permissionsFiled.patchValue(permissionsFiled.value.filter((selectedPermissionId: number) => {
                    return selectedPermissionId !== permission.id;
                }));
            } else {
                permissionsFiled.patchValue([...permissionsFiled.value, permission.id]);
            }
        }
    }

    protected submit(): void {
        if (this.role) {
            this.roleService.update(this.role.id, this.roleForm.value).subscribe((role: Role) => {
                this.toastService.show('COMMONS.SUCCESS');
                this.dialogRef.close(role);
            });
        } else {
            this.roleService.create(this.roleForm.value).subscribe((role: Role) => {
                this.toastService.show('COMMONS.SUCCESS');
                this.dialogRef.close(role);
            });
        }
    }

}
